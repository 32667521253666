// @flow

import * as R from "ramda";

import type {  ValidityObject } from "./types";
// import type { DataEntryFormOutput } from "../../types";
// import type { InputNode, ProcessingNode, RetentionNode } from "../../../types";
import { validationErrors, CALLOUT_NAME, CALLOUT_LABEL } from "./constants";

// Grab all datatypes associated on the nodes
// and compare them to the current calloutDataType.
// Return true if at least one node has a valid type.
export const getMultiTypeValidity = (
  nodes = {},
  dataType: string
) => {
  return R.compose(
    R.any(Boolean),
    R.map(val => R.any(R.includes(dataType), val)),
    R.uniq,
    R.flatten,
    R.values,
    R.map(node => node.dataTypes)
  )(nodes);
};

// Check if any input nodes have the same type as
// the current calloutDataType
export const validateInputNodes = (
  nodes = {},
  dataType: string
) => {
  return R.compose(
    R.any(Boolean),
    R.values,
    R.map(node => node.dataType === dataType)
  )(nodes);
};

// For each calloutDataType, Checks that:
// At least one input node matches callout datatype
// at least one processing node or one retention node matches callout type
export const calculateValidity = (
  calloutDataTypes: Array<string> = [],
  inputNodes,
  processingNodes,
  retentionNodes
) => {
  return (
    calloutDataTypes.length > 0 &&
    R.compose(
      R.all(Boolean),
      R.map(dataType => {
        const inputValidity = validateInputNodes(inputNodes, dataType);
        const pNodeValidity = getMultiTypeValidity(processingNodes, dataType);
        const rNodeValidity = getMultiTypeValidity(retentionNodes, dataType);
        return inputValidity && (pNodeValidity || rNodeValidity);
      })
    )(calloutDataTypes)
  );
};

export const getCalloutValidity = (
  values
): Array<ValidityObject> => {
  const { inputNodes, retentionNodes, processingNodes } = values;
  const calloutDataTypes = values.generalInfo.calloutDataTypes;
  const shouldCheckDataTypes =
    !R.isNil(calloutDataTypes) && !R.isEmpty(calloutDataTypes);
  const isValid = shouldCheckDataTypes
    ? calculateValidity(
        calloutDataTypes,
        inputNodes,
        processingNodes,
        retentionNodes
      )
    : true;
  const error = isValid ? "" : validationErrors.calloutValidity;

  return [
    {
      name: CALLOUT_NAME,
      label: CALLOUT_LABEL,
      isValid,
      error
    }
  ];
};
