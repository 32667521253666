import { SET_USER_INFO, SET_USERS, SET_USER_ORG } from "../actions/constant";

const initialState = {};

export function userInfoReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state,
                data: action.data
            };
        case SET_USERS:
            return {
                ...state,
                users: action.data
            }
        case SET_USER_ORG:
            return {
                ...state,
                userOrgInfo: action.data
            }
        default:
            return state;
    }
}