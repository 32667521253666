import {
  EDIT_GENERAL_INFO,
  EDIT_PRODUCT_INFO,
  EDIT_DATA_CENTERS,
  EDIT_INPUT_NODES,
  EDIT_PROCESSING_NODES,
  EDIT_RETENTION_NODES
} from "../actions/reviewAction";

const initialState = {};

export function editReviewData(state = initialState, action) {
  switch (action.type) {
    case EDIT_GENERAL_INFO:
      return {
          ...state,
        editGI: action.data
      };
    case EDIT_PRODUCT_INFO:
      return {
          ...state,
        editPI: action.data
      };
    case EDIT_DATA_CENTERS:
      return {
        ...state,
        editDC: action.data
      };
    case EDIT_INPUT_NODES:
      return {
        ...state,
        editIN: action.data
      };
    case EDIT_PROCESSING_NODES:
      return {
        ...state,
        editPN: action.data
      };
    case EDIT_RETENTION_NODES:
      return {
        ...state,
        editRN: action.data
      };
    default:
      return state;
  }
}
