export const ADD_DATA_CENTERS_INFO = "ADD_DATA_CENTERS_INFO";
export const SET_DATA_CENTERS_JSON_VALUE = "SET_DATA_CENTERS_JSON_VALUE";
import { IS_UPDATED } from "./constant";
import * as api from "../apis/datamaps.api";

export function addDataCentersInfo(data) {

  return async (dispatch) => {
    console.log("Calling Update data centers Api...")
    const payload = {
      "mapStatus": "draft",
      "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "modifiedDate": new Date(),
      "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "message": "Data Centers info data created",
      "dataCenters": data
    }
    const res = await api.updateDataCentersInfo(payload, window.MAP_DATA.datamapId);
    if (res && res.status === 200) {
      dispatch({
        type: ADD_DATA_CENTERS_INFO,
        data: data
      })
      dispatch({
        type: IS_UPDATED,
        data: true
      })
    }
  }
  /*return {
    type: ADD_DATA_CENTERS_INFO,
    data: data
  }; */
}

export function setDataCenterJsonValue(JsonValue) {
  return {
    type: SET_DATA_CENTERS_JSON_VALUE,
    data: JsonValue
  };
}