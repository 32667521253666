/* eslint-disable no-useless-escape */
// @flow

import React, { Component } from "react";
import "./index.scss";
import ProcessingNodesSvg from "../processingNodesSvg";
import { connect } from "react-redux";
import * as R from "ramda";
import Warning from "../svgIcons/warning";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import {
  editProcessingNodes,
  editInputNodes,
  editRetentionNodes,
  editGeneralInfo
} from "../../actions/reviewAction";
import { Link } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getUsersByOrgId } from "../../actions/userInfoAction";
import { addReviewers } from "../../actions/metaDataAction";

const CISCO = "0";
const CUSTOMER = "1";
const SUBPROCESSOR = "2";

class ReviewSection extends Component<any, any> {
  constructor(props) {
    super(props);
  }
  state = {
    productName: "",
    dataSheetVersion: "",
    dataSheetURL: "",
    date: "",
    policiesDescription: "",
    ciscoLocations: [],
    thirdPartyLocations: [],
    dataTypes: [],
    inputNodes: [],
    customerToSubprocessor: false,
    callout: false,
    calloutDataTypes: [],
    ciscoNodes: [],
    customernodes: [],
    subprocessornodes: [],
    processingnodes: [],
    retentionnodes: [],
    ciscoRetensionNodes: [],
    subprocessorRetensionNodes: [],
    dataCentersWarn: true,
    homeWarn: true,
    dataTypeWarn: true,
    userList: []
  };
  commaseperatedstring = () => {
    var commaseperatedstring = [];
    for (let i = 0; i < this.state.calloutDataTypes.length; i++) {
      let value = this.state.calloutDataTypes[i];
      if (this.state.dataTypes[value]) {
        commaseperatedstring.push(this.state.dataTypes[value].datatype);
      }
    }
    return commaseperatedstring.join(",");
  };
  decodeSvgString = value => {
    let decodedSvg = "";
    if (!R.isEmpty(value) && value) {
      decodedSvg = R.compose(
        decodeURIComponent,
        escape,
        atob
      )(value);
    }
    return decodedSvg;
  };

  textFieldProcessingNode = nodeCopy => {
    return (
      <div
        className="card-text"
        style={{
          fontSize: "14px",
          color: "#39393B",
          fontFamily: "CiscoSansTTRegular"
        }}
      >
        {nodeCopy}
      </div>
    );
  };

  addReviewers = (val) =>{
    console.log(val);
    this.props.addReviewers(val);

  }
  

  componentDidMount() {
    //this.props.onRef(this);
    const email = window.USER_INFO ? window.USER_INFO.email : ""
    this.props.getUsersByOrgId(email);
  }


  // static getDerivedStateFromProps(nextPros, prevState) {
  //   if (prevState.userList !== nextPros.users) {
  //     this.setState({
  //       userList: nextPros.users
  //     })
  //   }
  // }

  UNSAFE_componentWillMount() {
    const {
      generalInfo,
      productInfo,
      dataCenters,
      inputNodes,
      processingnodes,
      retentionnodes,
      users
    } = this.props;
    if (processingnodes) {
      this.setState({
        processingnodes: processingnodes,
        ciscoNodes: processingnodes.ciscoNodes,
        customernodes: processingnodes.customernodes,
        subprocessornodes: processingnodes.subprocessornodes,
        
      });
    }
    if (retentionnodes) {
      this.setState({
        retentionnodes: retentionnodes,
        ciscoRetensionNodes: retentionnodes.ciscoNodes,
        subprocessorRetensionNodes: retentionnodes.subprocessorNodes
      });
    }
    if (inputNodes) {
      let inputNode = [];
      for (let key in inputNodes) {
        inputNode.push(inputNodes[key]);
      }
      this.setState({
        inputNodes: inputNode
      });
    }
    if (generalInfo) {
      // let d = new Date();
      // let dd = String(d.getDate()).padStart(2, "0");
      // let mm = String(d.getMonth() + 1).padStart(2, "0");
      // let yyyy = d.getFullYear();
      // let dateformat = mm + "-" + dd + "-" + yyyy;
      console.log(generalInfo.date);
      // let dateformat = moment(new Date(generalInfo.date.replace(/-/g, '\/'))).format("MM/DD/YYYY")
      let dateformat = generalInfo.date.toString();

      this.setState({
        productName: generalInfo.productName,
        dataSheetVersion: generalInfo.dataSheetVersion,
        dataSheetURL: generalInfo.dataSheetURL,
        date: dateformat,

      });
    }
    if (productInfo) {
      this.setState({
        customerToSubprocessor: productInfo.contracts.customerToSubprocessor,
        callout: productInfo.callout,
        calloutCopy: productInfo.calloutCopy,
        calloutDataTypes: productInfo.calloutDataTypes,
        dataTypes: productInfo.dataTypes 
      })
    }
    if (dataCenters) {
      this.setState({
        policiesDescription: dataCenters.policiesDescription,
        ciscoLocations: dataCenters.ciscoLocations
          ? dataCenters.ciscoLocations
          : [],
        thirdPartyLocations: dataCenters.thirdPartyLocations
          ? dataCenters.thirdPartyLocations
          : []
      });
    }
    if (users) {
      this.setState({
        userList: users
      })

    }
  }

  mappingRetentionNodeContent = (mapData, controllerType) => {
    return (
      <>
        {mapData.map((data, index) => {
          let uniqindex = "Retension" + data.controller + data.timeline + index;
          return (
            <div
              style={{ position: "relative" }}
              key={index}
              onMouseEnter={() => {
                this.handleOnHover(uniqindex);
                if (data.controller === "0") {
                  document.getElementById(
                    "restention-Cisco-Edit"
                  ).style.display = "none";
                }
                if (data.controller === "2") {
                  document.getElementById(
                    "restention-Subprocessor-Edit"
                  ).style.display = "none";
                }
              }}
              onMouseLeave={() => {
                this.mouseOut(uniqindex);
                if (data.controller === "0") {
                  document.getElementById(
                    "restention-Cisco-Edit"
                  ).style.display = "flex";
                }
                if (data.controller === "2") {
                  document.getElementById(
                    "restention-Subprocessor-Edit"
                  ).style.display = "flex";
                }
              }}
            >
              {" "}
              <div className="padding15" key={index}>
                {this.generateRetentionNodeContent(
                  data.dataTypes,
                  data.nodeCopy
                )}
              </div>
              <div
                id={uniqindex}
                className="overlay-processingNode"
                style={{ position: "absolute" }}
                onClick={() => {
                  this.off(uniqindex);
                  this.props.editRetentionNodes({
                    node: controllerType,
                    nodeIndex: data.id
                  });
                }}
              >
                <div style={{ color: "white" }}>EDIT</div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  handleOnHover = index => {
    document.getElementById(index).style.display = "flex";
  };
  off = index => {
    document.getElementById(index).style.display = "none";
  };
  mouseOut = index => {
    document.getElementById(index).style.display = "none";
  };
  mappingProcessingNodeContent = (mapData, controllerType) => {
    return (
      <>
        {mapData.map((data, index) => {
          let uniqindex =
            "Processing" + data.controller + data.subcategory + index;
          return (
            <div
              style={{ position: "relative" }}
              key={index}
              onMouseEnter={() => {
                this.handleOnHover(uniqindex);
                if (data.controller === "1") {
                  document.getElementById(
                    "processsing-Customers-Edit"
                  ).style.display = "none";
                }
                if (data.controller === "0") {
                  document.getElementById(
                    "processsing-Cisco-Edit"
                  ).style.display = "none";
                }
                if (data.controller === "2") {
                  document.getElementById(
                    "processsing-Subprocessor-Edit"
                  ).style.display = "none";
                }
              }}
              onMouseLeave={() => {
                this.mouseOut(uniqindex);
                if (data.controller === "1") {
                  document.getElementById(
                    "processsing-Customers-Edit"
                  ).style.display = "flex";
                }
                if (data.controller === "0") {
                  document.getElementById(
                    "processsing-Cisco-Edit"
                  ).style.display = "flex";
                }
                if (data.controller === "2") {
                  document.getElementById(
                    "processsing-Subprocessor-Edit"
                  ).style.display = "flex";
                }
              }}
            >
              {" "}
              <div className="padding15">
                {this.generateProcessingNodeContent(
                  data.dataTypes,
                  data.accessors,
                  data.nodeCopy
                )}
              </div>
              <div
                id={uniqindex}
                className="overlay-processingNode"
                style={{ position: "absolute" }}
                onClick={() => {
                  this.off(uniqindex);
                  this.props.editProcessingNodes({
                    node: controllerType,
                    nodeIndex: data.id
                  });
                }}
              >
                <div style={{ color: "white" }}>EDIT</div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  generateProcessingNodeContent = (dataTypes, accessors, nodeCopy) => {
    return (
      <div>
        {this.getProcessingNodeDataType(dataTypes)}
        <ProcessingNodesSvg accessors={accessors} />
        {this.textFieldProcessingNode(nodeCopy)}
      </div>
    );
  };
  generateRetentionNodeContent = (dataTypes, nodeCopy) => {
    return (
      <>
        {this.getProcessingNodeDataType(dataTypes)}
        {this.textFieldProcessingNode(nodeCopy)}
      </>
    );
  };
  getCustomerProcessingNodes = () => {
    let { customerNodes } = this.state.processingnodes;
    if (customerNodes) {
      let admin = [...customerNodes];
      let provide = [...customerNodes];
      let service = [...customerNodes];
      let customeradmin = admin.filter((data, index) => {
        if (data.subcategory === "0") {
          data["id"] = index;
          return data;
        }
      });
      let customerprovide = provide.filter((data, index) => {
        if (data.subcategory === "1") {
          data["id"] = index;
          return data;
        }
      });
      let customerservice = service.filter((data, index) => {
        if (data.subcategory === "2") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <div
          className="content-input rectangle flex"
          id="processsing-Customers"
        >
          <div className="dataBox">
            {this.mappingProcessingNodeContent(customeradmin, CUSTOMER)}
          </div>

          <div className="dataBox">
            {this.mappingProcessingNodeContent(customerprovide, CUSTOMER)}
          </div>

          <div className="dataBox">
            {this.mappingProcessingNodeContent(customerservice, CUSTOMER)}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="rectangle-wrap review-input-fields"
          style={{
            fontStyle: "italic",
            padding: "25px",
            backgroundColor: "#FFF8DC",
            justifyContent: "center"
          }}
        >
          No data entered
        </div>
      );
    }
  };
  getCiscoProcessingNodes = () => {
    let { ciscoNodes } = this.state.processingnodes;
    if (ciscoNodes) {
      let admin = [...ciscoNodes];
      let provide = [...ciscoNodes];
      let service = [...ciscoNodes];
      let ciscoNodesadmin = admin.filter((data, index) => {
        if (data.subcategory === "0") {
          data["id"] = index;
          return data;
        }
      });
      let ciscoNodesprovide = provide.filter((data, index) => {
        if (data.subcategory === "1") {
          data["id"] = index;
          return data;
        }
      });
      let ciscoNodesservice = service.filter((data, index) => {
        if (data.subcategory === "2") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <div className="content-input rectangle flex" id="processsing-Cisco">
          <div className="dataBox">
            {this.mappingProcessingNodeContent(ciscoNodesadmin, CISCO)}
          </div>

          <div className="dataBox">
            {this.mappingProcessingNodeContent(ciscoNodesprovide, CISCO)}
          </div>

          <div className="dataBox">
            {this.mappingProcessingNodeContent(ciscoNodesservice, CISCO)}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="rectangle-wrap review-input-fields"
          style={{
            fontStyle: "italic",
            padding: "25px",
            backgroundColor: "#FFF8DC",
            justifyContent: "center"
          }}
        >
          No data entered
        </div>
      );
    }
  };
  getSubprocessorProcessingNodes = () => {
    let { subprocessorNodes } = this.state.processingnodes;
    if (subprocessorNodes) {
      let admin = [...subprocessorNodes];
      let provide = [...subprocessorNodes];
      let service = [...subprocessorNodes];
      let subprocessorNodesadmin = admin.filter((data, index) => {
        if (data.subcategory === "0") {
          data["id"] = index;
          return data;
        }
      });
      let subprocessorNodesprovide = provide.filter((data, index) => {
        if (data.subcategory === "1") {
          data["id"] = index;
          return data;
        }
      });
      let subprocessorNodesservice = service.filter((data, index) => {
        if (data.subcategory === "2") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <div
          className="content-input rectangle flex"
          id="processsing-Subprocessor"
        >
          <div className="dataBox">
            {this.mappingProcessingNodeContent(
              subprocessorNodesadmin,
              SUBPROCESSOR
            )}
          </div>

          <div className="dataBox">
            {this.mappingProcessingNodeContent(
              subprocessorNodesprovide,
              SUBPROCESSOR
            )}
          </div>

          <div className="dataBox">
            {this.mappingProcessingNodeContent(
              subprocessorNodesservice,
              SUBPROCESSOR
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="rectangle-wrap review-input-fields"
          style={{
            fontStyle: "italic",
            padding: "25px",
            backgroundColor: "#FFF8DC",
            justifyContent: "center"
          }}
        >
          No data entered
        </div>
      );
    }
  };

  getCiscoNoRetentionNodes = () => {
    let { ciscoNodes } = this.state.retentionnodes;
    if (ciscoNodes) {
      let noRetention = [...ciscoNodes];
      let ciscoNodesnoRetention = noRetention.filter((data, index) => {
        if (data.timeline === "0") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {ciscoNodesnoRetention.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                No Retention
              </div>
              {this.mappingRetentionNodeContent(ciscoNodesnoRetention, CISCO)}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getCiscoWeeksNodes = () => {
    let { ciscoNodes } = this.state.retentionnodes;
    if (ciscoNodes) {
      let weeks = [...ciscoNodes];
      let ciscoNodesWeeks = weeks.filter((data, index) => {
        if (data.timeline === "2") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {ciscoNodesWeeks.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Weeks
              </div>
              {this.mappingRetentionNodeContent(ciscoNodesWeeks, CISCO)}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getCiscoDaysNodes = () => {
    let { ciscoNodes } = this.state.retentionnodes;
    if (ciscoNodes) {
      let days = [...ciscoNodes];
      let ciscoNodesDays = days.filter((data, index) => {
        if (data.timeline === "1") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {ciscoNodesDays.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Days
              </div>
              {this.mappingRetentionNodeContent(ciscoNodesDays, CISCO)}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getCiscoMonthsNodes = () => {
    let { ciscoNodes } = this.state.retentionnodes;
    if (ciscoNodes) {
      let months = [...ciscoNodes];
      let ciscoNodesMonths = months.filter((data, index) => {
        if (data.timeline === "3") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {ciscoNodesMonths.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Months
              </div>
              {this.mappingRetentionNodeContent(ciscoNodesMonths, CISCO)}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getCiscoYearsNodes = () => {
    let { ciscoNodes } = this.state.retentionnodes;
    if (ciscoNodes) {
      let years = [...ciscoNodes];
      let ciscoNodesYears = years.filter((data, index) => {
        if (data.timeline === "4") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {ciscoNodesYears.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Years
              </div>
              {this.mappingRetentionNodeContent(ciscoNodesYears, CISCO)}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getCiscoByRequestNodes = () => {
    let { ciscoNodes } = this.state.retentionnodes;
    if (ciscoNodes) {
      let byRequest = [...ciscoNodes];
      let ciscoNodesByRequest = byRequest.filter((data, index) => {
        if (data.timeline === "5") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {ciscoNodesByRequest.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                By Request
              </div>
              {this.mappingRetentionNodeContent(ciscoNodesByRequest, CISCO)}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getSubprocessorNoRetentionNodes = () => {
    let { subprocessorNodes } = this.state.retentionnodes;
    if (subprocessorNodes) {
      let noRetention = [...subprocessorNodes];
      let subprocessorNodesnoRetention = noRetention.filter((data, index) => {
        if (data.timeline === "0") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {subprocessorNodesnoRetention.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                No Retention
              </div>
              {this.mappingRetentionNodeContent(
                subprocessorNodesnoRetention,
                SUBPROCESSOR
              )}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getSubprocessorWeeksNodes = () => {
    let { subprocessorNodes } = this.state.retentionnodes;
    if (subprocessorNodes) {
      let weeks = [...subprocessorNodes];
      let subprocessorNodesWeeks = weeks.filter((data, index) => {
        if (data.timeline === "2") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {subprocessorNodesWeeks.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Weeks
              </div>
              {this.mappingRetentionNodeContent(
                subprocessorNodesWeeks,
                SUBPROCESSOR
              )}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getSubprocessorDaysNodes = () => {
    let { subprocessorNodes } = this.state.retentionnodes;
    if (subprocessorNodes) {
      let days = [...subprocessorNodes];
      let subprocessorNodesDays = days.filter((data, index) => {
        if (data.timeline === "1") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {subprocessorNodesDays.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Days
              </div>
              {this.mappingRetentionNodeContent(
                subprocessorNodesDays,
                SUBPROCESSOR
              )}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getSubprocessorMonthsNodes = () => {
    let { subprocessorNodes } = this.state.retentionnodes;
    if (subprocessorNodes) {
      let months = [...subprocessorNodes];
      let subprocessorNodesMonths = months.filter((data, index) => {
        if (data.timeline === "3") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {subprocessorNodesMonths.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Months
              </div>
              {this.mappingRetentionNodeContent(
                subprocessorNodesMonths,
                SUBPROCESSOR
              )}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getSubprocessorYearsNodes = () => {
    let { subprocessorNodes } = this.state.retentionnodes;
    if (subprocessorNodes) {
      let years = [...subprocessorNodes];
      let subprocessorNodesYears = years.filter((data, index) => {
        if (data.timeline === "4") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {subprocessorNodesYears.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                Years
              </div>
              {this.mappingRetentionNodeContent(
                subprocessorNodesYears,
                SUBPROCESSOR
              )}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };
  getSubprocessorByRequestNodes = () => {
    let { subprocessorNodes } = this.state.retentionnodes;
    if (subprocessorNodes) {
      let byRequest = [...subprocessorNodes];
      let subprocessorNodesByRequest = byRequest.filter((data, index) => {
        if (data.timeline === "5") {
          data["id"] = index;
          return data;
        }
      });
      return (
        <>
          {subprocessorNodesByRequest.length > 0 ? (
            <div className="dataBox">
              <div
                className="hours"
                style={{
                  fontSize: "16px",
                  color: "#39393B",
                  fontFamily: "CiscoSansTTRegular"
                }}
              >
                By Request
              </div>
              {this.mappingRetentionNodeContent(
                subprocessorNodesByRequest,
                SUBPROCESSOR
              )}
            </div>
          ) : (
            ""
          )}
        </>
      );
      // return this.mappingRetentionNodeContent(subprocessorNodesByRequest)
    }
  };
  getProcessingNodeDataType = dataTypes => {
    let sorted = dataTypes.sort();
    let sortedJoin = sorted.join("");
    return (
      <div>
        {sortedJoin === "012" ? (
          <div>
            <div className="Registration height-10 border-top-left-right-8" />
            <div className="Generated height-10" />
            <div className="Host-and-Usage height-10" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "01" ? (
          <div>
            <div className="Registration height-15 border-top-left-right-8" />
            <div className="Generated height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "02" ? (
          <div>
            <div className="Registration height-15 border-top-left-right-8" />
            <div className="Host-and-Usage height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "12" ? (
          <div>
            <div className="Generated height-15 border-top-left-right-8" />
            <div className="Host-and-Usage height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "0" ? (
          <div>
            <div className="Registration height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "1" ? (
          <div>
            <div className="Generated height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "2" ? (
          <div>
            <div className="Host-and-Usage height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  render() {
    return (
      <div className="main-container">
        <div className="container-heading flex" >
          <div className="review-section-title nowrap tr pr-20">Verify Information</div>
          <hr className="divider-line"/>
        </div>
        <Accordion className="container-1" defaultExpanded={true} elevation={0} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            className="container-heading flex">
            <div className="review-section-header nowrap tr pr-20">Home</div>
          </AccordionSummary>
          <AccordionDetails>
            {(!this.state.productName ||
              !this.state.dataSheetURL ||
              !this.state.dataSheetVersion ||
              !this.state.date) &&
              this.state.homeWarn ? (
              <div style={{ paddingBottom: "20px" }}>
                <div
                  style={{
                    color: "#916C00",
                    fontSize: "18px",
                    paddingBottom: "10px"
                  }}
                >
                  Do you want to add additional data?
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "20px" }}>
                    <Button
                      className="no-button"
                      onClick={() => {
                        this.setState({
                          homeWarn: false
                        });
                      }}
                    >
                      No, ignore
                    </Button>
                  </div>
                  <div>
                    {" "}
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Button className="yes-button" onClick={() => this.props.editGeneralInfo(true)}>Yes, add</Button>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="container-content-1">
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer"
                  }}
                  onClick={() => this.props.editGeneralInfo(true)}
                  onMouseEnter={() => {
                    if (this.state.productName || !this.state.homeWarn) {
                      document.getElementById("productName-Edit").style.display =
                        "flex";
                      document.getElementById(
                        "productName"
                      ).style.backgroundColor = "#e8ebf1";
                    }
                  }}
                  onMouseLeave={() => {
                    if (this.state.productName || !this.state.homeWarn) {
                      document.getElementById("productName-Edit").style.display =
                        "none";
                      document.getElementById(
                        "productName"
                      ).style.backgroundColor = "";
                    }
                  }}
                >
                  <div
                    id="productName"
                    className={
                      "centerPolicies otherCenters " +
                      (!this.state.productName && this.state.homeWarn
                        ? "no-data-entered"
                        : "")
                    }
                    style={{
                      alignItems: "center",
                      width: "90%"
                    }}
                  >
                    <div className="tr width-25-per review-input-label">
                      Product Name
                    </div>
                    <div className="content-right centerValue">
                      {this.state.productName ? (
                        <div
                          style={{
                            width: "80%",
                            textAlign: "left",
                            fontSize: "16px",
                            color: "#39393B",
                            fontFamily: "CiscoSansTTLight"
                          }}
                        >
                          {this.state.productName}
                        </div>
                      ) : (
                        <div
                          className="review-input-fields tl"
                          style={{
                            textAlign: "left",
                            fontStyle: "italic"
                          }}
                        >
                          No data entered
                        </div>
                      )}
                    </div>
                    {this.state.productName || !this.state.homeWarn ? (
                      <div
                        className="warn-edit"
                        style={{ display: "none" }}
                        id="productName-Edit"
                      >
                        <div className="edit-text" style={{ paddingLeft: "20px" }}>
                          EDIT
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {!this.state.productName && this.state.homeWarn ? (
                      <div className="warn-edit">
                        <Warning />
                        <div className="edit-text" style={{ paddingLeft: "20px" }}>
                          EDIT
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer"
                  }}
                  onClick={() => this.props.editGeneralInfo(true)}
                  onMouseEnter={() => {
                    if (this.state.dataSheetURL || !this.state.homeWarn) {
                      document.getElementById(
                        "datasheet-url-Edit"
                      ).style.display = "flex";
                      document.getElementById(
                        "Data-Sheet-url"
                      ).style.backgroundColor = "#e8ebf1";
                    }
                  }}
                  onMouseLeave={() => {
                    if (this.state.dataSheetURL || !this.state.homeWarn) {
                      document.getElementById(
                        "datasheet-url-Edit"
                      ).style.display = "none";
                      document.getElementById(
                        "Data-Sheet-url"
                      ).style.backgroundColor = "";
                    }
                  }}
                >
                  <div
                    id="Data-Sheet-url"
                    style={{ alignItems: "center", width: "90%" }}
                    className={
                      "centers otherCenters " +
                      (!this.state.dataSheetURL && this.state.homeWarn
                        ? "no-data-entered"
                        : "")
                    }
                  >
                    <div className="tr width-25-per review-input-label">
                      {" "}
                      Data Sheet URL
                    </div>
                    <div className="content-right centerValue">
                      {this.state.dataSheetURL ? (
                        <div
                          style={{
                            width: "80%",
                            textAlign: "left",
                            fontSize: "16px",
                            color: "#39393B",
                            fontFamily: "CiscoSansTTLight"
                          }}
                        >
                          {this.state.dataSheetURL}
                        </div>
                      ) : (
                        <>
                          <div
                            className="review-input-fields tl"
                            style={{
                              width: "80%",
                              fontStyle: "italic"
                            }}
                          >
                            {" "}
                            No data entered
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {this.state.dataSheetURL || !this.state.homeWarn ? (
                    <div
                      className="warn-edit"
                      style={{ display: "none" }}
                      id="datasheet-url-Edit"
                    >
                      <div className="edit-text" style={{ paddingLeft: "20px" }}>
                        EDIT
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!this.state.dataSheetURL && this.state.homeWarn ? (
                    <div className="warn-edit">
                      <Warning />
                      <div className="edit-text" style={{ paddingLeft: "20px" }}>
                        EDIT
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer"
                  }}
                  onClick={() => this.props.editGeneralInfo(true)}
                  onMouseEnter={() => {
                    if (this.state.dataSheetVersion || !this.state.homeWarn) {
                      document.getElementById(
                        "dataSheetVersion-Edit"
                      ).style.display = "flex";
                      document.getElementById(
                        "dataSheetVersion"
                      ).style.backgroundColor = "#e8ebf1";
                    }
                  }}
                  onMouseLeave={() => {
                    if (this.state.dataSheetVersion || !this.state.homeWarn) {
                      document.getElementById(
                        "dataSheetVersion-Edit"
                      ).style.display = "none";
                      document.getElementById(
                        "dataSheetVersion"
                      ).style.backgroundColor = "";
                    }
                  }}
                >
                  <div
                    id="dataSheetVersion"
                    style={{
                      alignItems: "center",
                      width: "90%",
                      cursor: "pointer"
                    }}
                    className={
                      "otherCenters " +
                      (!this.state.dataSheetVersion && this.state.homeWarn
                        ? "no-data-entered"
                        : "")
                    }
                  >
                    <div className="tr width-25-per review-input-label">
                      Data Sheet Version
                    </div>
                    <div className="content-right centerValue">
                      {this.state.dataSheetVersion ? (
                        <div
                          style={{
                            width: "80%",
                            textAlign: "left",
                            fontSize: "16px",
                            color: "#39393B",
                            fontFamily: "CiscoSansTTLight"
                          }}
                        >
                          {this.state.dataSheetVersion}
                        </div>
                      ) : (
                        <div
                          className="review-input-fields tl"
                          style={{
                            fontStyle: "italic",
                            width: "80%"
                          }}
                        >
                          No data entered
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.dataSheetVersion || !this.state.homeWarn ? (
                    <div
                      className="warn-edit"
                      style={{ display: "none" }}
                      id="dataSheetVersion-Edit"
                    >
                      <div className="edit-text" style={{ paddingLeft: "20px" }}>
                        EDIT
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!this.state.dataSheetVersion && this.state.homeWarn ? (
                    <div className="warn-edit">
                      <Warning />
                      <div className="edit-text" style={{ paddingLeft: "20px" }}>
                        EDIT
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer"
                  }}
                  onClick={() => this.props.editGeneralInfo(true)}
                  onMouseEnter={() => {
                    if (this.state.date || !this.state.homeWarn) {
                      document.getElementById("date-Edit").style.display = "flex";
                      document.getElementById(
                        "versiondate"
                      ).style.backgroundColor = "#e8ebf1";
                    }
                  }}
                  onMouseLeave={() => {
                    if (this.state.date || !this.state.homeWarn) {
                      document.getElementById("date-Edit").style.display = "none";
                      document.getElementById(
                        "versiondate"
                      ).style.backgroundColor = "";
                    }
                  }}
                >
                  <div
                    id="versiondate"
                    style={{ alignItems: "center", width: "90%" }}
                    className={
                      "otherCenters " +
                      (!this.state.date && this.state.homeWarn
                        ? "no-data-entered"
                        : "")
                    }
                  >
                    <div className="tr width-25-per review-input-label">
                      Version Date
                    </div>
                    <div className="content-right centerValue">
                      {this.state.date ? (
                        <div
                          style={{
                            width: "80%",
                            textAlign: "left",
                            fontSize: "16px",
                            color: "#39393B",
                            fontFamily: "CiscoSansTTLight"
                          }}
                        >
                          {this.state.date}
                        </div>
                      ) : (
                        <div
                          className="review-input-fields tl"
                          style={{
                            width: "80%",
                            fontStyle: "italic"
                          }}
                        >
                          No data entered
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.date || !this.state.homeWarn ? (
                    <div
                      className="warn-edit"
                      style={{ display: "none" }}
                      id="date-Edit"
                    >
                      <div className="edit-text" style={{ paddingLeft: "20px" }}>
                        EDIT
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!this.state.date && this.state.homeWarn ? (
                    <div className="warn-edit">
                      <Warning />
                      <div className="edit-text" style={{ paddingLeft: "20px" }}>
                        EDIT
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className="container-1" elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            className="container-heading flex">
            <div className="review-section-header nowrap tr pr-20">
              Product Information
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            {(!this.state.dataTypes[0].datatype ||
              !this.state.dataTypes[1].datatype ||
              !this.state.dataTypes[2].datatype ||
              !this.state.dataTypes[0].dataAssets ||
              !this.state.dataTypes[1].dataAssets ||
              !this.state.dataTypes[2].dataAssets) && this.state.dataTypeWarn ? (
              <div style={{ paddingBottom: "20px" }}>
                <div
                  style={{
                    color: "#916C00",
                    fontSize: "18px",
                    paddingBottom: "10px"
                  }}
                >
                  Do you want to add additional data?
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "20px" }}>
                    <Button
                      className="no-button"
                      onClick={() => {
                        this.setState({
                          dataTypeWarn: false
                        });
                      }}
                    >
                      No, ignore
                    </Button>
                  </div>
                  <div>
                    {" "}
                    <Button
                      onClick={() => {
                        this.props.gotospecificForm(0);
                      }}
                      className="yes-button"
                    >
                      Yes, add
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className="container-content flex"
              style={{ flexDirection: "column" }}
            >
              <div className="otherCenters">
                <div className="tr width-25-per review-input-label">
                  Data Types
                </div>
              </div>
              {this.state.dataTypes.map((data, index) => {
                let uniqindex = "DataType" + index;
                return (
                  <div key={index}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        this.props.gotospecificForm(0);
                      }}
                      onMouseEnter={() => {
                        if (
                          this.state.dataTypes.length > 0 ||
                          !this.state.dataTypeWarn
                        ) {
                          document.getElementById(
                            uniqindex + "Edit"
                          ).style.display = "flex";
                          document.getElementById(
                            uniqindex
                          ).style.backgroundColor = "#e8ebf1";
                        }
                      }}
                      onMouseLeave={() => {
                        if (
                          this.state.dataTypes.length > 0 ||
                          !this.state.dataTypeWarn
                        ) {
                          document.getElementById(
                            uniqindex + "Edit"
                          ).style.display = "none";
                          document.getElementById(
                            uniqindex
                          ).style.backgroundColor = "";
                        }
                      }}
                    >
                      <div
                        id={uniqindex}
                        className={
                          "otherCenters " +
                          (!data.dataType && this.state.dataTypeWarn
                            ? "no-data-entered"
                            : "")
                        }
                        style={{
                          alignItems: "center",
                          width: "90%",
                          cursor: "pointer",
                          marginBottom: "0px"
                        }}
                      >
                        <div className="tr width-25-per review-input-secondary-label">
                          Name
                        </div>
                        <div
                          className="review-input-fields tl w-70-per"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {index === 0 ? (
                            <div className="box box-color-cyan" />
                          ) : (
                            ""
                          )}
                          {index === 1 ? (
                            <div className="box box-color-yellow" />
                          ) : (
                            ""
                          )}
                          {index === 2 ? (
                            <div className="box box-color-red" />
                          ) : (
                            ""
                          )}
                          {data.dataType ? (
                            <div>{data.dataType}</div>
                          ) : (
                            <div
                              className="review-input-fields"
                              style={{ fontStyle: "italic" }}
                            >
                              No data entered
                            </div>
                          )}
                        </div>
                      </div>
                      {data.dataType || !this.state.dataTypeWarn ? (
                        <div
                          className="warn-edit"
                          style={{ display: "none" }}
                          id={uniqindex + "Edit"}
                        >
                          <div
                            className="edit-text"
                            style={{ paddingLeft: "20px" }}
                          >
                            EDIT
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {!data.dataType && this.state.dataTypeWarn ? (
                        <div className="warn-edit">
                          <Warning />
                          <div
                            className="edit-text"
                            style={{ paddingLeft: "20px" }}
                          >
                            EDIT
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        this.props.gotospecificForm(0);
                      }}
                      onMouseEnter={() => {
                        if (data.dataAssets || !this.state.dataTypeWarn) {
                          document.getElementById(
                            uniqindex + "dataAssetsEdit"
                          ).style.display = "flex";
                          document.getElementById(
                            uniqindex + "dataAssets"
                          ).style.backgroundColor = "#e8ebf1";
                        }
                      }}
                      onMouseLeave={() => {
                        if (data.dataAssets || !this.state.dataTypeWarn) {
                          document.getElementById(
                            uniqindex + "dataAssetsEdit"
                          ).style.display = "none";
                          document.getElementById(
                            uniqindex + "dataAssets"
                          ).style.backgroundColor = "";
                        }
                      }}
                    >
                      <div
                        id={uniqindex + "dataAssets"}
                        className={
                          "otherCenters " +
                          (!data.dataAssets && this.state.dataTypeWarn
                            ? "no-data-entered"
                            : "")
                        }
                        style={{
                          alignItems: "center",
                          width: "90%",
                          cursor: "pointer"
                        }}
                      >
                        <div className="tr width-25-per review-input-secondary-label">
                          Assets
                        </div>
                        <div
                          className="tl w-70-per review-input-fields"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            className="box box-color-cyan"
                            style={{ visibility: "hidden" }}
                          />
                          <div>
                            {data.dataAssets ? (
                              <div>{data.dataAssets}</div>
                            ) : (
                              <div
                                className="review-input-fields"
                                style={{ fontStyle: "italic" }}
                              >
                                No data entered
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {data.dataAssets || !this.state.dataTypeWarn ? (
                        <div
                          className="warn-edit"
                          style={{ display: "none" }}
                          id={uniqindex + "dataAssetsEdit"}
                        >
                          <div
                            className="edit-text"
                            style={{ paddingLeft: "20px" }}
                          >
                            EDIT
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {!data.dataAssets && this.state.dataTypeWarn ? (
                        <div className="warn-edit">
                          <Warning />
                          <div
                            className="edit-text"
                            style={{ paddingLeft: "20px" }}
                          >
                            EDIT
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                cursor: "pointer"
              }}
              onClick={() => {
                this.props.gotospecificForm(0);
              }}
            // onMouseEnter={() => {
            //   if (
            //     this.state.customerToSubprocessor ||
            //     !this.state.customerToSubprocessor ||
            //     !this.state.dataTypeWarn
            //   ) {
            //     document.getElementById(
            //       "customerToSubprocessor-Edit"
            //     ).style.display = "flex";
            //     document.getElementById(
            //       "customerToSubprocessor"
            //     ).style.backgroundColor = "#e8ebf1";
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (
            //     this.state.customerToSubprocessor ||
            //     !this.state.customerToSubprocessor ||
            //     !this.state.dataTypeWarn
            //   ) {
            //     document.getElementById(
            //       "customerToSubprocessor-Edit"
            //     ).style.display = "none";
            //     document.getElementById(
            //       "customerToSubprocessor"
            //     ).style.backgroundColor = "";
            //   }
            // }}
            >
              <div
                className="otherCenters"
                style={{
                  alignItems: "center",
                  width: "90%",
                  cursor: "pointer"
                }}
                id="customerToSubprocessor"
              >
                <div className="tr width-25-per review-input-label">
                  Contract with Subprocessor
                </div>
                <div className="tl w-70-per review-input-fields">
                  {this.state.customerToSubprocessor ? "yes" : "no"}
                </div>
              </div>
              {this.state.customerToSubprocessor ||
                !this.state.customerToSubprocessor ||
                !this.state.dataTypeWarn ? (
                <div
                  className="warn-edit"
                  style={{ display: "none" }}
                  id="customerToSubprocessor-Edit"
                >
                  <div className="edit-text" style={{ paddingLeft: "20px" }}>
                    EDIT
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                cursor: "pointer"
              }}
              onClick={() => {
                this.props.gotospecificForm(0);
              }}
            // onMouseEnter={() => {
            //   if (
            //     this.state.callout ||
            //     !this.state.callout ||
            //     !this.state.dataTypeWarn
            //   ) {
            //     document.getElementById("callout-Edit").style.display = "flex";
            //     document.getElementById("callout").style.backgroundColor =
            //       "#e8ebf1";
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (
            //     this.state.callout ||
            //     !this.state.callout ||
            //     !this.state.dataTypeWarn
            //   ) {
            //     document.getElementById("callout-Edit").style.display = "none";
            //     document.getElementById("callout").style.backgroundColor = "";
            //   }
            // }}
            >
              <div
                className="otherCenters"
                style={{
                  alignItems: "center",
                  width: "90%",
                  cursor: "pointer"
                }}
                id="callout"
              >
                <div className="tr width-25-per review-input-label">
                  Additional Callout
                </div>
                <div className="tl w-70-per review-input-fields">
                  {this.state.callout ? "yes" : "no"}
                </div>
              </div>
              {this.state.callout ||
                !this.state.callout ||
                !this.state.dataTypeWarn ? (
                <div
                  className="warn-edit"
                  style={{ display: "none" }}
                  id="callout-Edit"
                >
                  <div className="edit-text" style={{ paddingLeft: "20px" }}>
                    EDIT
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {this.state.callout ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.props.gotospecificForm(0);
                }}
                onMouseEnter={() => {
                  if (
                    this.state.calloutDataTypes.length > 0 ||
                    !this.state.calloutDataTypes.length > 0 ||
                    !this.state.dataTypeWarn
                  ) {
                    document.getElementById(
                      "calloutDataTypes-Edit"
                    ).style.display = "flex";
                    document.getElementById(
                      "calloutDataTypes"
                    ).style.backgroundColor = "#e8ebf1";
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.calloutDataTypes.length > 0 ||
                    !this.state.calloutDataTypes.length > 0 ||
                    !this.state.dataTypeWarn
                  ) {
                    document.getElementById(
                      "calloutDataTypes-Edit"
                    ).style.display = "none";
                    document.getElementById(
                      "calloutDataTypes"
                    ).style.backgroundColor = "";
                  }
                }}
              >
                <div
                  className="otherCenters"
                  style={{
                    alignItems: "center",
                    width: "90%",
                    cursor: "pointer"
                  }}
                  id="calloutDataTypes"
                >
                  <div className="tr width-25-per review-input-secondary-label">
                    Affected Data Type(s)
                  </div>
                  <div className="tl w-70-per review-input-fields">
                    {this.state.calloutDataTypes.length > 0
                      ? this.commaseperatedstring()
                      : "no data entered"}
                  </div>
                </div>
                {this.state.calloutDataTypes.length > 0 ||
                  !this.state.calloutDataTypes.length > 0 ||
                  !this.state.dataTypeWarn ? (
                  <div
                    className="warn-edit"
                    style={{ display: "none" }}
                    id="calloutDataTypes-Edit"
                  >
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {this.state.callout ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.props.gotospecificForm(0);
                }}
                onMouseEnter={() => {
                  if (
                    this.state.calloutCopy ||
                    !this.state.calloutCopy ||
                    !this.state.dataTypeWarn
                  ) {
                    document.getElementById("calloutCopy-Edit").style.display =
                      "flex";
                    document.getElementById("calloutCopy").style.backgroundColor =
                      "#e8ebf1";
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.calloutCopy ||
                    !this.state.calloutCopy ||
                    !this.state.dataTypeWarn
                  ) {
                    document.getElementById("calloutCopy-Edit").style.display =
                      "none";
                    document.getElementById("calloutCopy").style.backgroundColor =
                      "";
                  }
                }}
              >
                <div
                  className="otherCenters"
                  style={{
                    alignItems: "center",
                    width: "90%",
                    cursor: "pointer"
                  }}
                  id="calloutCopy"
                >
                  <div className="tr width-25-per review-input-secondary-label">
                    Callout Text
                  </div>
                  <div className="tl w-70-per review-input-fields">
                    {this.state.calloutCopy
                      ? this.state.calloutCopy
                      : "no data entered"}
                  </div>
                </div>
                {this.state.calloutCopy ||
                  !this.state.calloutCopy ||
                  !this.state.dataTypeWarn ? (
                  <div
                    className="warn-edit"
                    style={{ display: "none" }}
                    id="calloutCopy-Edit"
                  >
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion className="container-1" elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            className="container-heading flex">
            <div className="review-section-header nowrap tr pr-20">
              Data Centers
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            {(!this.state.ciscoLocations.length > 0 ||
              !this.state.policiesDescription ||
              !this.state.thirdPartyLocations.length > 0) &&
              this.state.dataCentersWarn ? (
              <div style={{ paddingBottom: "20px" }}>
                <div
                  style={{
                    color: "#916C00",
                    fontSize: "18px",
                    paddingBottom: "10px"
                  }}
                >
                  Do you want to add additional data?
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "20px" }}>
                    <Button
                      className="no-button"
                      onClick={() => {
                        this.setState({
                          dataCentersWarn: false
                        });
                      }}
                    >
                      No, ignore
                    </Button>
                  </div>
                  <div>
                    {" "}
                    <Button
                      className="yes-button"
                      onClick={() => {
                        this.props.gotospecificForm(1);
                      }}
                    >
                      Yes, add
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="container-content-1">
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.props.gotospecificForm(1);
                }}
                onMouseEnter={() => {
                  if (
                    this.state.policiesDescription ||
                    !this.state.dataCentersWarn
                  ) {
                    document.getElementById(
                      "dataCenterPolicies-Edit"
                    ).style.display = "flex";
                    document.getElementById(
                      "dataCenterPolicies"
                    ).style.backgroundColor = "#e8ebf1";
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.policiesDescription ||
                    !this.state.dataCentersWarn
                  ) {
                    document.getElementById(
                      "dataCenterPolicies-Edit"
                    ).style.display = "none";
                    document.getElementById(
                      "dataCenterPolicies"
                    ).style.backgroundColor = "";
                  }
                }}
              >
                <div
                  id="dataCenterPolicies"
                  className={
                    "centerPolicies " +
                    (!this.state.policiesDescription && this.state.dataCentersWarn
                      ? "no-data-entered"
                      : "")
                  }
                  style={{
                    alignItems: "center",
                    width: "90%",
                    cursor: "pointer"
                  }}
                >
                  <div className="tr width-25-per review-input-label">
                    Data Center Policies
                  </div>
                  {this.state.policiesDescription ? (
                    <div className="tl w-70-per review-input-fields">
                      {this.state.policiesDescription}
                    </div>
                  ) : (
                    <div
                      className="tl w-70-per review-input-fields"
                      style={{
                        fontStyle: "italic"
                      }}
                    >
                      No data entered
                    </div>
                  )}
                </div>
                {this.state.policiesDescription || !this.state.dataCentersWarn ? (
                  <div
                    className="warn-edit"
                    style={{ display: "none" }}
                    id="dataCenterPolicies-Edit"
                  >
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {!this.state.policiesDescription && this.state.dataCentersWarn ? (
                  <div className="warn-edit">
                    <Warning />
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.props.gotospecificForm(1);
                }}
                onMouseEnter={() => {
                  if (
                    this.state.ciscoLocations.length > 0 ||
                    !this.state.dataCentersWarn
                  ) {
                    document.getElementById("ciscoLocations-Edit").style.display =
                      "flex";
                    document.getElementById(
                      "ciscoLocations"
                    ).style.backgroundColor = "#e8ebf1";
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.ciscoLocations.length > 0 ||
                    !this.state.dataCentersWarn
                  ) {
                    document.getElementById("ciscoLocations-Edit").style.display =
                      "none";
                    document.getElementById(
                      "ciscoLocations"
                    ).style.backgroundColor = "";
                  }
                }}
              >
                <div
                  id="ciscoLocations"
                  className={
                    "centers " +
                    (!this.state.ciscoLocations.length > 0 &&
                      this.state.dataCentersWarn
                      ? "no-data-entered"
                      : "")
                  }
                  style={{
                    alignItems: "center",
                    width: "90%",
                    cursor: "pointer"
                  }}
                >
                  <div className="tr width-25-per review-input-label">
                    Data Centers
                  </div>

                  {this.state.ciscoLocations.length > 0 ? (
                    <ul
                      type="none"
                      className="tl w-70-per review-input-fields m0 p0"
                    >
                      {this.state.ciscoLocations.map((data, index) => {
                        return <li key={index}>{data}</li>;
                      })}
                    </ul>
                  ) : (
                    <ul
                      type="none"
                      className="tl w-70-per review-input-fields m0 p0"
                    >
                      <li style={{ fontStyle: "italic" }}>No data entered</li>
                    </ul>
                  )}
                </div>
                {this.state.ciscoLocations.length > 0 ||
                  !this.state.dataCentersWarn ? (
                  <div
                    className="warn-edit"
                    style={{ display: "none" }}
                    id="ciscoLocations-Edit"
                  >
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {!this.state.ciscoLocations.length > 0 &&
                  this.state.dataCentersWarn ? (
                  <div className="warn-edit">
                    <Warning />
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.props.gotospecificForm(1);
                }}
                onMouseEnter={() => {
                  if (
                    this.state.thirdPartyLocations.length > 0 ||
                    !this.state.dataCentersWarn
                  ) {
                    document.getElementById(
                      "thirdPartyLocations-Edit"
                    ).style.display = "flex";
                    document.getElementById(
                      "thirdPartyLocations"
                    ).style.backgroundColor = "#e8ebf1";
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.thirdPartyLocations.length > 0 ||
                    !this.state.dataCentersWarn
                  ) {
                    document.getElementById(
                      "thirdPartyLocations-Edit"
                    ).style.display = "none";
                    document.getElementById(
                      "thirdPartyLocations"
                    ).style.backgroundColor = "";
                  }
                }}
              >
                <div
                  id="thirdPartyLocations"
                  className={
                    "otherCenters " +
                    // (!this.state.thirdPartyLocations.length > 0 &&
                    // this.state.dataCentersWarn
                    //   ? "no-data-entered"
                    //   : "")
                    (!this.state.thirdPartyLocations.length > 0 &&
                      this.state.dataCentersWarn
                      ? " no-data-entered "
                      : "")
                  }
                  style={{
                    alignItems: "center",
                    width: "90%",
                    cursor: "pointer"
                  }}
                >
                  <div className="tr width-25-per review-input-label">
                    Third Party Data Centers
                  </div>

                  {this.state.thirdPartyLocations.length > 0 ? (
                    <ul
                      type="none"
                      className="tl w-70-per review-input-fields m0 p0"
                    >
                      {this.state.thirdPartyLocations.map((data, index) => {
                        return <li key={index}>{data}</li>;
                      })}
                    </ul>
                  ) : (
                    <ul
                      type="none"
                      className="tl w-70-per review-input-fields m0 p0"
                    >
                      <li style={{ fontStyle: "italic" }}>No data entered</li>
                    </ul>
                  )}
                </div>
                {this.state.thirdPartyLocations.length > 0 ||
                  !this.state.dataCentersWarn ? (
                  <div
                    className="warn-edit"
                    style={{ display: "none" }}
                    id="thirdPartyLocations-Edit"
                  >
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {!this.state.thirdPartyLocations.length > 0 &&
                  this.state.dataCentersWarn ? (
                  <div className="warn-edit">
                    <Warning />
                    <div className="edit-text" style={{ paddingLeft: "20px" }}>
                      EDIT
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className="container-1" elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            className="container-heading flex">
            <div className="review-section-header nowrap tr pr-20">
              Data Inputs
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <div className="registration margin-bottom">
              {this.state.inputNodes.map((data, index) => {
                let uniqindex = "inputNodes" + data.dataType + index;
                return (
                  <div
                    className="container-content-1 flex itmes-center"
                    key={index}
                    style={{
                      marginBottom: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onMouseEnter={() => {
                      if (this.state.dataTypes[data.dataType].datatype) {
                        document.getElementById(
                          uniqindex + "Edit"
                        ).style.display = "flex";
                        document.getElementById(
                          uniqindex + "hover"
                        ).style.backgroundColor = "#c4cee0";
                      }
                    }}
                    onMouseLeave={() => {
                      if (this.state.dataTypes[data.dataType].datatype) {
                        document.getElementById(
                          uniqindex + "Edit"
                        ).style.display = "none";
                        document.getElementById(
                          uniqindex + "hover"
                        ).style.backgroundColor = "";
                      }
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <div className="tr width-15-per review-input-label">
                        {this.state.dataTypes[data.dataType].datatype}
                      </div>
                      <div
                        className="input-right rectangle flex"
                        id={uniqindex + "hover"}
                      >
                        <div className="dataInputBox">
                          <div className="icons">
                            <div
                              id={`iconPreviewImage-${1234}`}
                              dangerouslySetInnerHTML={{
                                __html: this.decodeSvgString(data.iconSvgString)
                              }}
                            />
                          </div>
                          <div
                            style={{ position: "relative" }}
                            key={index}
                            onMouseEnter={() => {
                              this.handleOnHover(uniqindex);
                            }}
                            onMouseLeave={() => {
                              this.mouseOut(uniqindex);
                            }}
                          >
                            <div className="padding15" style={{ width: "230px" }}>
                              {data.dataType === "0" ? (
                                <div className="Registration height-30 border-top-left-right-8" />
                              ) : (
                                ""
                              )}
                              {data.dataType === "1" ? (
                                <div className="Generated height-30 border-top-left-right-8" />
                              ) : (
                                ""
                              )}
                              {data.dataType === "2" ? (
                                <div className="Host-and-Usage height-30 border-top-left-right-8" />
                              ) : (
                                ""
                              )}
                              {this.textFieldProcessingNode(data.nodeCopy)}
                            </div>
                            <div
                              id={uniqindex}
                              className="overlay-processingNode"
                              style={{ position: "absolute" }}
                              onMouseEnter={() => {
                                if (
                                  this.state.dataTypes[data.dataType].datatype
                                ) {
                                  document.getElementById(
                                    uniqindex + "Edit"
                                  ).style.display = "none";
                                }
                              }}
                              onMouseLeave={() => {
                                if (
                                  this.state.dataTypes[data.dataType].datatype
                                ) {
                                  document.getElementById(
                                    uniqindex + "Edit"
                                  ).style.display = "flex";
                                }
                              }}
                              onClick={() => {
                                this.props.editInputNodes({
                                  node: "input",
                                  nodeIndex: index
                                });
                                this.props.gotospecificForm(2);
                              }}
                            >
                              <div style={{ color: "white" }}>EDIT</div>
                            </div>
                          </div>
                          <div className="icons" style={{ visibility: "hidden" }}>
                            <img src="assets/add-contact.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="warn-edit"
                      style={{ display: "none" }}
                      id={uniqindex + "Edit"}
                    >
                      <div
                        className="edit-text"
                        style={{ paddingLeft: "20px" }}
                        onClick={() => {
                          this.props.gotospecificForm(2);
                        }}
                      >
                        EDIT
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className="container-1" elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            className="container-heading flex">
            <div className="review-section-header nowrap tr pr-20">
              Processing Nodes
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <div style={{ width: "90%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div className="tr width-15-per review-input-label" style={{ visibility: "hidden" }}>Customers</div>
              <div className="processing-title">
                <div className="processing-subtitle">
                  Admin/Logistics
                </div>
                <div className="processing-subtitle">
                  Provide Service
                </div>
                <div className="processing-subtitle">
                  Service-related/other
                </div>
              </div>
            </div>
            <div
              className="registration margin-bottom cursor-pointer "
              onClick={() => {
                this.props.gotospecificForm(3);
              }}
            >
              <div
                className="container-content-1 flex itmes-center"
                onMouseEnter={() => {
                  if (
                    this.state.processingnodes.customerNodes ||
                    !this.state.processingnodes.customerNodes
                  ) {
                    document.getElementById(
                      "processsing-Customers-Edit"
                    ).style.display = "flex";
                    if (
                      this.state.processingnodes.customerNodes &&
                      this.state.processingnodes.customerNodes.length > 0
                    ) {
                      document.getElementById(
                        "processsing-Customers"
                      ).style.backgroundColor = "#c4cee0";
                    }
                  }
                }
                }
                onMouseLeave={() => {
                  if (
                    this.state.processingnodes.customerNodes ||
                    !this.state.processingnodes.customerNodes
                  ) {
                    document.getElementById(
                      "processsing-Customers-Edit"
                    ).style.display = "none";
                    if (
                      this.state.processingnodes.customerNodes &&
                      this.state.processingnodes.customerNodes.length > 0
                    ) {
                      document.getElementById(
                        "processsing-Customers"
                      ).style.backgroundColor = "";
                    }
                  }
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="tr width-15-per review-input-label">
                    Customers
                  </div>
                  {this.state.processingnodes.customerNodes &&
                    this.state.processingnodes.customerNodes.length > 0 ? (
                    this.getCustomerProcessingNodes()
                  ) : (
                    <div
                      className="rectangle-wrap review-input-fields"
                      style={{
                        fontStyle: "italic",
                        padding: "25px",
                        backgroundColor: "#FFF8DC",
                        justifyContent: "center"
                      }}
                    >
                      No data entered
                    </div>
                  )}
                </div>
                <div
                  className="warn-edit"
                  style={{ display: "none" }}
                  id="processsing-Customers-Edit"
                >
                  {" "}
                  <div className="edit-text" style={{ paddingLeft: "20px" }}>
                    EDIT
                  </div>
                </div>
              </div>
            </div>
            <div
              className="generated margin-bottom cursor-pointer "
              onClick={() => {
                this.props.gotospecificForm(3);
              }}
            >
              <div
                className="container-content-1 flex itmes-center"
                onMouseEnter={() => {
                  if (
                    this.state.processingnodes.ciscoNodes ||
                    !this.state.processingnodes.ciscoNodes
                  ) {
                    document.getElementById(
                      "processsing-Cisco-Edit"
                    ).style.display = "flex";
                    if (
                      this.state.processingnodes.ciscoNodes &&
                      this.state.processingnodes.ciscoNodes.length > 0
                    ) {
                      document.getElementById(
                        "processsing-Cisco"
                      ).style.backgroundColor = "#c4cee0";
                    }
                  }
                }
                }
                onMouseLeave={() => {
                  if (
                    this.state.processingnodes.ciscoNodes ||
                    !this.state.processingnodes.ciscoNodes
                  ) {
                    document.getElementById(
                      "processsing-Cisco-Edit"
                    ).style.display = "none";
                    if (
                      this.state.processingnodes.ciscoNodes &&
                      this.state.processingnodes.ciscoNodes.length > 0
                    ) {
                      document.getElementById(
                        "processsing-Cisco"
                      ).style.backgroundColor = "";
                    }
                  }
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="tr width-15-per review-input-label">Cisco</div>
                  {this.state.processingnodes.ciscoNodes &&
                    this.state.processingnodes.ciscoNodes.length > 0 ? (
                    this.getCiscoProcessingNodes()
                  ) : (
                    <div
                      className="rectangle-wrap review-input-fields"
                      style={{
                        fontStyle: "italic",
                        padding: "25px",
                        backgroundColor: "#FFF8DC",
                        justifyContent: "center"
                      }}
                    >
                      No data entered
                    </div>
                  )}
                </div>
                <div
                  className="warn-edit"
                  style={{ display: "none" }}
                  id="processsing-Cisco-Edit"
                >
                  {" "}
                  <div className="edit-text" style={{ paddingLeft: "20px" }}>
                    EDIT
                  </div>
                </div>
              </div>
            </div>
            <div
              className="host cursor-pointer "
              onClick={() => {
                this.props.gotospecificForm(3);
              }}
            >
              <div
                className="container-content-1 flex itmes-center"
                onMouseEnter={() => {
                  if (
                    this.state.processingnodes.subprocessorNodes ||
                    !this.state.processingnodes.subprocessorNodes
                  ) {
                    document.getElementById(
                      "processsing-Subprocessor-Edit"
                    ).style.display = "flex";
                    if (
                      this.state.processingnodes.subprocessorNodes &&
                      this.state.processingnodes.subprocessorNodes.length > 0
                    ) {
                      document.getElementById(
                        "processsing-Subprocessor"
                      ).style.backgroundColor = "#c4cee0";
                    }
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.processingnodes.subprocessorNodes ||
                    !this.state.processingnodes.subprocessorNodes
                  ) {
                    document.getElementById(
                      "processsing-Subprocessor-Edit"
                    ).style.display = "none";
                    if (
                      this.state.processingnodes.subprocessorNodes &&
                      this.state.processingnodes.subprocessorNodes.length > 0
                    ) {
                      document.getElementById(
                        "processsing-Subprocessor"
                      ).style.backgroundColor = "";
                    }
                  }
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="tr width-15-per review-input-label">
                    Subprocessor
                  </div>
                  {this.state.processingnodes.subprocessorNodes &&
                    this.state.processingnodes.subprocessorNodes.length > 0 ? (
                    this.getSubprocessorProcessingNodes()
                  ) : (
                    <div
                      className="rectangle-wrap review-input-fields"
                      style={{
                        fontStyle: "italic",
                        padding: "25px",
                        backgroundColor: "#FFF8DC",
                        justifyContent: "center"
                      }}
                    >
                      No data entered
                    </div>
                  )}
                </div>
                <div
                  className="warn-edit"
                  style={{ display: "none" }}
                  id="processsing-Subprocessor-Edit"
                >
                  {" "}
                  <div className="edit-text" style={{ paddingLeft: "20px" }}>
                    EDIT
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className="container-1" elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            className="container-heading flex">
            <div className="review-section-header nowrap tr pr-20">
              Retention
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <div
              style={{ cursor: "pointer" }}
              className="registration margin-bottom"
              onClick={() => {
                this.props.gotospecificForm(4);
              }}
            >
              <div
                className="container-content-1 flex itmes-center"
                onMouseEnter={() => {
                  if (
                    this.state.ciscoRetensionNodes ||
                    !this.state.ciscoRetensionNodes
                  ) {
                    document.getElementById(
                      "restention-Cisco-Edit"
                    ).style.display = "flex";
                    if (
                      this.state.ciscoRetensionNodes &&
                      this.state.ciscoRetensionNodes.length > 0
                    ) {
                      document.getElementById(
                        "restention-Cisco"
                      ).style.backgroundColor = "#c4cee0";
                    }
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.ciscoRetensionNodes ||
                    !this.state.ciscoRetensionNodes
                  ) {
                    document.getElementById(
                      "restention-Cisco-Edit"
                    ).style.display = "none";
                    if (
                      this.state.ciscoRetensionNodes &&
                      this.state.ciscoRetensionNodes.length > 0
                    ) {
                      document.getElementById(
                        "restention-Cisco"
                      ).style.backgroundColor = "";
                    }
                  }
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="tr width-15-per review-input-label">Cisco</div>
                  {this.state.ciscoRetensionNodes &&
                    this.state.ciscoRetensionNodes.length > 0 ? (
                    <div className="rectangle-wrap" id="restention-Cisco">
                      {this.getCiscoNoRetentionNodes()}
                      {this.getCiscoWeeksNodes()}
                      {this.getCiscoDaysNodes()}
                      {this.getCiscoMonthsNodes()}
                      {this.getCiscoYearsNodes()}
                      {this.getCiscoByRequestNodes()}
                    </div>
                  ) : (
                    <div
                      className="rectangle-wrap review-input-fields"
                      style={{
                        fontStyle: "italic",
                        padding: "25px",
                        backgroundColor: "#FFF8DC",
                        justifyContent: "center"
                      }}
                    >
                      No data entered
                    </div>
                  )}
                </div>
                <div
                  className="warn-edit"
                  style={{ display: "none" }}
                  id="restention-Cisco-Edit"
                >
                  {" "}
                  <div className="edit-text" style={{ paddingLeft: "20px" }}>
                    EDIT
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ cursor: "pointer" }}
              className="registration margin-bottom"
              onClick={() => {
                this.props.gotospecificForm(4);
              }}
            >
              <div
                className="container-content-1 flex itmes-center"
                onMouseEnter={() => {
                  if (
                    this.state.subprocessorRetensionNodes ||
                    !this.state.subprocessorRetensionNodes
                  ) {
                    document.getElementById(
                      "restention-Subprocessor-Edit"
                    ).style.display = "flex";
                    if (
                      this.state.subprocessorRetensionNodes &&
                      this.state.subprocessorRetensionNodes.length > 0
                    ) {
                      document.getElementById(
                        "restention-Subprocessor"
                      ).style.backgroundColor = "#c4cee0";
                    }
                  }
                }}
                onMouseLeave={() => {
                  if (
                    this.state.subprocessorRetensionNodes ||
                    !this.state.subprocessorRetensionNodes
                  ) {
                    document.getElementById(
                      "restention-Subprocessor-Edit"
                    ).style.display = "none";
                    if (
                      this.state.subprocessorRetensionNodes &&
                      this.state.subprocessorRetensionNodes.length > 0
                    ) {
                      document.getElementById(
                        "restention-Subprocessor"
                      ).style.backgroundColor = "";
                    }
                  }
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="tr width-15-per review-input-label">
                    Subprocessor
                  </div>
                  {this.state.subprocessorRetensionNodes &&
                    this.state.subprocessorRetensionNodes.length > 0 ? (
                    <div className="rectangle-wrap" id="restention-Subprocessor">
                      {this.getSubprocessorNoRetentionNodes()}
                      {this.getSubprocessorWeeksNodes()}
                      {this.getSubprocessorDaysNodes()}
                      {this.getSubprocessorMonthsNodes()}
                      {this.getSubprocessorYearsNodes()}
                      {this.getSubprocessorByRequestNodes()}
                    </div>
                  ) : (
                    <div
                      className="rectangle-wrap review-input-fields"
                      style={{
                        fontStyle: "italic",
                        padding: "25px",
                        backgroundColor: "#FFF8DC",
                        justifyContent: "center"
                      }}
                    >
                      No data entered
                    </div>
                  )}
                </div>
                <div
                  className="warn-edit"
                  style={{ display: "none" }}
                  id="restention-Subprocessor-Edit"
                >
                  {" "}
                  <div className="edit-text" style={{ paddingLeft: "20px" }}>
                    EDIT
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="container-2">
          <div className="container-heading flex pt-20" >
            <div className="review-section-title nowrap tr pr-20">Share</div>
            <hr className="divider-line"/>
          </div>
          <div style={{ marginBottom: "40px" }}>
            <div className="input-label">Add Reviewers</div>
            <Autocomplete
              multiple
              id="combo-box-demo"
              options={this.state.userList}
              getOptionLabel={(option) => option.emailId}
              style={{ width: "100%" }}
              onChange={(e, val) => {
                this.addReviewers(val);
            }}
              renderInput={(params) => <TextField {...params} label="Email, comma separated" variant="outlined" />}
            />
          </div>
          {/* <div style={{ marginBottom: "40px" }}>
            <div className="input-label">Transfer Editor</div>
            <Autocomplete
              id="combo-box-demo"
              options={this.state.userList}
              getOptionLabel={(option) => option.emailId}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Email, comma separated" variant="outlined" />}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      editProcessingNodes,
      editRetentionNodes,
      editInputNodes,
      editGeneralInfo,
      getUsersByOrgId,
      addReviewers
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    generalInfo: state.dataEntry.generalInfo,
    productInfo: state.productInfo.data,
    dataCenters: state.dataCenters.dataCenters,
    dataTypes: state.productInfo.data && state.productInfo.data.dataTypes,
    inputNodes: state.inputnodes.inputNodes,
    processingnodes: state.processingnodes,
    retentionnodes: state.retentionnodes,
    users: state.userInfo.users
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewSection);
