// @flow

import type { DataEntryFormOutput } from "../dataEntry/types";
import type { ActionSVG } from "./actions";
import { SET_SVG_CODE, SVG_CREATION_REQUESTED } from "./actions";

type State = {
  formDataForSvg?: DataEntryFormOutput,
  finalSvgCode: string | typeof undefined
};

const initialState: State = {
  formDataForSvg: undefined,
  finalSvgCode: undefined
};

export const generateSVG = (
  state: State = initialState,
  action: ActionSVG
): State => {
  switch (action.type) {
    case SVG_CREATION_REQUESTED:
      return Object.assign(state, { formDataForSvg: action.payload });
    case SET_SVG_CODE: {
      const newState = Object.assign(
        { ...state },
        { finalSvgCode: action.payload }
      );
      return newState;
    }
    default:
      return state;
  }
};
