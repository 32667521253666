// @flow

import * as R from "ramda";
import { SET_EXISTING_SVG, RECOVERED_JSON_FROM_SVG } from "../actions/loadExistingAction";

export function existingSvg(state: State = {}, action: Action): State {
    const { type, data } = action;
    switch (type) {
      case SET_EXISTING_SVG:
        return R.assoc("existingSvgString", data, state);
      case RECOVERED_JSON_FROM_SVG:
        return R.assoc("extractedFormState", data, state);
      default:
        return state;
    }
  }