// @flow

import * as R from "ramda";

import type { NodeCoordinates } from "../positioning";
import type { Connectable } from "./connectableCommon";

export type LineDrawData = {
  current: Connectable,
  candidates: Array<Connectable>,
  connectionType: string
};

export function getReferenceToNode(
  draw: any,
  id: string,
  source: "boundingbox" | "viewbox" = "boundingbox"
) {
  const d = draw.select(`#${id}`).members[0];

  let box;
  if (source === "boundingbox") {
    box = d.bbox();
  } else {
    box = d.viewbox();

    // Edge case for Sketch : Lines will not align perfectly, but designers can adjust them anyway.
    if (box.width === 0 || box.height === 0) {
      box = d.bbox();
    }

    box = {
      ...box,
      w: box.width,
      h: box.height
    };
  }

  return { d, box };
}

const yOffsetsForMultiDatatypeNodes = [[0], [-15, 15], [-30, 0, 30]];

export function getOffsetForLine(
  unifiedDataTypes: Array<string>,
  connectionType: string
) {
  return yOffsetsForMultiDatatypeNodes[unifiedDataTypes.length - 1][
    unifiedDataTypes.indexOf(connectionType)
  ];
}

export function fetchExistingVirtualConnectableCoordinates(
  existingSVGInterpretation: any,
  metadata: {
    purpose: Array<string>,
    dataTypes: Array<string>
  }
) {
  return R.pick<any>(["connectableId", "coordinates"])(
    existingSVGInterpretation.fetchExistingNode(metadata) || {}
  );
}

export function testCircleCall(
  draw: any,
  nodeBase: NodeCoordinates,
  color: string = "cyan"
) {
  draw
    .circle(30)
    .cx(nodeBase.coordinates.x)
    .cy(nodeBase.coordinates.y)
    .fill(color)
    .addClass("testcircle");
}
