// @flow

import { SVG } from "../logic/svgWrapper";
import { fontsSVGLibrary } from "../../../styles";
import {
  LeftXDataCenterContent,
  LeftXThirdPartyCentersList,
  BaselineDataCentersLists,
  BaselineDataCentersTitles,
  XPosCiscoCircle,
  XPosThirdPartyCircle,
  TopYCircleMarkerLegend,
} from "../grid";
import { colors } from "../../../styles";
import { bubbleMarkerRadius } from "./index";
import type { JSONSchema } from "../../types";
import { calculateLines, calculateWordWidths } from "../nodeSvg/nodesCommon";

const lineHeight = 30;

export const generateTextSection = (
  SVGMainId: string,
  SVGAuxId: string,
  dataCenterMapData: $PropertyType<JSONSchema, "dataCenters">
) => {
  const ciscoDataCenterNames = dataCenterMapData.ciscoLocations || [];

  const thirdPartyDataCenterNames = dataCenterMapData.thirdPartyLocations || [];

  // generate description
  const drawMain = SVG(SVGMainId);
  const descriptionTextSvg = drawMain.group();
  descriptionTextSvg.font({
    ...fontsSVGLibrary.node,
  });

  // let fontStyles = fontsPlainSVG.node;
  // const descriptionTextString = generateSingleLineTextWithInRect(
  //   dataCenterMapData.policiesDescription,
  //   LeftXDataCenterContent,
  //   BaselineDataCenterDescription,
  //   50,
  //   fontStyles,
  //   "dataCenter",
  //   ""
  // );
  // descriptionTextSvg.svg(descriptionTextString);

  let lineWidth = 50
  let wordsWithComputedWidth = calculateWordWidths(
    dataCenterMapData.policiesDescription
  );
  let lines = calculateLines(wordsWithComputedWidth, lineWidth);
  let fontSize = "23";

  var nested = drawMain.nested()
  var group = nested.group().translate(2837, 2660);
  let y = 68;
  let x = 13;
 
  var text = drawMain
    .text(function(add) {
      for (let i = 0; i < lines.length; i++) {
        add
          .tspan(lines[i])
          .x(x)
          .y(y);
        y = y + 30;
      }
    })
    .font({
      family: fontsSVGLibrary.node.family,
      weight: 400,
      size: fontSize,
      fill: "#57585B",
    });

  group.add(text)
  // generate lists titles
  const dataCentersListsTitleSvg = drawMain.group();
  dataCentersListsTitleSvg.font({
    ...fontsSVGLibrary.dataCentersTitle,
  });

  const dataCenterTitleString = `<text x="0" y="0">Cisco Data Centers:</text><text x=${LeftXThirdPartyCentersList -
    LeftXDataCenterContent} y="0">Third-Party Data Centers:</text>`;
  dataCentersListsTitleSvg.svg(dataCenterTitleString);
  dataCentersListsTitleSvg
    .x(LeftXDataCenterContent)
    .y(BaselineDataCentersTitles);

  // generate marker circle legends
  const ciscoDataCenterMarkerSvg = drawMain.group();
  ciscoDataCenterMarkerSvg.circle(bubbleMarkerRadius * 2).fill(colors.darkAqua);
  ciscoDataCenterMarkerSvg.x(XPosCiscoCircle).y(TopYCircleMarkerLegend);
  const thirdPartyDataCenterMarkerSvg = drawMain.group();
  thirdPartyDataCenterMarkerSvg
    .circle(bubbleMarkerRadius * 2)
    .fill(colors.lightAqua);
  thirdPartyDataCenterMarkerSvg
    .x(XPosThirdPartyCircle)
    .y(TopYCircleMarkerLegend);

  // generate the data centers lists
  const ciscoDataCentersListSvg = drawMain.group();
  ciscoDataCentersListSvg.font({
    ...fontsSVGLibrary.node,
  });
  if (ciscoDataCenterNames.length > 0) {
    ciscoDataCenterNames.forEach((center, index) => {
      ciscoDataCentersListSvg.svg(
        `<text x="0" y=${lineHeight * index}>${center}</text>`
      );
    });
  }
  ciscoDataCentersListSvg.x(LeftXDataCenterContent).y(BaselineDataCentersLists);

  const thirdPartyDataCentersListSvg = drawMain.group();
  thirdPartyDataCentersListSvg.font({
    ...fontsSVGLibrary.node,
  });
  if (thirdPartyDataCenterNames.length > 0) {
    thirdPartyDataCenterNames.forEach((center, index) => {
      thirdPartyDataCentersListSvg.svg(
        `<text x="0" y=${lineHeight * index}>${center}</text>`
      );
    });
  }
  thirdPartyDataCentersListSvg
    .x(LeftXThirdPartyCentersList)
    .y(BaselineDataCentersLists);
  const result = drawMain.svg();
  drawMain.clear();
  return result;
};
