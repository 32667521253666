
import * as moment from 'moment';

export function convertToDate (date){
    let dateformat = moment(date).format("MMM DD");
    console.log(dateformat)
    return dateformat
  }

 export function getConvertDate(date) {
   if(new Date(date) === new Date()){
      return moment().format('[Today] h:mm A z');
   }
   else{
     return moment(date).format("MMMM DD, YYYY");
   }
  
  }