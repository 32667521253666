import { createGlobalStyle } from 'styled-components';
import CiscoSansTTBold from './CiscoSansTTBold.ttf';
import CiscoSansTTLight from './CiscoSansTTLight.ttf';
import CiscoSansTTThin from './CiscoSansTTThin.ttf';
import CiscoSansTTRegular from './CiscoSansTTRegular.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'CiscoSansTTBold';
        src: local('CiscoSansTTBold'), local('CiscoSansTTBold'),
        url(${CiscoSansTTBold}) format('woff2'),
        url(${CiscoSansTTBold}) format('woff');
    }
    @font-face {
        font-family: 'CiscoSansTTRegular';
        src: local('CiscoSansTTRegular'), local('CiscoSansTTRegular'),
        url(${CiscoSansTTRegular}) format('woff2'),
        url(${CiscoSansTTRegular}) format('woff');
    }
    @font-face {
        font-family: 'CiscoSansTTThin';
        src: local('CiscoSansTTThin'), local('CiscoSansTTThin'),
        url(${CiscoSansTTThin}) format('woff2'),
        url(${CiscoSansTTThin}) format('woff');
    }
    @font-face {
        font-family: 'CiscoSansTTLight';
        src: local('CiscoSansTTLight'), local('CiscoSansTTLight'),
        url(${CiscoSansTTLight}) format('woff2'),
        url(${CiscoSansTTLight}) format('woff');
    }
`;
