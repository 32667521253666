// @flow
import * as R from "ramda";

import {
  appendUnifiedDataTypes,
  initializeRemainingConnectionsSet
} from "./connectableCommon";
import { getOffsetForLine, getReferenceToNode } from "./util";
import { outlineStrokeWidth } from "../../nodeSvg/nodesCommon";
import type { ConnectableConcrete, CoordTypeAndSVG } from "./connectableCommon";
import type { Output as InputNodeInterpretationOutput } from "../../nodeSvg/inputNode";
import type { Output as ProcessingNodeInterpretationOutput } from "../../nodeSvg/processingNode";
import type { Output as RetentionNodeInterpretationOutput } from "../../nodeSvg/retentionNode";

function withLineConnectionComputationForConcreteNode(
  draw: any,
  node: CoordTypeAndSVG
): {
  computeLineConnectionPosition: $PropertyType<ConnectableConcrete,
    "computeLineConnectionPosition">
} {
  return {
    ...node,
    computeLineConnectionPosition: (connectionType, mode) => {
      const { box } = getReferenceToNode(draw, `${node.nodeId}`, "viewbox");

      const dataTypes = node.unifiedDataTypes;
      const offset = getOffsetForLine(dataTypes, connectionType);

      return {
        x:
          node.coordinates.x +
          (mode === "right" ? box.w + outlineStrokeWidth - 10 : 10),
        y: node.coordinates.y + box.h / 2 + offset
      };
    },
    getDrawSpecialFlags: () => []
  };
}

export const createConcreteConnectableFromNodeCall = (
  draw: any,
  target: | InputNodeInterpretationOutput
    | ProcessingNodeInterpretationOutput
    | RetentionNodeInterpretationOutput
): any => {
  const withLineConnectionComputation = withLineConnectionComputationForConcreteNode.bind(
    null,
    draw
  );

  return R.compose(
    R.assoc("connectableId", target.nodeId),
    R.assoc("kind", "concrete"),
    withLineConnectionComputation,
    initializeRemainingConnectionsSet,
    appendUnifiedDataTypes
  )(target);
};
