// @flow
import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

const HomeSection = (props: any) => {
  const [openGoBack, setopenGoBack] = React.useState(false);
  return (
    <div>
      <div>
        <HomeIcon
          className="home-icon"
          onClick={() => {
            setopenGoBack(true);
          }}
        />
      </div>
      <Dialog
        open={openGoBack}
        onClose={() => {
          setopenGoBack(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to save the data added to the form?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your data will be lost if you don&apos;t save them.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                className="secondary-button"
                onClick={() => {
                  props.clearProgess();
                  setopenGoBack(false);
                  window.inputNodeCount = 1;
                  window.processNodeCount = 1;
                  window.retentionNodeCount = 1;
                }}
                color="primary"
              >
                Home
              </Button>
            </Link>
            <Button
              className="secondary-button"
              onClick={() => {
                setopenGoBack(false);
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            {/* <SaveProgressSection buttonType="button" /> */}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearProgess: () => {
      dispatch({ type: "RESET" });
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(HomeSection);
