// @flow
import * as R from "ramda";
import type {
  InputNode,
  Position,
  ProcessingNode,
  RetentionNode
} from "../../../types";
import type { NodeCoordinates } from "../positioning";
import type { SVGRepresentation } from "../../nodeSvg/nodesCommon";

export type DrawSpecialFlags =
  | "ContractsConnection"
  | "CalloutConnection"
  | "CiscoInputConnection"
  | "SubprocessorInputConnection";

export type CoordTypeAndSVG = NodeCoordinates &
  SVGRepresentation & {
    unifiedDataTypes: Array<string>
  };

export type AdditionalDrawData = {
  unifiedDataTypes: Array<string>,
  remainingConnections: Set<string>
};

type ConnectableBase = {
  computeLineConnectionPosition: (
    connectionType: string,
    mode: "right" | "left"
  ) => Position,
  getDrawSpecialFlags: () => Array<DrawSpecialFlags>,
  connectableId: $ReadOnly<string>
};

export type ConnectableConcrete = AdditionalDrawData &
  ConnectableBase & { kind: "concrete" };
export type ConnectableVirtual = AdditionalDrawData &
  ConnectableBase & {
    kind: "virtual",
    isPristine: () => boolean
  };

export type Connectable = ConnectableConcrete | ConnectableVirtual;

// Unify input and other nodes' data type entries. Sort the resulting array.
export const getDataTypeOrTypes = (
  node: InputNode | ProcessingNode | RetentionNode
) =>
  R.sort(
    R.comparator(R.lt),
    R.ifElse(
      R.has("dataTypes"),
      R.prop("dataTypes"),
      R.ifElse(
        R.has("dataType"),
        R.compose(
          x => [x],
          R.prop("dataType")
        ),
        R.always([])
      )
    )(node)
  );

// Appends a bookkeeping entry to keep track of remaining data types to connect.
export function initializeRemainingConnectionsSet(node: {
  unifiedDataTypes: Array<string>
}): any {
  return {
    ...node,
    remainingConnections: new Set(node.unifiedDataTypes)
  };
}

export const appendUnifiedDataTypes = (
  node: InputNode | ProcessingNode | RetentionNode
) => ({
  ...node,
  unifiedDataTypes: getDataTypeOrTypes(node)
});
