import React, { Component } from "react";

class NoCallout extends Component {
  render() {
    return (
      <svg
        width="106px"
        height="106px"
        viewBox="0 0 106 106"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>no callout icon</title>
        <desc>Created with Sketch.</desc>
        <g
          id="no-callout-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect
            id="Rectangle"
            fill="#E8EBF1"
            x="0"
            y="0"
            width="106"
            height="106"
          />
          <line
            x1="42.5"
            y1="0.670886076"
            x2="42.5"
            y2="105.329114"
            id="Line-7"
            stroke="#E03C2D"
            strokeWidth="7"
          />
          <line
            x1="59"
            y1="0.670886076"
            x2="59"
            y2="105.329114"
            id="Line-7"
            stroke="#4EB3DF"
            strokeWidth="7"
          />
          <line
            x1="50.75"
            y1="0.670886076"
            x2="50.75"
            y2="105.329114"
            id="Line-7"
            stroke="#FFC435"
            strokeWidth="7"
          />
        </g>
      </svg>
    );
  }
}
export default NoCallout;
