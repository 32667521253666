export const ADD_CISCO_RETENTION_NODES= "ADD_CISCO_RETENTION_NODES";
export const ADD_SUBPROCESSOR_RETENTION_NODES = "ADD_SUBPROCESSOR_RETENTION_NODES";
export const SET_RETENTION_NODES_JSON_VALUES = "SET_RETENTION_NODES_JSON_VALUES";

export function addCiscoRetentionNodes(nodesData){
    return {
        type: ADD_CISCO_RETENTION_NODES,
        data: nodesData
    }
}

export function addSubprocessorRetentionNodes(nodesData){
    return {
        type: ADD_SUBPROCESSOR_RETENTION_NODES,
        data: nodesData
    }
}


export function setRetentionNodesJsonValues(jsondata){
    let obj = {};
    let cisconodes = [];
    let subprocessnodes = [];
  
    for (let key in jsondata) {
      if (jsondata[key].controller === "0"){
        let node = jsondata[key]
        node["identifer"] = key
        cisconodes.push(node);
      } 
      else if (jsondata[key].controller === "1"){
        let node = jsondata[key]
        node["identifer"] = key
        subprocessnodes.push(node);
      }
    }
  
    if (cisconodes.length > 0) obj["ciscoNodes"] = cisconodes;
  
    if (subprocessnodes.length > 0) obj["subprocessorNodes"] = subprocessnodes;

    return {
        type : SET_RETENTION_NODES_JSON_VALUES,
        data : obj
    }

}



