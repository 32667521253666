// @flow

import * as React from "react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import styled from "styled-components";

import type { DataEntryFormOutput } from "../dataEntry/types";

export const SVGMainId = "datamapSvgRenderMain";
export const SVGAuxId = "datamapSvgRenderAuxiliary";

type OwnProps = {};
type StateProps = {
  productName: string,
  date: string,
  isCiscoToSubContract: boolean,
  formValues: DataEntryFormOutput,
  finalSvgCode: string
};

type Props = OwnProps & StateProps;

type State = {
  finalSvgCode: string
};

function mapStateToProps(state: any) {
  let productName = "default";
  let date = "default";
  if (state.svgGeneration.formDataForSvg) {
    productName = state.svgGeneration.formDataForSvg.generalInfo.productName;
    date = state.svgGeneration.formDataForSvg.generalInfo.date;
  }
  return {
    productName,
    date,
    finalSvgCode: state.svgGeneration.finalSvgCode
      ? state.svgGeneration.finalSvgCode
      : ""
  };
}

class GenerateSvgView extends React.Component<Props, State> {
  componentDidMount() {
    const SVGMain = document.createElement("div");
    SVGMain.setAttribute("id", SVGMainId);
    const SVGAux = document.createElement("div");
    SVGAux.setAttribute("id", SVGAuxId);
    const DataCenterMapRender = document.createElement("div");
    DataCenterMapRender.setAttribute("id", "dataCenterMap");
    DataCenterMapRender.setAttribute("style", "width:869px; height:520px");
    const RenderingComponent = document.getElementById("svgRender");
    if (RenderingComponent) {
      RenderingComponent.append(SVGMain);
      RenderingComponent.append(SVGAux);
      RenderingComponent.append(DataCenterMapRender);
    }
  }

  componentDidUpdate() {
    const { finalSvgCode } = this.props;
    if (finalSvgCode) {
      const today = new Date().toLocaleDateString().replace(/\//g, "-");
      saveAs(finalSvgCode, `datamap_${today}.svg`);
    }
  }

  render() {
    const { productName, date, finalSvgCode } = this.props;
    return (
      <React.Fragment>
        {finalSvgCode ? (
          <DownloadButton
            role="button"
            href={finalSvgCode}
            download={`dataMap_${productName}_${date}.svg`}
            Download
          >
            Re-download Data-Map
          </DownloadButton>
        ) : null}
        {process.env.REACT_APP_UNHIDE_SVG_STAGING ? (
          <React.Fragment>
            <StagingImage src={finalSvgCode} />
          </React.Fragment>
        ) : null}
        <Rendering id="svgRender" />
      </React.Fragment>
    );
  }
}

export const GenerateSvg = connect(mapStateToProps)(GenerateSvgView);

const DownloadButton = styled.a`
  padding: 15px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  display: block;
  width: 200px;
  text-align: center;
  text-decoration: none;
`;

const StagingImage = styled.img`
  width: 100%;
`;

const Rendering = styled.section`
  background-color: green;
  position: fixed;
  left: -9999px;
`;
