// @flow
import * as R from "ramda";

import { getReferenceToNode } from "./util";
import { initializeRemainingConnectionsSet } from "./connectableCommon";
import type {
  AdditionalDrawData,
  ConnectableVirtual,
  CoordTypeAndSVG
} from "./connectableCommon";
import type { Position } from "../../../types";

export function* createVirtualConnectableFromConcreteNode(
  draw: any,
  node: CoordTypeAndSVG & AdditionalDrawData
): Generator<any, any, any> {
  const { box } = R.compose(
    elementId => getReferenceToNode(draw, elementId, "viewbox"),
    R.prop("nodeId")
  )(node);
  const coords = node.coordinates;

  const resultBase = yield { box, coords };

  return createVirtualConnectableFromCoordinates(resultBase);
}

type VirtualNodeInput = {
  coordinates: Position,
  unifiedDataTypes: Array<string>,
  tags: {
    purpose: Array<string>
  },
  connectableId: string
};

export function createVirtualConnectableFromCoordinates(
  input: VirtualNodeInput
) {
  let processedDrawModeStates = new Set();

  return R.compose(
    R.over(R.lensPath(["tags", "dataTypes"]), R.always(input.unifiedDataTypes)),
    R.mergeRight({
      getDrawSpecialFlags: () => [],
      isPristine: () => {
        return processedDrawModeStates.size < 1;
      },
      computeLineConnectionPosition: ((connectionType, mode) => {
        processedDrawModeStates.add(mode);
        return input.coordinates;
      }: $PropertyType<ConnectableVirtual, "computeLineConnectionPosition">)
    }),
    initializeRemainingConnectionsSet,
    R.assoc("kind", "virtual")
  )(input);
}
