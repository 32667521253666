// @flow

import { SVG } from "../logic/svgWrapper";

import { colors, fontsSVGLibrary } from "../../../styles/index";
import {
  CallOutRectWidthHeight,
  LeftXCallOutRect,
  TopYCallOutRect
} from "../grid";
import { calculateLines, calculateWordWidths } from "../nodeSvg/nodesCommon";

export const calloutBoxId = "callOutBox";

export const CallOutSvg = (
  callOutCopy: string,
  SVGMainId: string,
  SVGAuxId: string
) => {
  const drawMain = SVG(SVGMainId);
  const drawAuxiliary = SVG(SVGAuxId);
  const strokeWidth = 5;

  const callOutSvg = drawMain.group().id(calloutBoxId);
  callOutSvg
    .rect(CallOutRectWidthHeight.width, CallOutRectWidthHeight.height)
    .rx(15)
    .attr({
      fill: colors.white,
      "stroke-linejoin": "round",
      "stroke-linecap": "round",
      stroke: colors.softBlack,
      "stroke-width": strokeWidth,
      opacity: 0.8,
      "stroke-dasharray": [15, 10]
    });

  // 3. Generate textbox.
  // callOutSvg.svg(
  //   // x && y set to sensible defaults... text node may be moved by designer later
  //   generateSingleLineTextWithInRect(callOutCopy, 5, 10, 0, "CallOutSvg")
  // );

  let lineWidth = 30;
  let wordsWithComputedWidth = calculateWordWidths(callOutCopy);
  let lines = calculateLines(wordsWithComputedWidth, lineWidth);
  let fontSize = "23";

  if (lines.length > 4) {
    lineWidth = 33;
    fontSize = "20";
    wordsWithComputedWidth = calculateWordWidths(callOutCopy);
    lines = calculateLines(wordsWithComputedWidth, lineWidth);
  }

  var textBoxNested = callOutSvg.nested();
  var group = textBoxNested.group().translate(198, -40);

  let y = 68;
  let x = 13;

  if (lines.length === 1) {
    y = 104;
  } else if (lines.length === 2) {
    y = 93;
  } else if (lines.length === 3) {
    y = 84;
  }

  var text = callOutSvg
    .text(function(add) {
      for (let i = 0; i < lines.length; i++) {
        add
          .tspan(lines[i])
          .x(x)
          .y(y);
        y = y + 25;
      }
    })
    .font({
      family: fontsSVGLibrary.node.family,
      weight: 400,
      size: fontSize,
      fill: "#57585B",
      anchor: "middle",
    });

  group.add(text);

  // todo: revisit perhaps moving this to position.js once we draw the lines
  callOutSvg.x(LeftXCallOutRect).y(TopYCallOutRect);
  const result = drawMain.svg();
  drawMain.clear();
  drawAuxiliary.clear();

  return result;
};
