// @flow

import { type Position } from "../../../features/types";

const DataProcessingColumnWidth = 675;
const DataProcessingColumnOneX = 1050;
const DataProcessingColumnTwoX = 1685;
const DataProcessingColumnThreeX = 2360;
const CustomerRowHeight = 570;
const CiscoRowHeight = 780;
const SubprocessorRowHeight = 400;
const TopYCustomerRow = 530;
const TopYCiscoRow = 1200;
const TopYSubprocessorRow = 2080;
const StandardNodeHeight = 130;
const DeletionColumnWidth = 250;
const DeletionColumnOneX = 2920;
const HeaderHeight = 192;

export const LeftXVersionDescription = 51;
export const TopYVersionDescription = 3305;
export const LeftXVersionDescription_II = 44;
export const TopYVersionDescription_II = 2906;
export const LeftXDataCenterContent = 2849;
export const LeftXThirdPartyCentersList = 3175;
export const BaselineDataCenterDescription = 2727;
export const BaselineDataCentersTitles = 2852;
export const BaselineDataCentersLists = 2890;
export const XPosCiscoCircle = 3122;
export const XPosThirdPartyCircle = 3520;
export const TopYCircleMarkerLegend = 2840;

export const LeftXDataCenterMap = 3618;
export const TopYDataCenterMap = 2688;

export const LeftXDataAssets = 102;
export const TopYDataAssets = 2750;

export const TopYCallOutRect = 1310;
export const LeftXCallOutRect = 430;
export const CallOutRectWidthHeight = {
  width: 430,
  height: 120
};
export const SvgWidthHeight = {
  width: 4522,
  height: 3360
};
export const HeaderImageCoords = { x: 160, y: 135 };
export const HeaderTitleCoords = { x: 489, y: 125 };
export const HeaderSubTitleCoords = { x: 2920, y: 102 };

export const nodeColorConstants = {
  BLUE: "B00",
  BLUE_YELLOW: "BY0",
  YELLOW: "0Y0",
  BLUE_YELLOW_RED: "BYR",
  BLUE_RED: "B0R",
  YELLOW_RED: "0YR",
  RED: "00R"
};

export class Quadrant {
  leftXCord: number;
  topYCord: number;
  leftMargin: number;
  rightMargin: number;
  width: number;
  height: number;
  nodeHeight: number;
  constructor(
    leftXCord: number,
    topYCord: number,
    leftMargin: number,
    rightMargin: number,
    width: number,
    height: number,
    nodeHeight: number
  ) {
    this.leftXCord = leftXCord;
    this.topYCord = topYCord;
    this.leftMargin = leftMargin;
    this.rightMargin = rightMargin;
    this.width = width;
    this.height = height;
    this.nodeHeight = nodeHeight;
  }
  getXCordWithPercent(percent: number) {
    const width = this.calcUsableWidth();
    return this.leftXCord + this.leftMargin + (percent / 100) * width;
  }

  getYCordWithPercent(percent: number) {
    return this.topYCord + (percent / 100) * this.height;
  }

  getCordsForNodeLengthOne(node: any) {
    const nodesPositions = [];

    if (node.maskHumanReadable) {
      let addingUnit = 0;
      let topCord = 0;

      if (this.topYCord === 1200) {
        topCord = this.topYCord + 32.5;
        addingUnit = 200;
      } else if (this.topYCord === 530) {
        topCord = this.topYCord + 16.5;
        addingUnit = 135;
      } else if (this.topYCord === 2080) {
        topCord = this.topYCord + 10;
        addingUnit = 120;
      }

      if (node.maskHumanReadable === nodeColorConstants.BLUE) {
        // const heightOfSections = 780
        // const padding = (heightOfSections - 400) / 2;
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: this.topYCord + (heightOfSections * (i - 1) + padding)
          y: topCord
        });
      } else if (node.maskHumanReadable === nodeColorConstants.BLUE_YELLOW) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * 1
        });
      } else if (
        node.maskHumanReadable === nodeColorConstants.YELLOW ||
        node.maskHumanReadable === nodeColorConstants.BLUE_RED ||
        node.maskHumanReadable === nodeColorConstants.BLUE_YELLOW_RED
      ) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * 1.5
        });
      } else if (node.maskHumanReadable === nodeColorConstants.YELLOW_RED) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) : topCord + addingUnit * 2.5
        });
      } else if (node.maskHumanReadable === nodeColorConstants.RED) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) : (topCord + addingUnit * 3)
        });
      }
    } else {
      let addingUnit = 0;
      let topCord = 0;
      if (this.topYCord === 530) {
        topCord = this.topYCord + 12.5;
        addingUnit = 120;

        if (node.dataType === "0") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: topCord
          });
        } else if (node.dataType === "1") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: topCord + addingUnit
          });
        } else if (node.dataType === "2") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: topCord + addingUnit * 3
          });
        }
      }

      // const heightOfSections = this.height;
      // const padding = (heightOfSections - this.nodeHeight) / 2;
      // nodesPositions.push({
      //   x: this.getXCordWithPercent(0),
      //   y: this.topYCord  + padding
      // });
    }

    return nodesPositions;
  }

  getCordesForNodeLengthTwo(nodes: any) {
    const nodesPositions = [];
    const node1 = nodes[0];

    if (node1.maskHumanReadable) {
      let addingUnit = 0;
      let topCord = 0;

      if (this.topYCord === 1200) {
        topCord = this.topYCord + 32.5;
        addingUnit = 200;
      } else if (this.topYCord === 530) {
        topCord = this.topYCord + 16.25;
        addingUnit = 135;
      } else if (this.topYCord === 2080) {
        topCord = this.topYCord + 10;
        addingUnit = 120;
      }

      if (node1.maskHumanReadable === nodeColorConstants.BLUE) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1232.5
          y: topCord
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1427
          y: topCord + addingUnit * 1
        });
      } else if (node1.maskHumanReadable === nodeColorConstants.BLUE_YELLOW) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1329
          y: topCord + addingUnit * 1
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1525
          y: topCord + addingUnit * 2
        });
      } else if (
        node1.maskHumanReadable === nodeColorConstants.YELLOW ||
        node1.maskHumanReadable === nodeColorConstants.BLUE_RED ||
        node1.maskHumanReadable === nodeColorConstants.BLUE_YELLOW_RED
      ) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1427
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1) : (topCord + addingUnit * 1.5)
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1622.5
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) : (topCord + addingUnit * 3)
        });
      } else if (node1.maskHumanReadable === nodeColorConstants.YELLOW_RED) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1622.5
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1) : (topCord + addingUnit * 2)
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1820
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) : (topCord + addingUnit * 3)
        });
      } else if (node1.maskHumanReadable === nodeColorConstants.RED) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1622.5
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1): (topCord + addingUnit * 2)
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1820
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) : (topCord + addingUnit * 3)
        });
      }
    } else {
      // const numNodes = nodes.length;
      // const heightOfSections = this.height / numNodes;

      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].dataType === "0") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: 551.66
          });
        } else if (nodes[i].dataType === "1") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: 735
          });
        } else if (nodes[i].dataType === "2") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: 918.33
          });
        }
      }
    }

    return nodesPositions;
  }

  getCordesForNodeLengthThree(nodes: any) {
    const nodesPositions = [];
    const node1 = nodes[0];

    if (node1.maskHumanReadable) {
      let addingUnit = 0;
      let topCord = 0;

      if (this.topYCord === 1200) {
        topCord = this.topYCord + 32.5;
        addingUnit = 200;
      } else if (this.topYCord === 530) {
        topCord = this.topYCord + 16.25;
        addingUnit = 135;
      } else if (this.topYCord === 2080) {
        topCord = this.topYCord + 10;
        addingUnit = 120;
      }

      if (node1.maskHumanReadable === nodeColorConstants.BLUE) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * 1
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * 2
        });
      } else if (node1.maskHumanReadable === nodeColorConstants.BLUE_YELLOW) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * 0.5
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * 1.5
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * 2.5
        });
      } else if (node1.maskHumanReadable === nodeColorConstants.YELLOW) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y:(addingUnit=== 120) ? (topCord):  topCord + addingUnit * 1
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1) : topCord + addingUnit * 2
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1820
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) :topCord + addingUnit * 3
        });
      } else if (
        node1.maskHumanReadable === nodeColorConstants.BLUE_YELLOW_RED
      ) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y:(addingUnit=== 120) ? (topCord):  topCord + addingUnit * 1
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1) : topCord + addingUnit * 2
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1820
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) :topCord + addingUnit * 3
        });

      } else if (node1.maskHumanReadable === nodeColorConstants.YELLOW_RED) {
                nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y:(addingUnit=== 120) ? (topCord):  topCord + addingUnit * 1
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1) : topCord + addingUnit * 2
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1820
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) :topCord + addingUnit * 3
        });

      }
       else if (node1.maskHumanReadable === nodeColorConstants.BLUE_RED) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y:(addingUnit=== 120) ? (topCord):  topCord + addingUnit * 0.5
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1) : topCord + addingUnit * 1.5
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1820
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) :topCord + addingUnit * 2.5
        });
      } 
      else if (node1.maskHumanReadable === nodeColorConstants.RED) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y:(addingUnit=== 120) ? (topCord):  topCord + addingUnit * 1
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: (addingUnit=== 120) ? (topCord + addingUnit * 1) : topCord + addingUnit * 2
        });

        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          // y: 1820
          y: (addingUnit=== 120) ? (topCord + addingUnit * 2) :topCord + addingUnit * 3
        });
      }
    } else {

      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].dataType === "0") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: 541.66
          });
        } else if (nodes[i].dataType === "1") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: 736
          });
        } else if (nodes[i].dataType === "2") {
          nodesPositions.push({
            x: this.getXCordWithPercent(0),
            y: 930.33
          });
        }
      }
    }
    return nodesPositions;
  }

  getCordsForNodeLengthFour(nodes: any) {
    const nodesPositions = [];
    const node1 = nodes[0];

    if (node1.maskHumanReadable) {
      let addingUnit = 0;
      let topCord = 0;

      if (this.topYCord === 1200) {
        topCord = this.topYCord + 32.5;
        addingUnit = 200;
      } else if (this.topYCord === 530) {
        topCord = this.topYCord + 16.25;
        addingUnit = 135;
      } else if (this.topYCord === 2080) {
        topCord = this.topYCord + 10;
        addingUnit = 120;
      }

      for (let i = 0; i < nodes.length; i++) {
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: topCord + addingUnit * i
        });
      }
    } 
    else {
      const numNodes = nodes.length;
      const heightOfSections = this.height / numNodes;
      for (let i = 1; i <= numNodes; i++) {
        const padding = (heightOfSections - this.nodeHeight) / 2;
        nodesPositions.push({
          x: this.getXCordWithPercent(0),
          y: this.topYCord + (heightOfSections * (i - 1) + padding)
        });
      }
    }

    return nodesPositions;
  }

  getCordsForNodes(nodes: any): Array<Position> {
      if (nodes.length === 1) {
        const node = nodes[0];
        return this.getCordsForNodeLengthOne(node);
      } else if (nodes.length === 2) {
        return this.getCordesForNodeLengthTwo(nodes);
      } else if (nodes.length === 3) {
        return this.getCordesForNodeLengthThree(nodes);
      } else if (nodes.length === 4) {
        return this.getCordsForNodeLengthFour(nodes);
      } 
      else {
        return []
      }
  }

  // getRetentionCodesForNodes(numNodes: number) {
  //   const nodesPositions = [];
  //   if (numNodes > 0 && numNodes < 5) {
  //     const heightOfSections = this.height / numNodes;
  //     for (let i = 1; i <= numNodes; i++) {
  //       const padding = (heightOfSections - this.nodeHeight) / 2;
  //       nodesPositions.push({
  //         x: this.getXCordWithPercent(0),
  //         y: this.topYCord + (heightOfSections * (i - 1) + padding)
  //       });
  //     }
  //   }
  //   return nodesPositions;
  // }

  calcUsableWidth() {
    return this.width - this.leftMargin - this.rightMargin;
  }
}

export const CustomerDataInputGrid = new Quadrant(
  244,
  TopYCustomerRow,
  110,
  0,
  773,
  550,
  140
);

export const CustomerDataProcessingOneGrid = new Quadrant(
  DataProcessingColumnOneX,
  TopYCustomerRow,
  20,
  20,
  DataProcessingColumnWidth,
  CustomerRowHeight,
  StandardNodeHeight
);

export const CustomerDataProcessingTwoGrid = new Quadrant(
  DataProcessingColumnTwoX,
  TopYCustomerRow,
  20,
  20,
  DataProcessingColumnWidth,
  CustomerRowHeight,
  StandardNodeHeight
);

export const CustomerDataProcessingThreeGrid = new Quadrant(
  DataProcessingColumnThreeX,
  TopYCustomerRow,
  20,
  20,
  DataProcessingColumnWidth,
  CustomerRowHeight,
  StandardNodeHeight
);

export const CiscoDataProcessingOneGrid = new Quadrant(
  DataProcessingColumnOneX,
  TopYCiscoRow,
  20,
  20,
  DataProcessingColumnWidth,
  CiscoRowHeight,
  StandardNodeHeight
);

export const CiscoDataProcessingTwoGrid = new Quadrant(
  DataProcessingColumnTwoX,
  TopYCiscoRow,
  20,
  20,
  DataProcessingColumnWidth,
  CiscoRowHeight,
  StandardNodeHeight
);

export const CiscoDataProcessingThreeGrid = new Quadrant(
  DataProcessingColumnThreeX,
  TopYCiscoRow,
  20,
  20,
  DataProcessingColumnWidth,
  CiscoRowHeight,
  StandardNodeHeight
);

export const SubDataProcessingOneGrid = new Quadrant(
  DataProcessingColumnOneX,
  TopYSubprocessorRow,
  20,
  20,
  DataProcessingColumnWidth,
  SubprocessorRowHeight,
  StandardNodeHeight
);

export const SubDataProcessingTwoGrid = new Quadrant(
  DataProcessingColumnTwoX,
  TopYSubprocessorRow,
  20,
  20,
  DataProcessingColumnWidth,
  SubprocessorRowHeight,
  StandardNodeHeight
);

export const SubDataProcessingThreeGrid = new Quadrant(
  DataProcessingColumnThreeX,
  TopYSubprocessorRow,
  20,
  20,
  DataProcessingColumnWidth,
  SubprocessorRowHeight,
  StandardNodeHeight
);

const DeletionCiscoSharedInfo = [
  0,
  0,
  DeletionColumnWidth,
  CiscoRowHeight,
  StandardNodeHeight
];

export const DeletionCiscoOneGrid = new Quadrant(
  DeletionColumnOneX,
  TopYCiscoRow,
  ...DeletionCiscoSharedInfo
);

export const DeletionCiscoTwoGrid = new Quadrant(
  DeletionColumnOneX + DeletionColumnWidth,
  TopYCiscoRow,
  ...DeletionCiscoSharedInfo
);

export const DeletionCiscoThreeGrid = new Quadrant(
  DeletionColumnOneX + 2 * DeletionColumnWidth,
  TopYCiscoRow,
  ...DeletionCiscoSharedInfo
);

export const DeletionCiscoFourGrid = new Quadrant(
  DeletionColumnOneX + 3 * DeletionColumnWidth,
  TopYCiscoRow,
  ...DeletionCiscoSharedInfo
);

export const DeletionCiscoFiveGrid = new Quadrant(
  DeletionColumnOneX + 4 * DeletionColumnWidth,
  TopYCiscoRow,
  ...DeletionCiscoSharedInfo
);

export const DeletionCiscoSixGrid = new Quadrant(
  DeletionColumnOneX + 5 * DeletionColumnWidth,
  TopYCiscoRow,
  ...DeletionCiscoSharedInfo
);

const DeletionSubSharedInfo = [
  0,
  0,
  DeletionColumnWidth,
  SubprocessorRowHeight,
  StandardNodeHeight
];

export const DeletionSubOneGrid = new Quadrant(
  DeletionColumnOneX,
  TopYSubprocessorRow,
  ...DeletionSubSharedInfo
);

export const DeletionSubTwoGrid = new Quadrant(
  DeletionColumnOneX + DeletionColumnWidth,
  TopYSubprocessorRow,
  ...DeletionSubSharedInfo
);

export const DeletionSubThreeGrid = new Quadrant(
  DeletionColumnOneX + 2 * DeletionColumnWidth,
  TopYSubprocessorRow,
  ...DeletionSubSharedInfo
);

export const DeletionSubFourGrid = new Quadrant(
  DeletionColumnOneX + 3 * DeletionColumnWidth,
  TopYSubprocessorRow,
  ...DeletionSubSharedInfo
);

export const DeletionSubFiveGrid = new Quadrant(
  DeletionColumnOneX + 4 * DeletionColumnWidth,
  TopYSubprocessorRow,
  ...DeletionSubSharedInfo
);

export const DeletionSubSixGrid = new Quadrant(
  DeletionColumnOneX + 5 * DeletionColumnWidth,
  TopYSubprocessorRow,
  ...DeletionSubSharedInfo
);

export const HeaderGrid = new Quadrant(
  0,
  0,
  20,
  20,
  4518,
  HeaderHeight,
  StandardNodeHeight
);
