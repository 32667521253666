import React, { Component } from "react";
import "./index.scss";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Error from "../svgIcons/error";
import Warning from "../svgIcons/warning";
import { bindActionCreators } from "redux";
import Snackbar from "@material-ui/core/Snackbar";
import { editRetentionNodes } from "../../actions/reviewAction";
import {
  addCiscoRetentionNodes,
  addSubprocessorRetentionNodes,
} from "../../actions/retentionNodesAction";
import { saveRetentionNodeData, toggleIsUpdated } from "../../actions/metaDataAction";

const controller0 = "0";
const controller1 = "2";

const controlleroptions = [
  { value: "0", label: "Cisco" },
  { value: "2", label: "Subprocessor" },
];

const timelineoptions = [
  { value: "0", label: "No retention" },
  { value: "1", label: "Days" },
  { value: "2", label: "Weeks" },
  { value: "3", label: "Months" },
  { value: "4", label: "Years" },
  { value: "5", label: "By request" },
];
class RetentionNodeSection extends Component<any, any> {
  constructor(props) {
    super(props);
    this.handleAddNode = this.handleAddNode.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
  }

  state = {
    selectedControlledOption: { value: "Cisco", label: "cisco" },
    selectedTimelineoption: { value: "No retention", label: "No retention" },
    dataTypes: [],
    controller: "0",
    timeline: "0",
    nodeCopy: "",
    cisconodes: [],
    subprocessornodes: [],
    selectedController: "",
    selectedIndex: "",
    error: false,
    warning: false,
    openGoBack: false,
    openDeleteNode: false,
    showAddNodeButton: true,
    showUpdateNodeButton: false,
    showSnackbar: false,
    openAddNodeError: false,
    openPartiallyFilled: false,
    registrationDataType: "",
    generatedDataType: "",
    hostUsageDataType: "",
    changed: false,
    checkIndex: "0",
    checkController: "",
    showUpdateSnackbar: false,
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.editData) {
      const { editData } = this.props;
      if (editData.node && editData.node === controller0) {
        let data = this.state.cisconodes[editData.nodeIndex];
        this.setState({
          dataTypes: data.dataTypes,
          controller: data.controller,
          timeline: data.timeline,
          nodeCopy: data.nodeCopy,
          showUpdateNodeButton: true,
          selectedIndex: editData.nodeIndex,
          selectedController: editData.node,
        });
      } else if (editData.node && editData.node === controller1) {
        let data = this.state.subprocessornodes[editData.nodeIndex];
        this.setState({
          dataTypes: data.dataTypes,
          controller: data.controller,
          timeline: data.timeline,
          nodeCopy: data.nodeCopy,
          showUpdateNodeButton: true,
          selectedIndex: editData.nodeIndex,
          selectedController: editData.node,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { ciscoNodes, subprocessorNodes } = nextProps;
      if (ciscoNodes) {
        this.setState({
          cisconodes: ciscoNodes,
        });
      }
      if (subprocessorNodes) {
        this.setState({
          subprocessornodes: subprocessorNodes,
        });
      }
    }
  }

  UNSAFE_componentWillMount() {
    const { ciscoNodes, subprocessorNodes, dataTypes } = this.props;
    if (dataTypes && dataTypes.length > 0) {
      dataTypes.forEach((dt, i) => {

        if (i === 0) {
          this.setState({
            registrationDataType: dt.dataType,
          })
        }
        else if (i === 1) {
          this.setState({
            generatedDataType: dt.dataType,
          })
        }
        else if (i === 2) {
          this.setState({
            hostUsageDataType: dt.dataType,
          })
        }
      })
      // this.setState({
      //   registrationDataType:
      //     dataTypes.registrationData && dataTypes.registrationData.datatype,
      //   generatedDataType:
      //     dataTypes.generatedData && dataTypes.generatedData.datatype,
      //   hostUsageDataType:
      //     dataTypes.hostUsageData && dataTypes.hostUsageData.datatype,
      // });
    }
    if (ciscoNodes) {
      this.setState({
        cisconodes: ciscoNodes,
      });
    }
    if (subprocessorNodes) {
      this.setState({
        subprocessornodes: subprocessorNodes,
      });
    }
  }

  componentWillUnmount() {
    this.props.editRetentionNodes({});
  }

  saveRetentionNodes() {

    if (this.props.isEdit) {
      this.props.toggleIsUpdated(true);
    }
    else {
      const {
        ciscoNodes,
        subprocessorNodes,
      } = this.props;

      console.log(ciscoNodes);
      console.log(subprocessorNodes);

      let nodes = []

      if (ciscoNodes) {
        nodes.push(...ciscoNodes);
      }

      if (subprocessorNodes) {
        nodes.push(...subprocessorNodes);
      }
      this.props.saveRetentionNodeData(nodes);
    }

  }

  getRetentionNodeDataType = (dataTypes) => {
    let sorted = dataTypes.sort();
    let sortedJoin = sorted.join("");
    return (
      <div>
        {sortedJoin === "012" ? (
          <div>
            <div className="Registration height-10 border-top-left-right-8" />
            <div className="Generated height-10" />
            <div className="Host-and-Usage height-10" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "01" ? (
          <div>
            <div className="Registration height-15 border-top-left-right-8" />
            <div className="Generated height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "02" ? (
          <div>
            <div className="Registration height-15 border-top-left-right-8" />
            <div className="Host-and-Usage height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "12" ? (
          <div>
            <div className="Generated height-15 border-top-left-right-8" />
            <div className="Host-and-Usage height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "0" ? (
          <div>
            <div className="Registration height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "1" ? (
          <div>
            <div className="Generated height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "2" ? (
          <div>
            <div className="Host-and-Usage height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  // Loads the INITIAL STATE
  loadInitialData() {
    this.setState({
      controller: "0",
      timeline: "0",
      dataTypes: [],
      nodeCopy: "",
      showUpdateNodeButton: false,
      changed: false,
    });
  }

  ValidateNode() {
    if (!this.state.nodeCopy) {
      return false;
    } else if (!this.state.dataTypes.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  handleAddNode() {
    let isNodevalid = this.ValidateNode;
    if (isNodevalid) {
      let node = {
        dataTypes: this.state.dataTypes,
        controller: this.state.controller,
        timeline: this.state.timeline,
        nodeCopy: this.state.nodeCopy,
      };
      if (this.state.controller === "0") {
        let nodes = [...this.state.cisconodes];
        nodes.push(node);
        this.props.addCiscoRetentionNodes(nodes);
      } else if (this.state.controller === "2") {
        let nodes = [...this.state.subprocessornodes];
        nodes.push(node);
        this.props.addSubprocessorRetentionNodes(nodes);
      }
      this.loadInitialData();
      this.setState({
        showSnackbar: true,
        changed: false,
      });
      window.retentionNodeCount++;
      console.log(window.retentionNodeCount)
    } else {
      console.log("input fields are empty");
    }
  }

  handleOpenPopUpDelete = (controller, index) => {
    this.setState({
      selectedController: controller,
      selectedIndex: index,
      openDeleteNode: true,
    });
  };
  isPartialFilled = () => {
    if (!this.state.nodeCopy && this.state.dataTypes.length > 0) {
      return true;
    }
    if (this.state.nodeCopy && !this.state.dataTypes.length > 0) {
      return true;
    }
    if (this.state.nodeCopy && this.state.dataTypes.length > 0) {
      return true;
    }

    return false;
  };

  handleDeleteNode = () => {
    if (this.state.selectedController === controller0) {
      let nodes = [...this.state.cisconodes];
      nodes.splice(this.state.selectedIndex, 1);
      this.props.addCiscoRetentionNodes(nodes);
    } else if (this.state.selectedController === controller1) {
      let nodes = [...this.state.subprocessornodes];
      nodes.splice(this.state.selectedIndex, 1);
      this.props.addSubprocessorRetentionNodes(nodes);
    }
    this.loadInitialData();
  };
  handleCheckClick = (controller, index) => {
    const isPartialFilled = this.isPartialFilled();
    if (isPartialFilled && this.state.changed) {
      this.setState({
        openPartiallyFilled: true,
        checkIndex: index,
        checkController: controller,
      });
    } else {
      this.handleOnClickNode(controller, index);
    }
  };

  handleOnClickNode = (controller, index) => {
    if (controller === controller0) {
      let data = this.state.cisconodes[index];
      this.setState({
        dataTypes: data.dataTypes,
        controller: data.controller,
        timeline: data.timeline,
        nodeCopy: data.nodeCopy,
      });
    } else if (controller === controller1) {
      let data = this.state.subprocessornodes[index];
      this.setState({
        dataTypes: data.dataTypes,
        controller: data.controller,
        timeline: data.timeline,
        nodeCopy: data.nodeCopy,
      });
    }

    this.setState({
      showUpdateNodeButton: true,
      selectedIndex: index,
      selectedController: controller,
      changed: false,
    });
  };

  handleUpdateNode = () => {
    if (this.state.controller !== this.state.selectedController) {
      if (this.state.selectedController === controller0) {
        let nodes = [...this.state.cisconodes];
        nodes.splice(this.state.selectedIndex, 1);
        this.props.addCiscoRetentionNodes(nodes);
      } else if (this.state.selectedController === controller1) {
        let nodes = [...this.state.subprocessornodes];
        nodes.splice(this.state.selectedIndex, 1);
        this.props.addSubprocessorRetentionNodes(nodes);
      }

      let node = {
        dataTypes: this.state.dataTypes,
        controller: this.state.controller,
        timeline: this.state.timeline,
        nodeCopy: this.state.nodeCopy,
      };
      if (this.state.controller === "0") {
        let nodes = [...this.state.cisconodes];
        nodes.push(node);
        this.props.addCiscoRetentionNodes(nodes);
      } else if (this.state.controller === "2") {
        let nodes = [...this.state.subprocessornodes];
        nodes.push(node);
        this.props.addSubprocessorRetentionNodes(nodes);
      }
    } else {
      if (this.state.selectedController === controller0) {
        let nodes = [...this.state.cisconodes];
        let data = nodes[this.state.selectedIndex];
        data.dataTypes = this.state.dataTypes;
        data.controller = this.state.controller;
        data.timeline = this.state.timeline;
        data.nodeCopy = this.state.nodeCopy;
        data.accessors = this.state.accessors;
        nodes[this.state.selectedIndex] = data;
        this.props.addCiscoRetentionNodes(nodes);
      } else if (this.state.selectedController === controller1) {
        let nodes = [...this.state.subprocessornodes];
        let data = nodes[this.state.selectedIndex];
        data.dataTypes = this.state.dataTypes;
        data.controller = this.state.controller;
        data.timeline = this.state.timeline;
        data.nodeCopy = this.state.nodeCopy;
        data.accessors = this.state.accessors;
        nodes[this.state.selectedIndex] = data;
        this.props.addSubprocessorRetentionNodes(nodes);
      }
    }
    this.setState({
      showUpdateSnackbar: true,
    });
    this.loadInitialData();
  };

  handleChange(value) {
    let checkList = [...this.state.dataTypes];
    const index = checkList.indexOf(value);
    if (index > -1) {
      checkList.splice(index, 1);
    } else {
      checkList.push(value);
    }
    if (checkList.length > 0) {
      this.setState({
        dataTypes: checkList,
        dataTypeError: false,
        changed: true,
      });
    } else {
      this.setState({
        dataTypes: checkList,
        dataTypeError: true,
        changed: true,
      });
    }
  }

  changeprod = (event) => {
    this.setState({
      nodeCopy: event.target.value,
      error: false,
      changed: true,
    });
  };

  message = (event) => {
    if (!event.target.value) {
      this.setState({
        error: true,
      });
    } else {
      this.setState({
        error: false,
      });
    }
  };

  handlecontrolleroptionsChange = (selectedControlledOption) => {
    this.setState({
      controller: selectedControlledOption.value,
      changed: true,
    });
  };
  handletimelineoptionsChange = (selectedTimelineoption) => {
    this.setState({ timeline: selectedTimelineoption.value, changed: true });
  };
  islocalandstateEqual = () => {
    if (this.state.selectedController === this.state.controller) {
      if (this.state.controller === "0") {
        let selectedNode = this.state.cisconodes[this.state.selectedIndex];
        if (
          selectedNode.nodeCopy === this.state.nodeCopy &&
          selectedNode.dataTypes === this.state.dataTypes &&
          selectedNode.controller === this.state.controller &&
          selectedNode.timeline === this.state.timeline
        ) {
          return true;
        }
      } else if (this.state.controller === "2") {
        let selectedNode = this.state.subprocessornodes[
          this.state.selectedIndex
        ];
        if (
          selectedNode.nodeCopy === this.state.nodeCopy &&
          selectedNode.dataTypes === this.state.dataTypes &&
          selectedNode.controller === this.state.controller &&
          selectedNode.timeline === this.state.timeline
        ) {
          return true;
        }
      } else return false;
    }

    return false;
  };

  validateretensionNode() {
    // if (
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.subprocessornodes.length > 0) &&
    //   this.state.showUpdateNodeButton
    // ) {
    //   const islocalandstateEqual = this.islocalandstateEqual();
    //   if (islocalandstateEqual) {
    //     return true;
    //   } else {
    //     this.setState({
    //       openGoBack: true,
    //     });
    //     return false;
    //   }
    // }
    // if (
    //   !this.state.cisconodes.length > 0 &&
    //   !this.state.subprocessornodes.length > 0
    // ) {
    //   this.setState({
    //     openAddNodeError: true,
    //   });
    //   return false;
    // }
    // if (
    //   !this.state.nodeCopy &&
    //   this.state.dataTypes.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.subprocessornodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.nodeCopy &&
    //   !this.state.dataTypes.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.subprocessornodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.nodeCopy &&
    //   this.state.dataTypes.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.subprocessornodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    return true;
  }

  render() {
    const isEnabled =
      this.state.dataTypes.length > 0 &&
      this.state.nodeCopy &&
      this.state.timeline.length > 0 &&
      this.state.controller.length > 0;
    return (
      <div style={{
        display: "flex", justifyContent: "space-evenly", paddingBottom: "15%",
        overflow: "auto", height: "500px"
      }}>
        <Dialog
          open={this.state.openPartiallyFilled}
          onClose={() => {
            this.setState({
              openPartiallyFilled: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You have unsaved changes. Are you sure you want to proceed?
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openPartiallyFilled: false,
                  });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => {
                  this.handleOnClickNode(
                    this.state.checkController,
                    this.state.checkIndex
                  );
                  this.setState({
                    openPartiallyFilled: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Proceed
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openAddNodeError}
          onClose={() => {
            this.setState({
              openAddNodeError: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Please create at least one retention node.
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className="primary-button"
                onClick={() => {
                  this.setState({
                    openAddNodeError: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className="snackbar"
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({
              showSnackbar: false,
            });
          }}
          message="Node successfully added."
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className="snackbar"
          open={this.state.showUpdateSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({
              showUpdateSnackbar: false,
            });
          }}
          message="Node successfully updated."
        />
        <Dialog
          open={this.state.openGoBack}
          onClose={() => {
            this.setState({
              openGoBack: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You have unsaved changes. Are you sure you want to proceed?
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openGoBack: false,
                  });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => {
                  this.props.handleNext();
                  this.setState({
                    openGoBack: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Proceed
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        {/* Delete */}
        <Dialog
          open={this.state.openDeleteNode}
          onClose={() => {
            this.setState({
              openDeleteNode: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this node?
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openDeleteNode: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                className="danger-button"
                onClick={() => {
                  this.handleDeleteNode();
                  this.setState({
                    openDeleteNode: false,
                  });
                }}
                color="primary"
              >
                Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <div
          style={{
            width: "50%",
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "3%",
          }}
        >
          <div className="input-label" style={{ paddingBottom: "10px" }}>
            Data Type <span className="color-red">*</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={this.state.dataTypes.includes("0")}
              onChange={() => this.handleChange("0")}
              value="primary"
              style={{ color: "black" }}
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#4EB3DF",
              }}
            />
            <div className="input-active">
              {this.state.registrationDataType}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={this.state.dataTypes.includes("1")}
              onChange={() => this.handleChange("1")}
              value=""
              style={{ color: "black" }}
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#ffc435",
              }}
            />
            <div className="input-active">{this.state.generatedDataType}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={this.state.dataTypes.includes("2")}
              onChange={() => this.handleChange("2")}
              value=""
              style={{ color: "black" }}
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#e03c2d",
              }}
            />
            <div className="input-active">{this.state.hostUsageDataType}</div>
          </div>
          {this.state.dataTypeError ? (
            <div className="Error-color">
              Please select at least one data type.
            </div>
          ) : (
            ""
          )}
          <div
            className="input-label"
            style={{ paddingBottom: "20px", paddingTop: "40px" }}
          >
            Controller <span className="color-red">*</span>
          </div>
          <div className="rn-dropdown">
            <Select
              value={controlleroptions[parseInt(this.state.controller)]}
              onChange={this.handlecontrolleroptionsChange}
              options={controlleroptions}
            />
          </div>
          <div
            className="input-label"
            style={{ paddingBottom: "20px", paddingTop: "40px" }}
          >
            Timeline <span className="color-red">*</span>
          </div>
          <div className="rn-dropdown">
            <Select
              value={timelineoptions[parseInt(this.state.timeline)]}
              onChange={this.handletimelineoptionsChange}
              options={timelineoptions}
            />
          </div>
          <div
            className="input-label"
            style={{ paddingBottom: "20px", paddingTop: "40px" }}
          >
            Node Text <span className="color-red">*</span>
          </div>
          <div className="border-bottom-1 input-wrapper width-428">
            <input
              value={this.state.nodeCopy}
              placeholder="e.g., Provide the service"
              className="input-text-active width-428"
              id="test"
              onBlur={this.message}
              onChange={this.changeprod}
            />
            {this.state.error ? <Error /> : ""}
            {this.state.nodeCopy.length >= 60 ? <Warning /> : ""}
          </div>
          {this.state.error ? (
            <div style={{ color: "#CD0000", width: "428px" }}>
              Please enter the text that should appear in the node.
            </div>
          ) : (
            ""
          )}
          {this.state.nodeCopy.length >= 60 &&
            this.state.nodeCopy.length < 100 ? (
            <div style={{ color: "#916C00", width: "428px" }}>
              Node text size will be smaller if character count exceeds 60.
            </div>
          ) : (
            ""
          )}
          {this.state.nodeCopy.length >= 100 ? (
            <div style={{ color: "#916C00", width: "428px" }}>
              Node will expand beyond its usual size if character count exceeds
              100.
            </div>
          ) : (
            ""
          )}
          <p className="helper-text">
            Character count: {this.state.nodeCopy.length}/60
          </p>
          <div
            style={{
              width: "428px",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
              paddingTop: "40px",
            }}
          />
          <div
            style={{
              width: "428px",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
              paddingTop: "40px",
            }}
          >
            {this.state.showUpdateNodeButton ? (
              <div>
                <Button
                  variant="contained"
                  disabled={!isEnabled}
                  className="primary-button"
                  // onClick={() => {this.handleAddNode}}
                  onClick={() => this.handleUpdateNode()}
                >
                  Update Node
                </Button>{" "}
                <div
                  className="cursor-pointer"
                  style={{ textAlign: "center" }}
                  onClick={() => this.loadInitialData()}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <Button
                variant="contained"
                disabled={!isEnabled}
                className="primary-button"
                onClick={() => this.handleAddNode()}
              >
                Add Node
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            width: "30%",
            height: "calc(100vh - 160px)",
            minHeight: "calc(100vh - 160px)",
            overflowY: "auto",
            backgroundColor: "#E8EBF1",
            paddingTop: "5%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "70%", textAlign: "left" }}>
              {this.state.cisconodes.length > 0 ? "Cisco" : ""}
            </div>
            <div style={{ width: "70%", height: "max-content" }}>
              {this.state.cisconodes.map((node, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div className="padding15-processingnode">
                        {this.getRetentionNodeDataType(node.dataTypes)}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.handleCheckClick(controller0, index)
                          }
                        >
                          <div
                            className="text-processingnode"
                            style={{
                              fontSize: "14px",
                              color: "#39393B",
                              fontFamily: "CiscoSansTTRegular",
                            }}
                          >
                            {node.nodeCopy}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        fontStyle: "italic",
                        textDecoration: "underline",
                        color: "black",
                      }}
                      onClick={() =>
                        this.handleOpenPopUpDelete(controller0, index)
                      }
                    >
                      <Error />
                    </div>
                  </div>
                );
              })}
            </div>

            <div style={{ width: "70%", textAlign: "left" }}>
              {this.state.subprocessornodes.length > 0 ? "Subprocessor" : ""}
            </div>
            <div style={{ width: "70%", height: "max-content" }}>
              {this.state.subprocessornodes.map((node, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div className="padding15-processingnode">
                        {this.getRetentionNodeDataType(node.dataTypes)}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.handleCheckClick(controller1, index)
                          }
                        >
                          <div
                            className="text-processingnode"
                            style={{
                              fontSize: "14px",
                              color: "#39393B",
                              fontFamily: "CiscoSansTTRegular",
                            }}
                          >
                            {node.nodeCopy}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        fontStyle: "italic",
                        textDecoration: "underline",
                        color: "black",
                      }}
                      onClick={() =>
                        this.handleOpenPopUpDelete(controller1, index)
                      }
                    >
                      <Error />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCiscoRetentionNodes,
      addSubprocessorRetentionNodes,
      editRetentionNodes,
      saveRetentionNodeData,
      toggleIsUpdated
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    ciscoNodes: state.retentionnodes.ciscoNodes,
    subprocessorNodes: state.retentionnodes.subprocessorNodes,
    dataTypes: state.productInfo.data && state.productInfo.data.dataTypes,
    editData: state.editData.editRN,
    isEdit: state.metaData.isEditFlow
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetentionNodeSection);
