// @flow

// Datatypes for JSON Schema
import React from 'react';

type IdNameTuple = {
  id: string,
  name: string
};

export type Config = {
  dataTypes: Array<DataType>,
  controllers: Array<Controller>,
  accessors: Array<Accessor>
};

export type Controller = IdNameTuple;

export type Contract = [
  $PropertyType<Controller, "id">,
  $PropertyType<Controller, "id">
];

export type Subcategory = {
  id: "0" | "1" | "2",
  name: "Admin/Logistics" | "Provide Services" | "Service Related/Other"
};

export type DataType = IdNameTuple & {
  color: string
};

export type Accessor = IdNameTuple;

export type Position = {
  x: number,
  y: number
};

export type RetentionDuration = {
  id: "0" | "1" | "2" | "3" | "4" | "5",
  meaning: "No retention" | "Days" | "Weeks" | "Months" | "Years" | "By request"
};

export type InputNode = {
  dataType: $PropertyType<DataType, "id">,
  iconSvgString: string,
  nodeCopy: string,
  dataAssets: string
};

export type ProcessingNode = {
  id: string,
  dataTypes: Array<$PropertyType<DataType, "id">>,
  controller: $PropertyType<Controller, "id">,
  accessors: Array<$PropertyType<Accessor, "id">>,
  subcategory: $PropertyType<Subcategory, "id">,
  dataSheetSection: 2 | 4,
  dataSheetCopy: string,
  nodeCopy: string,
  pos: Position
};

export type RetentionNode = {
  id: string,
  dataTypes: Array<$PropertyType<DataType, "id">>,
  timeline: $PropertyType<RetentionDuration, "id">,
  controller: $PropertyType<Controller, "id">,
  nodeCopy: string,
  exactCopy: string,
  pos: Position
};

export type AdditionalCallout = {
  dataTypes: Array<$PropertyType<DataType, "id">>,
  copy: string,
  pos?: Position
};

export type GeneralInfo = {
  productName: string,
  dataSheetVersion: string,
  dataSheetURL: string,
  date: string,
  contracts?: {
    customerToSubprocessor?: boolean
  },
  callout: boolean,
  calloutCopy?: string,
  calloutDataTypes?: Array<$PropertyType<DataType, "id">>
};

export type newGeneralInfo = {
  productName: string,
  dataSheetVersion: string,
  dataSheetURL: string,
  date: string

}

export type productionInfo = {
  dataTypes: [];
  contracts?: {
    customerToSubprocessor?: boolean
  },
  callout: boolean,
  calloutCopy?: string,
  calloutDataTypes?: Array<$PropertyType<DataType, "id">>
}

export const ProcessingNodeIndividualEntryPrefix = "processNodeForm";
export const RetentionNodeIndividualEntryPrefix = "retentionNodeForm";

export const MapStatus = {
  DRAFT: "draft",
  REVIEW: "review",
  PUBLISHED: "published",
  APPROVED: "approved"
}

export const Messages = {
  UPLOAD: "Data sheet imported from Trust Center",
  TRANSFER_EDITOR: "DataMaps Current Editor Changed",
  REVIEW: "in review status"
}

export const navTitles = [
  {
    title: "All Maps",
    icon: <img src='./icons/allmaps.svg' width="30px" height="30px" />
  },
  {
    title: "My Drafts",
    icon: <img src='./icons/draft.svg' width="30px" height="30px" />
  },
  {
    title: "To Review",
    icon: <img src='./icons/review.svg' width="35px" height="35px" />
  },
  {
    title: "Starred",
    icon: <img src='./icons/star.svg' width="22px" height="22px" style={{ marginLeft: "7px" }} />
  },
]

export const stepperTitles = [
  'Product Information',
  'Data Centers',
  'Data Input',
  'Processing Nodes',
  'Retention Nodes',
  'Review'
]

export const ImgSliderValues = {
  minWidth: 500,
  maxwidth: 800
}

export const SlidingAction = {
  INC: "inc",
  DEC: "dec"

}

export const SplitBtns = {
  CREATE_NEW_MAP : "NEW MAP",
  CONVERT_MAP : "CONVERT MAP"
}

export const StatusChipColors = {
  REVIEW : "#9FD9F2",
  DRAFT: "#DCEC2B",
  APPROVED: "#B7EB84",

}

export const AvatarColors = [
  "#DCEC2B",
  "#9FD9F2",
  "#FAB5E2",
  "#F4CB61",
  "#CDB9EA",
  "#B7EB84",
  "#FBB1B1"
]