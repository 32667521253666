import { SET_USER_INFO, SET_USERS, SET_USER_ORG } from "./constant";
import * as api from "../apis/datamaps.api";

export function setUserInfo(data) {
  return {
    type: SET_USER_INFO,
    data: data
  }
}

export function getUsersByOrgId(emailId) {

  return async (dispatch) => {
    console.log("calling get users by org id api...")
    const response = await api.getUserByOrgId(emailId);
    dispatch({
      type: SET_USERS,
      data: response.data
    });
  }
}

export function getUserOrgInfo(emailId) {
  return async (dispatch) => {
    console.log("calling get user org info api...")
    const response = await api.getUserOrgInfo(emailId);
    dispatch({
      type: SET_USER_ORG,
      data: response.data
    });
  }

}