/* eslint-disable no-useless-escape */
// @flow

import React from "react";

const ProcessingNodesSvg = (props: any) => {
   const { accessors} = props
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        paddingTop: "10px",
        paddingRight: "10px",
        paddingLeft: "10px"
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="30px"
        height="30px"
        viewBox="0 0 48 48"
        enableBackground="new 0 0 48 48"
        space="preserve"
        opacity={  accessors && accessors.includes("0") ? "1": "0.3"}
      >
        <g>
          <polygon points="24,2 24,2 24.001,2 	" />
          <path
            d="M44.648,37.734c-0.066-0.398-0.406-0.889-0.755-1.091l-12.691-7.364c-0.349-0.201-0.752-0.675-0.895-1.052
		l-1.495-3.348c2.104-1.487,3.43-6.356,3.43-9.13v-5.5C32.242,5.714,28.536,2,24,2c-4.535,0-8.241,3.714-8.241,8.25v5.5
		c0,2.773,1.324,7.643,3.43,9.13l-1.495,3.348c-0.144,0.377-0.546,0.851-0.896,1.052L4.108,36.644
		c-0.35,0.202-0.689,0.692-0.756,1.091L2.009,46h43.982L44.648,37.734z"
          />
        </g>
      </svg>
      <svg
        version="1.1"
        id="briefcase"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="30px"
        height="30px"
        viewBox="0 0 48 48"
        enableBackground="new 0 0 48 48"
        space="preserve"
        opacity={ accessors && accessors.includes("1") ? "1": "0.3"}
      >
        <g>
          <path d="M0,13v28c0,1.1,0.9,2,2,2h2V11H2C0.9,11,0,11.9,0,13z" />
          <path d="M46,11h-2v32h2c1.1,0,2-0.9,2-2V13C48,11.9,47.1,11,46,11z" />
          <path d="M35,5c0-1.1-0.9-2-2-2H15c-1.1,0-2,0.9-2,2v6H8v32h32V11h-5V5z M31,11H17V7h14V11z" />
        </g>
      </svg>
      <svg
        x="0px"
        y="0px"
        width="30px"
        height="30px"
        viewBox="0 0 50 29"
        enableBackground="new 0 0 50 29"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        opacity={ accessors && accessors.includes("2") ? "1": "0.3"}
      >
        <title>cisco</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="cisco" fill="#7F7F89" fillRule="nonzero">
            <polygon
              stroke="none"
              fill="#57585b"
              id="Fill-1"
              points="14.1616545 18.7548387 16.2181061 18.7548387 16.2181061 28.5435484 14.1616545 28.5435484"
            />
            <path
              d="M34.3971384,21.633871 C34.3148803,21.5516129 33.5745577,21.1403226 32.4229448,21.1403226 C30.9422997,21.1403226 29.8729448,22.2096774 29.8729448,23.6903226 C29.8729448,25.0887097 30.8600416,26.2403226 32.4229448,26.2403226 C33.4922997,26.2403226 34.3148803,25.8290323 34.3971384,25.7467742 L34.3971384,28.2967742 C34.0681061,28.3790323 33.3277835,28.6258065 32.2584287,28.6258065 C29.6261706,28.6258065 27.3229448,26.733871 27.3229448,23.6903226 C27.3229448,20.8935484 29.3793964,18.7548387 32.2584287,18.7548387 C33.3277835,18.7548387 34.1503642,19.0016129 34.3971384,19.083871 L34.3971384,21.633871"
              id="Fill-2"
            />
            <path
              d="M11.1181061,21.633871 C11.035848,21.5516129 10.2955255,21.1403226 9.14391256,21.1403226 C7.6632674,21.1403226 6.59391256,22.2096774 6.59391256,23.6903226 C6.59391256,25.0887097 7.58100934,26.2403226 9.14391256,26.2403226 C10.2132674,26.2403226 11.035848,25.8290323 11.1181061,25.7467742 L11.1181061,28.2967742 C10.7890739,28.3790323 10.0487513,28.6258065 8.97939644,28.6258065 C6.34713837,28.6258065 4.04391256,26.733871 4.04391256,23.6903226 C4.04391256,20.8935484 6.10036418,18.7548387 8.97939644,18.7548387 C10.0487513,18.7548387 10.8713319,19.0016129 11.1181061,19.083871 L11.1181061,21.633871"
              id="Fill-3"
            />
            <path
              d="M40.9777835,21.1403226 C39.6616545,21.1403226 38.7568158,22.2919355 38.7568158,23.6080645 C38.7568158,25.0064516 39.7439126,26.0758065 40.9777835,26.0758065 C42.2939126,26.0758065 43.1987513,24.9241935 43.1987513,23.6080645 C43.2810093,22.2919355 42.2939126,21.1403226 40.9777835,21.1403226 Z M45.5842351,23.6080645 C45.5842351,26.3225806 43.6922997,28.5435484 41.0600416,28.5435484 C38.4277835,28.5435484 36.535848,26.3225806 36.535848,23.6080645 C36.535848,20.8935484 38.4277835,18.6725806 41.0600416,18.6725806 C43.6100416,18.7548387 45.5842351,20.8935484 45.5842351,23.6080645 Z"
              id="Fill-4"
            />
            <path
              d="M24.6906868,21.0580645 C24.6906868,21.0580645 23.70359,20.7290323 22.9632674,20.7290323 C22.0584287,20.7290323 21.5648803,21.0580645 21.5648803,21.4693548 C21.5648803,22.0451613 22.2229448,22.2096774 22.5519771,22.3741935 L23.1277835,22.6209677 C24.6084287,23.1145161 25.2664932,24.183871 25.2664932,25.3354839 C25.2664932,27.7209677 23.2922997,28.5435484 21.6471384,28.5435484 C20.4955255,28.5435484 19.3439126,28.2967742 19.2616545,28.2967742 L19.2616545,26.0758065 C19.4261706,26.1580645 20.4132674,26.4048387 21.4003642,26.4048387 C22.469719,26.4048387 23.0455255,26.0758065 23.0455255,25.5 C23.0455255,25.0064516 22.6342351,24.7596774 22.0584287,24.5951613 C21.8939126,24.5129032 21.7293964,24.5129032 21.5648803,24.4306452 C20.3310093,24.0193548 19.2616545,23.1967742 19.2616545,21.633871 C19.2616545,19.8241935 20.4955255,18.6725806 22.5519771,18.6725806 C23.6213319,18.6725806 24.6906868,18.9193548 24.6906868,19.0016129 L24.6906868,21.0580645"
              id="Fill-5"
            />
            <path
              d="M2.06971902,8.88387097 C2.06971902,8.30806452 1.65842869,7.81451613 1.08262224,7.81451613 C0.50681579,7.81451613 0.0955254677,8.30806452 0.0955254677,8.88387097 L0.0955254677,11.1048387 C0.0955254677,11.6806452 0.589073855,12.1741935 1.08262224,12.1741935 C1.65842869,12.1741935 2.06971902,11.6806452 2.06971902,11.1048387 L2.06971902,8.88387097"
              id="Fill-6"
            />
            <path
              d="M8.07455773,5.67580645 C8.07455773,5.1 7.58100934,4.52419355 7.08746095,4.52419355 C6.5116545,4.52419355 6.10036418,5.01774194 6.10036418,5.67580645 L6.10036418,11.1048387 C6.10036418,11.7629032 6.59391256,12.2564516 7.08746095,12.2564516 C7.6632674,12.2564516 8.07455773,11.7629032 8.07455773,11.1048387 L8.07455773,5.67580645"
              id="Fill-7"
            />
            <path
              d="M14.1616545,1.3983871 C14.1616545,0.740322581 13.6681061,0.164516129 13.1745577,0.164516129 C12.5987513,0.164516129 12.187461,0.740322581 12.187461,1.3983871 L12.187461,14.2306452 C12.187461,14.8887097 12.6810093,15.4645161 13.1745577,15.4645161 C13.7503642,15.4645161 14.1616545,14.8887097 14.1616545,14.2306452 L14.1616545,1.3983871"
              id="Fill-8"
            />
            <path
              d="M20.2487513,5.67580645 C20.2487513,5.1 19.7552029,4.52419355 19.2616545,4.52419355 C18.685848,4.52419355 18.2745577,5.01774194 18.2745577,5.67580645 L18.2745577,11.1048387 C18.2745577,11.7629032 18.7681061,12.2564516 19.2616545,12.2564516 C19.837461,12.2564516 20.2487513,11.7629032 20.2487513,11.1048387 L20.2487513,5.67580645"
              id="Fill-9"
            />
            <path
              d="M26.335848,8.88387097 C26.335848,8.30806452 25.8422997,7.81451613 25.3487513,7.81451613 C24.7729448,7.81451613 24.3616545,8.30806452 24.3616545,8.88387097 L24.3616545,11.1048387 C24.3616545,11.6806452 24.8552029,12.1741935 25.3487513,12.1741935 C25.9245577,12.1741935 26.335848,11.6806452 26.335848,11.1048387 L26.335848,8.88387097"
              id="Fill-10"
            />
            <path
              d="M32.4229448,5.67580645 C32.4229448,5.1 31.9293964,4.52419355 31.435848,4.52419355 C30.8600416,4.52419355 30.4487513,5.01774194 30.4487513,5.67580645 L30.4487513,11.1048387 C30.4487513,11.7629032 30.8600416,12.2564516 31.435848,12.2564516 C32.0116545,12.2564516 32.4229448,11.7629032 32.4229448,11.1048387 L32.4229448,5.67580645"
              id="Fill-11"
            />
            <path
              d="M38.4277835,1.3983871 C38.4277835,0.740322581 38.0164932,0.164516129 37.4406868,0.164516129 C36.8648803,0.164516129 36.45359,0.740322581 36.45359,1.3983871 L36.45359,14.2306452 C36.45359,14.8887097 36.9471384,15.4645161 37.4406868,15.4645161 C38.0164932,15.4645161 38.4277835,14.8887097 38.4277835,14.2306452 L38.4277835,1.3983871"
              id="Fill-12"
            />
            <path
              d="M43.5277835,5.67580645 C43.5277835,5.1 43.1164932,4.52419355 42.5406868,4.52419355 C41.9648803,4.52419355 41.55359,5.01774194 41.55359,5.67580645 L41.55359,11.1048387 C41.55359,11.7629032 41.9648803,12.2564516 42.5406868,12.2564516 C43.1164932,12.2564516 43.5277835,11.7629032 43.5277835,11.1048387 L43.5277835,5.67580645"
              id="Fill-13"
            />
            <path
              d="M49.6148803,8.88387097 C49.6148803,8.30806452 49.1213319,7.81451613 48.6277835,7.81451613 C48.0519771,7.81451613 47.6406868,8.30806452 47.6406868,8.88387097 L47.6406868,11.1048387 C47.6406868,11.6806452 48.0519771,12.1741935 48.6277835,12.1741935 C49.20359,12.1741935 49.6148803,11.6806452 49.6148803,11.1048387 L49.6148803,8.88387097"
              id="Fill-14"
            />
          </g>
        </g>
      </svg>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="30px"
        height="30px"
        viewBox="0 0 48 48"
        enableBackground="new 0 0 48 48"
        space="preserve"
        opacity={ accessors && accessors.includes("3") ? "1": "0.3"}
      >
        <path
          d="M48,29.5c0-4.95-3.43-9.09-8.041-10.198C39.979,19.036,40,18.771,40,18.5C40,12.701,35.299,8,29.5,8
	c-4.527,0-8.375,2.871-9.849,6.888C18.556,14.324,17.317,14,16,14c-4.418,0-8,3.582-8,8c0,0.34,0.028,0.672,0.069,1H8v0.025
	c-4.46,0.26-8,3.949-8,8.475s3.54,8.215,8,8.475V40h30v-0.025C43.565,39.712,48,35.13,48,29.5z"
        />
      </svg>
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 51 51"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        opacity={ accessors && accessors.includes("4") ? "1": "0.3"}
      >
        <g transform="translate(0, 7.750000)" fill="#000000" fillRule="nonzero">
          <path d="M50.3625,24.65 C50.3625,24.4375 50.15,24.225 50.04375,24.11875 L43.88125,20.6125 C43.66875,20.50625 43.5625,20.29375 43.45625,20.08125 L42.7125,18.38125 C43.66875,17.6375 44.4125,15.3 44.4125,14.025 L44.4125,11.36875 C44.4125,9.1375 42.60625,7.33125 40.375,7.33125 C38.14375,7.33125 36.44375,9.1375 36.44375,11.36875 L36.44375,14.025 C36.44375,15.40625 37.08125,17.74375 38.14375,18.4875 L37.4,20.08125 C37.29375,20.29375 37.1875,20.50625 36.975,20.6125 L35.9125,21.25 L42.5,25.075 C43.5625,25.7125 44.4125,26.88125 44.625,28.15625 L44.73125,28.6875 L51,28.6875 L50.3625,24.65 Z" />
          <path d="M15.19375,21.25 L14.13125,20.6125 C13.91875,20.50625 13.8125,20.29375 13.70625,20.08125 L12.9625,18.38125 C13.91875,17.6375 14.6625,15.3 14.6625,14.025 L14.6625,11.36875 C14.6625,9.1375 12.85625,7.33125 10.625,7.33125 C8.39375,7.33125 6.69375,9.1375 6.69375,11.36875 L6.69375,14.025 C6.69375,15.40625 7.33125,17.74375 8.39375,18.4875 L7.65,20.08125 C7.54375,20.29375 7.4375,20.50625 7.225,20.6125 L1.0625,24.11875 C0.85,24.225 0.74375,24.4375 0.74375,24.65 L0.10625,28.6875 L6.375,28.6875 L6.48125,28.15625 C6.69375,26.88125 7.54375,25.7125 8.60625,25.075 L15.19375,21.25 Z" />
          <path d="M41.4375,28.6875 C41.4375,28.36875 41.11875,28.05 40.90625,27.8375 L31.13125,22.1 C30.8125,21.99375 30.6,21.56875 30.3875,21.25 L29.21875,18.59375 C29.9625,18.0625 30.70625,16.7875 31.13125,15.40625 C31.55625,14.025 31.875,12.64375 31.875,11.58125 L31.875,7.33125 C31.875,3.825 29.00625,0.95625 25.5,0.95625 C21.99375,0.95625 19.125,3.825 19.125,7.33125 L19.125,11.58125 C19.125,13.70625 20.1875,17.53125 21.78125,18.59375 L20.6125,21.14375 C20.50625,21.4625 20.1875,21.78125 19.86875,21.99375 L10.09375,27.73125 C9.775,27.8375 9.5625,28.2625 9.5625,28.58125 L9.5625,28.58125 L8.5,34.95625 L42.5,34.95625 L41.4375,28.6875 L41.4375,28.6875 Z" />
        </g>
      </svg>
    </div>
  );
};
export default ProcessingNodesSvg;
