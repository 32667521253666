
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DATAMAP_DATA = "GET_DATAMAP_DATA";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAILURE = "UPLOAD_FAILURE";
export const IS_UPLOADED = "IS_UPLOADED";
export const SET_PRODUCT_INFO = "SET_PRODUCT_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USERS = "SET_USERS";
export const SET_DATAMAP_ID = "SET_DATA_MAP_ID";
export const IS_UPDATED = "IS_UPDATED";
export const GET_SVG_FILE = "GET_SVG_FILE";
export const GET_REVIEW_DETAILS = "GET_REVIEW_DETAILS";
export const IS_EDIT_FLOW = "IS_EDIT_FLOW";
export const IS_COMMENT_ADD = "IS_COMMENT_ADD";
export const COMMENT_DETAILS = "COMMENT_DETAILS";
export const SHOW_MARK_AS_APPROVE = "SHOW_MARK_AS_APPROVE";
export const MARK_APPROVED = "MARK_APPROVED";
export const SET_USER_ORG = "SET_USER_ORG";
export const EDITOR_UPDATED = "EDITOR_UPDATED";
export const REVIEWER_ADDED = "REVIEWER_ADDED";
export const ADD_REVIEVERS = "ADD_REVIEVERS";


// ERRORS
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
