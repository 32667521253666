import { SET_DATAMAP_ID, IS_UPDATED ,IS_EDIT_FLOW , COMMENT_DETAILS, SHOW_MARK_AS_APPROVE, ADD_REVIEVERS} from "../actions/constant"; ``
import * as api from "../apis/datamaps.api";
import { MapStatus} from "../constant";

export function setDataMapId(dataMapId) {
  return {
    type: SET_DATAMAP_ID,
    data: dataMapId
  };
}

export function toggleIsUpdated(status) {
  return {
    type: IS_UPDATED,
    data: status
  };
}

export function isEditFlow(status){
  return {
  type: IS_EDIT_FLOW, 
  data: status
  }
}

export function toggleIsCommented(status){
  return {
  type: COMMENT_DETAILS, 
  data: status
  }
}

export function showMarkAsApproveBtn(status){
  return {
    type: SHOW_MARK_AS_APPROVE, 
    data: status
    }
} 

export function saveInputNodes(data) {

  return async (dispatch) => {
    console.log("Calling Update Input nodes Api...");
    const payload = {
      "mapStatus": MapStatus.DRAFT,
      "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "modifiedDate": new Date(),
      "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "message": "Inputnodes info data created",
      "inputNodes": data
    }

    const res = await api.updateInputNodes(payload, window.MAP_DATA.datamapId);
    if (res && res.status === 200) {
      dispatch({
        type: IS_UPDATED,
        data: true
      })
    }
  }
}


export function saveProcessingNodeData(data) {

  return async (dispatch) => {
    console.log("Calling Update Retention nodes Api...");
    const payload = {
      "mapStatus": MapStatus.DRAFT,
      "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "modifiedDate": new Date(),
      "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "message": "processingNodes info data created",
      "processingNodes": data
    }

    const res = await api.updateProcessingNodes(payload, window.MAP_DATA.datamapId);
    if (res && res.status === 200) {
      dispatch({
        type: IS_UPDATED,
        data: true
      })
    }
  }
}

export function saveRetentionNodeData(data) {

  return async (dispatch) => {
    console.log("Calling Update Processing nodes Api...");
    const payload = {
      "mapStatus": MapStatus.REVIEW,
      "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "modifiedDate": new Date(),
      "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "message": "retentionNodes info data created",
      "retentionNodes": data
    }

    const res = await api.updateRetentionNodes(payload, window.MAP_DATA.datamapId);
    if (res && res.status === 200) {
      dispatch({
        type: IS_UPDATED,
        data: true
      })
    }
  }
}

export function addReviewers(reviewers){
  return {
    type: ADD_REVIEVERS, 
    data: reviewers
    }
}