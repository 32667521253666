/* eslint-disable no-useless-escape */
// @flow
import React from "react";
import * as R from "ramda";
import * as moment from "moment";
import "./index.scss";
// import FormPropsTextFields from "../TextFields";d
import {Button } from "@material-ui/core";
import "date-fns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import {
  setInitialState,
  setDataTypes,
  setDataTypeValues,
  addGeneralInfo,
} from "../../actions/dataEntryActions";
import { setDataCenterJsonValue } from "../../actions/dataCentersAction";
import { setInputNodesJsonValues } from "../../actions/inputNodesAction";
import { setProcessingNodesJsonValues } from "../../actions/processNodesAction";
import { setRetentionNodesJsonValues } from "../../actions/retentionNodesAction";
import {
  setSvgString,
  recoverJsonFromSVG,
} from "../../actions/loadExistingAction";
import { setSVGGenerationFlag } from "../../actions/svgRegenerationAction";
import { SVG } from "../../svgGenerationLogic/features/generateSvg/logic/svgWrapper";
import { extractJSONFromSVG, isSketchEditFileOrNot } from "./parser";
import { uploadExistingData, uploadExistingDataFromJSON } from "../../actions/apiActions";
import { MapStatus, Messages } from "../../constant";
import Chip from '@material-ui/core/Chip';
import Loader from "./loader";

export const SVGRegenerationFormKey = "svgRegeneration";

class UploardSvg extends React.Component<any, any> {
  drawHandle: any;

  state = {
    setdate: "",
    productInfo: "",
    dataSheetURL: "",
    dataSheetVersion: "",
    uploadSvg: true,
    uploadPreview: false,
    fileName: "",
    selectedFileType: "",
    productNameError: false,
    payload: {},
    redirect: false,
    isEnabled: false,
    svgFile: {},
    loader: false
  };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.handleOnRemove = this.handleOnRemove.bind(this);
  }


  convertDateformat = (date) => {
    let dateformat = moment(date).format("MM-DD-YYYY")
    return dateformat;
  };

  onPublish = () => {
    this.setState({
      isEnabled: false,
      loader: true
    })
    if (this.state.selectedFileType === 'JSON') {
      this.props.uploadExistingDataFromJSON(this.state.payload);
    }
    else {
      this.props.uploadExistingData(this.state.payload, this.state.svgFile);
    }
  }

  convertToDate = date => {
    let d = new Date(date);
    let dd = String(d.getDate()).padStart(2, "0");
    let mm = String(d.getMonth() + 1).padStart(2, "0");
    let yyyy = d.getFullYear();
    let dateformat = yyyy + "-" + mm + "-" + dd
    return dateformat
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isUploaded !== this.props.isUploaded) {
      // const { data } = nextProps;
      // console.log(data);
      // if (data) {
      //   this.setState({
      //     productInfo: data.productName ? data.productName : "",
      //     dataSheetVersion: data.dataSheetVersion ? data.dataSheetVersion : "",
      //     dataSheetURL: data.dataSheetURL ? data.dataSheetURL : "",
      //     setdate: data.date ? this.convertToDate(data.date) : "",
      //   });
      //   this.forceUpdate();
      //   console.log(this.state.setdate);
      // }

      this.setState({
        loader: false
      })
      this.props.history && this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    const { existingSvg } = this.props;

    if (
      existingSvg.existingSvgString &&
      !R.equals(
        existingSvg.existingSvgString,
        R.prop("existingSvgString")(prevProps.existingSvg)
      )
    ) {
      let { drawHandle } = this;

      if (drawHandle) {
        drawHandle.clear();
      }
      drawHandle = SVG("existing-loaded-svg");
      drawHandle.svg(existingSvg.existingSvgString);

      const extractedJSON = extractJSONFromSVG(
        drawHandle,
        "datamap-formvalues"
      );

      const isSketchEdit = isSketchEditFileOrNot(
        drawHandle,
        "datamap-formvalues"
      );

      console.log(isSketchEdit);

      drawHandle.clear();

      if (extractedJSON) {

        const { processingNodes } = extractedJSON;

        for (let key in processingNodes) {
          processingNodes[key].identifer = key
        }

        const p =
        {
          "newVersion": false,
          "mapStatus": MapStatus.PUBLISHED,
          "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
          "modifiedDate": new Date(),
          "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
          "message": Messages.UPLOAD,
          ...extractedJSON
        }

        delete p['svgRegeneration'];
        console.log(p);
        this.setState({
          payload: { ...p },
          isEnabled: true
        })

        // const {
        //   generalInfo,
        //   dataCenters,
        //   inputNodes,
        //   processingNodes,
        //   retentionNodes,
        // } = extractedJSON;
        // if (generalInfo) {
        //   this.props.setInitialState(generalInfo);
        // }

        // if (dataCenters) {
        //   this.props.setDataCenterJsonValue(dataCenters);
        // }
        // if (inputNodes) {
        //   if (generalInfo && generalInfo.dataTypes) {
        //     this.props.setDataTypeValues(generalInfo.dataTypes);
        //   } else {
        //     this.props.setDataTypes(inputNodes);
        //   }
        //   this.props.setInputNodesJsonValues(inputNodes);
        // }
        // if (processingNodes) {
        //   this.props.setProcessingNodesJsonValues(processingNodes);
        // }
        // if (retentionNodes) {
        //   this.props.setRetentionNodesJsonValues(retentionNodes);
        // }

        // if (isSketchEdit) {
        //   const extractedJSONWithSVGFlag = R.mergeDeepLeft({
        //     [SVGRegenerationFormKey]: {
        //       svgRegenerationFlag: "modify",
        //     },
        //   })(extractedJSON);

        //   this.props.recoverJsonFromSVG(extractedJSONWithSVGFlag);
        //   this.props.setSVGGenerationFlag("modify");
        // } else {
        //   this.props.recoverJsonFromSVG({});
        // }
      }
    }
  }

  handleClick = () => {
    const { data } = this.props;
    if (!R.isEmpty(data)) {
      (data.productName = this.state.productInfo),
        (data.dataSheetVersion = this.state.dataSheetVersion),
        (data.dataSheetURL = this.state.dataSheetURL),
        (data.date = this.convertDateformat(this.state.setdate));
      this.props.addGeneralInfo(data);
    }
  }

  handleOnRemove = () => {
    this.setState({
      fileName: "",
      payload: {},
      selectedFileType: "",
      isEnabled: false,
      svgFile: {}
    });
  }

  handleInputChange = (ev: any, inputType: string) => {
    const file = R.pathOr([], ["target", "files"])(ev)[0];
    const loadFileRef = this.loadProgressFileRef || undefined;
    const reader = new FileReader();

    console.log(file);
    if (file && loadFileRef && inputType === "load-progress") {
      reader.onload = (evReader: any) => {
        try {
          const initObj = R.compose(JSON.parse)(evReader.target.result);

          const p =
          {
            "newVersion": false,
            "mapStatus": MapStatus.PUBLISHED,
            "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
            "modifiedDate": new Date(),
            "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
            "message": Messages.UPLOAD,
            ...initObj
          }

          //this.props.uploadExistingData(p)
          // const {
          //   newVersion,
          //   generalInfo,
          //   dataCenters,
          //   inputNodes,
          //   processingNodes,
          //   retentionNodes,
          // } = initObj;
          // if (generalInfo) {
          //   this.props.setInitialState(generalInfo);
          // }

          // if (dataCenters) {
          //   this.props.setDataCenterJsonValue(dataCenters);
          // }

          // if (inputNodes) {
          //   if (R.isNil(newVersion)) {
          //     this.props.setDataTypes(inputNodes);
          //   }
          //   this.props.setInputNodesJsonValues(inputNodes);
          // }
          // if (processingNodes) {
          //   this.props.setProcessingNodesJsonValues(processingNodes);
          // }
          // if (retentionNodes) {
          //   this.props.setRetentionNodesJsonValues(retentionNodes);
          // }

          // this.props.setSVGGenerationFlag("modify");

          this.setState({
            payload: p,
            fileName: file.name ? file.name : "",
            selectedFileType: "JSON",
            isEnabled: true
          });

          loadFileRef.value = "";
        } catch (e) {
          alert("Incorrect file content ; cannot restore form content.");
        }
      };
    } else {
      reader.onload = (evReader: any) => {
        const { setSvgString } = this.props;
        console.log(typeof (evReader.target.result));
        setSvgString(evReader.target.result);
        this.setState({
          svgFile: file,
          fileName: file.name ? file.name : "",
          selectedFileType: "SVG",
        });
      };
    }
    reader.readAsText(file);
  };

  onBack = () => {
    this.setState({
      uploadSvg: true,
      uploadPreview: false,
    });
  };

  handleDateChange = (e) => {
    this.setState({
      setdate: e.target.value,
    });
  };

  render() {
    console.log(this.state.setdate);
    const { existingSvg } = this.props;

    // if(this.state.redirect){
    //   history.push("/dashboard");
    // }

    return (
      <div>
        {this.state.uploadSvg ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              padding: "0 20%"
            }}
          >
            <div
              style={{
                margin: "50px 0px",
                color: "#9E9EA2",
                fontSize: "24px",
                fontWeight: "100",
                width: "75%",
                fontFamily: "CiscoSansTTLight",
              }}
            >
              Continue working on a data map by uploading an existing data map
              SVG or JSON file.
            </div>
            <div>
              <div>
                <div style={{ paddingBottom: "30px" }}>
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#39393B",
                    }}
                    className="Font-family-CiscoSansTTRegular"
                  >
                    <span style={{ fontFamily: "CiscoSansTTRegular" }}>
                      {" "}
                      Upload
                    </span>{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "CiscoSansTTRegular",
                      }}
                    >
                      SVG
                    </span>{" "}
                    <span style={{ fontFamily: "CiscoSansTTRegular" }}>
                      if...
                    </span>
                  </div>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    <li style={{ paddingBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            width: "75%",
                            fontFamily: "CiscoSansTTLight",
                            fontSize: "16px",
                            color: "#39393B",
                          }}
                        >
                          You want to edit an existing Data Map that has already
                          been cleaned up in Sketch{" "}
                        </div>
                        <label className="secondary-button-lable cursor-pointer">
                          {" "}
                          Choose SVG
                          <input
                            type="file"
                            ref={(elem) => (this.loadProgressFileRef = elem)}
                            accept=".svg"
                            size="60"
                            onChange={(ev) =>
                              this.handleInputChange(ev, "load-svg")
                            }
                          />
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div>
                  <div
                    style={{ fontSize: "18px", color: "#39393B" }}
                    className="Font-family-CiscoSansTTRegular"
                  >
                    <span style={{ fontFamily: "CiscoSansTTRegular" }}>
                      {" "}
                      Upload
                    </span>{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "CiscoSansTTRegular",
                      }}
                    >
                      JSON
                    </span>{" "}
                    <span style={{ fontFamily: "CiscoSansTTRegular" }}>
                      if...
                    </span>
                  </div>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    <li>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            width: "75%",
                            fontFamily: "CiscoSansTTLight",
                            fontSize: "16px",
                            color: "#39393B",
                          }}
                        >
                          You want to continue your process in creating a data
                          map, or you want to edit a previous file{" "}
                        </div>
                        <label className="secondary-button-lable cursor-pointer">
                          {" "}
                          Choose JSON
                          <input
                            type="file"
                            ref={(elem) => (this.loadProgressFileRef = elem)}
                            accept=".json"
                            size="60"
                            onChange={(ev) =>
                              this.handleInputChange(ev, "load-progress")
                            }
                          />
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ paddingTop: "50px", display: "flex", justifyContent: "flex-end" }}>
                {
                  this.state.fileName &&
                  <Chip
                    className="file-name-chip"
                    label={this.state.fileName}
                    style={{ backgroundColor: "#e8ebf1" }}
                    onDelete={() => this.handleOnRemove()}
                  />
                }
                <Link to='/' style={{ textDecoration: "none", marginRight: "20px" }}>
                  <Button
                    variant="contained"
                    disabled={false}
                    className="primary-button"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disabled={!this.state.isEnabled}
                  className="primary-button"
                  onClick={() => this.onPublish()}
                >
                  Publish
                </Button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {existingSvg.existingSvgString ? (
          <div id="existing-loaded-svg" />
        ) : null}
        {this.state.loader ? <Loader /> : null}
      </div>

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setInitialState,
      setDataTypes,
      setSvgString,
      setDataCenterJsonValue,
      setInputNodesJsonValues,
      setProcessingNodesJsonValues,
      setRetentionNodesJsonValues,
      addGeneralInfo,
      recoverJsonFromSVG,
      setSVGGenerationFlag,
      setDataTypeValues,
      uploadExistingData,
      uploadExistingDataFromJSON
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    existingSvg: state.existingSvg,
    data: state.dataEntry.generalInfo,
    isUploaded: state.uploadData.isUploaded,
    userInfo: state.userInfo.data
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UploardSvg));
