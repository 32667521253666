import account from "!!raw-loader!./account.svg";
import addContact from "!!raw-loader!./add-contact.svg";
import analysis from "!!raw-loader!./analysis.svg";
import annotation from "!!raw-loader!./annotation.svg";
import briefcase from "!!raw-loader!./briefcase.svg";
import cisco from "!!raw-loader!./cisco.svg";
import clipboard from "!!raw-loader!./clipboard.svg";
import cloud from "!!raw-loader!./cloud.svg";
import cog from "!!raw-loader!./cog.svg";
import communities from "!!raw-loader!./communities.svg";
import contact from "!!raw-loader!./contact.svg";
import contractLogin from "!!raw-loader!./contract-login.svg";
import dataUsage from "!!raw-loader!./data-usage.svg";
import devices from "!!raw-loader!./devices.svg";
import email from "!!raw-loader!./email.svg";
import headset from "!!raw-loader!./headset.svg";
import insights from "!!raw-loader!./insights.svg";
import lock from "!!raw-loader!./lock.svg";
import network from "!!raw-loader!./network.svg";
import pencil from "!!raw-loader!./pencil.svg";
import persistentChat from "!!raw-loader!./persistent-chat.svg";
import phone from "!!raw-loader!./phone.svg";
import signIn from "!!raw-loader!./sign-in.svg";
import storage from "!!raw-loader!./storage.svg";
import tools from "!!raw-loader!./tools.svg";
import video from "!!raw-loader!./video.svg";

export const iconsListIdToSVGMap = [
  { id: "account", svg: account },
  { id: "add-contact", svg: addContact },
  { id: "analysis", svg: analysis },
  { id: "annotation", svg: annotation },
  { id: "briefcase", svg: briefcase },
  { id: "cisco", svg: cisco },
  { id: "clipboard", svg: clipboard },
  { id: "cloud", svg: cloud },
  { id: "cog", svg: cog },
  { id: "communities", svg: communities },
  { id: "contact", svg: contact },
  { id: "contractLogin", svg: contractLogin },
  { id: "data-usage", svg: dataUsage },
  { id: "devices", svg: devices },
  { id: "email", svg: email },
  { id: "headset", svg: headset },
  { id: "insights", svg: insights },
  { id: "lock", svg: lock },
  { id: "network", svg: network },
  { id: "pencil", svg: pencil },
  { id: "persistent-chat", svg: persistentChat },
  { id: "phone", svg: phone },
  { id: "sign-in", svg: signIn },
  { id: "storage", svg: storage },
  { id: "tools", svg: tools },
  { id: "video", svg: video }
].reduce((acc, { id, svg }) => ({ ...acc, [id]: svg }), {});
