// @flow

import { dataCentersList } from "./locationsWithType";

export type Item = {
  value: string,
  id: string,
  label: string
};

export const dataCentersDropdownList = (): Array<Item> => {
  const dataCentersListItems = dataCentersList.map(location => {
    return Object.assign(
      {},
      {
        id: location.name,
        value: location.name,
        label: location.name
      }
    );
  });
  return dataCentersListItems;
};
