// @flow
import * as R from "ramda";
import { SVG } from "./svgWrapper";

import type { DataEntryFormOutput } from "../../dataEntry/types";
import {
  InputNodesFormKey,
  ProcessingNodesFormKey,
  RetentionNodesFormKey,
} from "../../dataEntry/types";
import type { Position } from "../../types";
import { CustomerDataProcessingOneGrid, SvgWidthHeight } from "../grid";
import { SubTitleSvg, TitleSvg } from "../headerSvg";
import type { Output as InputNodeInterpretationOutput } from "../nodeSvg/inputNode";
import {
  generateInputNodes,
  iconIdIdentifierSuffix,
} from "../nodeSvg/inputNode";
import type { Output as ProcessingNodeInterpretationOutput } from "../nodeSvg/processingNode";
import {
  drawProcessingNodeIcons,
  generateProcessingNodes,
  IconsMainSuffix,
  widthOfIcon,
} from "../nodeSvg/processingNode";
import { calculateLines, calculateWordWidths } from "../nodeSvg/nodesCommon";
import type {
  ProcessingNodeSectionIdentifiers,
  RetentionNodeSectionIdentifiers,
} from "./positioning";
import {
  relocateAndAddInputNodes,
  relocateAndAddProcessingNodes,
  relocateAndAddRetentionNodes,
} from "./positioning";
import {
  generateDrawMetaData,
  generateFormValuesMetaData,
} from "../metaDataSvg";
import { drawLines } from "./lines";
import type { Output as RetentionNodeInterpretationOutput } from "../nodeSvg/retentionNode";
import { generateRetentionNodes } from "../nodeSvg/retentionNode";
import { CallOutSvg } from "../callOutSvg";
import { DataCentersSection } from "../dataCenterSvg/index";
import {
  DataAssetsSectionId,
  generateDataAssets,
} from "../dataAssetsSvg/dataAssets";
import { VersionDescriptionSvg, VersionDescriptionSvg2} from "../versionDescriptionSvg";
import type { State as ExistingSVGState } from "../../dataEntry/loadExisting/reducer";
import { existingSVGDiff } from "./existingSVGDiff";
import { interpretExistingSVG } from "./lines/existingSVGInterpretation";
import { extractJSONFromSVG } from "../../dataEntry/loadExisting/parser";
import { generateInitialDrawTrackingMetadata } from "./lines/drawPathExtension";
import { removeElement, reorderDatamapContent, toFront } from "./util";
import {
  drawMultiTypeNodeAndOutlines,
  nodeInnerBoxSuffix,
} from "../nodeSvg/nodesCommon";
import { fontsSVGLibrary } from "../../../styles";

const defaultViewbox = {
  x: 0,
  y: 0,
  width: SvgWidthHeight.width,
  height: SvgWidthHeight.height,
};

const DATAMAP_OUR_CONTENT_ID = "datamap-content-container-root";
const URI_PREFIX = "data:image/svg+xml;base64,";

function generateEncodedSVG(svgContent: string) {
  return R.compose(
    R.concat(URI_PREFIX),
    btoa,
    unescape,
    encodeURIComponent
  )(svgContent);
}

function initializeDrawCanvas(SVGMainId: string) {
  const draw = SVG(SVGMainId);
  draw.clear();
  draw
    .viewbox(defaultViewbox)
    .size(defaultViewbox.width, defaultViewbox.height);

  return draw;
}

function injectMetadata(draw, { formValues, drawMeta }) {
  // note : Sketch doesn't respect custom namespace/metadata ; we need to use this workaround to retain our
  // metadata by using an invisible text field.
  const metadataRoot = draw
    .nested()
    .viewbox(defaultViewbox)
    .size(defaultViewbox.width, defaultViewbox.height)
    .style("overflow", "hidden")
    .id("datamap-metadata-container");

  metadataRoot.svg(generateFormValuesMetaData(formValues));
  metadataRoot.svg(generateDrawMetaData(drawMeta));

  metadataRoot.back();
}

function generateNodeSVGs(
  {
    SVGMainId,
    SVGAuxId,
    inputNodesData,
    processingNodesData,
    retentionNodesData,
  },
  shouldProduceDummyNodes: boolean = false
) {
  const inputNodes = generateInputNodes(
    inputNodesData,
    SVGMainId,
    SVGAuxId,
    shouldProduceDummyNodes
  );
  const processingNodes = generateProcessingNodes(
    processingNodesData,
    SVGMainId,
    SVGAuxId,
    shouldProduceDummyNodes
  );
  const retentionNodes = generateRetentionNodes(
    retentionNodesData,
    SVGMainId,
    SVGAuxId,
    shouldProduceDummyNodes
  );
  return { inputNodes, processingNodes, retentionNodes };
}

/**
 todo : generateSVGExisting will imitate combine as we build more SVG modification functionality ; we could instead  make combine receive a set of functions to make the "combination" more abstract and keep the SVG add/remove code within those functions.
 */
function combineFreshSVGComponents(
  SVGMainId: string,
  baseSvgCode: string,
  formValues: DataEntryFormOutput,
  inputNodes: Array<InputNodeInterpretationOutput>,
  processingNodes: {
    [controllerSubcategoryCombination: ProcessingNodeSectionIdentifiers]: Array<ProcessingNodeInterpretationOutput>,
  },
  retentionNodes: {
    [controllerSubcategoryCombination: RetentionNodeSectionIdentifiers]: Array<RetentionNodeInterpretationOutput>,
  },
  callOutSvg: string,
  DataCenterMapSvg: ?string,
  dataAssets: string
) {
  const draw = initializeDrawCanvas(SVGMainId);

  // 1. Base template.
  draw.svg(baseSvgCode);

  // 2. Draw Header
  const HeaderString = `${TitleSvg(formValues)}  ${SubTitleSvg()}`;
  draw.svg(HeaderString);

  // Draw version description
  draw.svg(VersionDescriptionSvg(formValues));

  const drawContent = draw.nested().id(DATAMAP_OUR_CONTENT_ID);
  drawContent
    .viewbox(defaultViewbox)
    .size(defaultViewbox.width, defaultViewbox.height);

  // 3. Nodes
  // 3.1. Input nodes.
  const inputNodeElements = relocateAndAddInputNodes(drawContent, inputNodes);

  // 3.2. Processing nodes.
  const processingNodeElements = relocateAndAddProcessingNodes(
    drawContent,
    processingNodes
  );

  // 3.3. Retention nodes.
  const retentionNodeElements = relocateAndAddRetentionNodes(
    drawContent,
    retentionNodes
  );

  // draw callOut if it is required
  if (formValues.generalInfo.callout) {
    drawContent.svg(callOutSvg);
  }

  // extract contracts box from the root svg & place it in our own content root.
  draw.select("#contracts").each(function() {
    this.remove();
    drawContent.add(this);
    toFront.call(this);
  });
  draw.select("#contracts-subprocessor").each(function() {
    this.remove();
    drawContent.add(this);
    toFront.call(this);
  });

  const drawMeta = drawLines(formValues, drawContent, {
    input: inputNodeElements,
    processing: processingNodeElements,
    retention: retentionNodeElements,
  });

  // Reorder stuff.
  reorderDatamapContent(drawContent);

  // put in data center map svg
  draw.svg(DataCenterMapSvg);

  // put data assets section
  draw.svg(dataAssets);

  // Render Meta Data
  injectMetadata(draw, { formValues, drawMeta });

  const result = draw.svg();

  return generateEncodedSVG(result);
}

export async function generateSVGFresh(
  SVGMainId: string,
  SVGAuxId: string,
  formValues: DataEntryFormOutput,
  baseSvgCode: string
) {
  const inputNodesData = formValues.inputNodes || {};
  const processingNodesData = formValues.processingNodes || {};
  const retentionNodesData = formValues.retentionNodes || {};
  const dataCenterData = formValues.dataCenters;
  const dataTypes =
    formValues.generalInfo && formValues.generalInfo.dataTypes
      ? formValues.generalInfo.dataTypes
      : {};

  const dataAssets = generateDataAssets(
    inputNodesData,
    SVGMainId,
    SVGAuxId,
    dataTypes
  );

  const { inputNodes, processingNodes, retentionNodes } = generateNodeSVGs({
    SVGMainId,
    SVGAuxId,
    inputNodesData,
    processingNodesData,
    retentionNodesData,
  });

  let callOutSvg = "";
  if (formValues.generalInfo.callout) {
    callOutSvg = CallOutSvg(
      formValues.generalInfo.calloutCopy || "",
      SVGMainId,
      SVGAuxId
    );
  }
  // generate data Center Map and get svg string
  const DataCenterMapSvg = await DataCentersSection(
    dataCenterData,
    SVGMainId,
    SVGAuxId
  );

  return combineFreshSVGComponents(
    SVGMainId,
    baseSvgCode,
    formValues,
    inputNodes,
    processingNodes,
    retentionNodes,
    callOutSvg,
    DataCenterMapSvg,
    dataAssets
  );
}

function recalculateNodes(
  differences,
  SVGMainId,
  SVGAuxId,
  formValues,
  draw,
  existingSvg,
  nodesToDraw
) {
  const {
    inputNodes: inputNodesNew,
    processingNodes: processingNodesNew,
    retentionNodes: retentionNodesNew,
  } = generateNodeSVGs({
    SVGMainId,
    SVGAuxId,
    inputNodesData: R.pick(differences.additions)(formValues.inputNodes),
    processingNodesData: R.pick(differences.additions)(
      formValues.processingNodes
    ),
    retentionNodesData: R.pick(differences.additions)(
      formValues.retentionNodes
    ),
  });

  const {
    inputNodes: inputNodesOld,
    processingNodes: processingNodesOld,
    retentionNodes: retentionNodesOld,
  } = generateNodeSVGs(
    {
      SVGMainId,
      SVGAuxId,
      inputNodesData: R.pick(differences.existing[InputNodesFormKey])(
        formValues.inputNodes
      ),
      processingNodesData: R.pick(differences.existing[ProcessingNodesFormKey])(
        formValues.processingNodes
      ),
      retentionNodesData: R.pick(differences.existing[RetentionNodesFormKey])(
        formValues.retentionNodes
      ),
    },
    true
  );

  if (!draw) {
    draw = initializeDrawCanvas(SVGMainId);
    draw.svg(existingSvg.existingSvgString);
  }

  const drawContent = draw.select(`#${DATAMAP_OUR_CONTENT_ID}`).members[0];
  let sketchAlignTransform = R.map(R.identity);
  console.log(drawContent.node);
  console.log(draw.node);

  try {
    let currentSvgElement = drawContent.node;
    let matrixValues = { e: 0, f: 0 };
    while (currentSvgElement !== draw.node) {
      let newMatrix;
      try {
        newMatrix = currentSvgElement.transform.baseVal.consolidate().matrix;
      } catch (e) {
        newMatrix = { e: 0, f: 0 };
      }

      const matrixValuesNew = R.compose(R.pick(["e", "f"]))(newMatrix);

      matrixValues = R.mergeDeepWith(
        (a, b) => a + b,
        matrixValues,
        matrixValuesNew
      );

      currentSvgElement = currentSvgElement.parentElement;
    }

    sketchAlignTransform = ({ x, y }) => {
      if (matrixValues.e === 0 && matrixValues.f === 0) {
        return {
          x: x,
          y: y,
        };
      }
      return {
        x: x - matrixValues.e - 225,
        y: y - matrixValues.f - 140,
      };
    };
  } catch (e) {
    console.trace(e);
  }

  const rootContentNodeCTMTranslation = R.pick(["e", "f"])(
    drawContent.node.getScreenCTM()
  );

  const newNodeTransform = () => sketchAlignTransform;

  const existingNodeTransform = (draw: any, nodeId: string) => (): Position => {
    const aa = draw.select(`#${nodeId}`).members[0];
    const nodeCTMTranslation = R.pick(["e", "f"])(aa.node.getScreenCTM());

    return {
      x: nodeCTMTranslation.e - rootContentNodeCTMTranslation.e,
      y: nodeCTMTranslation.f - rootContentNodeCTMTranslation.f,
    };
  };

  nodesToDraw.input = R.concat(
    relocateAndAddInputNodes(
      drawContent,
      inputNodesNew,
      false,
      newNodeTransform
    ),
    relocateAndAddInputNodes(
      drawContent,
      inputNodesOld,
      true,
      existingNodeTransform
    )
  );
  nodesToDraw.processing = R.concat(
    relocateAndAddProcessingNodes(
      drawContent,
      processingNodesNew,
      false,
      newNodeTransform
    ),
    relocateAndAddProcessingNodes(
      drawContent,
      processingNodesOld,
      true,
      existingNodeTransform
    )
  );
  nodesToDraw.retention = R.concat(
    relocateAndAddRetentionNodes(
      drawContent,
      retentionNodesNew,
      false,
      newNodeTransform
    ),
    relocateAndAddRetentionNodes(
      drawContent,
      retentionNodesOld,
      true,
      existingNodeTransform
    )
  );

  return draw;
}

export async function generateSVGExisting(
  SVGMainId: string,
  SVGAuxId: string,
  formValues: DataEntryFormOutput,
  existingSvg: {
    existingSvgString: string,
    extractedFormState: any,
  }
) {
  const differences = existingSVGDiff(
    formValues,
    existingSvg.extractedFormState
  );

  const exractedSvg = existingSvg.extractedFormState

  let draw: any;
  let nodesToDraw = {};

  if (!R.isEmpty(differences.additions)) {
    draw = recalculateNodes(
      differences,
      SVGMainId,
      SVGAuxId,
      formValues,
      draw,
      existingSvg,
      nodesToDraw
    );
  }

  if (!draw) {
    draw = initializeDrawCanvas(SVGMainId);
    draw.svg(existingSvg.existingSvgString);
  }

  let haveDataTypesChanged = false;

  // RENTION NODES UPDATE

  const updateNodes = (differences) => {
    for (const nodeId in differences) {
      const nodeIdSelector = `#${nodeId}`;
      let changedAttributes = differences[nodeId];
      if (R.has("nodeCopy", changedAttributes)) {
        const node = draw.select(nodeIdSelector);
        console.log(node);
        node.each(function() {
          let nodetype = node.members[0].type;
          const nodeText = this.select("text");
          console.log(nodeText.members[0]);
          if (nodeText.members && nodeText.members.length > 0) {
            // Wrong approach
            // Case 1 : Sketch
            // let lineWidth = 25;
            // let wordsWithComputedWidth = calculateWordWidths(changedAttributes.nodeCopy);
            // let lines = calculateLines(wordsWithComputedWidth, lineWidth);
            // const nodeTextSpan = this.select("tspan", nodeText.members[0]);
            // if (nodeTextSpan.members && nodeTextSpan.members.length > 0) {
            //   try {
            //     R.compose(
            //       R.forEach((elem) => elem.node.remove()),
            //       R.tail
            //     )(nodeTextSpan.members);

            //     nodeTextSpan.members[0].node.innerHTML = lines[0];

            //   } catch (e) {
            //     // An unexpected element had been introduced. Trace the error & move on.
            //     console.trace(e);
            //   }
            // }
            // // Case 2 : Original SVG
            // else {
            //   nodeText.members[0].plain(changedAttributes.nodeCopy);
            // }

            if (nodetype && nodetype === "g") {
              let lineWidth = 14;
              let wordsWithComputedWidth = calculateWordWidths(
                changedAttributes.nodeCopy
              );
              let lines = calculateLines(wordsWithComputedWidth, lineWidth);

              let y = 20;
              let x = 37;
              if (lines.length === 1) {
                y = 35;
              } else if (lines.length === 2) {
                y = 30;
              }

              nodeText.members[0]
                .text(function(add) {
                  for (let i = 0; i < lines.length; i++) {
                    add
                      .tspan(lines[i])
                      .x(x)
                      .y(y);
                    y = y + 22;
                  }
                })
                .font({
                  family: fontsSVGLibrary.node.family,
                  weight: 400,
                  size: "20",
                  fill: "#57585B",
                  anchor: "middle",
                });
            }
          }
        });
        // node.clear();
      }
      if (R.has("iconSvgString", changedAttributes)) {
        const iconSvg = draw.select(
          `${nodeIdSelector}${iconIdIdentifierSuffix}`
        );
        iconSvg.each(function() {
          this.clear();
        });

        const iconSvgGroup = iconSvg.members[0];

        const newIcon = iconSvgGroup.svg(
          R.compose(
            decodeURIComponent,
            escape,
            atob
          )(changedAttributes.iconSvgString)
        );

        newIcon.select("path").each(function() {
          this.attr({ fill: fontsSVGLibrary.node.fill });
          this.attr({ stroke: fontsSVGLibrary.node.fill });
        });
      }
      if (R.has("accessors", changedAttributes)) {
        // const numOfAccessors = changedAttributes.accessors;
        const selector = `${nodeIdSelector}${IconsMainSuffix}`;
        const iconSvg = draw.select(selector).members[0];
        iconSvg.clear();

        drawProcessingNodeIcons({
          iconsSection: iconSvg,
          node: { accessors: changedAttributes.accessors },
          nodeId,
          xPositionStartOfIconGroup: 0,
          actualSize: { h: widthOfIcon / 2 },
        });
      }
      if (
        R.has("dataTypes", changedAttributes) ||
        R.has("dataType", changedAttributes)
      ) {
        haveDataTypesChanged = true;

        const dataTypes = changedAttributes["dataTypes"] || [
          changedAttributes["dataType"],
        ];
        const drawNodeSvgRepresentation = draw.select(nodeIdSelector)
          .members[0];

        const { w } = drawNodeSvgRepresentation.bbox();

        const identifierToSelect = `#${nodeId}${nodeInnerBoxSuffix}`;

        drawNodeSvgRepresentation
          .select(identifierToSelect)
          .members[0].remove();

        drawMultiTypeNodeAndOutlines(
          { dataTypes },
          drawNodeSvgRepresentation,
          {
            w,
            h: CustomerDataProcessingOneGrid.nodeHeight,
          },
          nodeId
        );

        drawNodeSvgRepresentation.select(identifierToSelect).each(function() {
          this.back();
        });
      }
    }
  };

  // INPUT NODES UPDATE

  const updateInputNodes = (differences) => {
    for (const nodeId in differences) {
      const nodeIdSelector = `#${nodeId}`;
      let changedAttributes = differences[nodeId];
      if (R.has("nodeCopy", changedAttributes)) {
        const node = draw.select(nodeIdSelector);
        console.log(node);
        node.each(function() {
          let nodetype = node.members[0].type;
          const nodeText = this.select("text");
          console.log(nodeText.members[0]);
          if (nodeText.members && nodeText.members.length > 0) {
            if (nodetype && nodetype === "g") {
              let lineWidth = 25;
              let wordsWithComputedWidth = calculateWordWidths(
                changedAttributes.nodeCopy
              );
              let lines = calculateLines(wordsWithComputedWidth, lineWidth);
              let y = 0;
              let x = 30;
              if (lines.length === 1) {
                y = 20;
              } else if (lines.length === 2) {
                y = 10;
              }

              nodeText.members[0]
                .text(function(add) {
                  for (let i = 0; i < lines.length; i++) {
                    add
                      .tspan(lines[i])
                      .x(x)
                      .y(y);
                    y = y + 25;
                  }
                })
                .font({
                  family: fontsSVGLibrary.node.family,
                  weight: 400,
                  size: "20",
                  fill: "#57585B",
                  anchor: "middle",
                });
            } else {
              let lineWidth = 23;
              let wordsWithComputedWidth = calculateWordWidths(
                changedAttributes.nodeCopy
              );
              let lines = calculateLines(wordsWithComputedWidth, lineWidth);
              let y = 75;
              let x = 20;
              if (lines.length === 1) {
                y = 95;
              } else if (lines.length === 2) {
                y = 85;
              }

              nodeText.members[0]
                .text(function(add) {
                  for (let i = 0; i < lines.length; i++) {
                    add
                      .tspan(lines[i])
                      .x(x)
                      .y(y);
                    y = y + 25;
                  }
                })
                .font({
                  family: fontsSVGLibrary.node.family,
                  weight: 400,
                  size: "23",
                  fill: "#57585B",
                  anchor: "middle",
                });
            }
          }
        });

        // node.clear();
      }
      if (R.has("iconSvgString", changedAttributes)) {
        const iconSvg = draw.select(
          `${nodeIdSelector}${iconIdIdentifierSuffix}`
        );
        iconSvg.each(function() {
          this.clear();
        });

        const iconSvgGroup = iconSvg.members[0];

        const newIcon = iconSvgGroup.svg(
          R.compose(
            decodeURIComponent,
            escape,
            atob
          )(changedAttributes.iconSvgString)
        );

        newIcon.select("path").each(function() {
          this.attr({ fill: fontsSVGLibrary.node.fill });
          this.attr({ stroke: fontsSVGLibrary.node.fill });
        });
      }

      if (
        R.has("dataTypes", changedAttributes) ||
        R.has("dataType", changedAttributes)
      ) {
        haveDataTypesChanged = true;

        const dataTypes = changedAttributes["dataTypes"] || [
          changedAttributes["dataType"],
        ];
        const drawNodeSvgRepresentation = draw.select(nodeIdSelector)
          .members[0];

        const { w } = drawNodeSvgRepresentation.bbox();

        const identifierToSelect = `#${nodeId}${nodeInnerBoxSuffix}`;

        drawNodeSvgRepresentation
          .select(identifierToSelect)
          .members[0].remove();

        drawMultiTypeNodeAndOutlines(
          { dataTypes },
          drawNodeSvgRepresentation,
          {
            w,
            h: CustomerDataProcessingOneGrid.nodeHeight,
          },
          nodeId
        );

        drawNodeSvgRepresentation.select(identifierToSelect).each(function() {
          this.back();
        });
      }
    }
  };

  // PROCESSING NODE UPDATE

  const updateProcessingNodes = (differences) => {
    for (const nodeId in differences) {
      const nodeIdSelector = `#${nodeId}`;
      let changedAttributes = differences[nodeId];
      if (R.has("nodeCopy", changedAttributes)) {
        // const numOfAccessors = changedAttributes.accessors.length;
        const node = draw.select(nodeIdSelector);
        console.log(node);

        node.each(function() {
          let nodetype = node.members[0].type;
          const nodeText = this.select("text");
          console.log(nodeText.members[0]);
          if (nodeText.members && nodeText.members.length > 0) {
            if (nodetype && nodetype === "g") {
              let lineWidth = 24;
              let fontSize = "20";

              // if (numOfAccessors === 2) {
              //   lineWidth = 21;
              // } else if (numOfAccessors === 3) {
              //   lineWidth = 18;
              // }

              let wordsWithComputedWidth = calculateWordWidths(
                changedAttributes.nodeCopy
              );
              let lines = calculateLines(wordsWithComputedWidth, lineWidth);

              let y = 0;
              let x = 15;
              if (lines.length === 1) {
                y = 15;
              } else if (lines.length === 2) {
                y = 10;
              }

              nodeText.members[0]
                .text(function(add) {
                  for (let i = 0; i < lines.length; i++) {
                    add
                      .tspan(lines[i])
                      .x(x)
                      .y(y);
                    y = y + 22;
                  }
                })
                .font({
                  family: fontsSVGLibrary.node.family,
                  weight: 400,
                  size: fontSize,
                  fill: "#57585B",
                  anchor: "middle",
                });
            } else {
              let lineWidth = 25;
              let fontSize = "23";
              // if (numOfAccessors === 2) {
              //   lineWidth = 22;
              // } else if (numOfAccessors === 3) {
              //   lineWidth = 19;
              // }

              let wordsWithComputedWidth = calculateWordWidths(
                changedAttributes.nodeCopy
              );
              let lines = calculateLines(wordsWithComputedWidth, lineWidth);

              let y = 75;
              let x = 15;
              if (lines.length === 1) {
                y = 90;
              } else if (lines.length === 2) {
                y = 85;
              }

              nodeText.members[0]
                .text(function(add) {
                  for (let i = 0; i < lines.length; i++) {
                    add
                      .tspan(lines[i])
                      .x(x)
                      .y(y);
                    y = y + 25;
                  }
                })
                .font({
                  family: fontsSVGLibrary.node.family,
                  weight: 400,
                  size: fontSize,
                  fill: "#57585B",
                  anchor: "middle",
                });
            }
          }
        });
        // node.clear();
      }

      if (R.has("accessors", changedAttributes)) {
        const numOfAccessors = changedAttributes.accessors.length;
        const node = draw.select(nodeIdSelector);
        let xpos = -20;

        node.each(function() {
          const rectboxes = this.select("rect");
          console.log(rectboxes);
          if (rectboxes.members && rectboxes.members.length === 3) {
            let w1 = 78.81118;
            let w2 = 37.6545;
            let x = 330.1139;
            let y = 0;

            if (numOfAccessors === 2) {
              w1 = 127.8502;
              w2 = 62.1737;
              x = 280.6;
              xpos = -70;
            }
            if (numOfAccessors === 3) {
              w1 = 196.8886;
              w2 = 86.6929;
              x = 215.0661;
              xpos = -135;
            }

            rectboxes.members[1]
              .attr({
                width: w1,
              })
              .move(x, y);
            rectboxes.members[2]
              .attr({
                width: w2,
              })
              .move(x, y);
          }
        });

        const selector = `${nodeIdSelector}${IconsMainSuffix}`;
        const iconSvg = draw.select(selector).members[0];
        iconSvg.clear();

        drawProcessingNodeIcons({
          iconsSection: iconSvg,
          node: { accessors: changedAttributes.accessors },
          nodeId,
          xPositionStartOfIconGroup: xpos,
          actualSize: { h: widthOfIcon / 2 },
        });
      }
      if (
        R.has("dataTypes", changedAttributes) ||
        R.has("dataType", changedAttributes)
      ) {
        haveDataTypesChanged = true;

        const dataTypes = changedAttributes["dataTypes"] || [
          changedAttributes["dataType"],
        ];
        const drawNodeSvgRepresentation = draw.select(nodeIdSelector)
          .members[0];

        const { w } = drawNodeSvgRepresentation.bbox();

        const identifierToSelect = `#${nodeId}${nodeInnerBoxSuffix}`;

        drawNodeSvgRepresentation
          .select(identifierToSelect)
          .members[0].remove();

        drawMultiTypeNodeAndOutlines(
          { dataTypes },
          drawNodeSvgRepresentation,
          {
            w,
            h: CustomerDataProcessingOneGrid.nodeHeight,
          },
          nodeId
        );

        drawNodeSvgRepresentation.select(identifierToSelect).each(function() {
          this.back();
        });
      }
    }
  };

  const removeNodes = (removals) => {
    removals.forEach((removal) => {
      const removalNode = draw.select(`#${removal}`);
      removalNode.each(function() {
        this.remove();
      });
    });
  };

  // check for differences in nodes and update text copy as required
  if (!R.isEmpty(differences.removals)) {
    removeNodes(differences.removals);
  }

  if (!R.isEmpty(differences.inputNodes)) {
    updateInputNodes(differences.inputNodes);
  }
  if (!R.isEmpty(differences.processingNodes)) {
    updateProcessingNodes(differences.processingNodes);
  }
  if (!R.isEmpty(differences.retentionNodes)) {
    updateNodes(differences.retentionNodes);
  }

  if (!R.isEmpty(differences.dataCenters)) {
    let dataCenters = draw.select("#section-datacenter-root");

    if (dataCenters.members && dataCenters.members.length > 0) {
      const data = dataCenters.members[0];
      const oldDataTypes =  exractedSvg.generalInfo && exractedSvg.generalInfo.dataTypes
      ? exractedSvg.generalInfo.dataTypes
      : {};
      if (data.type === "g" && !R.isEmpty(oldDataTypes)) {
        removeElement(draw, "#section-datacenter-root");

        const DataCenterMapSvg = await DataCentersSection(
          differences.dataCenters,
          SVGMainId,
          SVGAuxId,
          true
        );
        const dataCenterSvg = draw.group();
        dataCenterSvg
          .svg(DataCenterMapSvg)
          .x(-426)
          .y(-320);
      } else {
        removeElement(draw, "#section-datacenter-root");
        const DataCenterMapSvg = await DataCentersSection(
          differences.dataCenters,
          SVGMainId,
          SVGAuxId
        );
        draw.svg(DataCenterMapSvg);
      }
    }
  }

  if (!R.isEmpty(differences.generalInfo)) {
    if (differences.generalInfo.productName) {
      removeElement(draw, "#generalInfo-productName");
      draw.svg(TitleSvg(formValues));
    }

    if (
      differences.generalInfo.dataSheetVersion ||
      differences.generalInfo.date
    ) {
      removeElement(draw, "#generalInfo-version-date");
      draw.svg(VersionDescriptionSvg2(formValues));
    }
  }

  if (!R.isEmpty(differences.inputNodes) || differences.dataTypesChanged) {
    let assets = draw.select(`#${DataAssetsSectionId}`);
    console.log(assets);

    if (assets.members && assets.members.length > 0) {
      const data = assets.members[0];
      const oldDataTypes =  exractedSvg.generalInfo && exractedSvg.generalInfo.dataTypes
      ? exractedSvg.generalInfo.dataTypes
      : {};
      const dataTypes =
        formValues.generalInfo && formValues.generalInfo.dataTypes
          ? formValues.generalInfo.dataTypes
          : {};
      if (data.type === "g" && !R.isEmpty(oldDataTypes)) {
        removeElement(draw, `#${DataAssetsSectionId}`);
        const dataAssets = generateDataAssets(
          formValues.inputNodes,
          SVGMainId,
          SVGAuxId,
          dataTypes
        );
        const dataAssetsSvg = draw.group();
        dataAssetsSvg
          .svg(dataAssets)
          .x(0)
          .y(-340);
      } else {
        removeElement(draw, `#${DataAssetsSectionId}`);
        const dataAssets = generateDataAssets(
          formValues.inputNodes,
          SVGMainId,
          SVGAuxId,
          dataTypes
        );
        draw.svg(dataAssets);
      }
    }
  }
  if (!R.isEmpty(differences.inputNodes)) {
    removeElement(draw, `#${DataAssetsSectionId}`);
    const dataTypes = (formValues.generalInfo && formValues.generalInfo.dataTypes) ?formValues.generalInfo.dataTypes : {}
    const dataAssets = generateDataAssets(
      formValues.inputNodes,
      SVGMainId,
      SVGAuxId,
      dataTypes
    );
    draw.svg(dataAssets);
  }

  let drawMeta = extractJSONFromSVG(draw, "datamap-drawmeta");

  if (R.isEmpty(differences.additions) && haveDataTypesChanged) {
    recalculateNodes(
      differences,
      SVGMainId,
      SVGAuxId,
      formValues,
      draw,
      existingSvg,
      nodesToDraw
    );
  }

  if (!R.isEmpty(differences.additions) || haveDataTypesChanged) {
    drawMeta = drawMeta || generateInitialDrawTrackingMetadata();

    const existingSVGInterpretation = interpretExistingSVG(draw, drawMeta);

    const drawContent = draw.select(`#${DATAMAP_OUR_CONTENT_ID}`).members[0];
    const { input, processing, retention } = nodesToDraw;

    drawMeta = drawLines(
      formValues,
      drawContent,
      {
        input,
        processing,
        retention,
      },
      {
        existingSVGDrawTrackingData: drawMeta,
        existingSVGString: existingSvg.existingSvgString,
        existingSVGInterpretation,
      }
    );

    reorderDatamapContent(drawContent);
  }

  // Reinject form values.
  removeElement(draw, "#datamap-metadata-container");
  injectMetadata(draw, { formValues, drawMeta });

  return generateEncodedSVG(draw.svg());
}

export async function generateSVGMain(
  SVGMainId: string,
  SVGAuxId: string,
  formValues: DataEntryFormOutput,
  existingSVG: ExistingSVGState,
  baseSvgCode: string
) {
  const { existingSvgString, extractedFormState } = existingSVG;

  if (existingSvgString && !R.isEmpty(extractedFormState)) {
    return generateSVGExisting(SVGMainId, SVGAuxId, formValues, {
      existingSvgString,
      extractedFormState,
    });
  } else {
    return generateSVGFresh(SVGMainId, SVGAuxId, formValues, baseSvgCode);
  }
}
