/* eslint-disable */
// @flow

import * as R from "ramda";
import {
  CiscoDataProcessingOneGrid,
  CiscoDataProcessingThreeGrid,
  CiscoDataProcessingTwoGrid,
  CustomerDataInputGrid,
  CustomerDataProcessingOneGrid,
  CustomerDataProcessingThreeGrid,
  CustomerDataProcessingTwoGrid,
  DeletionCiscoFiveGrid,
  DeletionCiscoFourGrid,
  DeletionCiscoOneGrid,
  DeletionCiscoSixGrid,
  DeletionCiscoThreeGrid,
  DeletionCiscoTwoGrid,
  DeletionSubFiveGrid,
  DeletionSubFourGrid,
  DeletionSubOneGrid,
  DeletionSubSixGrid,
  DeletionSubThreeGrid,
  DeletionSubTwoGrid,
  Quadrant,
  SubDataProcessingOneGrid,
  SubDataProcessingThreeGrid,
  SubDataProcessingTwoGrid
} from "../grid";
import type { Position } from "../../types";
import type { Output as InputNodeInterpretationOutput } from "../nodeSvg/inputNode";
import type { Output as ProcessingNodeInterpretationOutput } from "../nodeSvg/processingNode";
import type { Output as RetentionNodeInterpretationOutput } from "../nodeSvg/retentionNode";

export type NodeCoordinates = {
  coordinates: Position
};

const ProcessingSectionToGridMap = {
  C1_S0: CustomerDataProcessingOneGrid,
  C1_S1: CustomerDataProcessingTwoGrid,
  C1_S2: CustomerDataProcessingThreeGrid,
  C0_S0: CiscoDataProcessingOneGrid,
  C0_S1: CiscoDataProcessingTwoGrid,
  C0_S2: CiscoDataProcessingThreeGrid,
  C2_S0: SubDataProcessingOneGrid,
  C2_S1: SubDataProcessingTwoGrid,
  C2_S2: SubDataProcessingThreeGrid
};
// prettier-ignore
export type ProcessingNodeSectionIdentifiers = $Keys<typeof ProcessingSectionToGridMap>;

const RetentionSectionToGridMap = {
  C0_T0: DeletionCiscoOneGrid,
  C0_T1: DeletionCiscoTwoGrid,
  C0_T2: DeletionCiscoThreeGrid,
  C0_T3: DeletionCiscoFourGrid,
  C0_T4: DeletionCiscoFiveGrid,
  C0_T5: DeletionCiscoSixGrid,
  C2_T0: DeletionSubOneGrid,
  C2_T1: DeletionSubTwoGrid,
  C2_T2: DeletionSubThreeGrid,
  C2_T3: DeletionSubFourGrid,
  C2_T4: DeletionSubFiveGrid,
  C2_T5: DeletionSubSixGrid
};
// prettier-ignore
export type RetentionNodeSectionIdentifiers = $Keys<typeof RetentionSectionToGridMap>;

type AlignTransform = (draw: any, nodeId: any) => Position => Position;
const identityAlignTransform = () => R.identity;

function iterateOverNodesAndCoordinatesAndPlaceInSVG(
  // Flow gave me a hard time ; NodeCoordinates & SVGRepresentation should check but cannot handle the intersection
  // type for some reason. Falling back to any.
  nodesAndCoordinates: Array<any>,
  draw
) {
  nodesAndCoordinates.forEach(
    ({ svg: svgContent, coordinates, nodeId: nodeId }) => {
      const grp = draw
        .group()
        .addClass("datamap-node")
        .attr("id", nodeId + "-group");
      const nodeRepresentation = grp.svg(svgContent);
      nodeRepresentation.x(coordinates.x);
      nodeRepresentation.y(coordinates.y);
    }
  );
}

function getPositioningFromGridOrDummyArray(
  nodetype : String,
  forExistingNodes: boolean,
  nodes: Array<any>,
  grid: Quadrant

) {
  let coordinatesArr: Array<Position>;

  if(nodetype === "retentionNodes"){
    if (!forExistingNodes) {
      coordinatesArr = grid.getCordsForNodes(nodes);
    } else {
      coordinatesArr = R.repeat({ x: 0, y: 0 }, nodes.length);
    }
  }
  else {
    if (!forExistingNodes) {
      coordinatesArr = grid.getCordsForNodes(nodes);
    } else {
      coordinatesArr = R.repeat({ x: 0, y: 0 }, nodes.length);
    }
  }

  return coordinatesArr;
}

export function relocateAndAddInputNodes(
  draw: any,
  inputNodes: Array<InputNodeInterpretationOutput>,
  forExistingNodes: boolean = false,
  alignTransform: AlignTransform = identityAlignTransform
) {
  let nodeType = "inputNodes"
  const coordsArray = getPositioningFromGridOrDummyArray(
    nodeType,
    forExistingNodes,
    inputNodes,
    CustomerDataInputGrid
  );

  
  // prettier-ignore
  const nodesAndCoordinates: Array<NodeCoordinates & InputNodeInterpretationOutput> = R.zipWith(
    (node: InputNodeInterpretationOutput, coordinates: Position) => ({
      coordinates: alignTransform(draw,node.nodeId)(coordinates),
      ...node
    })
  )(inputNodes, coordsArray);

  !forExistingNodes &&
    iterateOverNodesAndCoordinatesAndPlaceInSVG(nodesAndCoordinates, draw);

  return nodesAndCoordinates;
}

export function relocateAndAddProcessingNodes(
  draw: any,
  processingNodes: {
    [controllerSubcategoryCombination: ProcessingNodeSectionIdentifiers]: Array<ProcessingNodeInterpretationOutput>
  },
  forExistingNodes: boolean = false,
  alignTransform: AlignTransform = identityAlignTransform
) {

  let nodeType = "processingNodes"
  // prettier-ignore
  const nodesAndCoordinates: Array<NodeCoordinates & ProcessingNodeInterpretationOutput> = R.compose(
    R.flatten,
    R.values,
    //section is a *string* as per ramda, but we know that it's going to be ProcessingNodeSectionIdentifiers.
    //$FlowFixMe
    R.mapObjIndexed(
      (
        nodes: Array<ProcessingNodeInterpretationOutput>,
        section: ProcessingNodeSectionIdentifiers
      ) => {
        const coordinatesArr = getPositioningFromGridOrDummyArray(nodeType,forExistingNodes, nodes, ProcessingSectionToGridMap[
          section
          ]);
 
        return R.zipWith(
          (
            node: ProcessingNodeInterpretationOutput,
            coordinates: Position,
          ) => ({
            coordinates: alignTransform(draw,node.nodeId)(coordinates),
            ...node
          })
        )(nodes, coordinatesArr);
      }
    )
  )(processingNodes);

  !forExistingNodes &&
    iterateOverNodesAndCoordinatesAndPlaceInSVG(nodesAndCoordinates, draw);

  return nodesAndCoordinates;
}

export function relocateAndAddRetentionNodes(
  draw: any,
  retentionNodes: {
    [controllerSubcategoryCombination: RetentionNodeSectionIdentifiers]: Array<RetentionNodeInterpretationOutput>
  },
  forExistingNodes: boolean = false,
  alignTransform: AlignTransform = identityAlignTransform
) {
  // prettier-ignore
  let nodeType = "retentionNodes"
  const nodesAndCoordinates: Array<NodeCoordinates & RetentionNodeInterpretationOutput> = R.compose(
    R.flatten,
    R.values,
    //section is a *string* as per ramda, but we know that it's going to be ProcessingNodeSectionIdentifiers.
    //$FlowFixMe
    R.mapObjIndexed(
      (
        nodes: Array<RetentionNodeInterpretationOutput>,
        section: RetentionNodeSectionIdentifiers
      ) => {
        const coordinatesArr = getPositioningFromGridOrDummyArray( nodeType,forExistingNodes, nodes, RetentionSectionToGridMap[
          section
          ]);

        return R.zipWith(
          (node: RetentionNodeInterpretationOutput, coordinates: Position) => ({
            coordinates: alignTransform(draw,node.nodeId)(coordinates),
            ...node,
          })
        )(nodes, coordinatesArr);
      }
    )
  )(retentionNodes);

  !forExistingNodes &&
    iterateOverNodesAndCoordinatesAndPlaceInSVG(nodesAndCoordinates, draw);

  return nodesAndCoordinates;
}
