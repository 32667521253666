export const ADD_CUSTOMER_NODES = "ADD_CUSTOMER_NODES";
export const ADD_CISCO_NODES = "ADD_CISCO_NODES";
export const ADD_SUBPROCESSOR_NODES = "ADD_SUBPROCESSOR_NODES";
export const SET_PROCESSING_NODES_JSON_VALUES = "SET_PROCESSING_NODES_JSON_VALUES";

export function addCustomerNodes(nodesData) {
  return {
    type: ADD_CUSTOMER_NODES,
    data: nodesData
  };
}

export function addCiscoNodes(nodesData) {
  return {
    type: ADD_CISCO_NODES,
    data: nodesData
  };
}

export function addSubprocessorNodes(nodesData) {
  return {
    type: ADD_SUBPROCESSOR_NODES,
    data: nodesData
  };
}

export function setProcessingNodesJsonValues(jsondata) {
  let obj = {};
  let cisconodes = [];
  let customernodes = [];
  let subprocessnodes = [];

  for (let key in jsondata) {
    if (jsondata[key].controller === "1") {
      let node = jsondata[key]
      node["identifer"] = key
      customernodes.push(node);
    }
    else if (jsondata[key].controller === "0") {
      let node = jsondata[key]
      node["identifer"] = key
      cisconodes.push(node);}
    else if (jsondata[key].controller === "2"){
      let node = jsondata[key]
      node["identifer"] = key
      subprocessnodes.push(node);
    }
  }

  if (customernodes.length > 0) obj["customerNodes"] = customernodes;

  if (cisconodes.length > 0) obj["ciscoNodes"] = cisconodes;

  if (subprocessnodes.length > 0) obj["subprocessorNodes"] = subprocessnodes;

  return {
    type: SET_PROCESSING_NODES_JSON_VALUES,
    data: obj
  };
}
