// @flow

import * as R from "ramda";

export function extractTextFieldFromSVG(draw: any, targetId: string) {
  let buffer = "";
  const readContent = function() {
    buffer = `${buffer}${this.text()}`;
  };

  // Case 1 : Output from Sketch
  draw.select(`#${targetId} tspan`).each(readContent);

  // Case 2 : Ours
  if (R.isEmpty(buffer)) {
    draw.select(`#${targetId}`).each(readContent);
  }

  return buffer;
}

export function isSketchEditFileOrNot(draw: any, targetId: string){
  let buffer = "";
  let isSketchEdit = true;
  const readContent = function() {
    buffer = `${buffer}${this.text()}`;
  };

  // Case 1 : Output from Sketch
  draw.select(`#${targetId} tspan`).each(readContent);

  // Case 2 : Ours
  if (R.isEmpty(buffer)) {
    draw.select(`#${targetId}`).each(readContent);
    isSketchEdit = false
  }

  return isSketchEdit;

}

export function extractJSONFromSVG(draw: any, targetId: string): any | null {
  const formValueBuffer = extractTextFieldFromSVG(draw, targetId);

  if (!R.isEmpty(formValueBuffer)) {
    return JSON.parse(formValueBuffer);
  }

  return null;
}
