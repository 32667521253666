// @flow

import styled, { createGlobalStyle } from "styled-components";
/*import './style.css';*/

export const Global1 = createGlobalStyle`
  @font-face {
    font-family: "CiscoSansGlobal";
    src: url("./Cisco-Fonts/CiscoSans_Global.ttf") format('ttf');
  }

  @font-face {
    font-family: "CiscoSansGlobal Light";
    src: url("./Cisco-Fonts/CiscoSans_Global-Light.ttf") format('ttf');
  }

  @font-face {
    font-family: "CiscoSansGlobal Bold";
    src: url("./Cisco-Fonts/CiscoSans_Global-Bold.ttf") format('ttf');
  }
  @font-face {
    font-family: "Cisco Sans TT Bold";
    src: url("./Cisco-Fonts/CiscoSans_TT-Bold.ttf") format('ttf');
  }

  :root {
  --formBackground: #ffffff;
  --formBorder: lightgrey;
  --formRowBackground: #e1e2e3;
  --formReg: #64bbe3;
  --formRowBorder: #7f7f86;
}

  * {
    box-sizing: border-box;
  }

  body {
    margin:0;
  }

`;

export const RootFormElement = styled.form`
  width: 100%;
`;

export const colors = {
  black: "#000000",
  softBlack: "#57585B",
  white: "#FFFFFF",
  red: "#DD4E47",
  blue: "#64BBE3",
  yellow: "#FFCC00",
  lightGrey: "#F4F4F4",
  mediumGrey: "#E1E2E3",
  darkGrey: "#7F7F86",
  darkAqua: "#004F71",
  lightAqua: "#00BBEB"
};

// to be used in plain SVG markup
export const fontsPlainSVG = {
  title: `font-family='CiscoSansGlobal' font-weight='200' font-size='80' fill=${
    colors.lightAqua
  }`,
  node: `font-family='CiscoSansGlobal' font-weight='400' font-size='25' fill=${
    colors.softBlack
  }`,
  subTitle: `font-family='CiscoSansGlobal' font-weight='400' font-size='40' fill=${
    colors.darkAqua
  }`,
  subTitleLink: `font-family='CiscoSansGlobal' font-weight='800' font-size='40' fill=${
    colors.darkAqua
  }`,
  dataAssetsDataTypeNode: `font-family='CiscoSansGlobal' font-weight='400' font-size='36' fill=${
    colors.white
  }`,
  version: `font-family='CiscoSansGlobal' font-weight='400' font-size='30' fill=${
    colors.softBlack
  }`
};

// to be used in SVG library
export const fontsSVGLibrary = {
  node: {
    family: "CiscoSansGlobal",
    weight: 400,
    size: 25,
    style: "normal",
    fill: colors.softBlack
  },
  title: {
    family: "CiscoSansGlobal Light",
    weight: 400,
    size: 80,
    style: "normal",
    fill: colors.lightAqua
  },
  headerLink: {
    family: "CiscoSansGlobal",
    weight: 400,
    size: 40,
    style: "normal",
    fill: colors.darkAqua
  },
  dataCentersTitle: {
    family: "CiscoSansGlobal",
    weight: 400,
    size: 25,
    style: "normal",
    fill: colors.darkAqua
  },
  version: {
    family: "CiscoSansGlobal",
    weight: 400,
    size: 30,
    style: "normal",
    fill: colors.softBlack
  }
};
