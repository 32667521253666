// @flow

import Datamap from "datamaps";
import * as R from "ramda";

import { SVG } from "../logic/svgWrapper";
import { colors } from "../../../styles";
import { LeftXDataCenterMap, TopYDataCenterMap } from "../grid";
import type { JSONSchema } from "../../types";
import { latLngLookup } from "../../dataEntry/dataCentersSection/locationsWithType";
import { generateTextSection } from "./generateTextSection";

export const bubbleMarkerRadius = 5;

export const DataCentersSection = async (
  dataCenterMapData: $PropertyType<JSONSchema, "dataCenters">,
  SVGMainId: string,
  SVGAuxId: string,
  isEdit: false
) => {
  const { thirdPartyLocations = [], ciscoLocations = [] } = dataCenterMapData;

  /*if (R.isEmpty(thirdPartyLocations) && R.isEmpty(ciscoLocations)) {
    return;
  }*/

  const bothDataCentersList = R.intersection(
    thirdPartyLocations,
    ciscoLocations
  );

  const ciscoDataCentersList = R.difference(
    ciscoLocations,
    thirdPartyLocations
  );

  const thirdPartyDataCentersList = R.difference(
    thirdPartyLocations,
    ciscoLocations
  );

  const lookupHashTable = latLngLookup();
  let dataCentersList = [];
  let dataCentersCountryList = {};

  if (!R.isNil(bothDataCentersList) && !R.isEmpty(bothDataCentersList)) {
    bothDataCentersList.forEach(location => {
      let type = lookupHashTable[location].type;
      if (type == "city" || type == "state") {
        let data = {
          name: location,
          city: lookupHashTable[location].city,
          latitude: lookupHashTable[location].latitude,
          longitude: lookupHashTable[location].longitude,
          fillKey: "both",
          radius: bubbleMarkerRadius
        };
        dataCentersList.push(data);
      } else {
        let countryCode = lookupHashTable[location].code;
        dataCentersCountryList[countryCode] = { fillKey: "both" };
      }
    });
  }
  if (!R.isNil(ciscoDataCentersList) && !R.isEmpty(ciscoDataCentersList)) {
    ciscoDataCentersList.forEach(location => {
      let type = lookupHashTable[location].type;
      if (type == "city" || type == "state") {
        let data = {
          name: location,
          city: lookupHashTable[location].city,
          latitude: lookupHashTable[location].latitude,
          longitude: lookupHashTable[location].longitude,
          fillKey: "isCisco",
          radius: bubbleMarkerRadius
        };
        dataCentersList.push(data);
      } else {
        let countryCode = lookupHashTable[location].code;
        dataCentersCountryList[countryCode] = { fillKey: "isCisco" };
      }
    });
  }
  if (
    !R.isNil(thirdPartyDataCentersList) &&
    !R.isEmpty(thirdPartyDataCentersList)
  ) {
    thirdPartyDataCentersList.forEach(location => {
      let type = lookupHashTable[location].type;
      if (type == "city" || type == "state") {
        let data = {
          name: location,
          city: lookupHashTable[location].city,
          latitude: lookupHashTable[location].latitude,
          longitude: lookupHashTable[location].longitude,
          fillKey: "isThirdParty",
          radius: bubbleMarkerRadius
        };
        dataCentersList.push(data);
      } else {
        let countryCode = lookupHashTable[location].code;
        dataCentersCountryList[countryCode] = { fillKey: "isThirdParty" };
      }
    });
  }

  const map = new Datamap({
    element: document.getElementById("dataCenterMap"),
    projection: "mercator",
    fills: {
      defaultFill: colors.mediumGrey,
      isCisco: colors.darkAqua,
      isThirdParty: colors.lightAqua,
      both: colors.darkGrey
    },
    bubblesConfig: {
      borderWidth: 0,
      opacity: 1
    }
  });

  map.bubbles(dataCentersList, {});
  map.updateChoropleth(dataCentersCountryList);

  // need to wait so that the markers get rendered before we get the svg code from the #dataCenterMap element
  await new Promise(resolve => setTimeout(resolve, 1000));
  // get the map svg generated code from the DOM
  const DataCenterMapGenerated = document.getElementById("dataCenterMap");

  // draw map onto datamap
  const drawMain = SVG(SVGMainId).id("section-datacenter-root");
  const DataCenterMapSvg = drawMain.group();

  if(isEdit){
    if (DataCenterMapGenerated) {
      DataCenterMapSvg.svg(DataCenterMapGenerated.innerHTML);
    }
    DataCenterMapSvg.x(LeftXDataCenterMap - 98).y(TopYDataCenterMap-22);
  }
  else{
    if (DataCenterMapGenerated) {
      DataCenterMapSvg.svg(DataCenterMapGenerated.innerHTML);
    }
    DataCenterMapSvg.x(LeftXDataCenterMap).y(TopYDataCenterMap);
  }
  

  // generate data center desrciption and text
  const dataCentersText = generateTextSection(
    SVGMainId,
    SVGAuxId,
    dataCenterMapData
  );
  drawMain.svg(dataCentersText);

  const result = drawMain.svg();
  drawMain.clear();
  if (DataCenterMapGenerated) {
    DataCenterMapGenerated.innerHTML = "";
  }
  return result;
};
