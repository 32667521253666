export const validationErrors = {
  nodeValidity: "Missing valid input datatype connections for nodes",
  calloutValidity: "Invalid nodes added for given callout datatypes",
  processingRetentionNodeCountValidity:
    "Number of nodes for combination '${this.controller}/${this.subcategory}' exceed ${this.max_nodes}",
  inputNodeCountValidity: "Number of nodes exceed ${this.max_nodes}"
};

export const NODE_LABEL = "Processing/Retention Nodes";
export const NODE_NAME = "nodeValidity";

export const INPUT_NODE_LABEL = "Input Nodes";
export const PROCESSING_NODE_LABEL = "Processing Nodes";
export const RETENTION_NODE_LABEL = "Retention Nodes";
export const MAX_NODES = 4;
export const NODE_COUNT_NAME = "nodeCountValidity";

export const CALLOUT_LABEL = "Callout Datatypes";
export const CALLOUT_NAME = "calloutValidity";
