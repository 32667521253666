// @flow

import * as R from "ramda";

import type { DataEntryFormOutput } from "../../../dataEntry/types";
import type { NodeCoordinates } from "../positioning";
import type { Output as InputNodeInterpretationOutput } from "../../nodeSvg/inputNode";
import type { Output as ProcessingNodeInterpretationOutput } from "../../nodeSvg/processingNode";
import type { Output as RetentionNodeInterpretationOutput } from "../../nodeSvg/retentionNode";
import { getDefaultDataTypeColorMap } from "../../../dataEntry/sections/dataTypes";
import type { LineDrawData } from "./util";
import {
  fetchExistingVirtualConnectableCoordinates,
  getOffsetForLine,
  getReferenceToNode
} from "./util";
import {
  createVirtualConnectableFromConcreteNode,
  createVirtualConnectableFromCoordinates
} from "./connectableVirtual";
import { createConcreteConnectableFromNodeCall } from "./connectableConcrete";
import type {
  AdditionalDrawData,
  Connectable,
  ConnectableVirtual,
  CoordTypeAndSVG
} from "./connectableCommon";
import {
  drawForCallout,
  drawForContracts,
  drawForNodes,
  drawForSubprocessorCiscoPostCalloutConnection
} from "./drawVariants";
import { extendDrawWithPathCommandTracking } from "./drawPathExtension";
import type { DrawConnectableAndLineTrackingData } from "./drawPathExtension";
import { createExistingSVGInterpretation } from "./existingSVGInterpretation";

function drawLinesBetween(
  draw,
  { current, candidates, connectionType }: LineDrawData
) {
  // Get reference to current node.
  const { x: currentX, y: currentY } = current.computeLineConnectionPosition(
    connectionType,
    "left"
  );

  candidates.forEach(candidate => {
    // Get reference to the next candidate node.
    const {
      x: candidateX,
      y: candidateY
    } = candidate.computeLineConnectionPosition(connectionType, "right");

    const shouldSkip = draw.skipOrMemorizeConnectablePair(current, candidate);
    if (shouldSkip) {
      return;
    }

    // Define starting end ending positions.
    const start = [currentX, currentY];
    const end = [candidateX, candidateY];

    const currentLocation = `${start[0]},${start[1]}`;

    draw.startTrackingLineMetadata();

    let path;
    if (
      R.includes("CalloutConnection")(current.getDrawSpecialFlags()) ||
      R.includes("CalloutConnection")(candidate.getDrawSpecialFlags())
    ) {
      path = drawForCallout({
        draw,
        start,
        end,
        currentY,
        candidateY,
        currentX,
        candidateX
      });
    } else if (
      R.includes("ContractsConnection")(current.getDrawSpecialFlags())
    ) {
      path = drawForContracts({
        draw,
        currentLocation,
        end,
        currentY,
        candidateY,
        currentX,
        candidateX
      });
    } else if (
      R.includes("SubprocessorInputConnection")(
        current.getDrawSpecialFlags()
      ) &&
      R.includes("CiscoInputConnection")(candidate.getDrawSpecialFlags())
    ) {
      path = drawForSubprocessorCiscoPostCalloutConnection({
        draw,
        currentLocation,
        end,
        currentY,
        candidateY,
        currentX,
        candidateX
      });
    } else {
      path = drawForNodes({
        draw,
        currentLocation,
        end,
        currentY,
        candidateY,
        currentX,
        candidateX
      });
    }

    // Colorize the line.
    path
      .fill("none")
      .stroke({
        color: getDefaultDataTypeColorMap[connectionType],
        width: 30,
        linecap: "round",
        linejoin: "round"
      })
      .addClass("datamap-line")
      .addClass(`linetype-${connectionType}`);

    draw.applyTrackedLineMetadata({
      styleClasses: ["datamap-line", `linetype-${connectionType}`],
      connects: {
        left: current,
        right: candidate
      }
    });
  });
}

function scanBackwardsAndDrawLines(
  draw: any,
  controllerKey: string,
  nodeList: Array<Array<Connectable>>
) {
  // Tuple of indices (e.g. [[0,1],[1,2],...]), helping us know what "current" and "previous" column.
  // nodeList goes from right to left ; the first element represents the group of rightmost nodes.
  const indicesToProcess = R.compose(
    R.drop(2),
    R.scan((acc, next) => [acc[1], next], [-1, -1])
  )(R.range(0, nodeList.length));

  // Queue for nodes still waiting for a connection.
  const nodesWithPendingConnections: Array<Connectable> = [];

  for (const [currentSection, predecessor] of indicesToProcess) {
    nodeList[currentSection].forEach(node =>
      nodesWithPendingConnections.push(node)
    );
    const candidatesForPendingConnections = nodeList[predecessor];

    let nodesProcessed = 0;
    const nodeCount = nodesWithPendingConnections.length;
    while (nodesProcessed < nodeCount) {
      // Get the next node.
      const currentNodeWithPendingConnections: Connectable = nodesWithPendingConnections.pop();

      // Skip virtual nodes that haven't been on the receiving end before.
      if (
        currentNodeWithPendingConnections.kind === "virtual" &&
        currentNodeWithPendingConnections.isPristine()
      ) {
        nodesProcessed++;
        continue;
      }

      // After we draw the lines, we will drop any established connection from remainingConnections.
      const establishedConnections = [];
      const { remainingConnections } = currentNodeWithPendingConnections;

      // For each color, find candidates from the previous column.
      for (const connectionType of remainingConnections) {
        const candidatesWithMatchingConnections = R.filter(
          R.compose(
            R.contains(connectionType),
            R.prop("unifiedDataTypes")
          )
        )(candidatesForPendingConnections);

        drawLinesBetween(draw, {
          current: currentNodeWithPendingConnections,
          candidates: candidatesWithMatchingConnections,
          connectionType
        });

        // Store the data type/color ; will be removed once this loop ends.
        if (candidatesWithMatchingConnections.length > 0) {
          establishedConnections.push(connectionType);
        }
      }

      // Remove any established data type from the bookkeeping entry.
      establishedConnections.forEach(conn => remainingConnections.delete(conn));

      // Put it back to queue if we still have more data types to connect.
      if (remainingConnections.size > 0) {
        nodesWithPendingConnections.unshift(currentNodeWithPendingConnections);
      }

      nodesProcessed++;
    }
  }
}

function scanForwardsAndGenerateCustomerInputConnectionsCall(
  draw: any,
  existingSVGInterpretation: any,
  nodeList: Array<NodeCoordinates & InputNodeInterpretationOutput>
): Array<ConnectableVirtual> {
  return R.compose(
    R.map(R.view(R.lensIndex(1))),
    R.toPairs,
    R.mapObjIndexed((val, key) =>
      R.compose(
        node => {
          const nodeGenerator: any = createVirtualConnectableFromConcreteNode(
            draw,
            node
          );

          const {
            coordinates: existingNodeCoordinates,
            connectableId: existingId
          } = fetchExistingVirtualConnectableCoordinates(
            existingSVGInterpretation,
            {
              purpose: ["customer", "input"],
              dataTypes: [key]
            }
          );

          const { box, coords } = (nodeGenerator.next().value: any);
          const nodeBase = {
            coordinates: existingNodeCoordinates || {
              x: coords.x + box.w + (120 - Number(key) * 30),
              y: coords.y + box.h / 2
            },
            unifiedDataTypes: [key],
            tags: {
              purpose: ["customer", "input"]
            },
            connectableId: existingId || draw.getNextConnectableId()
          };

          return (nodeGenerator.next(nodeBase).value: any);
        },
        R.reduce(R.minBy(R.pathOr(Infinity, ["coordinates", "y"])), ({}: any))
      )(val)
    ),
    R.groupBy(R.prop("dataType"))
  )(nodeList);
}

export function scanForwardsAndGenerateCiscoSubprocessorInputConnectionsCall(
  draw: any,
  existingSVGInterpretation: any,
  customerInputVirtualNodes: Array<ConnectableVirtual>,
  nodeListAll: Array<Array<CoordTypeAndSVG & AdditionalDrawData>>,
  sectionType: "0" | "2",
  calloutDataTypes: Array<string>
): {
  mainConnectionNodes: Array<string>,
  contractsNodes?: Array<string>,
  calloutNodesTop?: Array<string>,
  calloutNodesBottom?: Array<string>
} {
  const dataTypeCandidates = new Set(["0", "1", "2"]);
  let result = {};

  const dataTypeToExistingCustomerInputSectionVirtualConnectablesMapping = R.reduce(
    (acc, next) => ({
      ...acc,
      [next.unifiedDataTypes[0]]: next
    }),
    {}
  )(customerInputVirtualNodes);

  let calloutNodesBottomMeta = {};
  let contractsNodesMeta = {};
  let contractsNodes = [];
  let calloutNodesTop = [];
  let calloutNodesBottom = [];
  if (calloutDataTypes.length > 0 && sectionType === "0") {
    // 0 = Cisco.
    R.forEach(dataType => {
      // get virtual connectable of same dtype (a)
      // duplicate (a) with different y @ contracts
      // have another virtual connectable @ callout, 250 - Number(key) * 30...

      const existingInputVirtualConnectable =
        dataTypeToExistingCustomerInputSectionVirtualConnectablesMapping[
          dataType
        ];

      // Contracts...
      {
        const { d, box } = getReferenceToNode(draw, "contracts");

        const { y } = { x: d.x(), y: d.y() };

        const yOffset = box.h - (3 - Number(dataType)) * 30;

        const {
          coordinates: existingNodeCoordinates,
          connectableId: existingId
        } = fetchExistingVirtualConnectableCoordinates(
          existingSVGInterpretation,
          {
            purpose: ["contracts"],
            dataTypes: [dataType]
          }
        );

        const coordinatesObj = {
          coordinates: existingNodeCoordinates || {
            x: 30 + existingInputVirtualConnectable.coordinates.x,
            y: y + yOffset
          }
        };

        contractsNodes.push(
          createVirtualConnectableFromCoordinates({
            ...coordinatesObj,
            unifiedDataTypes: [dataType],
            getDrawSpecialFlags: () => ["ContractsConnection"],
            tags: {
              purpose: ["contracts"]
            },
            connectableId: existingId || draw.getNextConnectableId()
          })
        );

        contractsNodesMeta = {
          ...contractsNodesMeta,
          [dataType]: coordinatesObj
        };
      }

      // Callout...
      {
        const contracts = contractsNodesMeta[dataType];

        const x = contracts.coordinates.x - 140;
        const y = contracts.coordinates.y + 172;

        const {
          coordinates: existingNodeCoordinatesTop,
          connectableId: existingIdTop
        } = fetchExistingVirtualConnectableCoordinates(
          existingSVGInterpretation,
          {
            purpose: ["callout", "top"],
            dataTypes: [dataType]
          }
        );
        const coordinatesObjTop = {
          coordinates: existingNodeCoordinatesTop || { x: x, y: y }
        };

        const {
          coordinates: existingNodeCoordinatesBottom,
          connectableId: existingIdBottom
        } = fetchExistingVirtualConnectableCoordinates(
          existingSVGInterpretation,
          {
            purpose: ["callout", "bottom"],
            dataTypes: [dataType]
          }
        );
        const coordinatesObjBottom = {
          coordinates: existingNodeCoordinatesBottom || { x: x, y: y + 40 }
        };

        calloutNodesTop.push(
          createVirtualConnectableFromCoordinates({
            ...coordinatesObjTop,
            unifiedDataTypes: [dataType],
            getDrawSpecialFlags: () => ["CalloutConnection"],
            tags: {
              purpose: ["callout", "top"]
            },
            connectableId: existingIdTop || draw.getNextConnectableId()
          })
        );

        calloutNodesBottom.push(
          createVirtualConnectableFromCoordinates({
            ...coordinatesObjBottom,
            unifiedDataTypes: [dataType],
            getDrawSpecialFlags: () => ["CalloutConnection"],
            tags: {
              purpose: ["callout", "bottom"]
            },
            connectableId: existingIdBottom || draw.getNextConnectableId()
          })
        );
        calloutNodesBottomMeta = {
          ...calloutNodesBottomMeta,
          [dataType]: coordinatesObjBottom
        };
      }

      result = {
        contractsNodes,
        calloutNodesTop,
        calloutNodesBottom
      };
    })(calloutDataTypes);
  }

  let mainNodes = [];
  R.forEach(
    R.forEach(node => {
      const newTypes = R.intersection(
        node.unifiedDataTypes,
        Array.from(dataTypeCandidates)
      );

      for (const newType of newTypes) {
        const nodeGenerator = createVirtualConnectableFromConcreteNode(
          draw,
          node
        );
        const { box, coords } = (nodeGenerator.next().value: any);

        const dataTypes = node.unifiedDataTypes;
        const offset = getOffsetForLine(dataTypes, newType);

        // todo 40 ?
        let x =
          60 +
          dataTypeToExistingCustomerInputSectionVirtualConnectablesMapping[
            newType
          ].coordinates.x;
        let y = coords.y + box.h / 2 + offset;

        const contractsNodeOpt = contractsNodesMeta[newType];
        const calloutNodeOpt = calloutNodesBottomMeta[newType];
        if (contractsNodeOpt && calloutNodeOpt) {
          x = contractsNodeOpt.coordinates.x;
          y = calloutNodeOpt.coordinates.y + 172;
        }

        let drawFlagsObj = {};
        if (sectionType === "0") {
          drawFlagsObj = {
            getDrawSpecialFlags: () => ["CiscoInputConnection"]
          };
        } else {
          drawFlagsObj = {
            getDrawSpecialFlags: () => ["SubprocessorInputConnection"]
          };
        }

        const {
          coordinates: existingNodeCoordinates,
          connectableId: existingId
        } = fetchExistingVirtualConnectableCoordinates(
          existingSVGInterpretation,
          {
            purpose: [sectionType === "0" ? "cisco" : "subprocessor", "input"],
            dataTypes: [newType]
          }
        );

        const nodeBase = {
          coordinates: existingNodeCoordinates || {
            x,
            y
          },
          unifiedDataTypes: [newType],
          ...drawFlagsObj,
          tags: {
            purpose: [sectionType === "0" ? "cisco" : "subprocessor", "input"]
          },
          connectableId: existingId || draw.getNextConnectableId()
        };

        mainNodes.push((nodeGenerator.next(nodeBase).value: any));
      }

      newTypes.forEach(type => dataTypeCandidates.delete(type));
    })
  )(nodeListAll);

  return R.mergeLeft({
    mainConnectionNodes: mainNodes
  })(result);
}

export function scanForwardsAndGenerateProcessingRetentionConnectionsCall(
  draw: any,
  existingSVGInterpretation: any,
  nodeList: Array<CoordTypeAndSVG & AdditionalDrawData>,
  keyController: string,
  groupPrimary: string,
  keySubgroup: string
): Array<ConnectableVirtual> {
  const purposeControllerMap = {
    "0": "cisco",
    "1": "customer",
    "2": "subprocessor"
  };
  const dataTypeCandidates = new Set(["0", "1", "2"]);
  let nodesToReturn = [];

  R.forEach(node => {
    const newTypes = R.intersection(
      node.unifiedDataTypes,
      Array.from(dataTypeCandidates)
    );

    let iterationsSoFar = 0;
    for (const newType of newTypes) {
      const nodeGenerator = createVirtualConnectableFromConcreteNode(
        draw,
        node
      );
      const { box, coords } = (nodeGenerator.next().value: any);

      const dataTypes = node.unifiedDataTypes;
      const offset = getOffsetForLine(dataTypes, newType);

      const {
        coordinates: existingNodeCoordinates,
        connectableId: existingId
      } = fetchExistingVirtualConnectableCoordinates(
        existingSVGInterpretation,
        {
          purpose: [
            purposeControllerMap[keyController],
            `group-${groupPrimary}`,
            `subgroup-${keySubgroup}`
          ],
          dataTypes: [newType]
        }
      );

      const nodeBase = {
        coordinates: existingNodeCoordinates || {
          x: coords.x + box.w + (115 - iterationsSoFar * 30),
          y: coords.y + box.h / 2 + offset
        },
        unifiedDataTypes: [newType],
        tags: {
          purpose: [
            purposeControllerMap[keyController],
            `group-${groupPrimary}`,
            `subgroup-${keySubgroup}`
          ]
        },
        connectableId: existingId || draw.getNextConnectableId()
      };

      nodesToReturn.push((nodeGenerator.next(nodeBase).value: any));

      iterationsSoFar++;
    }

    newTypes.forEach(type => dataTypeCandidates.delete(type));
  })(nodeList);

  return nodesToReturn;
}

type DrawExistingSVGParams = {
  existingSVGDrawTrackingData: DrawConnectableAndLineTrackingData,
  existingSVGString: string,
  existingSVGInterpretation: any
};

export function drawLines(
  formValues: DataEntryFormOutput,
  drawBase: any,
  entries: {
    input: Array<NodeCoordinates & InputNodeInterpretationOutput>,
    processing: Array<NodeCoordinates & ProcessingNodeInterpretationOutput>,
    retention: Array<NodeCoordinates & RetentionNodeInterpretationOutput>
  },
  existingSVGData: ?DrawExistingSVGParams
) {
  /**
   *  The process, simplified :
   *
   *  We split the map by each individual controller.
   *
   *  Then, for each controller, we start from the last column of the map (currently : Service Related/Other) and start
   *  scanning backwards :
   *  For each node in our "current" column, we take look at the currently unconnected data types. Then, for each of
   *  those data types, we peek at the "previous" column and gather a list of all the nodes containing that data type.
   *  We draw a line between nodes (let's call them "source" and "target") with matching data types/colors.
   *
   *  If we draw a line betwen "source" and "target", we drop the relevant data type from the set of "source" node
   *  unconnected data types. The "target" node will be now responsible with connecting the data type all the way down
   *  to the input nodes.
   *
   *  If there are still any data type that isn't connected, it means that we need to scan even further to find a match.
   *  (i.e. There might be no red nodes on Admin/Logistics and Provide Services ; red nodes on Service Related/Other
   *  will have to connect with Input nodes)
   *
   *  Otherwise, we're done with the node ; we won't be adding it back to the queue of nodes with pending connections.
   *
   *  We repeat this process until we reach input nodes.
   *
   */

  const { input, processing, retention } = entries;
  existingSVGData = existingSVGData || {};

  const draw = extendDrawWithPathCommandTracking(
    drawBase,
    R.prop("existingSVGDrawTrackingData")(existingSVGData)
  );

  const existingSVGInterpretation =
    R.prop("existingSVGInterpretation")(existingSVGData) ||
    createExistingSVGInterpretation({ virtualConnectablesAndTagsObj: {} });

  const [
    scanForwardsAndGenerateCustomerInputConnections,
    scanForwardsAndGenerateProcessingRetentionConnections,
    scanForwardsAndGenerateCiscoSubprocessorInputConnections
  ]: Array<any> = R.map((x: any) =>
    x.bind(null, draw, existingSVGInterpretation)
  )([
    scanForwardsAndGenerateCustomerInputConnectionsCall,
    scanForwardsAndGenerateProcessingRetentionConnectionsCall,
    scanForwardsAndGenerateCiscoSubprocessorInputConnectionsCall
  ]);

  const createConcreteConnectableFromNode = createConcreteConnectableFromNodeCall.bind(
    null,
    draw
  );

  // Categorize by controller ; no inter-controller connections are possible except in the case of input nodes.
  const categorizeNodesByGroupAndGenerateConnectables = (
    groupPrimary: string,
    groupSecondary: string
  ): any =>
    R.compose(
      //$FlowFixMe
      R.mapObjIndexed((valController, keyController) =>
        R.compose(
          R.chain(R.identity),
          R.map(R.view(R.lensIndex(1))),
          R.sortBy(R.view(R.lensIndex(0))),
          R.toPairs,
          //$FlowFixMe
          R.mapObjIndexed((valSubgroup, keySubgroup) => [
            valSubgroup,
            scanForwardsAndGenerateProcessingRetentionConnections(
              valSubgroup,
              keyController,
              groupPrimary,
              keySubgroup
            )
          ]),
          R.groupBy(R.prop(groupSecondary)),
          R.map(createConcreteConnectableFromNode)
        )(valController)
      ),
      R.groupBy(R.prop("controller"))
    );

  const processingNodesByController = categorizeNodesByGroupAndGenerateConnectables(
    "processing",
    "subcategory"
  )(processing);
  const retentionNodesByController = categorizeNodesByGroupAndGenerateConnectables(
    "retention",
    "timeline"
  )(retention);

  // Merge processing & retention nodes ; we'll still have 3 loops over Customer, Cisco & Subprocessor, but we'd like
  // to process a continuous combination of Input, Processing and Retention sections.
  const processingAndRetentionNodes: {
    [controllerId: string]: any
  } = R.compose(
    R.mergeDeepRight({ "1": [] }),
    //$FlowFixMe
    R.apply(R.mergeWith(R.concat))
  )([processingNodesByController, retentionNodesByController]);

  // Initialize storage for input section virtual connectables ; callout boxes require some additional work.
  let inputVirtualConnectables: {
    [controllerId: string]: any
  } = {};
  const virtualConnectablesCustomerInput = scanForwardsAndGenerateCustomerInputConnections(
    input
  );
  inputVirtualConnectables["1"] = virtualConnectablesCustomerInput;

  const calloutDataTypes =
    (formValues.generalInfo.callout &&
      formValues.generalInfo.calloutDataTypes) ||
    [];

  // Generate virtual connectables for cisco & subprocessor input
  R.forEachObjIndexed((nodes, controllerKey) => {
    if (controllerKey !== "1") {
      // ignore customer.
      if (nodes.length > 0) {
        const virtualNodes = scanForwardsAndGenerateCiscoSubprocessorInputConnections(
          virtualConnectablesCustomerInput,
          nodes,
          controllerKey,
          calloutDataTypes
        );

        const {
          mainConnectionNodes,
          contractsNodes,
          calloutNodesTop,
          calloutNodesBottom
        } = virtualNodes;

        inputVirtualConnectables[controllerKey] = mainConnectionNodes;

        if (contractsNodes) {
          inputVirtualConnectables["callout"] = {
            contractsNodes,
            calloutNodesTop,
            calloutNodesBottom
          };
        }
      } else {
        inputVirtualConnectables[controllerKey] = [];
      }
    }
  })(processingAndRetentionNodes);

  // Draw cisco & subprocessor sections
  R.forEachObjIndexed((nodes, controllerKey) => {
    if (controllerKey !== "1") {
      // ignore customer.
      if (nodes.length > 0) {
        let finalNodeList;
        if (inputVirtualConnectables["callout"] && controllerKey === "0") {
          const calloutVirtualConnectables =
            inputVirtualConnectables["callout"];

          finalNodeList = [
            [inputVirtualConnectables["1"]],
            [calloutVirtualConnectables["contractsNodes"]],
            [calloutVirtualConnectables["calloutNodesTop"]],
            [calloutVirtualConnectables["calloutNodesBottom"]],
            [inputVirtualConnectables[controllerKey]],
            nodes
          ];
        } else {
          if (controllerKey === "0") {
            finalNodeList = [
              [inputVirtualConnectables["1"]],
              [inputVirtualConnectables[controllerKey]],
              nodes
            ];
            /*
            todo/fixme : CDC-115
            Subprocessors' data lines will attempt connect to cisco instead of going directly to the customer input
            section since we now have to take care of the callout box.
            If we have a subprocessor node that uses a data type unused by cisco, we'll run into a visual bug where
            subprocessor data line will stop abruptly & won't visually connect with input section.
          */
          } else {
            finalNodeList = [
              [inputVirtualConnectables["0"]],
              [inputVirtualConnectables[controllerKey]],
              nodes
            ];
          }
        }

        const nodeList = R.compose(
          R.reverse,
          R.chain(R.identity)
        )(finalNodeList);

        scanBackwardsAndDrawLines(draw, controllerKey, nodeList);
      }
    }
  })(processingAndRetentionNodes);

  // Draw customer section.
  const inputNodesConcreteConnectables = [
    R.map(createConcreteConnectableFromNode)(input)
  ];
  const finalCustomerNodesList = [
    inputNodesConcreteConnectables,
    [inputVirtualConnectables["1"]],
    processingAndRetentionNodes["1"]
  ];

  const customerNodesOrdered = R.compose(
    R.reverse,
    R.chain(R.identity)
  )(finalCustomerNodesList);

  scanBackwardsAndDrawLines(draw, "1", customerNodesOrdered);

  return draw.trackingData;
}
