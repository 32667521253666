import { colors } from "../../../styles";

export const accessorsList = [
  { id: "0", svgName: "contact", name: "Users" },
  { id: "1", svgName: "briefcase", name: "Customer" },
  { id: "2", svgName: "cisco", name: "Cisco" },
  { id: "3", svgName: "cloud", name: "Subprocessor" },
  { id: "4", svgName: "communities", name: "Partner" }
];

export const dataCenterOwnerList = [
  { id: "0", name: "Cisco" },
  { id: "1", name: "3rd Party" }
];

export const dataTypesList = [
  {
    id: "0",
    name: "Registration Data",
    color: colors.blue
  },
  {
    id: "1",
    name: "Generated Data",
    color: colors.yellow
  },
  {
    id: "2",
    name: "Host & Usage Data",
    color: colors.red
  }
];

export const controllersList = [
  {
    id: "0",
    name: "Cisco"
  },
  {
    id: "1",
    name: "Customer"
  },
  {
    id: "2",
    name: "Subprocessor"
  }
];
export const subcategoryAccessList = [
  {
    id: "0",
    name: "Admin/Logistics"
  },
  {
    id: "1",
    name: "Provide Services"
  },
  {
    id: "2",
    name: "Service Related/Other"
  }
];

export const informationFromList = [
  {
    id: "2",
    name: "Section 2"
  },
  {
    id: "4",
    name: "Section 4"
  }
];

export const retentionDurationList = [
  {
    id: "0",
    name: "No retention"
  },
  {
    id: "1",
    name: "Days"
  },
  {
    id: "2",
    name: "Weeks"
  },
  {
    id: "3",
    name: "Months"
  },
  {
    id: "4",
    name: "Years"
  },
  {
    id: "5",
    name: "By request"
  }
];
