/* eslint-disable react/prop-types */

import React from "react";
import Button from "@material-ui/core/Button";

const ContainedButtons = (props) => {
  return (
    <div>
      <Button disabled={!props.buttonStatus} className="primary-button"  variant="contained">{props.buttonTitle}</Button>
    </div>
  );
};

export default ContainedButtons;
