import {
  ADD_DATA_CENTERS_INFO,
  SET_DATA_CENTERS_JSON_VALUE
} from "../actions/dataCentersAction";

const initialState = {};

export function addDataCenters(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_CENTERS_JSON_VALUE:
      return {
        dataCenters: action.data
      };
    case ADD_DATA_CENTERS_INFO:
      return {
        ...state,
        dataCenters: action.data
      };
    default:
      return state;
  }
}
