

const AuthURl = "https://datamaps-idp-dev.auth.us-east-1.amazoncognito.com"


export const IDENTITY_CONFIG = {
  authority: "https://datamaps-idp-dev.auth.us-east-1.amazoncognito.com",
  client_id: "5j9qi6caivn21lkunocp4v4gh1",
  client_secret: '1cm5pu5pttcuel0s9dt5qu5439f2hriqih2nae4q0ktiairufptv',
  login: AuthURl + "/login",
  redirect_uri: `${window.location.origin}/signin-callback.html`,
  loadUserInfo: false,
  response_type: "code",
  scope: "openid",
  webAuthResponseType: "code",
  audience: "5j9qi6caivn21lkunocp4v4gh1",
  grantType: "password",
  logout_uri: AuthURl + "/signout",
  post_logout_redirect_uri: `${window.location.origin}/signin-callback.html`,
};

export const METADATA_OIDC = {
  issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_K80UrTnsX',
  authorization_endpoint: AuthURl + "/oauth2/authorize",
  token_endpoint: AuthURl + "/oauth2/token",
  userinfo_endpoint: AuthURl + "/oauth2/userinfo",
};
