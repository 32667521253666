// @flow

import * as React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import Snackbar from "@material-ui/core/Snackbar";

type Props = {
  snapshot?: any,
  json?: any,
};

const base64Prefix = "data:text/json;base64,";

const formSnapshotToResource = (snapshot) => {
  return R.compose(
    R.concat(base64Prefix),
    btoa,
    unescape,
    encodeURIComponent
  )(JSON.stringify(snapshot));
};

// const FileDownload = ({ snapshot, json }: Props) => {
//   let result = <React.Fragment />;

//   const candidates = [
//     {
//       content: snapshot,
//       name: "datamap_save",
//       userPrompt: "Re-download Form Progress File",
//     },
//     {
//       content: json,
//       name: "datamap_result",
//       userPrompt: "Re-download Datamap JSON File",
//     },
//   ];
//   for (const targetResource of candidates) {
//     if (!targetResource.content) {
//       continue;
//     }

//     const stateResource = formSnapshotToResource(targetResource.content);
//     const now = format(new Date(), "yyyy_dd_mmm");
//     const filename = `${targetResource.name}_${now}.json`;

//     // autodownload resource when it's ready
//     React.useEffect(() => {
//       saveAs(stateResource, filename);
//     });

//     return (
//       <div>
//         {/* <h2>Your files are ready.</h2>
//         <section>
//           <a href={stateResource} download={filename}>
//             {targetResource.userPrompt}
//           </a>
//         </section> */}
//       </div>
//     );
//   }

//   return result;
// };

class FileDownload extends React.Component<Props, any> {
  state = {
    showsSnackbar: false,
    stateResource: "",
    filename: "",
  };
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { snapshot, json } = nextProps;

      if (!R.isNil(snapshot) && !R.isEmpty(snapshot)) {
        let name = "datamap_save"
        const stateResource = formSnapshotToResource(snapshot);
        const now = format(new Date(), "yyyy_dd_mmm");
        const filename = `${name}_${now}.json`;

        this.setState({
        stateResource : stateResource,
        filename: filename,
        showsSnackbar : true
        })
      }
      if (!R.isNil(json) && !R.isEmpty(json)) {
        let name = "datamap_result"
        const stateResource = formSnapshotToResource(json);
        const now = format(new Date(), "yyyy_dd_mmm");
        const filename = `${name}_${now}.json`;

        this.setState({
        stateResource : stateResource,
        filename: filename,
        showsSnackbar : true
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.stateResource !== this.state.stateResource){
      if(!R.isNil(this.state.stateResource)){
        saveAs(this.state.stateResource, this.state.filename)
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.showsSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({
              showsSnackbar: false,
            });
          }}
          message="JSON saved successfully."
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  snapshot: R.pathOr(null, ["formSnapshot", "latest"])(state),
  json: R.pathOr(null, ["jsonOutput", "latest"])(state),
});

export default connect(
  mapStateToProps,
  null
)(FileDownload);
