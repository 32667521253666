// @flow

import { SVG } from "../logic/svgWrapper";

// import { CustomerDataProcessingOneGrid } from "../grid";
import { colors } from "../../../styles";
import type { Accessor, JSONSchema, ProcessingNode } from "../../types";
import {
  outlineStrokeWidth,
  drawMultiTypeNodeAndOutlines,
  generateMulticoloredNode,
  // generateSingleLineTextWithInRect,
  emptySVGElement,
  calculateLines,
  calculateWordWidths
} from "./nodesCommon";
import type { ProcessingNodeSectionIdentifiers } from "../logic/positioning";
import { iconsListIdToSVGMap } from "../../../assets/icons/iconsLoadedList";
import { fontsSVGLibrary } from "../../../styles";
import { type SVGRepresentation, nodeRadius } from "./nodesCommon";
import { accessorsList } from "../../dataEntry/sections/config";

// Size & margins.
export const defaultNodeSize = { w: 480, h: 110 };
export const maxDefaultNodeSize = { w: 520, h: 140 };

// const minLineWidth = 28;
// const maxLineWidth = 35;

export const IconsMainSuffix = "-icons-main";
export const IconIndividualInfix = "-iconfor-";

export const widthOfIcon = 51;
export const iconHMargin = 15;

type NodeIconsInput = {
  node: { accessors: Array<$PropertyType<Accessor, "id">> },
  iconsSection: any,
  nodeId: string,
  xPositionStartOfIconGroup: number,
  actualSize: { h: number }
};
export function drawProcessingNodeIcons({
  node,
  iconsSection,
  nodeId,
  xPositionStartOfIconGroup,
  actualSize
}: NodeIconsInput) {
  // Create a group wrapper around each icon so that they can be positioned
  let groupWrapperArray = [];
  // sort the accessors list so that the icons appear left to right in this order:
  // User, Customer, Cisco, Subprocessor, Partner
  const sortedAccessorsArray = node.accessors.map(accessor =>
    parseInt(accessor)
  );
  sortedAccessorsArray.sort((a, b) => a - b);

  sortedAccessorsArray.forEach((accessorId, index) => {
    groupWrapperArray[index] = iconsSection
      .group()
      .id(`${nodeId}${IconIndividualInfix}${accessorId}`);
    let xPositionIcon =
      xPositionStartOfIconGroup +
      iconHMargin +
      index * (widthOfIcon + iconHMargin);
    groupWrapperArray[index]
      .svg(iconsListIdToSVGMap[accessorsList[accessorId].svgName])
      .x(xPositionIcon)
      .select("path,polygon")
      .each(function() {
        this.attr({ fill: fontsSVGLibrary.node.fill });
        // Ankit Tharwani: removed the stroke as it leads to bleading edges for some icons - fill should be sufficient
        /*this.attr({ stroke: fontsSVGLibrary.node.fill });*/
      });

    // Ankit Tharwani: changes to center the icons vertically; exception had to be made for cisco icon
    let { width, height } = groupWrapperArray[index].bbox();
    let maxDimension = Math.max(width, height);
    if (accessorsList[accessorId].svgName == "cisco")
      groupWrapperArray[index].cy(actualSize.h / 2);
    else
      groupWrapperArray[index].cy(
        actualSize.h / 2 - (maxDimension - height) / 4
      );
  });
}

export function generateProcessingNodeSVG(
  svgMain: string,
  svgAuxiliary: string,
  nodeId: string,
  node: ProcessingNode
) {
  const drawMain = SVG(svgMain);
  const drawAuxiliary = SVG(svgAuxiliary);
  drawMain
    .clear()
    .id(nodeId)
    .addClass("node-kind-processing")
    .addClass(`controller-${node.controller}`)
    .addClass(`subcategory-${node.subcategory}`);
  // 0. Start with some defaults. textbox size will be determined by how many accessors there are
  // determine how many accessors there are

  const iconGroupRightMargin = 5;
  const nodeLeftPadding = 15;
  const textBoxRightPadding = 15;
  const numOfAccessors = node.accessors.length;

  let lineWidth = 28;
  let fontSize = "23";
  if (numOfAccessors === 2) {
    lineWidth = 24;
  } else if (numOfAccessors === 3) {
    lineWidth = 21;
  }

  let actualSize = Object.assign({}, defaultNodeSize);
  actualSize.w = defaultNodeSize.w + (numOfAccessors - 1) * 20;
  // let fontStyles = fontsPlainSVG.node;

  let wordsWithComputedWidth = calculateWordWidths(node.nodeCopy);
  let lines = calculateLines(wordsWithComputedWidth, lineWidth);
  if (lines.length > 3) {
    lineWidth = 33;
    fontSize = "20";
    if (numOfAccessors === 2) {
      lineWidth = 29;
    } else if (numOfAccessors === 3) {
      lineWidth = 26;
    }

    wordsWithComputedWidth = calculateWordWidths(node.nodeCopy);
    lines = calculateLines(wordsWithComputedWidth, lineWidth);

    actualSize = Object.assign({}, maxDefaultNodeSize);
    actualSize.w = maxDefaultNodeSize.w + (numOfAccessors - 1) * 20;
  }

  // if there is only one accessor, have margin on the left side and right side of accessor
  let widthIconSection = widthOfIcon + 2 * iconHMargin;
  // if there is more then one accessor, have only margin on the left with a slight right margin on the last icon
  if (numOfAccessors > 1) {
    widthIconSection =
      numOfAccessors * (widthOfIcon + iconHMargin) + iconGroupRightMargin;
  }
  const textboxSize = (widthIconSection) => {
    return {
      w: actualSize.w - nodeLeftPadding - widthIconSection - textBoxRightPadding
    };
  };
  const xPositionStartOfIconGroup =
    textboxSize(widthIconSection).w + textBoxRightPadding;

  // grey background behind the icons generation function
  const drawIconGroupBackground = () => {
    drawMain
      .rect(
        widthIconSection + iconGroupRightMargin + outlineStrokeWidth / 2,
        actualSize.h - 2 * outlineStrokeWidth
      )
      .fill(colors.mediumGrey)
      .rx(nodeRadius)
      .move(xPositionStartOfIconGroup, outlineStrokeWidth);
    // draw another rectangle to get the square corners on left hand side
    drawMain
      .rect(
        (widthIconSection + iconGroupRightMargin) * 0.5,
        actualSize.h - 2 * outlineStrokeWidth
      )
      .fill(colors.mediumGrey)
      .move(xPositionStartOfIconGroup, outlineStrokeWidth);
  };

  // 2. Main viewbox & node background
  drawMain
    .viewbox(0, 0, actualSize.w, actualSize.h)
    .size(actualSize.w, actualSize.h);

  drawMultiTypeNodeAndOutlines(node, drawMain, actualSize, nodeId);
  drawIconGroupBackground();


  // 3. Generate textbox.
  // drawMain.svg(
  //   generateSingleLineTextWithInRect(
  //     node.nodeCopy,
  //     nodeLeftPadding,
  //     actualSize.h / 2,
  //     lineWidth,
  //     fontStyles,
  //     "processingNode",
  //     xspan
  //   )
  // )

  var nested = drawMain.nested().attr({
    height: "50",
    width: "200",
  });

  var group = nested.group().translate(180, -40);
  let y;
  let x = 15;
  if(fontSize === "23"){
    if(lines.length === 1){
      y = 100;
    }
    else if(lines.length === 2){
      y = 87
    }
    else {
      y = 78;
    }
  }
  else {
    x = 20;
    if(lines.length === 1){
      y = 104;
    }
    else if(lines.length === 2){
      y = 90
    }
    else  if(lines.length === 3) {
      y = 87;
    }
    else {
      y = 78
    }
  }

  var text = drawMain
    .text(function(add) {
      for (let i = 0; i < lines.length; i++) {
        add
          .tspan(lines[i])
          .x(x)
          .y(y);
        y = y + 25;
      }
    })
    .font({
      family: fontsSVGLibrary.node.family,
      weight: 400,
      size: fontSize,
      fill: "#57585B",
      anchor: "middle",
    });

  group.add(text);

  // if (lines.length < 3) {
  //   let y = 55
  //   let x= 13
  //   for (let i = 0; i < lines.length; i++) {
  //     group.add(
  //       drawMain.text(lines[i]).x(x).y(y)
  //       .font({
  //         family: fontsSVGLibrary.node.family,
  //         weight: 400,
  //         size: "23",
  //         fill: "#57585B",
  //         anchor: "middle",
  //       })
  //     );
  //     y = y + 25
  //   }
  // }
  // else {
  //   wordsWithComputedWidth = calculateWordWidths(node.nodeCopy);
  //   lines = calculateLines(wordsWithComputedWidth, lineWidth);
  //   let y =55
  //   let x= 13
  //   for (let i = 0; i < lines.length; i++) {
  //     group.add(
  //       drawMain.text(lines[i]).x(x).y(y)
  //       .font({
  //         family: "CiscoSansGlobal",
  //         weight: 400,
  //         size: "20",
  //         fill: "#57585B",
  //         anchor: "middle",

  //       })
  //     );
  //     y = y + 20
  //   }
  // }

  // 4. Generate Icons

  // 4. Icons
  const iconsSection = drawMain.group().id(`${nodeId}${IconsMainSuffix}`);
  drawProcessingNodeIcons({
    node,
    iconsSection,
    nodeId,
    xPositionStartOfIconGroup,
    actualSize,
  });

  // 5. Memorize the result & cleanup...
  const result = drawMain.svg();
  drawMain.clear();
  drawAuxiliary.clear();

  return result;
}

export type Output = ProcessingNode & SVGRepresentation;

export function generateProcessingNodesInner(
  // prettier-ignore
  processingNodesData: $NonMaybeType<$PropertyType<JSONSchema, "processingNodes">>,
  SVGMainId: string,
  SVGAuxId: string,
  svgGenerator: typeof generateProcessingNodeSVG
): {
  [controllerSubcategoryCombination: ProcessingNodeSectionIdentifiers]: Array<Output>
} {
  return generateMulticoloredNode(
    processingNodesData,
    SVGMainId,
    SVGAuxId,
    svgGenerator,
    ({ controller, subcategory }) => `C${controller}_S${subcategory}`
  );
}

export function generateProcessingNodes(
  // prettier-ignore
  processingNodesData: $NonMaybeType<$PropertyType<JSONSchema, "processingNodes">>,
  SVGMainId: string,
  SVGAuxId: string,
  shouldProduceDummyNodes: boolean
) {
  return generateProcessingNodesInner(
    processingNodesData,
    SVGMainId,
    SVGAuxId,
    !shouldProduceDummyNodes ? generateProcessingNodeSVG : emptySVGElement
  );
}