
import { IS_UPDATED, SET_PRODUCT_INFO } from "./constant";
import * as api from "../apis/datamaps.api";

export function addProductInfo(data) {

  return async (dispatch) => {
    console.log("Calling Update ProductInfo Api...")
    const payload = {
      "mapStatus": "draft",
      "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "modifiedDate": new Date(),
      "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
      "message": "Product info data created",
      "productInfo": data
    }
    const res = await api.updateProductInfo(payload, window.MAP_DATA.datamapId);
    if (res && res.status === 200) {
      dispatch({
        type: SET_PRODUCT_INFO,
        data: data
      })
      dispatch({
        type: IS_UPDATED,
        data: true
      })
    }
  }
}

export function setProductInfo(data){
  return {
    type: SET_PRODUCT_INFO,
    data: data
  };
}