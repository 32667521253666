// @flow

import * as R from "ramda";
import type { Connectable } from "./connectableCommon";

export type DrawConnectableAndLineTrackingData = {
  connectables: { [id: string]: ConnectableMetadata },
  lineTracker: {
    currentlyPreparedPathId: string | void,
    committed: { [id: string]: LineMetadata }
  },
  idDraw?: number,
  idConnectable?: number
};

export type LineMetadata = {
  styleClasses: Array<string>,
  connects: {
    left: string,
    right: string
  }
};

export type ConnectableMetadata = {
  connectableId: string,
  kind: "virtual" | "concrete",
  tags?: { [descriptor: string]: Array<any> }
};

export function generateInitialDrawTrackingMetadata() {
  return {
    connectables: {},
    lineTracker: {
      currentlyPreparedPathId: "",
      committed: {}
    },
    idDraw: 1,
    idConnectable: 1
  };
}

function* idGenerator(tracking, prop) {
  let nextId = tracking[prop] || 1;

  while (true) {
    nextId++;
    tracking[prop] = nextId;
    yield nextId;
  }
}

function extractConnectableDataForTracking(connectable: Connectable) {
  if (connectable.kind === "virtual") {
    return R.pick(["kind", "connectableId", "tags"])((connectable: any));
  } else {
    return R.pick(["kind", "connectableId"])((connectable: any));
  }
}

function extractLineDataForTracking(connectable: Connectable) {
  return R.prop("connectableId")((connectable: any));
}

export const drawPathProxy = (
  trackingMetadata: DrawConnectableAndLineTrackingData
) => {
  return {
    get: function(target, prop) {
      const { currentlyPreparedPathId } = trackingMetadata.lineTracker;

      if (!R.isEmpty(currentlyPreparedPathId)) {
        switch (prop) {
          case "path": {
            return (...args) => {
              const pathResult = target[prop].apply(target, args);
              return pathResult.id(currentlyPreparedPathId);
            };
          }
        }
      }

      return target[prop] || target;
    }
  };
};

function extendDrawWithConnectableAndLineStateTracking(
  drawBase: any,
  previouslyCapturedDrawMetadata: ?DrawConnectableAndLineTrackingData
) {
  const trackingData: DrawConnectableAndLineTrackingData =
    previouslyCapturedDrawMetadata || generateInitialDrawTrackingMetadata();

  const drawIdGen = idGenerator(trackingData, "idDraw");
  const connectableIdGen = idGenerator(trackingData, "idConnectable");

  return Object.assign(drawBase, {
    getNextConnectableId() {
      const id = (connectableIdGen.next().value: any);
      return `connectable-${id}`;
    },
    skipOrMemorizeConnectablePair: (current, candidate) => {
      const previousLineExists = R.compose(
        R.find(
          ({ connects: { left, right } }) =>
            left === current.connectableId && right === candidate.connectableId
        ),
        R.values
      )(trackingData.lineTracker.committed);

      if (previousLineExists) {
        return true;
      }

      trackingData.connectables = R.mergeRight(
        R.reduce(
          (acc, next) =>
            R.assoc(
              next.connectableId,
              extractConnectableDataForTracking(next)
            )(acc),
          {}
        )([current, candidate]),
        trackingData.connectables
      );

      return false;
    },
    startTrackingLineMetadata: () => {
      trackingData.lineTracker.currentlyPreparedPathId = `datamap-line-${(drawIdGen.next()
        .value: any)}`;
    },
    applyTrackedLineMetadata: metadata => {
      const { lineTracker } = trackingData;

      if (R.isEmpty(lineTracker.currentlyPreparedPathId)) {
        return;
      }

      lineTracker.committed = R.assoc(
        lineTracker.currentlyPreparedPathId,
        R.over(
          R.lensProp("connects"),
          //$FlowFixMe
          R.lift(extractLineDataForTracking)
        )(metadata)
      )(lineTracker.committed);

      lineTracker.currentlyPreparedPathId = "";
    },
    get trackingData() {
      return trackingData;
    }
  });
}

export const extendDrawWithPathCommandTracking = (
  drawBase: any,
  previouslyCapturedDrawMetadata: ?DrawConnectableAndLineTrackingData
) => {
  const extended = extendDrawWithConnectableAndLineStateTracking(
    drawBase,
    previouslyCapturedDrawMetadata
  );

  return new Proxy<*>(extended, drawPathProxy(extended.trackingData));
};
