export const EDIT_GENERAL_INFO = "EDIT_GENERAL_INFO";
export const EDIT_PRODUCT_INFO = "EDIT_PRODUCT_INFO";
export const EDIT_DATA_CENTERS = "EDIT_DATA_CENTERS";
export const EDIT_INPUT_NODES = "EDIT_INPUT_NODES";
export const EDIT_PROCESSING_NODES = "EDIT_PROCESSING_NODES";
export const EDIT_RETENTION_NODES = "EDIT_RETENTION_NODES";


export function editGeneralInfo(editData){
    return{
        type: EDIT_GENERAL_INFO,
        data: editData
    }
}

export function editProductInfo(editData){
    return{
        type: EDIT_PRODUCT_INFO,
        data: editData
    }
}

export function editDataCenters(editData){
    return{
        type: EDIT_DATA_CENTERS,
        data: editData
    }
}

export function editInputNodes(editData){
    return{
        type: EDIT_INPUT_NODES,
        data: editData
    }
}

export function editProcessingNodes(editData){
    return{
        type: EDIT_PROCESSING_NODES,
        data: editData
    }
}

export function editRetentionNodes(editData){
    return{
        type: EDIT_RETENTION_NODES,
        data: editData
    }
}




