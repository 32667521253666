//$FlowFixMe
import BackgroundNoSub from "!!raw-loader!../../../assets/backgrounds/noSubVariation3.svg";
//$FlowFixMe
import BackgroundSub from "!!raw-loader!../../../assets/backgrounds/subVariation4.svg";
import { generateSVGMain } from "./index";

export const getFinalSVGCode = async (
  formValues,
  existingSvg,
  isCiscoToSubContract,
  SVGMainId,
  SVGAuxId
) => {
  const BackgroundSvgString = isCiscoToSubContract
    ? BackgroundSub
    : BackgroundNoSub;
  const finalSvgCode = await generateSVGMain(
    SVGMainId,
    SVGAuxId,
    formValues,
    existingSvg,
    BackgroundSvgString
  );
  return finalSvgCode;
};
