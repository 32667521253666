import {
  SET_INITIAL_STATE,
  ADD_GENERAL_INFO,
  ADD_PRODUCTION_INFO,
  SET_DATA_TYPES
} from "../actions/dataEntryActions";

// const initialState = {
//   generalInfo: {
//     productName: "Cisco UCM Cloud",
//     dataSheetVersion: "1.0a",
//     dataSheetURL: "www.cisco.com",
//     date: "2019-10-25"
//   },
//   productionInfo: {
//     dataTypes: {
//       registrationData: {
//         datatype: "apple",
//         dataAssets: "New laptop activation"
//       },
//       generatedData: {
//         datatype: "cisco",
//         dataAssets: "New system data"
//       },
//       hostUsageData: {
//         datatype: "microsoft",
//         dataAssets: "New logical flow with data"
//       }
//     },
//     contracts: {
//       customerToSubprocessor: true
//     },
//     callout: true,
//     calloutCopy: "zxjjnckackjbxb",
//     calloutDataTypes: ["0", "1"]
//   },
//   dataCenters: {
//     policiesDescription:
//       "Cisco delivers the Service from its own Cisco Webex data centers.",
//     ciscoLocations: ["Los Angeles", "London", "Reston", "Amsterdam"],
//     thirdPartyLocations: ["Bangalore", "Mumbai"]
//   },
//   inputNodes: {
//     "inputNodeForm-1": {
//       iconSvgString:
//         "PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNDhweCIgaGVpZ2h0PSI0OHB4IiB2aWV3Qm94PSIwIDAgNDggNDgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQ4IDQ4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoICBkPSJNNDEuODgzLDM2LjY0NWwtMTIuNjg5LTcuMzY1Yy0wLjM0OS0wLjIwMS0wLjc1Mi0wLjY3NC0wLjg5Ni0xLjA1MWwtMS40OTUtMy4zNDgNCgkJYzIuMTA1LTEuNDg4LDMuNDMxLTYuMzU4LDMuNDMxLTkuMTMxdi01LjVjMC00LjUzNi0zLjcwNi04LjI1LTguMjQyLTguMjVjLTQuNTM2LDAtOC4yNDIsMy43MTQtOC4yNDIsOC4yNXY1LjUNCgkJYzAsMi43NzMsMS4zMjUsNy42NDMsMy40Myw5LjEzMWwtMS40OTUsMy4zNDhjLTAuMTQzLDAuMzc3LTAuNTQ2LDAuODUtMC44OTYsMS4wNTFsLTEyLjY5LDcuMzY1DQoJCWMtMC4zNDksMC4yMDEtMC42ODksMC42OTEtMC43NTYsMS4wOUwwLDQ2aDQzLjk4MmwtMS4zNDQtOC4yNjZDNDIuNTcyLDM3LjMzNiw0Mi4yMzIsMzYuODQ2LDQxLjg4MywzNi42NDV6Ii8+DQoJPHBhdGggIGQ9Ik00NiwyMC45OThsLTQsMC4wMDF2LTRjMC0xLjEtMC45LTItMi0ycy0yLDAuOS0yLDJ2NGgtNGMtMS4xLDAtMiwwLjktMiwyUzMyLjksMjUsMzQsMjVoNHY0DQoJCWMwLDEuMTAyLDAuOSwyLDIsMnMyLTAuODk4LDItMnYtNGw0LTAuMDAyYzEuMSwwLDItMC44OTksMi0yUzQ3LjEsMjAuOTk4LDQ2LDIwLjk5OHoiLz4NCjwvZz4NCjwvc3ZnPg0K",
//       dataType: "2",
//       nodeCopy: "Customer creates account",
//       dataAssets:
//         "Device Activation Codes\n• Name and Aliases\n• Email Address\n• Profile Picture\n• Phone Number, Directory\nExtension, Direct Line\n• SIP Identifier\n• Voicemail Box Number\n• Credentials - User ID, Password,\nPINs\n• Cookies\n• Company Name\n• Company Contact Name and Title\n• Company Physical Address\n• Company Time Zone\n• Organization ID\n• Optional Feature\no Contact List (Names,\nnumbers, emails,\ntitles, Speed dials) "
//     }
//   },
//   processingNodes: {
//     "processNodeForm-1": {
//       dataSheetSection: "2",
//       controller: "1",
//       subcategory: "2",
//       dataTypes: ["0", "2"],
//       dataSheetCopy:
//         "communicate with you on status and availability of the serve, notify you of features and updates, sned you cisco marketing commnications",
//       nodeCopy:
//         "Send communications such as updates, service availability, marketing",
//       accessors: ["2", "1"]
//     }
//   },
//   retentionNodes: {
//     "retentionNodeForm-1": {
//       controller: "0",
//       timeline: "3",
//       dataTypes: ["0", "2", "1"],
//       nodeCopy: "Deleted within 3 months of service termination",
//       exactCopy: "Deleted within 3 months of service termination"
//     }
//   },
//   svgRegeneration: {
//     svgRegenerationFlag: "fresh"
//   }
// };

const initialState = {};

export function addDataEntries(state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return { generalInfo: action.data };
    case ADD_GENERAL_INFO:
      return {
        ...state,
        generalInfo: { ...state.generalInfo, ...action.data }
      };
    case ADD_PRODUCTION_INFO:
      return {
        ...state,
        generalInfo: { ...state.generalInfo, ...action.data }
      };
    case SET_DATA_TYPES:
      return {
        ...state,
        generalInfo: { ...state.generalInfo, dataTypes: action.data }
      };
    default:
      return state;
  }
}
