// @flow

type Input = $Shape<{
  draw: any,
  currentLocation: string,
  start: Array<number>,
  end: Array<number>,
  currentY: number,
  candidateY: number,
  currentX: number,
  candidateX: number
}>;

export function drawForNodes({
  draw,
  currentLocation,
  end,
  currentY,
  candidateY,
  currentX,
  candidateX
}: Input) {
  let path;

  const diffY = currentY - candidateY;
  const diffYAbs = Math.abs(diffY);
  if (diffYAbs < 1) {
    // Straight line
    path = draw.path(`M${currentLocation} L${end[0]},${end[1]}`);
  } else {
    // Curved line
    const diffYSign = diffY < 0 ? -1 : 1;
    const diffXAbs = Math.abs(currentX - candidateX);
    const regularCurveRadius = Math.min(30, diffYAbs / 2);

    if (diffXAbs <= 2 * regularCurveRadius) {
      const curveRadius = diffXAbs / 2;
      const straightY = -diffYSign * Math.abs(diffYAbs - 2 * curveRadius);

      const circle1 = `${curveRadius},${curveRadius} 0 0 ${
        diffYSign < 0 ? 0 : 1
      } -${curveRadius},${-diffYSign * curveRadius}`;
      const circle2 = `${curveRadius},${curveRadius} 0 0 ${
        diffYSign > 0 ? 0 : 1
      } -${curveRadius},${-diffYSign * curveRadius}`;

      path = draw.path(
        `M${currentLocation} a${circle1} v${straightY} a${circle2}`
      );
    } else {
      const circle1 = `${regularCurveRadius},${regularCurveRadius} 0 0 ${
        diffYSign < 0 ? 0 : 1
      } -${regularCurveRadius},${-diffYSign * regularCurveRadius}`;
      const circle2 = `${regularCurveRadius},${regularCurveRadius} 0 0 ${
        diffYSign > 0 ? 0 : 1
      } -${regularCurveRadius},${-diffYSign * regularCurveRadius}`;

      const midpointDistance = Math.abs(diffXAbs / 2 - regularCurveRadius);
      const straightY =
        -diffYSign * Math.abs(diffYAbs - 2 * regularCurveRadius);

      path = draw.path(
        `M${currentLocation} h${-midpointDistance} a${circle1} v${straightY} a${circle2} h${-midpointDistance}`
      );
    }
  }
  return path;
}

export function drawForSubprocessorCiscoPostCalloutConnection({
  draw,
  currentLocation,
  currentY,
  candidateY
}: Input) {
  let path;

  const diffY = currentY - candidateY;
  const diffYAbs = Math.abs(diffY);

  const curveRadius = Math.min(30, diffYAbs / 2);

  const diffYSign = diffY < 0 ? -1 : 1;
  const circle1 = `${curveRadius},${curveRadius} 0 0 ${
    diffYSign < 0 ? 0 : 1
  } -${curveRadius},${-diffYSign * curveRadius}`;

  const straightY = Math.min(
    0,
    -diffYSign * Math.abs(diffYAbs - curveRadius / 2)
  );

  path = draw.path(`M${currentLocation} a${circle1} v${straightY}`);

  return path;
}

export function drawForContracts({
  draw,
  currentLocation,
  currentY,
  candidateY
}: Input) {
  let path;

  const diffY = currentY - candidateY;
  const diffYAbs = Math.abs(diffY);
  {
    const curveRadius = Math.min(30, diffYAbs / 2);

    const diffYSign = diffY < 0 ? -1 : 1;
    const circle2 = `${curveRadius},${curveRadius} 0 0 ${
      diffYSign > 0 ? 0 : 1
    } -${curveRadius},${-diffYSign * curveRadius}`;

    const straightY = Math.min(
      0,
      -diffYSign * Math.abs(diffYAbs - curveRadius)
    );

    path = draw.path(`M${currentLocation} v${straightY} a${circle2}`);
  }
  return path;
}

export function drawForCallout({
  draw,
  start,
  end,
  currentX,
  candidateX
}: Input) {
  const diffX = candidateX - currentX;

  let path;
  if (Math.abs(diffX) < 1) {
    // Straight line
    path = draw.path(`M${start[0]},${start[1]} L${end[0]},${end[1]}`);
  } else {
    const pathToRight = end[0] > start[0];
    let pathCommand;

    if (pathToRight) {
      pathCommand = `
      M ${end[0]} ${end[1]}
      c 0 16 -11 38 -24 48
      M ${start[0]} ${start[1]}
      c 0 -16 11 -38 24 -48
      L ${end[0] - 24} ${end[1] + 48}`;
    } else {
      pathCommand = `
      M ${end[0]} ${end[1]}
      c 0 16 11 38 24 48
      M ${start[0]} ${start[1]}
      c 0 -16 -11 -38 -24 -48
      L ${end[0] + 24} ${end[1] + 48}`;
    }

    path = draw.path(pathCommand);
  }

  return path;
}
