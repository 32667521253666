/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from '@material-ui/core/styles';
import {
    Drawer, Toolbar, Button, Dialog, DialogTitle, CircularProgress
} from '@material-ui/core';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { stepperTitles } from '../../constant';
import ProductionInfoSection from "../productionInfoSection";
import InputNodeSection from "../inputNodeSection";
import ProcessingNodeInfo from "../processingNodeSection";
import RetentionNodeSection from "../retentionNodeSection";
import DataCentersSection from "../dataCentersSection";
import HomeSection from "../homeSection";
import ReviewSection from "../reviewSection";
import { toggleIsUpdated } from "../../actions/metaDataAction";
import SVGGeneration from "../svgGeneration";

const drawerWidth = 190;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        '& .MuiInputBase-input': {
            fontFamily: "SFProTextRegular !important",
            fontSize: "14px",
            textTransform: "capitalize",
            color: "#24242D",
            height: "30px",
            marginLeft: "10px"
        },
        margin: theme.spacing(1),
        minWidth: 140,

    },
    title: {
        fontFamily: "SFProTextLight !important",
        fontSize: "16px",
        paddingLeft: "8px",
        textTransform: "capitalize",
        color: "#24242D"
    },
    tableheading: {
        fontFamily: "SFProDisplaySemibold !important",
        fontSize: "24px",
        color: "#24242D"
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "25px"
    },
    button: {
        marginRight: theme.spacing(2),
        color: "#ffffff",
        backgroundColor: "#049fd9",
        height: "34px",
        borderRadius: "20px !important",
        textTransform: "capitalize"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(5) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    listContent: {
        "& .MuiTypography-body1": {
            fontFamily: "inherit",
        },
        "& .MuiBadge-colorError": {
            backgroundColor: "#4C42BF"
        },
        "& .MuiBadge-badge": {
            height: "14px",
            minWidth: "10px",
            borderRadius: "14px",
            padding: "0px 4px"
        }
    },
    dial_root: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    dial_paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    badge: {
        fontSize: "10px",
        marginTop: "3px",

    },
    bodyText: {
        paddingRight: "10px"
    },
    dialog: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        '& .MuiDialog-paperScrollPaper': {
            alignItems: "center",
        }
    }
}));

const HorizontalStepper = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [open, setOpen] = React.useState(true);
    const [navBtn, setNavBtn] = useState(new Set([0]));
    const [loader, setLoader] = useState(false);

    let child = null;
    console.log("child", child);

    const { isUpdated, toggleIsUpdated, generalInfo } = props;
    const handlesteps = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    useEffect(() => {
        if (isUpdated) {
            setNavBtn((prev) => new Set(prev.add(activeStep + 1)));
            setActiveStep(prevActiveStep => prevActiveStep + 1);
            toggleIsUpdated(false);
            setLoader(false);
        }
    }, [isUpdated]);

    const handleNext = () => {

        console.log(activeStep);

        if (activeStep === 0) {
            console.log(child);
            if (child.validationProductinfo()) {
                let op = child.validationProductinfo();
                if (op) {
                    setLoader(true);
                    child.saveProductionInfo();
                }
            }
        } else if (activeStep === 1) {
            if (child.validationDataCenters()) {
                let op = child.validationDataCenters();
                if (op) {
                    setLoader(true);
                    child.saveDataCentersInfo();
                }
            }
        } else if (activeStep === 2) {
            if (child.validationDataInputs()) {
                let op = child.validationDataInputs();
                if (op) {
                    child.saveInputNodes();
                    setLoader(true);
                }
            }
        } else if (activeStep === 3) {
            if (child.validateprocessingNode()) {
                let op = child.validateprocessingNode();
                if (op) {
                    child.saveProcessingNodes();
                    setLoader(true);
                }
            }
        } else if (activeStep === 4) {
            if (child.validateretensionNode()) {
                let op = child.validateretensionNode();
                if (op) {
                    child.saveRetentionNodes();
                    setLoader(true);
                }
            }
            // setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const gotospecificForm = (index) => {
        setActiveStep(index);
    };
    const handleDrawer = () => {
        setOpen(!open);
    };

    const handleOnSelect = (index) => {
        setActiveStep(index);
    }
    

    const getStepContent = step => {
        switch (step) {
            case 0:
                return (
                    <ProductionInfoSection
                        handleNext={handlesteps}
                        onRef={ref => (child = ref)}
                    />
                );
            case 1:
                return (
                    <DataCentersSection
                        handleNext={handlesteps}
                        onRef={ref => (child = ref)}
                    />
                );
            case 2:
                return (
                    <InputNodeSection
                        handleNext={handlesteps}
                        onRef={ref => (child = ref)}
                    />
                );
            case 3:
                return (
                    <ProcessingNodeInfo
                        handleNext={handlesteps}
                        onRef={ref => (child = ref)}
                    />
                );
            case 4:
                return (
                    <RetentionNodeSection
                        handleNext={handlesteps}
                        onRef={ref => (child = ref)}
                    />
                );
            case 5:
                return <ReviewSection gotospecificForm={gotospecificForm} />;
        }
    };

    const navBtnsMemo = useMemo(() => {

        // navList.add(activeStep.toString());
        // console.log(navList);
        return (<List className={classes.listContent} style={{ paddingTop: "0" }}>
            {stepperTitles.map((val, index) => (
                <ListItem button key={val} disabled={ !navBtn.has(index)} onClick={() => handleOnSelect(index)} style={{ backgroundColor: activeStep === index ? "#F8F7FA" : "" }}>
                    <ListItemText className={activeStep === index ? "bold-body-text" : "body-text"} primary={val} />
                </ListItem>
            ))}
        </List>)

    }, [activeStep]);

    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.drawerContainer}>
                    {open ? <div className="wrapper d-flex justify-content-space-between" >
                        <div className="left-arrow" onClick={handleDrawer}>
                            <ChevronLeftIcon />
                        </div>
                    </div> : <div className="wrapper">
                        <div className="right-arrow" onClick={handleDrawer}>
                            <ChevronRightIcon />
                        </div></div>
                    }
                    {navBtnsMemo}
                </div>
            </Drawer>
            <main className={classes.content} >
                <Toolbar style={{ display: "flex", justifyContent: "space-between", marginTop: "8px", paddingRight: "0px" }}>
                    <Typography variant="h1" style={{ marginBottom: "10px" }} noWrap className={classes.tableheading} >
                        {stepperTitles[activeStep]}
                    </Typography>
                </Toolbar>
                <div>
                    {getStepContent(activeStep)}
                </div>
            </main>
            <div
                style={{
                    position: "fixed",
                    bottom: "0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    borderTop: "2px solid whitesmoke",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    zIndex: 1500
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <HomeSection />
                    </div>
                    <div className="vertical-line" />
                    <div className="GR-h2">{generalInfo && generalInfo.productName}</div>

                    {/* <div>
                  <SaveProgressSection />
                </div> */}

                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ marginRight: "30px" }}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className="secondary-button"
                        >
                            Back
                        </Button>
                    </div>
                    <div>
                        {activeStep === stepperTitles.length - 1 ? (
                            <div>
                                <SVGGeneration />
                            </div>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className="primary-button"
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </div>
                {loader ? <Dialog
                    className={classes.dialog}
                    open={true}
                    BackdropProps={{
                        classes: {
                            root: classes.dial_root,
                        },
                    }}
                    PaperProps={{
                        classes: {
                            root: classes.dial_paper,
                        },
                    }}
                >
                    <DialogTitle id="alert-dialog-title" style={{ color: "#049FD9", fontSize: "16px" }}>Saving Data....</DialogTitle>
                    <CircularProgress
                        size={40}
                        thickness={5.6}
                        style={{ color: "#049FD9" }}
                    />
                </Dialog> : null}
            </div>

        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            toggleIsUpdated
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo.data,
        userOrgData: state.userInfo.userOrgInfo,
        isUpdated: state.metaData.isUpdated,
        generalInfo: state.dataEntry.generalInfo,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(HorizontalStepper);