/* eslint-disable no-useless-escape */
// @flow
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./index.scss";
import Checkbox from "@material-ui/core/Checkbox";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NoCallout from "../svgIcons/nocallouticon";
import YesCallout from "../svgIcons/yescalloutIcon";
import Error from "../svgIcons/error";
import Warning from "../svgIcons/warning";
import { addProductInfo } from "../../actions/productInfoAction";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toggleIsUpdated } from "../../actions/metaDataAction";
class ProductionInfoSection extends Component<any, any> {
  state = {
    openGoBack: false,
    registration_datatype: "Registered Data",
    registration_dataAssets: "",
    generated_datatype: "Generated Data",
    generated_dataAssets: "",
    host_usage_datatype: "Host & Usage Data",
    host_usage_dataAssets: "",
    issubprocessorChecked: false,
    iscalloutChecked: false,
    calloutCopy: "",
    calloutDataTypes: [],
    prodInfoNodeCopyError: false,
    registration_datatype_error: false,
    generated_datatype_error: false,
    host_usage_datatype_error: false,
    dataTypeError: false,
  };
  constructor() {
    super();
    this.handlecalloutChecked = this.handlecalloutChecked.bind(this); // set this, because you need get methods from CheckBox
    this.handlesubprocessorChecked = this.handlesubprocessorChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);

    // window.USER_INFO = {
    //   email: "karthik.n@hebbalelabs.com"
    // }
    // window.MAP_DATA = {
    //   datamapId: "karthiknhebbalelabscisco123",
    //   organisationId: "1"
    // }
  }

  componentDidMount() {
    this.props.onRef(this);
      const { data } = this.props;

      if (data && data.dataTypes) {
        data.dataTypes.forEach((dt, i) => {
          if (i === 0) {
            this.setState({
              registration_datatype: dt.dataType,
              registration_dataAssets: dt.dataAssets,
            });
          }
          else if (i === 1) {
            this.setState({
              generated_datatype: dt.dataType,
              generated_dataAssets: dt.dataAssets,
            });
          }
          else if (i == 2) {
            this.setState({
              host_usage_datatype: dt.dataType,
              host_usage_dataAssets: dt.dataAssets,
            });
          }
        })
        this.setState({
          calloutCopy: data.calloutCopy ? data.calloutCopy : "",
          iscalloutChecked: data.callout ? data.callout : false,
          issubprocessorChecked: data.contracts && data.contracts.customerToSubprocessor
              ? data.contracts.customerToSubprocessor: false,
          calloutDataTypes: data.calloutDataTypes ? data.calloutDataTypes : [],
        });
      }
     

  }


  // if(data && data.dataTypes) {
  // const { registrationData, generatedData, hostUsageData } = data.dataTypes;
  // if (registrationData) {
  //   this.setState({
  //     registration_datatype: registrationData.datatype,
  //     registration_dataAssets: registrationData.dataAssets,
  //   });
  // }
  // if (generatedData) {
  //   this.setState({
  //     generated_datatype: generatedData.datatype,
  //     generated_dataAssets: generatedData.dataAssets,
  //   });
  // }
  // if (hostUsageData) {
  //   this.setState({
  //     host_usage_datatype: hostUsageData.datatype,
  //     host_usage_dataAssets: hostUsageData.dataAssets,
  //   });
  // }


saveProductionInfo() {

  if(this.props.isEdit){
    this.props.toggleIsUpdated(true);
  }
  else{
    let saveData = {
      "dataTypes" :[
        {
          dataType: this.state.registration_datatype,
          dataAssets: this.state.registration_dataAssets,
          dataName: "registrationData"
        },
        {
          dataType: this.state.generated_datatype,
          dataAssets: this.state.generated_dataAssets,
          dataName: "generatedData"
        },
        {
          dataType: this.state.host_usage_datatype,
          dataAssets: this.state.host_usage_dataAssets,
          dataName: "hostUsageData"
        },
      ],
      "contracts": {
        customerToSubprocessor: this.state.issubprocessorChecked
      },
      "callout": this.state.iscalloutChecked,
      "calloutCopy": this.state.calloutCopy,
      "calloutDataTypes": this.state.calloutDataTypes,
    };
    console.log(saveData);
    this.props.addProductInfo(saveData);
  }
}

validationProductinfo() {
  // if (!this.state.registration_datatype) {
  //   this.setState({
  //     openGoBack: true,
  //   });
  //   return false;
  // }
  // if (!this.state.generated_datatype) {
  //   this.setState({
  //     openGoBack: true,
  //   });
  //   return false;
  // }
  // if (!this.state.host_usage_datatype) {
  //   this.setState({
  //     openGoBack: true,
  //   });
  //   return false;
  // }
  // if (this.state.iscalloutChecked) {
  //   if (!this.state.calloutCopy) {
  //     this.setState({
  //       openGoBack: true,
  //     });
  //     return false;
  //   }
  //   if (this.state.calloutDataTypes.length === 0) {
  //     this.setState({
  //       openGoBack: true,
  //     });
  //     return false;
  //   }
  // }
  return true;
}

handleChange(value) {
  let checkList = [...this.state.calloutDataTypes];
  const index = checkList.indexOf(value);
  if (index > -1) {
    checkList.splice(index, 1);
  } else {
    checkList.push(value);
  }
  if (checkList.length > 0) {
    this.setState({
      dataTypes: checkList,
      dataTypeError: false,
    });
  } else {
    this.setState({
      dataTypes: checkList,
      dataTypeError: true,
    });
  }
  this.setState({ calloutDataTypes: checkList });
}

handlecalloutChecked() {
  this.setState({ iscalloutChecked: !this.state.iscalloutChecked });
}

handlesubprocessorChecked() {
  this.setState({ issubprocessorChecked: !this.state.issubprocessorChecked });
}

handleChangeProdInfoNodeCopy = (event) => {
  this.setState({
    calloutCopy: event.target.value,
    prodInfoNodeCopyError: false,
  });
};
handleBlurProdInfoNodeCopy = (event) => {
  if (!event.target.value) {
    this.setState({
      prodInfoNodeCopyError: true,
    });
  } else {
    this.setState({
      prodInfoNodeCopyError: false,
    });
  }
};
render() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft : "8%",
        paddingBottom: "5%",
        width: "100%",
        overflow: "auto",
        height: "550px",
      }}
    >
      <Dialog
        open={this.state.openGoBack}
        onClose={() => {
          this.setState({
            openGoBack: false,
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please fill out the required fields. <span className="color-red">*</span>
        </DialogTitle>
        <DialogActions>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="primary-button"
              onClick={() => {
                this.setState({
                  openGoBack: false,
                });
              }}
              color="primary"
            >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <div
        style={{
          width: "40%",
          marginLeft: "30px",
          paddingBottom: "2%",
          paddingTop: "2%",
        }}
        className="input-label"
      >
        Data Types
      </div>
      <form
        style={{ display: "flex", flexDirection: "column", width: "40%" }}
      >
        <div className="form-container">
          <div
            className="small-square-box"
            style={{ backgroundColor: "#4EB3DF" }}
          >
            {" "}
          </div>
          <div className="data-box">
            <div className="padding-Bottom-10 input-label">
              Data Type Name <span className="color-red">*</span>
            </div>
            <div style={{ marginBottom: "25px" }}>
              <div className="border-bottom-1 input-wrapper width-550">
                <input
                  id="productInfo"
                  value={
                    this.state.registration_datatype
                      ? this.state.registration_datatype
                      : ""
                  }
                  placeholder="e.g., Webex Messenger"
                  className="input-text-active width-550"
                  onChange={() => {
                    this.setState({
                      registration_datatype: event.target.value,
                      registration_datatype_error: false,
                    });
                  }}
                  onBlur={() => {
                    if (!event.target.value) {
                      this.setState({
                        registration_datatype_error: true,
                      });
                    } else {
                      this.setState({
                        registration_datatype_error: false,
                      });
                    }
                  }}
                />
                {this.state.registration_datatype_error ? <Error /> : ""}
              </div>
              {this.state.registration_datatype_error ? (
                <div style={{ color: "#CD0000", width: "428px" }}>
                  Please enter the text that should appear in the node.
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="padding-Bottom-10 input-label">Data Assests</div>
            <div className="border-bottom-1 input-wrapper width-550">
              <input
                id="productInfo"
                placeholder="e.g., Name,address"
                value={
                  this.state.registration_dataAssets
                    ? this.state.registration_dataAssets
                    : ""
                }
                className="input-text-active width-550"
                onChange={() => {
                  this.setState({
                    registration_dataAssets: event.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-container">
          <div
            className="small-square-box"
            style={{ backgroundColor: "#FFC435" }}
          >
            {" "}
          </div>
          <div className="data-box">
            <div className="padding-Bottom-10 input-label">
              Data Type Name <span className="color-red">*</span>
            </div>
            <div style={{ marginBottom: "25px" }}>
              <div className="border-bottom-1 input-wrapper width-550">
                <input
                  id="productInfo"
                  value={
                    this.state.generated_datatype
                      ? this.state.generated_datatype
                      : ""
                  }
                  className="input-text-active width-550"
                  onChange={() => {
                    this.setState({
                      generated_datatype: event.target.value,
                      generated_datatype_error: false,
                    });
                  }}
                  onBlur={() => {
                    if (!event.target.value) {
                      this.setState({
                        generated_datatype_error: true,
                      });
                    } else {
                      this.setState({
                        generated_datatype_error: false,
                      });
                    }
                  }}
                />
                {this.state.generated_datatype_error ? <Error /> : ""}
              </div>
              {this.state.generated_datatype_error ? (
                <div style={{ color: "#CD0000", width: "428px" }}>
                  Please enter the text that should appear in the node.
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="padding-Bottom-10 input-label">Data Assests</div>
            <div className="border-bottom-1 input-wrapper width-550">
              <input
                id="productInfo"
                placeholder="e.g., Email"
                value={
                  this.state.generated_dataAssets
                    ? this.state.generated_dataAssets
                    : ""
                }
                className="input-text-active width-550"
                onChange={() => {
                  this.setState({
                    generated_dataAssets: event.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-container">
          <div
            className="small-square-box"
            style={{ backgroundColor: "#E03C2D" }}
          >
            {" "}
          </div>
          <div className="data-box">
            <div className="padding-Bottom-10 input-label">
              Data Type Name <span className="color-red">*</span>
            </div>
            <div style={{ marginBottom: "25px" }}>
              <div className="border-bottom-1 input-wrapper width-550">
                <input
                  id="productInfo"
                  value={
                    this.state.host_usage_datatype
                      ? this.state.host_usage_datatype
                      : ""
                  }
                  className="input-text-active"
                  onChange={() => {
                    this.setState({
                      host_usage_datatype: event.target.value,
                      host_usage_datatype_error: false,
                    });
                  }}
                  onBlur={() => {
                    if (!event.target.value) {
                      this.setState({
                        host_usage_datatype_error: true,
                      });
                    } else {
                      this.setState({
                        host_usage_datatype_error: false,
                      });
                    }
                  }}
                />
                {this.state.host_usage_datatype_error ? <Error /> : ""}
              </div>
              {this.state.host_usage_datatype_error ? (
                <div style={{ color: "#CD0000", width: "428px" }}>
                  Please enter the text that should appear in the node.
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="padding-Bottom-10 input-label">Data Assests</div>
            <div className="border-bottom-1 input-wrapper width-550">
              <input
                id="productInfo"
                placeholder="e.g., IP Address,Host Name"
                value={
                  this.state.host_usage_dataAssets
                    ? this.state.host_usage_dataAssets
                    : ""
                }
                className="input-text-active width-550"
                onChange={() => {
                  this.setState({
                    host_usage_dataAssets: event.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </form>
      <div style={{ width: "40%", paddingBottom: "50px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "15px",
          }}
        >
          <label className="switch">
            <input
              type="checkbox"
              checked={this.state.issubprocessorChecked}
              onChange={this.handlesubprocessorChecked}
            />
            <span className="slider round" />
          </label>
          <div>
            <div
              style={{
                paddingBottom: "25px",
                fontSize: "14px",
                fontFamily: "CiscoSansTTRegular",
                color: "#39393B",
              }}
            >
              This product has a contract with a subprocessor.
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                {" "}
                {this.state.issubprocessorChecked === false ? (
                  <svg
                    width="106px"
                    height="106px"
                    viewBox="0 0 106 106"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>no subprocessor icon</title>
                    <desc>Created with Sketch.</desc>
                    <g
                      id="no-subprocessor-icon"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g id="Group">
                        <rect
                          id="Rectangle"
                          fill="#C6C7CA"
                          x="0"
                          y="0"
                          width="106"
                          height="106"
                        />
                        <rect
                          id="Rectangle"
                          fill="#E8EBF1"
                          x="9"
                          y="11"
                          width="88"
                          height="36"
                          rx="6"
                        />
                        <rect
                          id="Rectangle"
                          fill="#E8EBF1"
                          x="9"
                          y="60"
                          width="88"
                          height="33"
                          rx="6"
                        />
                      </g>
                    </g>
                  </svg>
                ) : (
                  <svg
                    width="106px"
                    height="106px"
                    viewBox="0 0 106 106"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>yes subprocessor icon</title>
                    <desc>Created with Sketch.</desc>
                    <g
                      id="yes-subprocessor-icon"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g id="Group-2">
                        <rect
                          id="Rectangle"
                          fill="#E8EBF1"
                          x="0"
                          y="0"
                          width="106"
                          height="106"
                        />
                        <path
                          d="M106,106 L0,106 L0,0 L106,0 L106,106 Z M87,11 L19,11 L19,47 L22.5,47 C26.0898509,47 29,49.9101491 29,53.5 C29,57.0898509 26.0898509,60 22.5,60 L19,60 L19,93 L87,93 L87,60 L51.5,60 C47.9101491,60 45,57.0898509 45,53.5 C45,49.9101491 47.9101491,47 51.5,47 L87,47 L87,11 Z"
                          id="Combined-Shape"
                          fill="#C6C7CA"
                        />
                        <rect
                          id="Rectangle"
                          fill="#E8EBF1"
                          x="9"
                          y="11"
                          width="88"
                          height="36"
                          rx="6"
                        />
                        <rect
                          id="Rectangle"
                          fill="#E8EBF1"
                          x="9"
                          y="60"
                          width="88"
                          height="33"
                          rx="6"
                        />
                      </g>
                    </g>
                  </svg>
                )}
              </div>
              <div style={{ paddingLeft: "25px" }}>
                {this.state.issubprocessorChecked === false ? (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "CiscoSansTTBold",
                      color: "#797979",
                    }}
                  >
                    No Contract with a subprocessor.
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "CiscoSansTTBold",
                      color: "#797979",
                    }}
                  >
                    Contract with a subprocessor.
                  </div>
                )}
                {this.state.issubprocessorChecked === false ? (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "CiscoSansTTRegular",
                      color: "#797979",
                    }}
                  >
                    The Cisco and Subprocessor sections will be completely
                    separate from each other.
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "CiscoSansTTRegular",
                      color: "#797979",
                    }}
                  >
                    The map will create a connection between Cisco and
                    Subprocessor sections.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "40%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "15px",
          }}
        >
          <label className="switch">
            <input
              type="checkbox"
              checked={this.state.iscalloutChecked}
              onChange={this.handlecalloutChecked}
            />
            <span className="slider round" />
          </label>
          <div>
            <div
              style={{
                paddingBottom: "25px",
                fontSize: "14px",
                fontFamily: "CiscoSansTTRegular",
                color: "#39393B",
              }}
            >
              Add an additional callout on the map.
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <div>
                {" "}
                {this.state.iscalloutChecked === false ? (
                  <NoCallout />
                ) : (
                  <YesCallout />
                )}
              </div>
              <div style={{ paddingLeft: "25px" }}>
                {this.state.iscalloutChecked === false ? (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "CiscoSansTTBold",
                      color: "#797979",
                    }}
                  >
                    No Additional Callout
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "CiscoSansTTBold",
                      color: "#797979",
                    }}
                  >
                    Additional Callout
                  </div>
                )}
                <div
                  style={{
                    fontSize: "12px",
                    fontFamily: "CiscoSansTTRegular",
                    color: "#797979",
                  }}
                >
                  The Cisco and Subprocessor sections will be completely
                  separate from each other.
                </div>
              </div>
            </div>
            {this.state.iscalloutChecked === true ? (
              <div>
                <div
                  className="input-label"
                  style={{
                    paddingBottom: "25px",
                  }}
                >
                  Affected Data Types(s) <span className="color-red">*</span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={this.state.calloutDataTypes.includes("0")}
                      onChange={() => this.handleChange("0")}
                      value="primary"
                      style={{ color: "black" }}
                    />
                    <div
                      style={{
                        float: "left",
                        height: "20px",
                        width: "20px",
                        margin: "15px",
                        backgroundColor: "#4EB3DF",
                      }}
                    />
                    <div className="input-active">
                      {this.state.registration_datatype}
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={this.state.calloutDataTypes.includes("1")}
                      onChange={() => this.handleChange("1")}
                      value=""
                      style={{ color: "black" }}
                    />
                    <div
                      style={{
                        float: "left",
                        height: "20px",
                        width: "20px",
                        margin: "15px",
                        backgroundColor: "#ffc435",
                      }}
                    />
                    <div className="input-active">
                      {this.state.generated_datatype}
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={this.state.calloutDataTypes.includes("2")}
                      onChange={() => this.handleChange("2")}
                      value=""
                      style={{ color: "black" }}
                    />
                    <div
                      style={{
                        float: "left",
                        height: "20px",
                        width: "20px",
                        margin: "15px",
                        backgroundColor: "#e03c2d",
                      }}
                    />
                    <div className="input-active">
                      {this.state.host_usage_datatype}
                    </div>
                  </div>
                  {this.state.dataTypeError ? (
                    <div className="Error-color">
                      Please select at least one data type.
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="input-label"
                    style={{ paddingBottom: "20px", paddingTop: "40px" }}
                  >
                    Node Copy <span className="color-red">*</span>
                  </div>
                  <div className="border-bottom-1 input-wrapper width-550">
                    <input
                      placeholder="e.g., Cisco will not access this unless shared by the customer."
                      className="input-text-active width-550"
                      value={this.state.calloutCopy}
                      onBlur={this.handleBlurProdInfoNodeCopy}
                      onChange={this.handleChangeProdInfoNodeCopy}
                    />
                    {this.state.prodInfoNodeCopyError ? <Error /> : ""}
                    {this.state.calloutCopy.length >= 60 ? <Warning /> : ""}
                  </div>
                  {this.state.prodInfoNodeCopyError ? (
                    <div style={{ color: "#CD0000", width: "428px" }}>
                      Please enter the text that should appear in the node.
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.calloutCopy.length >= 60 &&
                    this.state.calloutCopy.length < 100 ? (
                    <div style={{ color: "#916C00", width: "428px" }}>
                      Node text size will be smaller if character count
                      exceeds 60.
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.calloutCopy.length >= 100 ? (
                    <div style={{ color: "#916C00", width: "428px" }}>
                      Node will expand beyond its usual size if character
                      count exceeds 100.
                    </div>
                  ) : (
                    ""
                  )}
                  <p className="helper-text">
                    Character count: {this.state.calloutCopy.length}/60
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addProductInfo, toggleIsUpdated }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    data: state.productInfo.data,
    dataMapId: state.metaData.dataMapId,
    isEdit: state.metaData.isEditFlow
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionInfoSection);
