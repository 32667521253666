export const SAVE_FORM_PROGRESS = "SAVE_FORM_PROGRESS";
export const CLEAR_FORM_PROGRESS = "CLEAR_FORM_PROGRESS";
export const SAVE_ON_CLOSE_APP = "SAVE_ON_CLOSE_APP"


export function saveProgress(inputValues){
    return {
        type : SAVE_FORM_PROGRESS,
        data : inputValues
    }
}

export function clearProgess(){
    return {
        type : CLEAR_FORM_PROGRESS,
        data : {}
    }
}

export function saveProgresOnAppClose(data){
    return {
        type : SAVE_ON_CLOSE_APP,
        data : data
    }
}