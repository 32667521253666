import {
  ADD_CISCO_RETENTION_NODES,
  ADD_SUBPROCESSOR_RETENTION_NODES,
  SET_RETENTION_NODES_JSON_VALUES
} from "../actions/retentionNodesAction";

const initialState = {};

export function addRetentionNodes(state = initialState, action) {
  switch (action.type) {
    case SET_RETENTION_NODES_JSON_VALUES:
      return action.data;
    case ADD_CISCO_RETENTION_NODES:
      return {
        ...state,
        ciscoNodes: action.data
      };
    case ADD_SUBPROCESSOR_RETENTION_NODES:
      return {
        ...state,
        subprocessorNodes: action.data
      };
    default:
      return state;
  }
}
