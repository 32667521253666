import React, { Component } from "react";
import "./index.scss";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import ProcessingNodesSvg from "../processingNodesSvg";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addCustomerNodes,
  addCiscoNodes,
  addSubprocessorNodes,
} from "../../actions/processNodesAction";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Error from "../svgIcons/error";
import Warning from "../svgIcons/warning";
import Snackbar from "@material-ui/core/Snackbar";
import { editProcessingNodes } from "../../actions/reviewAction";
import { ProcessingNodeIndividualEntryPrefix } from "../../constant";
import { saveProcessingNodeData, toggleIsUpdated } from "../../actions/metaDataAction";

const SubcategoryofAccessoptions = [
  { value: "0", label: "Admin/Logistics" },
  { value: "1", label: "Provide Services" },
  { value: "2", label: "Service Related/Other" },
];
const controlleroptions = [
  { value: "0", label: "Cisco" },
  { value: "1", label: "Customer" },
  { value: "2", label: "Subprocessor" },
];

const controller0 = "0";
const controller1 = "1";
const controller2 = "2";

class ProcessingNodeInfo extends Component<any, any> {
  constructor(props) {
    super(props);
    this.handleAddNode = this.handleAddNode.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
  }
  state = {
    selectedSubcategoryofAccessoptions: {
      value: "0",
      label: "Admin/Logistics",
    },
    selectedControlledOption: {
      value: "0",
      label: "cisco",
    },
    controller: "0",
    subcategory: "0",
    dataTypes: [],
    accessors: [],
    nodeCopy: "",
    error: false,
    warning: false,
    openGoBack: false,
    customernodes: [],
    cisconodes: [],
    subprocessornodes: [],
    selectedController: "",
    selectedIndex: "",
    openDeleteNode: false,
    showAddNodeButton: true,
    showUpdateNodeButton: false,
    openAddNodeError: false,
    showSnackbar: false,
    openPartiallyFilled: false,
    dataTypeError: false,
    accessorsError: false,
    registrationDataType: "",
    generatedDataType: "",
    hostUsageDataType: "",
    changed: false,
    checkIndex: "0",
    checkController: "",
    showUpdateSnackbar: false,
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.editData) {
      const { editData } = this.props;
      if (editData.node && editData.node === controller0) {
        let data = this.state.cisconodes[editData.nodeIndex];
        this.setState({
          dataTypes: data.dataTypes,
          controller: data.controller,
          subcategory: data.subcategory,
          nodeCopy: data.nodeCopy,
          accessors: data.accessors,
          showUpdateNodeButton: true,
          selectedIndex: editData.nodeIndex,
          selectedController: editData.node,
        });
      } else if (editData.node && editData.node === controller1) {
        let data = this.state.customernodes[editData.nodeIndex];
        this.setState({
          dataTypes: data.dataTypes,
          controller: data.controller,
          subcategory: data.subcategory,
          nodeCopy: data.nodeCopy,
          accessors: data.accessors,
          showUpdateNodeButton: true,
          selectedIndex: editData.nodeIndex,
          selectedController: editData.node,
        });
      } else if (editData.node && editData.node === controller2) {
        let data = this.state.subprocessornodes[editData.nodeIndex];
        this.setState({
          dataTypes: data.dataTypes,
          controller: data.controller,
          subcategory: data.subcategory,
          nodeCopy: data.nodeCopy,
          accessors: data.accessors,
          showUpdateNodeButton: true,
          selectedIndex: editData.nodeIndex,
          selectedController: editData.node,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { customerNodes, ciscoNodes, subprocessorNodes } = nextProps;
      if (customerNodes) {
        this.setState({
          customernodes: customerNodes,
        });
      }
      if (ciscoNodes) {
        this.setState({
          cisconodes: ciscoNodes,
        });
      }
      if (subprocessorNodes) {
        this.setState({
          subprocessornodes: subprocessorNodes,
        });
      }
    }
  }

  UNSAFE_componentWillMount() {
    const {
      customerNodes,
      ciscoNodes,
      subprocessorNodes,
      dataTypes,
    } = this.props;

    if (dataTypes) {
      dataTypes.forEach((dt, i) => {

        if (i === 0) {
          this.setState({
            registrationDataType: dt.dataType,
          })
        }
        else if (i === 1) {
          this.setState({
            generatedDataType: dt.dataType,
          })
        }
        else if (i === 2) {
          this.setState({
            hostUsageDataType: dt.dataType,
          })
        }
      })
    }
    // if (dataTypes) {
    //   this.setState({
    //     registrationDataType:
    //       dataTypes.registrationData && dataTypes.registrationData.datatype,
    //     generatedDataType:
    //       dataTypes.generatedData && dataTypes.generatedData.datatype,
    //     hostUsageDataType:
    //       dataTypes.hostUsageData && dataTypes.hostUsageData.datatype,
    //   });
    // }
    if (customerNodes) {
      this.setState({
        customernodes: customerNodes,
      });
    }
    if (ciscoNodes) {
      this.setState({
        cisconodes: ciscoNodes,
      });
    }
    if (subprocessorNodes) {
      this.setState({
        subprocessornodes: subprocessorNodes,
      });
    }
  }

  componentWillUnmount() {
    this.props.editProcessingNodes({});
  }
  // checkNodecount(val) {
  //   if (val === "0") {
  //     const nodeTypeCount = {};
  //     let nodes = [...this.state.customernodes];
  //     for (let node of nodes) {
  //       nodeTypeCount[node.controller + "_" + node.subcategory] =
  //         (nodeTypeCount[node.controller + "_" + node.subcategory] || 0) + 1;
  //     }

  //     for(let [value, key] in nodeTypeCount){

  //     }
  //   } else if (val === "1") {
  //     let nodes = [...this.state.cisconodes];
  //   } else if (val === "2") {
  //     let nodes = [...this.state.subprocessornodes];
  //   }

  //   return true;
  // }
  getProcessingNodeDataType = (dataTypes) => {
    let sorted = dataTypes.sort();
    let sortedJoin = sorted.join("");
    return (
      <div>
        {sortedJoin === "012" ? (
          <div>
            <div className="Registration height-10 border-top-left-right-8" />
            <div className="Generated height-10" />
            <div className="Host-and-Usage height-10" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "01" ? (
          <div>
            <div className="Registration height-15 border-top-left-right-8" />
            <div className="Generated height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "02" ? (
          <div>
            <div className="Registration height-15 border-top-left-right-8" />
            <div className="Host-and-Usage height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "12" ? (
          <div>
            <div className="Generated height-15 border-top-left-right-8" />
            <div className="Host-and-Usage height-15" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "0" ? (
          <div>
            <div className="Registration height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "1" ? (
          <div>
            <div className="Generated height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
        {sortedJoin === "2" ? (
          <div>
            <div className="Host-and-Usage height-30 border-top-left-right-8" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  loadInitialData() {
    this.setState({
      controller: "0",
      subcategory: "0",
      dataTypes: [],
      accessors: [],
      nodeCopy: "",
      showUpdateNodeButton: false,
      changed: false,
    });
    this.forceUpdate();
  }

  handleOpenPopUpDelete = (controller, index) => {
    this.setState({
      selectedController: controller,
      selectedIndex: index,
      openDeleteNode: true,
    });
  };

  handleDeleteNode = () => {
    if (this.state.selectedController === controller0) {
      let nodes = [...this.state.cisconodes];
      nodes.splice(this.state.selectedIndex, 1);
      this.props.addCiscoNodes(nodes);
    } else if (this.state.selectedController === controller1) {
      let nodes = [...this.state.customernodes];
      nodes.splice(this.state.selectedIndex, 1);
      this.props.addCustomerNodes(nodes);
    } else if (this.state.selectedController === controller2) {
      let nodes = [...this.state.subprocessornodes];
      nodes.splice(this.state.selectedIndex, 1);
      this.props.addSubprocessorNodes(nodes);
    }
    this.loadInitialData();
  };

  isPartialFilled = () => {
    if (
      !this.state.nodeCopy &&
      this.state.dataTypes.length > 0 &&
      this.state.accessors.length > 0
    ) {
      return true;
    }
    if (
      !this.state.nodeCopy &&
      this.state.dataTypes.length > 0 &&
      !this.state.accessors.length > 0
    ) {
      return true;
    }
    if (
      this.state.nodeCopy &&
      this.state.dataTypes.length > 0 &&
      !this.state.accessors.length > 0
    ) {
      return true;
    }
    if (
      !this.state.nodeCopy &&
      !this.state.dataTypes.length > 0 &&
      this.state.accessors.length > 0
    ) {
      return true;
    }
    if (
      this.state.nodeCopy &&
      !this.state.dataTypes.length > 0 &&
      !this.state.accessors.length > 0
    ) {
      return true;
    }
    if (
      this.state.nodeCopy &&
      this.state.dataTypes.length > 0 &&
      this.state.accessors.length > 0
    ) {
      return true;
    }
    return false;
  };
  handleCheckClick = (controller, index) => {
    const isPartialFilled = this.isPartialFilled();
    if (isPartialFilled && this.state.changed) {
      this.setState({
        openPartiallyFilled: true,
        checkIndex: index,
        checkController: controller,
      });
    } else {
      this.handleOnClickNode(controller, index);
    }
  };

  handleOnClickNode = (controller, index) => {
    if (controller === controller0) {
      let data = this.state.cisconodes[index];
      this.setState({
        dataTypes: data.dataTypes,
        controller: data.controller,
        subcategory: data.subcategory,
        nodeCopy: data.nodeCopy,
        accessors: data.accessors,
      });
    } else if (controller === controller1) {
      let data = this.state.customernodes[index];
      this.setState({
        dataTypes: data.dataTypes,
        controller: data.controller,
        subcategory: data.subcategory,
        nodeCopy: data.nodeCopy,
        accessors: data.accessors,
      });
    } else if (controller === controller2) {
      let data = this.state.subprocessornodes[index];
      this.setState({
        dataTypes: data.dataTypes,
        controller: data.controller,
        subcategory: data.subcategory,
        nodeCopy: data.nodeCopy,
        accessors: data.accessors,
      });
    }

    this.setState({
      showUpdateNodeButton: true,
      selectedIndex: index,
      selectedController: controller,
      changed: false,
    });
  };

  handleUpdateNode = () => {
    if (this.state.controller !== this.state.selectedController) {
      if (this.state.selectedController === controller0) {
        let nodes = [...this.state.cisconodes];
        nodes.splice(this.state.selectedIndex, 1);
        this.props.addCiscoNodes(nodes);
      } else if (this.state.selectedController === controller1) {
        let nodes = [...this.state.customernodes];
        nodes.splice(this.state.selectedIndex, 1);
        this.props.addCustomerNodes(nodes);
      } else if (this.state.selectedController === controller2) {
        let nodes = [...this.state.subprocessornodes];
        nodes.splice(this.state.selectedIndex, 1);
        this.props.addSubprocessorNodes(nodes);
      }

      let node = {
        dataTypes: this.state.dataTypes,
        controller: this.state.controller,
        accessors: this.state.accessors,
        subcategory: this.state.subcategory,
        nodeCopy: this.state.nodeCopy,
      };
      if (this.state.controller === "1") {
        let nodes = [...this.state.customernodes];
        nodes.push(node);
        this.props.addCustomerNodes(nodes);
      } else if (this.state.controller === "0") {
        let nodes = [...this.state.cisconodes];
        nodes.push(node);
        this.props.addCiscoNodes(nodes);
      } else if (this.state.controller === "2") {
        let nodes = [...this.state.subprocessornodes];
        nodes.push(node);
        this.props.addSubprocessorNodes(nodes);
      }
    } else {
      if (this.state.selectedController === controller1) {
        let nodes = [...this.state.customernodes];
        let data = nodes[this.state.selectedIndex];
        data.dataTypes = this.state.dataTypes;
        data.controller = this.state.controller;
        data.subcategory = this.state.subcategory;
        data.nodeCopy = this.state.nodeCopy;
        data.accessors = this.state.accessors;
        nodes[this.state.selectedIndex] = data;
        this.props.addCustomerNodes(nodes);
      } else if (this.state.selectedController === controller0) {
        let nodes = [...this.state.cisconodes];
        let data = nodes[this.state.selectedIndex];
        data.dataTypes = this.state.dataTypes;
        data.controller = this.state.controller;
        data.subcategory = this.state.subcategory;
        data.nodeCopy = this.state.nodeCopy;
        data.accessors = this.state.accessors;
        nodes[this.state.selectedIndex] = data;
        this.props.addCiscoNodes(nodes);
      } else if (this.state.selectedController === controller2) {
        let nodes = [...this.state.subprocessornodes];
        let data = nodes[this.state.selectedIndex];
        data.dataTypes = this.state.dataTypes;
        data.controller = this.state.controller;
        data.subcategory = this.state.subcategory;
        (data.nodeCopy = this.state.nodeCopy),
          (data.accessors = this.state.accessors);
        nodes[this.state.selectedIndex] = data;
        this.props.addSubprocessorNodes(nodes);
      }
    }
    this.setState({
      showUpdateSnackbar: true,
    });
    this.loadInitialData();
  };

  ValidateNode() {
    if (!this.state.nodeCopy) {
      return false;
    } else if (!this.state.dataTypes.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  saveProcessingNodes() {

    if (this.props.isEdit) {
      this.props.toggleIsUpdated(true);
    }
    else {
      const {
        customerNodes,
        ciscoNodes,
        subprocessorNodes,
      } = this.props;

      let nodes = []
      if (customerNodes) {
        nodes.push(...customerNodes);
      }

      if (ciscoNodes) {
        nodes.push(...ciscoNodes);
      }

      if (subprocessorNodes) {
        nodes.push(...subprocessorNodes);
      }
      this.props.saveProcessingNodeData(nodes);
    }
  }

  handleAddNode() {
    // node validation
    let isNodevalid = this.ValidateNode;

    if (isNodevalid) {
      let node = {
        dataTypes: this.state.dataTypes,
        controller: this.state.controller,
        accessors: this.state.accessors,
        subcategory: this.state.subcategory,
        nodeCopy: this.state.nodeCopy,
        identifer: `${ProcessingNodeIndividualEntryPrefix}-${window.processNodeCount}`
      };
      if (this.state.controller === "1") {
        let nodes = [...this.state.customernodes];
        nodes.push(node);
        this.props.addCustomerNodes(nodes);
      } else if (this.state.controller === "0") {
        let nodes = [...this.state.cisconodes];
        nodes.push(node);
        this.props.addCiscoNodes(nodes);
      } else if (this.state.controller === "2") {
        let nodes = [...this.state.subprocessornodes];
        nodes.push(node);
        this.props.addSubprocessorNodes(nodes);
      }
      this.loadInitialData();
      this.setState({
        showSnackbar: true,
        changed: false,
      });
      window.processNodeCount++;
      console.log(window.processNodeCount)
    } else {
      console.log("input fields are empty");
    }
  }
  islocalandstateEqual = () => {
    if (this.state.selectedController === this.state.controller) {
      if (this.state.controller === "1") {
        let selectedNode = this.state.customernodes[this.state.selectedIndex];
        if (
          selectedNode.nodeCopy === this.state.nodeCopy &&
          selectedNode.accessors === this.state.accessors &&
          selectedNode.subcategory === this.state.subcategory &&
          selectedNode.dataTypes === this.state.dataTypes &&
          selectedNode.controller === this.state.controller
        ) {
          return true;
        }
      } else if (this.state.controller === "0") {
        let selectedNode = this.state.cisconodes[this.state.selectedIndex];
        if (
          selectedNode.nodeCopy === this.state.nodeCopy &&
          selectedNode.accessors === this.state.accessors &&
          selectedNode.subcategory === this.state.subcategory &&
          selectedNode.dataTypes === this.state.dataTypes &&
          selectedNode.controller === this.state.controller
        ) {
          return true;
        }
      } else if (this.state.controller === "2") {
        let selectedNode = this.state.subprocessornodes[
          this.state.selectedIndex
        ];
        if (
          selectedNode.nodeCopy === this.state.nodeCopy &&
          selectedNode.accessors === this.state.accessors &&
          selectedNode.subcategory === this.state.subcategory &&
          selectedNode.dataTypes === this.state.dataTypes &&
          selectedNode.controller === this.state.controller
        ) {
          return true;
        }
      } else {
        return false;
      }
    }

    return false;
  };

  validateprocessingNode() {
    // if (
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.subprocessornodes.length > 0) &&
    //   this.state.showUpdateNodeButton
    // ) {
    //   const islocalandstateEqual = this.islocalandstateEqual();
    //   if (islocalandstateEqual) {
    //     return true;
    //   } else {
    //     this.setState({
    //       openGoBack: true,
    //     });
    //     return false;
    //   }
    // }
    // if (
    //   !this.state.customernodes.length > 0 &&
    //   !this.state.cisconodes.length > 0 &&
    //   !this.state.subprocessornodes.length > 0
    // ) {
    //   this.setState({
    //     openAddNodeError: true,
    //   });
    //   return false;
    // }
    // if (
    //   !this.state.nodeCopy &&
    //   this.state.dataTypes.length > 0 &&
    //   this.state.accessors.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.customernodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.nodeCopy &&
    //   !this.state.dataTypes.length > 0 &&
    //   this.state.accessors.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.customernodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.nodeCopy &&
    //   this.state.dataTypes.length > 0 &&
    //   !this.state.accessors.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.customernodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.nodeCopy &&
    //   !this.state.dataTypes.length > 0 &&
    //   !this.state.accessors.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.customernodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   !this.state.nodeCopy &&
    //   this.state.dataTypes.length > 0 &&
    //   !this.state.accessors.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.customernodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   !this.state.nodeCopy &&
    //   !this.state.dataTypes.length > 0 &&
    //   this.state.accessors.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.customernodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.nodeCopy &&
    //   this.state.dataTypes.length > 0 &&
    //   this.state.accessors.length > 0 &&
    //   (this.state.cisconodes.length > 0 ||
    //     this.state.customernodes.length > 0 ||
    //     this.state.customernodes.length > 0)
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (!this.state.nodeCopy) {
    //   this.setState({
    //     openGoBack: true
    //   });
    //   return false;
    // }
    // if (!this.state.dataTypes.length === 0) {
    //   this.setState({
    //     openGoBack: true
    //   });
    //   return false;
    // }

    return true;
  }

  changeprod = (event) => {
    this.setState({
      nodeCopy: event.target.value,
      error: false,
      changed: true,
    });
  };

  message = (event) => {
    if (!event.target.value) {
      this.setState({
        error: true,
      });
    } else {
      this.setState({
        error: false,
      });
    }
  };

  handleChange(value) {
    let checkList = [...this.state.dataTypes];
    const index = checkList.indexOf(value);
    if (index > -1) {
      checkList.splice(index, 1);
    } else {
      checkList.push(value);
    }
    if (checkList.length > 0) {
      this.setState({
        dataTypes: checkList,
        dataTypeError: false,
        changed: true,
      });
    } else {
      this.setState({
        dataTypes: checkList,
        dataTypeError: true,
        changed: true,
      });
    }
  }

  handleClickOnIcon(value) {
    let checkList = [...this.state.accessors];
    const index = checkList.indexOf(value);
    if (index > -1) {
      checkList.splice(index, 1);
    } else {
      checkList.push(value);
    }
    if (checkList.length > 0) {
      this.setState({
        accessors: checkList,
        accessorsError: false,
        changed: true,
      });
    } else {
      this.setState({
        accessors: checkList,
        accessorsError: true,
        changed: true,
      });
    }
  }

  handleSubcategoryofAccessoptionsChange = (
    selectedSubcategoryofAccessoptions
  ) => {
    this.setState({
      subcategory: selectedSubcategoryofAccessoptions.value,
      changed: true,
    });
  };
  handlecontrolleroptionsChange = (selectedControlledOption) => {
    this.setState({
      controller: selectedControlledOption.value,
      changed: true,
    });
  };
  render() {
    const isEnabled =
      this.state.dataTypes.length > 0 &&
      this.state.nodeCopy &&
      this.state.accessors.length > 0 &&
      this.state.subcategory.length > 0 &&
      this.state.controller.length > 0;
    return (
      <div style={{
        display: "flex", justifyContent: "space-evenly", overflow: "auto",
        height: "500px", paddingBottom: "15%"
      }}>
        <Dialog
          open={this.state.openPartiallyFilled}
          onClose={() => {
            this.setState({
              openPartiallyFilled: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You have unsaved changes. Are you sure you want to proceed?
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Editing a node will cause you to lose your current progress.
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openPartiallyFilled: false,
                  });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => {
                  this.handleOnClickNode(
                    this.state.checkController,
                    this.state.checkIndex
                  );
                  this.setState({
                    openPartiallyFilled: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Proceed
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className="snackbar"
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({
              showSnackbar: false,
            });
          }}
          message="Node successfully added."
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className="snackbar"
          open={this.state.showUpdateSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({
              showUpdateSnackbar: false,
            });
          }}
          message="Node successfully updated."
        />
        <Dialog
          open={this.state.openAddNodeError}
          onClose={() => {
            this.setState({
              openAddNodeError: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Please create at least one processing node.
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className="primary-button"
                onClick={() => {
                  this.setState({
                    openAddNodeError: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openGoBack}
          onClose={() => {
            this.setState({
              openGoBack: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You have unsaved changes. Are you sure you want to proceed?
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openGoBack: false,
                    changed: false,
                  });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => {
                  this.props.handleNext();
                  this.setState({
                    openGoBack: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Proceed
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDeleteNode}
          onClose={() => {
            this.setState({
              openDeleteNode: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this node?
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openDeleteNode: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                className="danger-button"
                onClick={() => {
                  this.handleDeleteNode();
                  this.setState({
                    openDeleteNode: false,
                  });
                }}
                color="primary"
              >
                Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <div
          style={{
            width: "50%",
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "3%",
          }}
        >
          <div className="input-label" style={{ paddingBottom: "10px" }}>
            Data Type <span className="color-red">*</span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={this.state.dataTypes.includes("0")}
              onChange={() => this.handleChange("0")}
              // onBlur={() => this.handleBlur()}
              value="primary"
              style={{ color: "black" }}
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#4EB3DF",
              }}
            />
            <div className="input-active">
              {this.state.registrationDataType}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={this.state.dataTypes.includes("1")}
              onChange={() => this.handleChange("1")}
              // onBlur={() => this.handleBlur()}
              value="primary"
              style={{ color: "black" }}
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#ffc435",
              }}
            />
            <div className="input-active">{this.state.generatedDataType}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={this.state.dataTypes.includes("2")}
              onChange={() => this.handleChange("2")}
              // onBlur={() => this.handleBlur()}
              value="primary"
              style={{ color: "black" }}
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#e03c2d",
              }}
            />
            <div className="input-active">{this.state.hostUsageDataType}</div>
          </div>
          {this.state.dataTypeError ? (
            <div className="Error-color">
              Please select at least one data type.
            </div>
          ) : (
            ""
          )}
          <div
            className="input-label"
            style={{ paddingBottom: "20px", paddingTop: "40px" }}
          >
            Controller <span className="color-red">*</span>
          </div>
          {/* <MultiSelect /> */}
          <div className="pn-dropdown">
            <Select
              // value={selectedControlledOption}
              onChange={this.handlecontrolleroptionsChange}
              options={controlleroptions}
              value={controlleroptions[parseInt(this.state.controller)]}
            />
          </div>

          <div
            className="input-label"
            style={{ paddingBottom: "20px", paddingTop: "40px" }}
          >
            Who can access this data? <span className="color-red">*</span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "350px",
            }}
          >
            <Tooltip title="Users" placement="bottom" arrow>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 48 48"
                enableBackground="new 0 0 48 48"
                space="preserve"
                className={
                  this.state.accessors.includes("0")
                    ? "selected"
                    : "hover-users"
                }
                onClick={() => this.handleClickOnIcon("0")}
              >
                <g>
                  <polygon points="24,2 24,2 24.001,2 	" />
                  <path
                    d="M44.648,37.734c-0.066-0.398-0.406-0.889-0.755-1.091l-12.691-7.364c-0.349-0.201-0.752-0.675-0.895-1.052
		l-1.495-3.348c2.104-1.487,3.43-6.356,3.43-9.13v-5.5C32.242,5.714,28.536,2,24,2c-4.535,0-8.241,3.714-8.241,8.25v5.5
		c0,2.773,1.324,7.643,3.43,9.13l-1.495,3.348c-0.144,0.377-0.546,0.851-0.896,1.052L4.108,36.644
		c-0.35,0.202-0.689,0.692-0.756,1.091L2.009,46h43.982L44.648,37.734z"
                  />
                </g>
              </svg>
            </Tooltip>
            <Tooltip title="Customer" placement="bottom" arrow>
              <svg
                version="1.1"
                id="briefcase"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 48 48"
                enableBackground="new 0 0 48 48"
                space="preserve"
                className={
                  this.state.accessors.includes("1")
                    ? "selected"
                    : "hover-users"
                }
                onClick={() => this.handleClickOnIcon("1")}
              >
                <g>
                  <path d="M0,13v28c0,1.1,0.9,2,2,2h2V11H2C0.9,11,0,11.9,0,13z" />
                  <path d="M46,11h-2v32h2c1.1,0,2-0.9,2-2V13C48,11.9,47.1,11,46,11z" />
                  <path d="M35,5c0-1.1-0.9-2-2-2H15c-1.1,0-2,0.9-2,2v6H8v32h32V11h-5V5z M31,11H17V7h14V11z" />
                </g>
              </svg>
            </Tooltip>
            <Tooltip title="Cisco" placement="bottom" arrow>
              <svg
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 50 29"
                enableBackground="new 0 0 50 29"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                className={
                  this.state.accessors.includes("2")
                    ? "selected"
                    : "hover-users"
                }
                onClick={() => this.handleClickOnIcon("2")}
              >
                <title>cisco</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g id="cisco" fill="#7F7F89" fillRule="nonzero">
                    <polygon
                      stroke="none"
                      fill="#57585b"
                      id="Fill-1"
                      points="14.1616545 18.7548387 16.2181061 18.7548387 16.2181061 28.5435484 14.1616545 28.5435484"
                    />
                    <path
                      d="M34.3971384,21.633871 C34.3148803,21.5516129 33.5745577,21.1403226 32.4229448,21.1403226 C30.9422997,21.1403226 29.8729448,22.2096774 29.8729448,23.6903226 C29.8729448,25.0887097 30.8600416,26.2403226 32.4229448,26.2403226 C33.4922997,26.2403226 34.3148803,25.8290323 34.3971384,25.7467742 L34.3971384,28.2967742 C34.0681061,28.3790323 33.3277835,28.6258065 32.2584287,28.6258065 C29.6261706,28.6258065 27.3229448,26.733871 27.3229448,23.6903226 C27.3229448,20.8935484 29.3793964,18.7548387 32.2584287,18.7548387 C33.3277835,18.7548387 34.1503642,19.0016129 34.3971384,19.083871 L34.3971384,21.633871"
                      id="Fill-2"
                    />
                    <path
                      d="M11.1181061,21.633871 C11.035848,21.5516129 10.2955255,21.1403226 9.14391256,21.1403226 C7.6632674,21.1403226 6.59391256,22.2096774 6.59391256,23.6903226 C6.59391256,25.0887097 7.58100934,26.2403226 9.14391256,26.2403226 C10.2132674,26.2403226 11.035848,25.8290323 11.1181061,25.7467742 L11.1181061,28.2967742 C10.7890739,28.3790323 10.0487513,28.6258065 8.97939644,28.6258065 C6.34713837,28.6258065 4.04391256,26.733871 4.04391256,23.6903226 C4.04391256,20.8935484 6.10036418,18.7548387 8.97939644,18.7548387 C10.0487513,18.7548387 10.8713319,19.0016129 11.1181061,19.083871 L11.1181061,21.633871"
                      id="Fill-3"
                    />
                    <path
                      d="M40.9777835,21.1403226 C39.6616545,21.1403226 38.7568158,22.2919355 38.7568158,23.6080645 C38.7568158,25.0064516 39.7439126,26.0758065 40.9777835,26.0758065 C42.2939126,26.0758065 43.1987513,24.9241935 43.1987513,23.6080645 C43.2810093,22.2919355 42.2939126,21.1403226 40.9777835,21.1403226 Z M45.5842351,23.6080645 C45.5842351,26.3225806 43.6922997,28.5435484 41.0600416,28.5435484 C38.4277835,28.5435484 36.535848,26.3225806 36.535848,23.6080645 C36.535848,20.8935484 38.4277835,18.6725806 41.0600416,18.6725806 C43.6100416,18.7548387 45.5842351,20.8935484 45.5842351,23.6080645 Z"
                      id="Fill-4"
                    />
                    <path
                      d="M24.6906868,21.0580645 C24.6906868,21.0580645 23.70359,20.7290323 22.9632674,20.7290323 C22.0584287,20.7290323 21.5648803,21.0580645 21.5648803,21.4693548 C21.5648803,22.0451613 22.2229448,22.2096774 22.5519771,22.3741935 L23.1277835,22.6209677 C24.6084287,23.1145161 25.2664932,24.183871 25.2664932,25.3354839 C25.2664932,27.7209677 23.2922997,28.5435484 21.6471384,28.5435484 C20.4955255,28.5435484 19.3439126,28.2967742 19.2616545,28.2967742 L19.2616545,26.0758065 C19.4261706,26.1580645 20.4132674,26.4048387 21.4003642,26.4048387 C22.469719,26.4048387 23.0455255,26.0758065 23.0455255,25.5 C23.0455255,25.0064516 22.6342351,24.7596774 22.0584287,24.5951613 C21.8939126,24.5129032 21.7293964,24.5129032 21.5648803,24.4306452 C20.3310093,24.0193548 19.2616545,23.1967742 19.2616545,21.633871 C19.2616545,19.8241935 20.4955255,18.6725806 22.5519771,18.6725806 C23.6213319,18.6725806 24.6906868,18.9193548 24.6906868,19.0016129 L24.6906868,21.0580645"
                      id="Fill-5"
                    />
                    <path
                      d="M2.06971902,8.88387097 C2.06971902,8.30806452 1.65842869,7.81451613 1.08262224,7.81451613 C0.50681579,7.81451613 0.0955254677,8.30806452 0.0955254677,8.88387097 L0.0955254677,11.1048387 C0.0955254677,11.6806452 0.589073855,12.1741935 1.08262224,12.1741935 C1.65842869,12.1741935 2.06971902,11.6806452 2.06971902,11.1048387 L2.06971902,8.88387097"
                      id="Fill-6"
                    />
                    <path
                      d="M8.07455773,5.67580645 C8.07455773,5.1 7.58100934,4.52419355 7.08746095,4.52419355 C6.5116545,4.52419355 6.10036418,5.01774194 6.10036418,5.67580645 L6.10036418,11.1048387 C6.10036418,11.7629032 6.59391256,12.2564516 7.08746095,12.2564516 C7.6632674,12.2564516 8.07455773,11.7629032 8.07455773,11.1048387 L8.07455773,5.67580645"
                      id="Fill-7"
                    />
                    <path
                      d="M14.1616545,1.3983871 C14.1616545,0.740322581 13.6681061,0.164516129 13.1745577,0.164516129 C12.5987513,0.164516129 12.187461,0.740322581 12.187461,1.3983871 L12.187461,14.2306452 C12.187461,14.8887097 12.6810093,15.4645161 13.1745577,15.4645161 C13.7503642,15.4645161 14.1616545,14.8887097 14.1616545,14.2306452 L14.1616545,1.3983871"
                      id="Fill-8"
                    />
                    <path
                      d="M20.2487513,5.67580645 C20.2487513,5.1 19.7552029,4.52419355 19.2616545,4.52419355 C18.685848,4.52419355 18.2745577,5.01774194 18.2745577,5.67580645 L18.2745577,11.1048387 C18.2745577,11.7629032 18.7681061,12.2564516 19.2616545,12.2564516 C19.837461,12.2564516 20.2487513,11.7629032 20.2487513,11.1048387 L20.2487513,5.67580645"
                      id="Fill-9"
                    />
                    <path
                      d="M26.335848,8.88387097 C26.335848,8.30806452 25.8422997,7.81451613 25.3487513,7.81451613 C24.7729448,7.81451613 24.3616545,8.30806452 24.3616545,8.88387097 L24.3616545,11.1048387 C24.3616545,11.6806452 24.8552029,12.1741935 25.3487513,12.1741935 C25.9245577,12.1741935 26.335848,11.6806452 26.335848,11.1048387 L26.335848,8.88387097"
                      id="Fill-10"
                    />
                    <path
                      d="M32.4229448,5.67580645 C32.4229448,5.1 31.9293964,4.52419355 31.435848,4.52419355 C30.8600416,4.52419355 30.4487513,5.01774194 30.4487513,5.67580645 L30.4487513,11.1048387 C30.4487513,11.7629032 30.8600416,12.2564516 31.435848,12.2564516 C32.0116545,12.2564516 32.4229448,11.7629032 32.4229448,11.1048387 L32.4229448,5.67580645"
                      id="Fill-11"
                    />
                    <path
                      d="M38.4277835,1.3983871 C38.4277835,0.740322581 38.0164932,0.164516129 37.4406868,0.164516129 C36.8648803,0.164516129 36.45359,0.740322581 36.45359,1.3983871 L36.45359,14.2306452 C36.45359,14.8887097 36.9471384,15.4645161 37.4406868,15.4645161 C38.0164932,15.4645161 38.4277835,14.8887097 38.4277835,14.2306452 L38.4277835,1.3983871"
                      id="Fill-12"
                    />
                    <path
                      d="M43.5277835,5.67580645 C43.5277835,5.1 43.1164932,4.52419355 42.5406868,4.52419355 C41.9648803,4.52419355 41.55359,5.01774194 41.55359,5.67580645 L41.55359,11.1048387 C41.55359,11.7629032 41.9648803,12.2564516 42.5406868,12.2564516 C43.1164932,12.2564516 43.5277835,11.7629032 43.5277835,11.1048387 L43.5277835,5.67580645"
                      id="Fill-13"
                    />
                    <path
                      d="M49.6148803,8.88387097 C49.6148803,8.30806452 49.1213319,7.81451613 48.6277835,7.81451613 C48.0519771,7.81451613 47.6406868,8.30806452 47.6406868,8.88387097 L47.6406868,11.1048387 C47.6406868,11.6806452 48.0519771,12.1741935 48.6277835,12.1741935 C49.20359,12.1741935 49.6148803,11.6806452 49.6148803,11.1048387 L49.6148803,8.88387097"
                      id="Fill-14"
                    />
                  </g>
                </g>
              </svg>
            </Tooltip>
            <Tooltip title="Subprocessor" placement="bottom" arrow>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 48 48"
                enableBackground="new 0 0 48 48"
                space="preserve"
                className={
                  this.state.accessors.includes("3")
                    ? "selected"
                    : "hover-users"
                }
                onClick={() => this.handleClickOnIcon("3")}
              >
                <path
                  d="M48,29.5c0-4.95-3.43-9.09-8.041-10.198C39.979,19.036,40,18.771,40,18.5C40,12.701,35.299,8,29.5,8
	c-4.527,0-8.375,2.871-9.849,6.888C18.556,14.324,17.317,14,16,14c-4.418,0-8,3.582-8,8c0,0.34,0.028,0.672,0.069,1H8v0.025
	c-4.46,0.26-8,3.949-8,8.475s3.54,8.215,8,8.475V40h30v-0.025C43.565,39.712,48,35.13,48,29.5z"
                />
              </svg>
            </Tooltip>
            <Tooltip title="Partner" placement="bottom" arrow>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 51 51"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                className={
                  this.state.accessors.includes("4")
                    ? "selected"
                    : "hover-users"
                }
                onClick={() => this.handleClickOnIcon("4")}
              >
                <g
                  transform="translate(0, 7.750000)"
                  fill="#000000"
                  fillRule="nonzero"
                >
                  <path d="M50.3625,24.65 C50.3625,24.4375 50.15,24.225 50.04375,24.11875 L43.88125,20.6125 C43.66875,20.50625 43.5625,20.29375 43.45625,20.08125 L42.7125,18.38125 C43.66875,17.6375 44.4125,15.3 44.4125,14.025 L44.4125,11.36875 C44.4125,9.1375 42.60625,7.33125 40.375,7.33125 C38.14375,7.33125 36.44375,9.1375 36.44375,11.36875 L36.44375,14.025 C36.44375,15.40625 37.08125,17.74375 38.14375,18.4875 L37.4,20.08125 C37.29375,20.29375 37.1875,20.50625 36.975,20.6125 L35.9125,21.25 L42.5,25.075 C43.5625,25.7125 44.4125,26.88125 44.625,28.15625 L44.73125,28.6875 L51,28.6875 L50.3625,24.65 Z" />
                  <path d="M15.19375,21.25 L14.13125,20.6125 C13.91875,20.50625 13.8125,20.29375 13.70625,20.08125 L12.9625,18.38125 C13.91875,17.6375 14.6625,15.3 14.6625,14.025 L14.6625,11.36875 C14.6625,9.1375 12.85625,7.33125 10.625,7.33125 C8.39375,7.33125 6.69375,9.1375 6.69375,11.36875 L6.69375,14.025 C6.69375,15.40625 7.33125,17.74375 8.39375,18.4875 L7.65,20.08125 C7.54375,20.29375 7.4375,20.50625 7.225,20.6125 L1.0625,24.11875 C0.85,24.225 0.74375,24.4375 0.74375,24.65 L0.10625,28.6875 L6.375,28.6875 L6.48125,28.15625 C6.69375,26.88125 7.54375,25.7125 8.60625,25.075 L15.19375,21.25 Z" />
                  <path d="M41.4375,28.6875 C41.4375,28.36875 41.11875,28.05 40.90625,27.8375 L31.13125,22.1 C30.8125,21.99375 30.6,21.56875 30.3875,21.25 L29.21875,18.59375 C29.9625,18.0625 30.70625,16.7875 31.13125,15.40625 C31.55625,14.025 31.875,12.64375 31.875,11.58125 L31.875,7.33125 C31.875,3.825 29.00625,0.95625 25.5,0.95625 C21.99375,0.95625 19.125,3.825 19.125,7.33125 L19.125,11.58125 C19.125,13.70625 20.1875,17.53125 21.78125,18.59375 L20.6125,21.14375 C20.50625,21.4625 20.1875,21.78125 19.86875,21.99375 L10.09375,27.73125 C9.775,27.8375 9.5625,28.2625 9.5625,28.58125 L9.5625,28.58125 L8.5,34.95625 L42.5,34.95625 L41.4375,28.6875 L41.4375,28.6875 Z" />
                </g>
              </svg>
            </Tooltip>
          </div>
          {this.state.accessorsError ? (
            <div className="Error-color" style={{ paddingTop: "20px" }}>
              Please select at least one access party.
            </div>
          ) : (
            ""
          )}
          <div
            className="input-label"
            style={{ paddingBottom: "20px", paddingTop: "40px" }}
          >
            Subcategory of Access <span className="color-red">*</span>
          </div>
          <div className="pn-dropdown">
            <Select
              // value={selectedSubcategoryofAccessoptions}
              onChange={this.handleSubcategoryofAccessoptionsChange}
              options={SubcategoryofAccessoptions}
              value={SubcategoryofAccessoptions[parseInt(this.state.subcategory)]}
            />
          </div>

          <div
            className="input-label"
            style={{ paddingBottom: "20px", paddingTop: "40px" }}
          >
            Node Text <span className="color-red">*</span>
          </div>
          <div className="border-bottom-1 input-wrapper width-428">
            <input
              placeholder="e.g., Investigate security threats, troubleshooting"
              className="input-text-active width-428"
              value={this.state.nodeCopy}
              id="test"
              onBlur={this.message}
              onChange={this.changeprod}
            />
            {this.state.error ? <Error /> : ""}
            {this.state.nodeCopy.length >= 60 ? <Warning /> : ""}
          </div>
          {this.state.error ? (
            <div style={{ color: "#CD0000", width: "428px" }}>
              Please enter the text that should appear in the node.
            </div>
          ) : (
            ""
          )}
          {this.state.nodeCopy.length >= 60 &&
            this.state.nodeCopy.length < 100 ? (
            <div style={{ color: "#916C00", width: "428px" }}>
              Node text size will be smaller if character count exceeds 60.
            </div>
          ) : (
            ""
          )}
          {this.state.nodeCopy.length >= 100 ? (
            <div style={{ color: "#916C00", width: "428px" }}>
              Node will expand beyond its usual size if character count exceeds
              100.
            </div>
          ) : (
            ""
          )}
          <p className="helper-text">
            Character count: {this.state.nodeCopy.length}/60
          </p>
          <div
            style={{
              width: "428px",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
              paddingTop: "40px",
            }}
          >
            {this.state.showUpdateNodeButton ? (
              <div>
                <Button
                  variant="contained"
                  disabled={!isEnabled}
                  className="primary-button"
                  // onClick={() => {this.handleAddNode}}
                  onClick={() => this.handleUpdateNode()}
                >
                  Update Node
                </Button>{" "}
                <div
                  className="cursor-pointer"
                  style={{ textAlign: "center" }}
                  onClick={() => this.loadInitialData()}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <Button
                disabled={!isEnabled}
                variant="contained"
                className="primary-button"
                onClick={() => this.handleAddNode()}
              >
                Add Node
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            width: "30%",
            height: "calc(100vh - 160px)",
            minHeight: "calc(100vh - 160px)",
            overflowY: "auto",
            backgroundColor: "#E8EBF1",
            paddingTop: "5%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "70%", textAlign: "left" }}>
              {this.state.customernodes.length > 0 ? "Customer" : ""}
            </div>
            <div style={{ width: "70%", height: "max-content" }}>
              {this.state.customernodes.map((node, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div className="padding15-processingnode">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.handleCheckClick(controller1, index)
                          }
                        >
                          {this.getProcessingNodeDataType(node.dataTypes)}
                          <ProcessingNodesSvg accessors={node.accessors} />
                          <div
                            className="text-processingnode"
                            style={{
                              fontSize: "14px",
                              color: "#39393B",
                              fontFamily: "CiscoSansTTRegular",
                            }}
                          >
                            {node.nodeCopy}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        fontStyle: "italic",
                        textDecoration: "underline",
                        color: "black",
                      }}
                      onClick={() =>
                        this.handleOpenPopUpDelete(controller1, index)
                      }
                    >
                      <Error />
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "70%", textAlign: "left" }}>
              {this.state.cisconodes.length > 0 ? "Cisco" : ""}
            </div>
            <div style={{ width: "70%", height: "max-content" }}>
              {this.state.cisconodes.map((node, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div className="padding15-processingnode">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.handleCheckClick(controller0, index)
                          }
                        >
                          {this.getProcessingNodeDataType(node.dataTypes)}
                          <ProcessingNodesSvg accessors={node.accessors} />
                          <div
                            className="text-processingnode"
                            style={{
                              fontSize: "14px",
                              color: "#39393B",
                              fontFamily: "CiscoSansTTRegular",
                            }}
                          >
                            {node.nodeCopy}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        fontStyle: "italic",
                        textDecoration: "underline",
                        color: "black",
                      }}
                      onClick={() =>
                        this.handleOpenPopUpDelete(controller0, index)
                      }
                    >
                      <Error />
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "70%", textAlign: "left" }}>
              {this.state.subprocessornodes.length > 0 ? "Subprocessor" : ""}
            </div>
            <div style={{ width: "70%", height: "max-content" }}>
              {this.state.subprocessornodes.map((node, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div className="padding15-processingnode">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.handleCheckClick(controller2, index)
                          }
                        >
                          {this.getProcessingNodeDataType(node.dataTypes)}
                          <ProcessingNodesSvg accessors={node.accessors} />
                          <div
                            className="text-processingnode"
                            style={{
                              fontSize: "14px",
                              color: "#39393B",
                              fontFamily: "CiscoSansTTRegular",
                            }}
                          >
                            {node.nodeCopy}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        fontStyle: "italic",
                        textDecoration: "underline",
                        color: "black",
                      }}
                      onClick={() =>
                        this.handleOpenPopUpDelete(controller2, index)
                      }
                    >
                      <Error />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCustomerNodes,
      addCiscoNodes,
      addSubprocessorNodes,
      editProcessingNodes,
      saveProcessingNodeData,
      toggleIsUpdated
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    customerNodes: state.processingnodes.customerNodes,
    ciscoNodes: state.processingnodes.ciscoNodes,
    subprocessorNodes: state.processingnodes.subprocessorNodes,
    dataTypes: state.productInfo.data && state.productInfo.data.dataTypes,
    editData: state.editData.editPN,
    isEdit: state.metaData.isEditFlow
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessingNodeInfo);
