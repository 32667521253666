import React from "react";
import * as moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.scss";
import ContainedButtons from "../Button";
import { Link, withRouter } from "react-router-dom";
import { addGeneralInfo, createData, setInitialState } from "../../actions/dataEntryActions";
import Error from "../svgIcons/error";
import { editGeneralInfo } from "../../actions/reviewAction";
import { setProductInfo } from "../../actions/productInfoAction";
import { setDataCenterJsonValue } from "../../actions/dataCentersAction";
import { setInputNodesJsonValues } from "../../actions/inputNodesAction";
import { setProcessingNodesJsonValues } from "../../actions/processNodesAction";
import { setRetentionNodesJsonValues } from "../../actions/retentionNodesAction";
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();
class EditMap extends React.Component<any, any> {
    state = {
      productInfo: "",
      dataSheetURL: "",
      dataSheetVersion: "",
      versionDate: "",
      setdate: new Date(),
      productNameError: false,
      redirect: false
    };
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.handleOnChange = this.handleOnChange.bind(this);
    }

    // componentDidMount() {
    //   const { edit, data } = this.props
    //   if (edit && !R.isEmpty(data)) {

    //     this.setState({
    //       productInfo: data.productName ? data.productName : "",
    //       dataSheetVersion: data.dataSheetVersion ? data.dataSheetVersion : "",
    //       dataSheetURL: data.dataSheetURL ? data.dataSheetURL : "",
    //       setdate: data.date ? this.convertToDate(data.date) : ""
    //     });
    //   }
    // }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.data !== this.props.data) {
        //this.props.history && this.props.history.push("/input");

        console.log(nextProps.data);

        if (nextProps.data) {
          const {
            generalInfo,
            productInfo,
            dataCenters,
            inputNodes,
            processingNodes,
            retentionNodes,
          } = nextProps.data;
          if (generalInfo) {
            this.props.setInitialState(generalInfo);
          }
          if (productInfo) {
            this.props.setProductInfo(productInfo);
          }
          if (dataCenters) {
            this.props.setDataCenterJsonValue(dataCenters);
          }
          if (inputNodes) {
            this.props.setInputNodesJsonValues(inputNodes);
          }
          if (processingNodes) {
            this.props.setProcessingNodesJsonValues(processingNodes);
          }
          if (retentionNodes) {
            this.props.setRetentionNodesJsonValues(retentionNodes);
          }

          // if (inputNodes) {
          //   if (generalInfo && generalInfo.dataTypes) {
          //     this.props.setDataTypeValues(generalInfo.dataTypes);
          //   } else {
          //     this.props.setDataTypes(inputNodes);
          //   }
          //   this.props.setInputNodesJsonValues(inputNodes);
          // }
          // if (processingNodes) {
          //   this.props.setProcessingNodesJsonValues(processingNodes);
          // }
          // if (retentionNodes) {
          //   this.props.setRetentionNodesJsonValues(retentionNodes);
          // }
        }
      }
      if (nextProps.generalInfo !== this.props.generalInfo) {
        if (nextProps.generalInfo) {
          const data = nextProps.generalInfo;
          this.setState({
            productInfo: data.productName ? data.productName : "",
            dataSheetVersion: data.dataSheetVersion ? data.dataSheetVersion : "",
            dataSheetURL: data.dataSheetURL ? data.dataSheetURL : "",
            setdate: data.date ? this.convertToDate(data.date) : ""
          });
        }
      }
    }


    // componentWillUnmount() {
    //   this.props.editGeneralInfo(undefined)
    // }

    convertDateformat = (date) => {
      let dateformat = moment(date).format("MM-DD-YYYY")
      console.log(dateformat)
      return dateformat

    }

    convertToDate = date => {
      let d = new Date(date);
      let dd = String(d.getDate()).padStart(2, "0");
      let mm = String(d.getMonth() + 1).padStart(2, "0");
      let yyyy = d.getFullYear();
      let dateformat = yyyy + "-" + mm + "-" + dd
      return dateformat
    }

    handleDateChange = e => {
      this.setState({
        setdate: e.target.value,
      });
    };


    handleOnChange = event => {
      this.setState({
        [event.target.id]: event.target.value,
      });
      if (event.target.id === "productInfo") {
        this.setState({
          productNameError: false,
        });
      }
    };

    handleClick = () => {
      if (this.props.history) {
        this.props.history.push('input');
      }
    };

    handleOnSubmit = (e) => {
      e.preventDefault();
    };

    render() {
      const { productInfo } = this.state;
      const isEnabled = productInfo.length > 0;

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="fm"
            style={{
              margin: "50px 0px",
              color: "#9E9EA2",
              fontSize: "24px",
              fontWeight: "100",
              fontFamily: "CiscoSansTTLight",
            }}
          >
            Create a new data map based on a data sheet.
          </div>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <div style={{ marginBottom: "50px" }}>
              <div className="padding-Bottom-10 input-label">
                Product Name <span className="color-red">*</span>
              </div>
              <div className="border-bottom-1 input-wrapper width-428">
                <input
                  id="productInfo"
                  placeholder="e.g., Webex Messenger"
                  className="input-text-active width-428"
                  onChange={this.handleOnChange}
                  onBlur={() => {
                    if (!event.target.value) {
                      this.setState({
                        productNameError: true,
                      });
                    } else {
                      this.setState({
                        productNameError: false,
                      });
                    }
                  }}
                  value={this.state.productInfo}
                />
                {this.state.productNameError ? <Error /> : ""}
              </div>
              {this.state.productNameError ? (
                <div style={{ color: "#CD0000", width: "428px" }}>
                  Please enter the product name.
                </div>
              ) : (
                ""
              )}
            </div>
            <div style={{ marginBottom: "50px" }}>
              <div className="padding-Bottom-10 input-label">
                URL of Accompanying Data Sheet
              </div>
              <div className="border-bottom-1 input-wrapper width-428">
                <input
                  id="dataSheetURL"
                  placeholder="e.g., www.cisco.com/datasheets"
                  className="input-text-active width-428"
                  onChange={this.handleOnChange}
                  value={this.state.dataSheetURL}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
            >
              <div>
                <div className="padding-Bottom-10 input-label">
                  {" "}
                  Data Sheet Version
                </div>
                <div className="border-bottom-1 input-wrapper width-200">
                  <input
                    id="dataSheetVersion"
                    placeholder="e.g., 1.0"
                    className="input-text-active width-200"
                    onChange={this.handleOnChange}
                    value={this.state.dataSheetVersion}
                  />
                </div>
              </div>
              <div>
                <div className="padding-Bottom-10 input-label">Version Date</div>
                <input className="date-style" type="date" id="versionDate"
                  placeholder="mm-dd-yyyy"
                  value={this.state.setdate}
                  onChange={this.handleDateChange}
                ></input>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {
                <Link to='/' style={{ textDecoration: "none" }}>
                  <ContainedButtons
                    buttonTitle="Back to Dashboard"
                    buttonStatus={true}
                  />
                </Link>
              }
              <div
                onClick={() => this.handleClick()}
              >
                <ContainedButtons
                  buttonTitle="Edit Map"
                  buttonStatus={isEnabled}
                />
              </div>
            </div>
          </form>
        </div>
      );
    }
  }

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addGeneralInfo,
    editGeneralInfo,
    createData,
    setInitialState,
    setProductInfo,
    setDataCenterJsonValue,
    setInputNodesJsonValues,
    setProcessingNodesJsonValues,
    setRetentionNodesJsonValues
  }, dispatch);
};

const mapStateToProps = (state) => {
  console.log(state)
  return {
    edit: state.editData.editGI,
    data: state.dashboard.datamapData,
    generalInfo: state.dataEntry.generalInfo
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMap));
