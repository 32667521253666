export const SET_INPUT_NODES_JSON_VALUES = "SET_INPUT_NODES_JSON_VALUES";
export const ADD_INPUT_NODES = "ADD_INPUT_NODES";

export const InputNodeIndividualEntryPrefix = "inputNodeForm";

export function addInputNodeData(inputNodeData) {
  // let inputNodes = {};
  // for (let i = 0; i < inputNodeData.length; i++) {
  //   const identifier = `${InputNodeIndividualEntryPrefix}-${i + 1}`;
  //   inputNodes[identifier] = inputNodeData[i];
  // }
  return {
    type: ADD_INPUT_NODES,
    data: inputNodeData
  };
}

export function setInputNodesJsonValues(jsonData){
    return {
        type : SET_INPUT_NODES_JSON_VALUES,
        data: jsonData
    }
}