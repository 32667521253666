// @flow

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAs } from "file-saver";
import styled from "styled-components";
import { saveSVGFileById } from "../../actions/svgRegenerationAction";
import { updateReviewers } from "../../actions/apiActions";
import { Messages, MapStatus} from "../../constant";

import type { DataEntryFormOutput } from "../dataEntry/types";

export const SVGMainId = "datamapSvgRenderMain";
export const SVGAuxId = "datamapSvgRenderAuxiliary";

type OwnProps = {};
type StateProps = {
  productName: string,
  date: string,
  isCiscoToSubContract: boolean,
  formValues: DataEntryFormOutput,
  finalSvgCode: string,
  dataMapId: string,
  saveSVGFileById: Function,
  updateReviewers: Function,
  reviewers: Array,
};

type Props = OwnProps & StateProps;

type State = {
  finalSvgCode: string
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveSVGFileById,
    updateReviewers
  },
    dispatch
  );
};

function mapStateToProps(state: any) {
  let productName = "default";
  let date = "default";
  if (state.svgGeneration.formDataForSvg) {
    productName = state.svgGeneration.formDataForSvg.generalInfo.productName;
    date = state.svgGeneration.formDataForSvg.generalInfo.date;
  }
  return {
    productName,
    date,
    finalSvgCode: state.svgGeneration.finalSvgCode
      ? state.svgGeneration.finalSvgCode
      : "",
    dataMapId: state.metaData.dataMapId,
    reviewers: state.metaData.reviewers
  };
}

class GenerateSvgView extends React.Component<Props, State> {
  componentDidMount() {
    const SVGMain = document.createElement("div");
    SVGMain.setAttribute("id", SVGMainId);
    const SVGAux = document.createElement("div");
    SVGAux.setAttribute("id", SVGAuxId);
    const DataCenterMapRender = document.createElement("div");
    DataCenterMapRender.setAttribute("id", "dataCenterMap");
    DataCenterMapRender.setAttribute("style", "width:869px; height:520px");
    const RenderingComponent = document.getElementById("svgRender");
    if (RenderingComponent) {
      RenderingComponent.append(SVGMain);
      RenderingComponent.append(SVGAux);
      RenderingComponent.append(DataCenterMapRender);
    }
  }

  componentDidUpdate() {
    const { finalSvgCode, dataMapId, saveSVGFileById, reviewers, updateReviewers} = this.props;

    if (finalSvgCode) {
      const today = new Date().toLocaleDateString().replace(/\//g, "-");
      saveAs(finalSvgCode, `datamap_${today}.svg`);
      console.log(finalSvgCode);
      // var svgimg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      // svgimg.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', finalSvgCode);
      //console.log(svgimg);
      
      let arr = finalSvgCode.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file =  new File([u8arr], `datamap_${today}.svg`, { type: mime });
      console.log(file);
      saveSVGFileById(dataMapId, file);

      let reviewerList = [];
      reviewers && reviewers.forEach(val => {
        reviewerList.push(val.emailId);
      })
      const payload = {
        "mapStatus": MapStatus.REVIEW,
        "ownedBy": window.USER_INFO ? window.USER_INFO.email : "",
        "modifiedBy": window.USER_INFO ? window.USER_INFO.email : "",
        "message": Messages.REVIEW,
        "reviewers": reviewerList
        }

      updateReviewers(payload, dataMapId);
    }
  }

  render() {
    const { finalSvgCode } = this.props;
    return (
      <div>
        {/* {finalSvgCode ? (
          <div
            href={finalSvgCode}
            download={`dataMap_${productName}_${date}.svg`}
            Download
          >
          </div>
        ) : null} */}
        {process.env.REACT_APP_UNHIDE_SVG_STAGING ? (
          <React.Fragment>
            <StagingImage src={finalSvgCode} />
          </React.Fragment>
        ) : null}
        <RenderOffScreen id="svgRender" />
      </div>
    );
  }
}

export const GenerateSvg = connect(mapStateToProps, mapDispatchToProps)(GenerateSvgView);

// const DownloadButton = styled.a`
//   padding: 15px;
//   border: 1px solid lightgrey;
//   border-radius: 10px;
//   display: block;
//   width: 200px;
//   text-align: center;
//   text-decoration: none;
// `;

const StagingImage = styled.img`
  width: 100%;
`;

const RenderOffScreen = styled.div`
  position: fixed;
  margin-left: -900000;
  z-index: -200000;
`;
