// @flow

import * as R from "ramda";

import type { DataType } from "../../types";
import { dataTypesList } from "./config";

export const getDefaultDataTypes: () => Array<DataType> = () => {
  return dataTypesList;
};

export const getDefaultDataTypeColorMap = R.reduce(
  (acc, { id, color }) => ({ ...acc, [id]: color }),
  {}
)(getDefaultDataTypes());

export const getDefaultDataTypeDetailsMap = R.reduce(
  (acc, { id, ...rest }) => ({ ...acc, [id]: rest }),
  {}
)(getDefaultDataTypes());
