/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import * as R from "ramda";
import * as moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card, CardContent, Dialog, DialogTitle, DialogActions, Avatar, Chip } from '@material-ui/core';
import { Link } from "react-router-dom";
import { getDataMapsId, getReviewDetails, updateCurrentEditor } from "../../actions/apiActions";
import { setDataMapId } from "../../actions/metaDataAction";
import Alert from '@material-ui/lab/Alert';
import {
    Tooltip, Button, Paper, TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Messages, StatusChipColors} from "../../constant";
//StatusChipColors[mapStatus.toUpperCase()] ? StatusChipColors[mapStatus.toUpperCase()] :
import SearchField from "./search";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import "./index.scss";

const columns = [
    { id: 'product_name', label: 'Product Name', minWidth: "30%", icon: ""},
    { id: 'editor', label: 'Editor', minWidth: "20%", icon: <img src='./icons/downarrow.svg' width="12px" height="8px" /> },
    { id: 'status', label: 'Status', minWidth: "20%", align: 'left', icon: <img src='./icons/downarrow.svg' width="12px" height="8px" /> },
    {
        id: 'lastModified',
        label: 'Last Modified',
        minWidth: "20%",
        align: 'left',
        icon: <img src='./icons/downward.svg' width="12px" height="10px" />
    },
    { id: 'actions', label: '', minWidth: "10%", align: 'left', icon: "" }
];

const useStyles = makeStyles({
    error: {
        width: '100%',
    },
    card: {
        width: "274px",
        '& .MuiCardContent-root': {
            padding: "0px",
        },
        '& .MuiButton-root': {
            color: "#4C42BF",
            height: "30px",
            width: "max-content",
            fontSize: "10px",
            fontWeight: 500,
            fontFamily: "SFProTextRegular !important",
            lineHeight: "15.24px"
        },
        '& .MuiButton-outlined': {
            border: "1px solid #4C42BF",
        },
        '& .MuiButton-outlinedSizeSmall': {
            padding: "0px 2px"
        }
    },
    root: {
        width: '100%',
        '& .MuiAvatar-root':{
            color: "#000000",
            fontSize: "8px",
            fontWeight: "400",
            fontFamily: "SFProTextRegular !important",
            lineHeight: "7.62px",
        }
    },
    table: {
        width: '100%',
        '& .MuiTableCell-head': {
            fontFamily: "SFProTextRegular !important",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "15.24px",
            color: "#24242D"
        },
        '& .MuiTableCell-root': {
            border: "none",

        },
        '& .MuiTableCell-body': {
            fontFamily: "SFProTextRegular !important",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "15.24px",
            color: "#24242D"
        },
        '& .MuiTableRow-hover': {
            cursor: "pointer",
        }

    },
    reveiw: {
        width: '25%'
    },
    container: {
        maxHeight: "450px",
        overflow: 'auto'
    },
    btn: {
        backgroundColor: "#E8EBF1",
        width: "max-content",
        height: "22px",
        fontSize: "8px",
        fontFamily: "SFProTextRegular !important",
        color: "#24242D",
        marginTop: "20px",
        textTransform: "capitalize",
        padding: "0 20px",
        boxShadow: "none",
        whiteSpace: "nowrap",
        borderRadius: "20px",
        textAlign: "center",
    },
    chip: {
        fontFamily: "SFProTextRegular !important",
        fontSize: "10px",
        textTransform: "capitalize",
        color: "#24242D",
        width: "108px",
        height: "22px",
        backgroundColor: "#B7EB84"
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            width: "50%"
        },
    },
    dialogTitle: {
        display: "flex",
        flexDirection: "column"
    }
});

function MapTable(props) {
    const classes = useStyles();
    const { data, getDataMapsId, reviewDetails, getReviewDetails, users, updateCurrentEditor, setDataMapId } = props;
    const [rows, setRows] = useState([]);
    const [noMap, setNoMap] = useState(false);
    const [indexValue, setIndex] = useState(0);
    const [reviewHistory, setReviewHistory] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [userList, setUserList] = useState([]);
    const [dmapId, setDMapId] = useState("");
    const [transferId, setTransferId] = useState("");
    const [productName, setProductName] = useState("");
    const [showCard, setShowCard] = useState(true);
    const [cellKey, setCellKey] = useState(null);
    const screenEle = useRef(null);
    // const [ chipColor] = useState("#B7EB84")

    // const StyleChip = withStyles({
    //     root: {
    //       backgroundColor: chipColor
    //     }
    //   })(Chip);

    useEffect(() => {
        /*** Alert if clicked on outside of element ***/
        function handleClickOutside(event) {
          if (screenEle.current && !screenEle.current.contains(event.target)) {
            let ele = document.getElementById(cellKey);
            if (ele) {
                ele.style.display = "none";
            }
            setCellKey('');
          }
        }
    
        /*** Bind the event listener ***/
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          /*** Unbind the event listener on clean up ***/
          document.removeEventListener("mousedown", handleClickOutside);
        };
    
      }, [screenEle]);

    useEffect(() => {
        if (data && data.dataMaps) {
            if (data.dataMaps.length === 0) {
                props.setLoader && props.setLoader(false);
                setRows(data.dataMaps);
                setNoMap(true);
            }
            else {
                setIndex(0);
                props.setLoader && props.setLoader(false);
                setRows(data.dataMaps);
                setNoMap(false);
            }

        }
        else if (data) {
            if (data.length === 0) {
                setRows(data);
                setNoMap(true);

            }
            else {
                setIndex(0)
                setRows(data);
                setNoMap(false);
            }
            props.setLoader && props.setLoader(false);
        }

        if (data) {
            if (!R.isEmpty(data.reviewCount) && !R.isNil(data.reviewCount)) {
                props.setReviewCount(data.reviewCount);
            }
            if (!R.isEmpty(data.draftCount) && !R.isNil(data.draftCount)) {
                props.setDraftCount(data.draftCount);
            }
            if (!R.isEmpty(data.sandBoxCount) && !R.isNil(data.sandBoxCount)) {
                props.setSandboxCount(data.sandBoxCount);
            }
            if (!R.isEmpty(data.starredCount) && !R.isNil(data.starredCount)) {
                props.setStarredCount(data.starredCount);
            }
        }
    }, [data]);

    useEffect(() => {
        console.log(reviewDetails)
        if (reviewDetails) {
            setReviewHistory(reviewDetails);
        }
    }, [reviewDetails])

    useEffect(() => {
        if (rows[indexValue]) {
            const { datamapId } = rows[indexValue];
            getReviewDetails(datamapId);
        }

    }, [indexValue])

    useEffect(() => {
        if (users && users.length > 0) {
            setUserList(users);
        }
    }, [users])

    const handleOnEdit = (datamapsId) => {
        getDataMapsId(1, datamapsId);
        setDataMapId(datamapsId);
        if (props.history) {
            props.history.push("/edit");
        }
    }

    const handleTransferSubmit = () => {
        const emailId = window.USER_INFO ? window.USER_INFO.email : ""
        const payload =
        {
            "ownedBy": transferId,
            "modifiedDate": new Date(),
            "modifiedBy": emailId,
            "message": Messages.TRANSFER_EDITOR
        }
        updateCurrentEditor(payload, emailId, dmapId);
        setOpenDialog(false);
    }


    const handleDropDown = (key) => {
        console.log("key...");

        if (cellKey && cellKey === key) {
            let ele = document.getElementById(key);
            if (ele) {
                ele.style.display = "none";
                setCellKey('');
            }

        }
        else if (cellKey && cellKey !== key) {
            let ele = document.getElementById(cellKey);
            if (ele) {
                ele.style.display = "none";
            }
            ele = document.getElementById(key);
            if (ele) {
                ele.style.display = "flex";
            }
            setCellKey(key);
        }
        else {
            let ele = document.getElementById(key);
            if (ele) {
                ele.style.display = "flex";
            }
            setCellKey(key);
        }

    }


    const handleOnSelectRow = (index) => {

        if (showCard && indexValue === index) {
            setShowCard(false);
            let ele = document.getElementById('table-container');
            if (ele) {
                ele.style.width = "100%";
            }
        }
        else {
            setIndex(index);
            setShowCard(true);
            let ele = document.getElementById('table-container');
            if (ele) {
                ele.style.width = "75%";
            }

        }
    }

    const viewMemo = useMemo(() => {

        if (rows[indexValue]) {
            console.log(reviewHistory);
            const { generalInfo, mapStatus, isEdit, isView, datamapId, isEditor, firstName, lastName } = rows[indexValue];

            return (
                <Card elevation={0} className={classes.card} style={{ background: "#ffffff", marginLeft: "14px", width: "300px", height: "507px" }}>
                    <CardContent className={classes.cardView}>
                        <div className="card-title">
                            {generalInfo.productName}
                        </div>
                        <div className="card-buttons">
                            {
                                isView && <Link
                                    to={{
                                        pathname: "/review",
                                        state: { 
                                            mapId: datamapId,
                                            mapName: generalInfo.productName
                                         }
                                    }}
                                    style={{ textDecoration: "none" }}>
                                    <Tooltip title="View">
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            startIcon={<VisibilityIcon />}>
                                            View
                                        </Button>
                                    </Tooltip>
                                </Link>
                            }
                            {
                                isEdit &&
                                <Tooltip title="Edit">
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        startIcon={<EditIcon />}
                                        onClick={() => {
                                            handleOnEdit(datamapId);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Tooltip>
                            }
                            {isEditor &&
                                <Tooltip title="Transfer Editor">
                                    <Button size="small"
                                        variant="outlined"
                                        startIcon={<CompareArrowsIcon />}
                                        onClick={() => {
                                            setDMapId(datamapId);
                                            setOpenDialog(true);
                                            setProductName(generalInfo.productName);
                                        }}>
                                        Transfer Editor
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                        {/* <Button size="small" color="primary" className={classes.btn}>
                            {mapStatus}
                        </Button> */}
                        <div className="card-blocks">
                            <span className="label-text">Status</span>
                            <span className="body-text text-transform-captial #DDEBFF">
                                <Chip size="small" label={mapStatus} className={classes.chip} style={{ background: StatusChipColors[mapStatus.toUpperCase()] ? StatusChipColors[mapStatus.toUpperCase()] : "#F4CB61" }} />
                            </span>
                        </div>
                        <div className="card-blocks">
                            <span className="label-text">Editor</span>
                            <div className="avatar-editor">
                                <Avatar className="avatar-text" style={{ width: "22px", height: "22px", backgroundColor: "#DCEC2B" }} >{`${firstName ? firstName.charAt(0) : ""}${lastName ? lastName.charAt(0) : ""}`}</Avatar>
                                <div className="body-text word-break">{`${firstName ? firstName : ""} ${lastName ? lastName : ""}`}</div>
                            </div>
                        </div>

                        <div className="card-blocks">
                            <span className="label-text" >Data Sheet Version</span>
                            <div className="d-flex flex-row" style={{ gap: "5px" }}>
                                <Tooltip title="View Data Sheet">
                                    <a className="url-link visit-data-sheet" href={`//${generalInfo.dataSheetURL}`} target="_blank" rel="noopener noreferrer">
                                        <span className="body-text" style={{ color: "#4C42BF" }}>{`${generalInfo.dataSheetVersion}`}</span>
                                    </a>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="card-blocks">
                            <span className="label-text" >Map History</span>
                            <div style={{ height: "200px", overflow: "auto" }}>{
                                reviewHistory && reviewHistory.map((history, i) => {
                                    return (
                                        <div key={i} style={{ display: "flex", flexDirection: "column", gap: "5px", marginTop: "16px", alignItems: "baseline", width: "100%" }}>
                                            <span style={{ fontSize: "10px", fontWeight: 600, fontFamily: "SFProTextRegular", color: "#7E7E7E" }} >{moment(history.modifiedDate).format("MMMM DD, YYYY")}</span>
                                            <span className="body-text" >{`${history.firstName} ${history.lastName} ${history.message}`}</span>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </CardContent>
                </Card >)
        }
    },
        [rows, indexValue, reviewHistory]);

    return (
        <div>
            <div className="maps-toolbar">
                <SearchField />
            </div>
            <div className={classes.root} style={{ display: "flex", flexDirection: "row" }}>
                <div className={classes.table} id="table-container">
                    <Paper elevation={0}>
                        <TableContainer>
                            {!noMap ? <>
                                <div style={{ overflow: "auto", borderRadius: "12px" }} >
                                    <Table stickyHeader aria-label="sticky table" >
                                        <TableHead>
                                            <TableRow >
                                                {columns.map((column) => (
                                                    <TableCell
                                                        className="table-header-text"
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ width: column.minWidth, backgroundColor: "#F7F7FB" }}
                                                    >
                                                        {column.label}  {column.icon}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                                <div style={{ overflow: 'auto', height: '450px', }}>
                                    <Table style={{ tableLayout: 'fixed' }}>
                                        <TableBody >
                                            {rows.map((row, index) => {
                                                const { generalInfo, firstName, lastName, mapStatus, modifiedDate,
                                                    datamapId, isEdit, isView, isStarred
                                                } = row;
                                                return (
                                                    <TableRow hover tabIndex={-1} key={index} >
                                                        <div style={{ display: "contents" }} onClick={() => handleOnSelectRow(index)}>
                                                            <TableCell className="body-text word-break" align="left" width={columns[0].minWidth}  >{generalInfo.productName}</TableCell>
                                                            <TableCell align="left" width={columns[1].minWidth}>
                                                                <div className="avatar-editor">
                                                                    <Avatar className="avatar-text" style={{ width: "22px", height: "22px", backgroundColor: "#DCEC2B"}} >{`${firstName ? firstName.charAt(0) : ""}${lastName ? lastName.charAt(0) : ""}`}</Avatar>
                                                                    <div className="body-text word-break">{`${firstName ? firstName : ""} ${lastName ? lastName : ""}`}</div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="body-text word-break" align="left" width={columns[2].minWidth}>
                                                                <Chip size="small" label={mapStatus} className={classes.chip}
                                                                    style={{ background: StatusChipColors[mapStatus.toUpperCase()] ? StatusChipColors[mapStatus.toUpperCase()] : "#F4CB61" }} />
                                                            </TableCell>
                                                            <TableCell className="body-text word-break" align="left" width={columns[3].minWidth}>{moment(modifiedDate).format("MMMM DD, YYYY")}</TableCell>
                                                        </div>
                                                        <TableCell className="body-text word-break" align="left" width={columns[4].minWidth}>
                                                            <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                                                                {
                                                                    isStarred ? <img src='./icons/colorstar.svg' width="12px" height="12px" />: 
                                                                    <img src='./icons/star.svg' width="12px" height="12px" />
                                                                }
                                                                {(isView || isEdit) && <MoreHorizIcon onClick={() => {
                                                                    handleDropDown(`btnCard-${index}`);
                                                                }}
                                                                />}
                                                            </div>

                                                            <Card id={`btnCard-${index}`}
                                                                style={{
                                                                    width: 70, height: 60, background: "#ffffff", display: "none",
                                                                    zIndex: 5, position: "fixed", right: showCard ? "25%": "60px"
                                                                }} >
                                                                <CardContent style={{ padding: "8px 18px"}}>
                                                                    <div className="btn-list" >
                                                                        {
                                                                            isView &&
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/review",
                                                                                    state: { 
                                                                                        mapId: datamapId,
                                                                                        mapName: generalInfo.productName
                                                                                     }
                                                                                }}
                                                                                style={{ textDecoration: "none" }}>
                                                                                <div className="btn-text text-transform-captial"
                                                                                    onClick={() => {
                                                                                        handleDropDown(`btnCard-${index}`);
                                                                                    }}>View</div>
                                                                            </Link>
                                                                        }
                                                                        {isEdit &&
                                                                            <div className="btn-text text-transform-captial"
                                                                                onClick={() => {
                                                                                    handleDropDown(`btnCard-${index}`);
                                                                                    handleOnEdit(datamapId);
                                                                                }}>Edit</div>
                                                                        }
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            </> : <div className={classes.error}>
                                <Alert variant="outlined" severity="error">
                                    No Maps Found
                                </Alert></div>
                            }
                        </TableContainer>
                    </Paper>
                </div>
                <div className={classes.review}>
                    {showCard && viewMemo}
                </div>
                <Dialog
                    className={classes.dialog}
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {productName && <DialogTitle style={{ display: "flex", flexDirection: "column" }}>
                        <div className="bold-body-text" >{productName}</div>
                        <div className="body-text" >Transfer to new Owner</div>
                    </DialogTitle>}
                    <DialogActions>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
                            <Autocomplete
                                id="combo-box-demo"
                                options={userList}
                                getOptionLabel={(option) => option.emailId}
                                style={{ width: "100%" }}
                                onChange={(e, val) => {
                                    setTransferId(val.emailId);

                                }}
                                renderInput={(params) => <TextField {...params} label="Email ID" variant="outlined" />}
                            />
                            <div className="d-flex flex-row justify-content-end" style={{ gap: "15px" }} >
                                <Button
                                    className="secondary-regular-button"
                                    onClick={() => {
                                        setOpenDialog(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="primary-regular-button"
                                    onClick={() => {
                                        handleTransferSubmit();

                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div >
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDataMapsId,
            getReviewDetails,
            updateCurrentEditor,
            setDataMapId
        },
        dispatch
    );
}

const mapStateToProps = (state) => {
    return {
        data: state.dashboard.dashboardData,
        userInfo: state.userInfo.data,
        reviewDetails: state.dashboard.reviewDetails,
        users: state.userInfo.users
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(MapTable);