// @flow

import * as R from "ramda";

import SVGParser, { makeAbsolute } from "svg-path-parser";
import type { DrawConnectableAndLineTrackingData } from "./drawPathExtension";

export function parsePath(input: HTMLElement) {
  const pathCommand = input.getAttribute("d");

  if(R.isEmpty(pathCommand) || R.isNil(pathCommand)){
    const resultRaw =[];
    const resultAbsolute =[];
    const obj1 = {
      code: "M",
      command: "moveto",
      x: input.getAttribute("x1")|| 0,
      y: input.getAttribute("y1")|| 0,
      x2: input.getAttribute("x2")|| 0,
      y2: input.getAttribute("y2")|| 0, 
    }
  
    const obj2 ={
      code: "L",
      command: "lineto",
      x: input.getAttribute("x2")|| 0,
      y: input.getAttribute("y2")|| 0, 
    }

    resultRaw.push(obj1, obj2)
    resultAbsolute.push(obj1, obj2)

    return { raw: resultRaw, absolute: resultAbsolute };
  }

  const resultRaw = SVGParser(pathCommand);
  const resultAbsolute = makeAbsolute(SVGParser(pathCommand));
  return { raw: resultRaw, absolute: resultAbsolute };
}

export function getVirtualConnectableSectionIdentifier(
  purpose: Array<string>,
  dataTypes: Array<string>
) {
  return `${purpose.sort().join("||")} == ${dataTypes.sort().join("||")}`;
}

export function interpretExistingSVG(
  draw: any,
  meta: DrawConnectableAndLineTrackingData
) {
  console.log(draw.select("[id^=datamap-line]").members)

  const lineElements = R.compose(
    R.filter(
      R.compose(
        R.not,
        R.isNil
      )
    ),
    R.map(member => member.node)
  )(draw.select("[id^=datamap-line]").members);

  console.log(lineElements)

  const paths = R.map(lineElement => ({
    pathData: parsePath(lineElement),
    id: lineElement.id
  }))(lineElements);

  return processSVGPathsAndDrawTrackingData(paths, meta);
}

export function processSVGPathsAndDrawTrackingData(
  existingPaths: any,
  meta: DrawConnectableAndLineTrackingData
) {
  const reduceToIdMap = R.reduce(
    (acc, next) => ({ ...acc, [next.id]: next }),
    {}
  );

  // Lines from metadata
  const metaLines = meta.lineTracker.committed;
  const metaLinesObj = R.mapObjIndexed((val, key) => ({
    id: key,
    leftConnectableId: val.connects.left,
    rightConnectableId: val.connects.right
  }))(metaLines);

  // Lines from SVG
  const svgLinesObj = R.compose(
    reduceToIdMap,
    R.map(({ pathData: { absolute }, id }) => {
      let firstCommand = absolute[0];
      let lastCommand = absolute[absolute.length - 1];

      return {
        id: id,
        leftPos: { x: firstCommand.x, y: firstCommand.y },
        rightPos: { x: lastCommand.x, y: lastCommand.y }
      };
    })
  )(existingPaths);

  // Connectables
  const connectables = meta.connectables;
  const virtualConnectablesAndTagsObj = R.compose(
    reduceToIdMap,
    R.map(obj => {
      const linesConnectingThisConnectable = R.merge(
        R.filter(x => x.leftConnectableId === obj.connectableId)(metaLinesObj),
        R.filter(x => x.rightConnectableId === obj.connectableId)(metaLinesObj)
      );

      const linesCoordData = R.compose(
        R.values,
        R.mapObjIndexed((val, key) => {
          let relevantCoordinates;
          if (val.leftConnectableId === obj.connectableId) {
            relevantCoordinates = R.pick(["x", "y"])(svgLinesObj[key].leftPos);
          } else {
            relevantCoordinates = R.pick(["x", "y"])(svgLinesObj[key].rightPos);
          }

          return relevantCoordinates;
        })
      )(linesConnectingThisConnectable);

      // todo : Show some warning telling that designer has moved the lines incorrectly ? could use the following
      // snippet
      /*const coordinatesAreEqual = R.reduce(
        (acc,next) => (acc ? next === linesCoordData[0]: false), true
      )(linesCoordData);*/
      // or, take the average ?

      return {
        ...obj,
        id: getVirtualConnectableSectionIdentifier(
          obj.tags.purpose,
          obj.tags.dataTypes
        ),
        coordinates: linesCoordData[0]
      };
    }),
    R.map(R.view(R.lensIndex(1))),
    R.toPairs,
    R.mapObjIndexed(connectable => ({
      tags: connectable.tags,
      connectableId: connectable.connectableId,
      kind: connectable.kind
    })),
    R.filter(R.propEq("kind", "virtual"))
  )(connectables);

  return createExistingSVGInterpretation({
    metaLinesObj,
    svgLinesObj,
    virtualConnectablesAndTagsObj
  });
}

export function createExistingSVGInterpretation(base: any) {
  const { virtualConnectablesAndTagsObj } = base;

  return Object.assign(base, {
    fetchExistingNode: ({ purpose, dataTypes }) =>
      virtualConnectablesAndTagsObj[
        getVirtualConnectableSectionIdentifier(purpose, dataTypes)
      ]
  });
}
