import * as R from "ramda"
export const SET_EXISTING_SVG = "SET_EXISTING_SVG";
export const RECOVERED_JSON_FROM_SVG = "RECOVERED_JSON_FROM_SVG";

export const ProcessingNodeIndividualEntryPrefix = "processNodeForm";
export const RetentionNodeIndividualEntryPrefix = "retentionNodeForm";

export function setSvgString(svgString) {
  return {
    type: SET_EXISTING_SVG,
    data: svgString,
  };
}

export function recoverJsonFromSVG(data) {
  let { processingNodes, retentionNodes } = data;

  if (processingNodes) {
    const keys = R.keys(processingNodes);

    for(let key of keys){
      let val = key.split("-")
      if(val[1]){
        if(parseInt(val[1]) > window.processNodeCount){
          window.processNodeCount = parseInt(val[1])
        }
      }
    }

    window.processNodeCount++
  }

  if (retentionNodes) {
    const keys = R.keys(retentionNodes);

    for(let key of keys){
      let val = key.split("-")
      if(val[1]){
        if(parseInt(val[1]) > window.retentionNodeCount){
          window.retentionNodeCount = parseInt(val[1])
        }
      }
    }

    window.retentionNodeCount++ 
  }

  let finalData = JSON.parse(JSON.stringify(data));

  return {
    type: RECOVERED_JSON_FROM_SVG,
    data: finalData,
  };
}