
import Axios from "axios";
import { API_BASE_PATH, API_ROOT_PATH, headers } from './config';

export const API_URL = API_BASE_PATH + API_ROOT_PATH;

// export const orgId = window.ORG_ID;

/***** UPLOAD DATA MAP API ****/
export const uploadDataMap = (data) => Axios.post(`${API_URL}/org/${window.ORG_ID}/datamaps/upgrade`, data, { headers: headers() });

export const uploadSVGFile = (data, datamapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${datamapId}/uploadMaps`,
  data,
  {
    headers: headers(),
    params: {
      "mapFileName": "datamaps.svg"
    }
  });

/***** GET DATA CENTERS LOCATION API ****/
export const getLocations = () => Axios.get(`${API_URL}/upgrade`);

export const getUserOrgInfo = (emailId) => Axios.get(`${API_URL}/userInfo`, {
  headers: headers(),
  params: {
    "emailId": emailId
  }
});

/**** GET ALL DATA MAPS ****/
export const getDashboardData = (org_id, emailId) => Axios.get(`${API_URL}/org/${org_id}/datamaps/all`, {
  headers: headers(),
  params: {
    "mapStatus": "maplist",
    "ownedBy": emailId
  },
});

/**** GET REVIEW MAPS BY ORGANISATION ID ****/
export const getToReviewMaps = (emailId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/all`, {
  headers: headers(),
  params: {
    "mapStatus": "review",
    "ownedBy": emailId
  }
});

/**** GET DRAFT MAPS BY EMAIL ID ****/
export const getDraftMaps = (emailId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/all`, {
  headers: headers(),
  params: {
    "mapStatus": "draft",
    "ownedBy": emailId
  }
});

/**** GET STARRED MAPS BY EMAIL ID ****/
export const getStarredMaps = (emailId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/all`, {
  headers: headers(),
  params: {
    "mapStatus": "starred",
    "ownedBy": emailId
  }
});

/**** GET STARRED MAPS BY EMAIL ID ****/
export const getSandboxMaps = (emailId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/all`, {
  headers: headers(),
  params: {
    "mapStatus": "sandbox",
    "ownedBy": emailId
  }
});

/**** GET DATA MAPS BY ID ****/
export const getDataMapById = (org_id, datampapId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/${datampapId}`, { headers: headers() });

/***** CREATE NEW DATA MAP API ****/
//export const createNewMap = (data) => Axios.post(`${API_URL}/generalInfo`, data, headers);
export const createNewMap = (data) => Axios.post(`${API_URL}/org/${window.ORG_ID}/datamaps`, data, { headers: headers() });

/**** UPDATE PRODUCT INFO ****/
export const updateProductInfo = (data, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/productInfo`, data, { headers: headers() });

/**** UPDATE DATACENTERS Info ****/
export const updateDataCentersInfo = (data, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/dataCentersInfo`, data, { headers: headers() });

/**** UPDATE INPUT NODES ****/
export const updateInputNodes = (data, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/dataInputNodes`, data, { headers: headers() });

/**** UPDATE PROCESSING NODES ****/
export const updateProcessingNodes = (data, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/processingNodesInfo`, data, { headers: headers() });

/**** UPDATE RETENTION NODES ****/
export const updateRetentionNodes = (data, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/retentionNodesInfo`, data, { headers: headers() });

/**** GET USERS BY ORGANISATION ID ****/
export const getUserByOrgId = (emailId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/users`,
  {
    headers: headers(),
    params: {
      "emailId": emailId,
    }
  });

/**** GET SVG FILE PATH ***/
export const getSVGFile = (dataMapId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/mapsSvg`, { headers: headers() });

/**** GET HISTORY DETAILS ****/
export const getReviewDetailsByMapId = (dataMapId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/mapHistory`, { headers: headers() });

/*** ADD COMMENTS  */
export const addComments = (data, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/updateReviews`, data, { headers: headers() });

/*** GET COMMENTS  */
export const getComments = (dataMapId) => Axios.get(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/reviewDetails`, { headers: headers() });

/**** UPDATE APPROVED STATUS API ****/
export const updateApproveStatus = (data, emailId, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/updateApprover`,
  data,
  {
    headers: headers(),
    params: {
      "emailId": emailId,
      "mapStatus": "approved"
    }
  },
);


/**** UPDATE REVIEVERS  API ****/
export const updateReviewers = (data, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/reviewMaps`,
  data,
  {
    headers: headers(),
  },
);


export const updateCurrentEditor = (data, emailId, dataMapId) => Axios.put(`${API_URL}/org/${window.ORG_ID}/datamaps/${dataMapId}/updateCurrentEditor`,
  data,
  {
    headers: headers(),
    params: {
      "emailId": emailId,
    }
  });
