// @flow

import { HeaderTitleCoords, HeaderSubTitleCoords } from "../grid";
import { fontsPlainSVG } from "../../../styles/index";
import type { DataEntryFormOutput } from "../../dataEntry/types";

export const TitleSvg = (formValues: DataEntryFormOutput) => {
  const TitleString = `
    <text id="generalInfo-productName" 
    x="${HeaderTitleCoords.x}" 
    y="${HeaderTitleCoords.y}" 
    ${fontsPlainSVG.title}>${formValues.generalInfo.productName}</text>
    `;
  return TitleString;
};

export const SubTitleSvg = () => {
  const SubTitleString = `
    <text id="generalInfo-details" 
      x="${HeaderSubTitleCoords.x}" 
      y="${HeaderSubTitleCoords.y}"
      ${
        fontsPlainSVG.subTitle
      }>For full details, please refer to the corresponding Privacy Data Sheet</text>
    `;
  return SubTitleString;
};
