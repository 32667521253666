// @flow

import * as R from "ramda";

// import type { DataEntryFormOutput } from "../../types";
import type { MaybeErrorType } from "./types";
import { getCalloutValidity } from "./calloutValidity";
import { getNodeValidity } from "./nodeValidity";

export const alertErrors = (validationErrors: MaybeErrorType = {}): void => {
  !R.isEmpty(validationErrors.errors) &&
    window.alert(
      R.compose(
        R.join(""),
        R.values,
        R.map(valObj => `- ${valObj.label}: ${valObj.error}\n`)
      )(validationErrors.errors)
    );
};

export const checkAllValid = (validityObjs: any[]): boolean => {
  return R.compose(
    R.all(Boolean),
    R.map(valObj => valObj.isValid),
    R.defaultTo([])
  )(validityObjs);
};

export const collectErrors = (
  validityObjs: any[]
): { [error: string]: string } => {
  return validityObjs
    .filter(valObj => !valObj.isValid)
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.name]: { label: curr.label, error: curr.error }
      }),
      {}
    );
};

export function validatedSubmit(
  values,
  cb: MaybeErrorType => void
): Promise<Function> {
  return new Promise(resolve => {
    const validations = [
      ...getCalloutValidity(values),
      ...getNodeValidity(values)
    ];
    const errors = collectErrors(validations);
    const isFormValid = checkAllValid(validations);

    resolve(
      isFormValid
        ? cb({})
        : cb({
            errors
          })
    );
  });
}
