// @flow

import * as R from "ramda";

import type { ProcessingNode, RetentionNode, InputNode } from "../../../types";
import type { NodeList, ValidityObject } from "./types";
import type { DataEntryFormOutput } from "../../types";
import {
  validationErrors,
  NODE_NAME,
  NODE_LABEL,
  MAX_NODES,
  PROCESSING_NODE_LABEL,
  RETENTION_NODE_LABEL,
  INPUT_NODE_LABEL,
  NODE_COUNT_NAME
} from "./constants";
import {
  controllersList,
  subcategoryAccessList,
  retentionDurationList
} from "../../sections/config.js";

export const validateNodes = <T>(
  inputs: NodeList<InputNode> = {},
  nodes: NodeList<T>
) => {
  const nodeTypeCount = {};
  const isValid = !R.isEmpty(nodes)
    ? R.compose(
        R.all(Boolean),
        R.flatten,
        R.values,
        R.map(node => {
          // Ankit Tharwani: introduction of type counts to validate if they exceed the defaul
          if ("subcategory" in node)
            nodeTypeCount[node.controller + "_" + node.subcategory] =
              (nodeTypeCount[node.controller + "_" + node.subcategory] || 0) +
              1;
          else
            nodeTypeCount[node.controller + "_" + node.timeline] =
              (nodeTypeCount[node.controller + "_" + node.timeline] || 0) + 1;
          const nodeTypes = node.dataTypes;
          const inputTypes = R.values(R.map(node => node.dataType, inputs));
          return R.map(t => R.contains(t, inputTypes))(nodeTypes);
        })
      )(nodes)
    : true;

  return { isValid, nodeTypeCount };
};

export const getNodeValidity = (
  values: DataEntryFormOutput
): Array<ValidityObject> => {
  const validations: Array<ValidityObject> = [];
  const { inputNodes, processingNodes, retentionNodes } = values;
  const processingNodeValidation = validateNodes<ProcessingNode>(
    inputNodes,
    processingNodes
  );
  const retentionNodeValidation = validateNodes<RetentionNode>(
    inputNodes,
    retentionNodes
  );

  // Check for node data type validity
  const isValid =
    processingNodeValidation.isValid && retentionNodeValidation.isValid;
  const error = isValid ? "" : validationErrors.nodeValidity;
  validations.push({
    name: NODE_NAME,
    label: NODE_LABEL,
    isValid,
    error
  });

  // Check for node count validity
  const fillTemplate = function(templateString, templateVars) {
    return new Function("return `" + templateString + "`;").call(templateVars);
  };

  const checkNodeCount = (v, k) => {
    if (v > MAX_NODES) {
      const [controller, subcategory] = k.split("_");

      // Ankit Tharwani: for some reason, flow focus-check has been quite anal about
      // R.find and undefined type and a number of slicker alternatives are failing the check
      let controllerTxt = R.find(R.propEq("id", controller))(controllersList);
      if (controllerTxt == undefined) {
        controllerTxt = "unknown";
      } else {
        if (controllerTxt.hasOwnProperty("name"))
          controllerTxt = controllerTxt.name;
        else controllerTxt = "unknown";
      }

      let subcategoryTxt = R.find(R.propEq("id", subcategory))(subcategoryList);
      if (subcategoryTxt == undefined) {
        subcategoryTxt = "unknown";
      } else {
        if (subcategoryTxt.hasOwnProperty("name"))
          subcategoryTxt = subcategoryTxt.name;
        else subcategoryTxt = "unknown";
      }
      const error = fillTemplate(
        validationErrors.processingRetentionNodeCountValidity,
        {
          controller: controllerTxt,
          subcategory: subcategoryTxt,
          max_nodes: MAX_NODES
        }
      );
      const isValid = false;
      validations.push({
        name: NODE_COUNT_NAME,
        label: nodeLabel,
        isValid,
        error
      });
    }
  };

  // Check for processing node count
  let nodeLabel = PROCESSING_NODE_LABEL;
  let subcategoryList = subcategoryAccessList;
  R.forEachObjIndexed(checkNodeCount)(processingNodeValidation.nodeTypeCount);

  // Check for retention node count
  nodeLabel = RETENTION_NODE_LABEL;
  subcategoryList = retentionDurationList;
  R.forEachObjIndexed(checkNodeCount)(retentionNodeValidation.nodeTypeCount);

  // Check for input node count
  nodeLabel = INPUT_NODE_LABEL;
  if (Object.keys(inputNodes).length > MAX_NODES) {
    const isValid = false;
    const error = fillTemplate(validationErrors.inputNodeCountValidity, {
      max_nodes: MAX_NODES
    });
    validations.push({
      name: NODE_COUNT_NAME,
      label: nodeLabel,
      isValid,
      error
    });
  }

  return validations;
};
