import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CreateNewMap from "../create-new-map";
import "./index.scss";
import UploardSvg from "../upload-svg";
// import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="test"
    >
      {<Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    alignItems: "center",
    height: "100vh"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "20%"
  },
  tab: {
    textTransform: "capitalize",
    fontWeight: "lighter",
    color: "#797979",
    fontSize: "30px",
    width: "100%",
    maxWidth: "100%"
  },
  tabpanel: {
    width: "100%"
  }
}));

const VerticalTabs = (props) => {
  const { index = 0} = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        TabIndicatorProps={{ style: { background: "#049FD9" } }}
      >{
          index === 1 ? <Tab
            className={classes.tab}
            style={{
              fontSize: "48px",
              marginTop: "5%",
              fontFamily: "CiscoSansTTThin",
            }}
            label="Upload"
            {...a11yProps(1)} /> : <Tab
            className={classes.tab}
            style={{
              fontSize: "48px",
              marginTop: "5%",
              fontFamily: "CiscoSansTTThin",
            }}
            label="Create"
            {...a11yProps(0)}
          />
        }
        <Link
          to="/dashboard"
          style={{ textDecoration: "none" }}>
          <Tab
            className={classes.tab}
            style={{
              fontSize: "48px",
              marginTop: "5%",
              fontFamily: "CiscoSansTTThin",
            }}
            label="Back"
            {...a11yProps(1)}
          />
        </Link>

      </Tabs> */}
      <div index={index} >
        {index === 1 ? <UploardSvg /> : <CreateNewMap {...props} />}
      </div>
    </div>
  );
};

VerticalTabs.propTypes = {
  index: PropTypes.number,
};

export default VerticalTabs;
