// @flow

// import { format } from "date-fns";
// import * as moment from 'moment';
import { LeftXVersionDescription, TopYVersionDescription, LeftXVersionDescription_II,TopYVersionDescription_II } from "../grid";
import { fontsPlainSVG } from "../../../styles/index";
import type { DataEntryFormOutput } from "../../dataEntry/types";

 const convertDateformat = (date) => {
  let dateformat = date.toString();

  return dateformat;
};

export const VersionDescriptionSvg = (formValues: DataEntryFormOutput) => {

  // Wrong implimentation
  // const dateArray = formValues.generalInfo.date.split("-");
  // const formattedDate = format(
  //   new Date(
  //     Number(dateArray[0]),
  //     Number(dateArray[1]) - 1,
  //     Number(dateArray[2])
  //   ),
  //   "mmmm d, yyyy"
  // );

  const formattedDate = convertDateformat(formValues.generalInfo.date)
  const DescriptionString = `
    <text id="generalInfo-version-date" 
    x="${LeftXVersionDescription}" 
    y="${TopYVersionDescription}"
    ${fontsPlainSVG.version}> Version ${
    formValues.generalInfo.dataSheetVersion
  }, ${formattedDate}</text>
    `;
  return DescriptionString;
};


export const VersionDescriptionSvg2 = (formValues: DataEntryFormOutput) => {
  const formattedDate = convertDateformat(formValues.generalInfo.date)
  const DescriptionString = `
    <text id="generalInfo-version-date" 
    x="${LeftXVersionDescription_II}" 
    y="${TopYVersionDescription_II}"
    ${fontsPlainSVG.version}> Version ${
    formValues.generalInfo.dataSheetVersion
  }, ${formattedDate}</text>
    `;
  return DescriptionString;
};