/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext, useRef } from 'react';
// import Select from "react-select";
import clsx from 'clsx';
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDashboardData, getDraftData, getToReviewData, getStarredData, getSandboxData } from "../../actions/apiActions";
import { showMarkAsApproveBtn } from "../../actions/metaDataAction";
import { getUsersByOrgId, getUserOrgInfo } from "../../actions/userInfoAction";
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar, Button, Drawer, Dialog, CircularProgress,
  Typography, List, ListItem, ListItemText, Avatar, Card, CardContent, 
  DialogTitle, DialogContent, DialogContentText,
  //Modal, Box
} from '@material-ui/core';
// AppBar TextField CssBaseline
import MapTable from "./table";
import { setUserInfo } from "../../actions/userInfoAction";
import AuthService from "../auth/authService";
import { Link } from "react-router-dom";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { navTitles, SplitBtns } from '../../constant';
import { AuthConsumer } from "../auth/authProvider";

const drawerWidth = 175;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiDrawer-paperAnchorDockedLeft': {
      border: "none"
    }
  },
  formControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "10px",
    width: "100%",
    '& .MuiFormControl-root': {
      width: "160px",
    },
    '& .MuiInputBase-root': {
      height: "30px",
      paddingLeft: "8px",
      paddingTop: "4px",
      fontFamily: "SFProTextRegular !important",
      fontSize: "14px",
      textTransform: "capitalize",
      color: "#24242D",
    }
  },
  title: {
    fontFamily: "SFProTextLight !important",
    fontSize: "16px",
    paddingLeft: "8px",
    textTransform: "capitalize",
    color: "#24242D"
  },
  tableheading: {
    fontFamily: "SFProTextRegular !important",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "21.49px",
    color: "#24242D"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000000",
    backgroundColor: "#FFFFFF"
  },
  drawerPaper: {
    width: drawerWidth,

  },
  drawerContainer: {
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#F7F7FB",
    '& .MuiCardContent-root': {
      width: "100%"
    }
  },
  button: {
    marginRight: theme.spacing(2),
    color: "#ffffff",
    backgroundColor: "#049fd9",
    height: "34px",
    borderRadius: "20px !important",
    textTransform: "capitalize"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listContent: {
    "& .MuiTypography-body1": {
      fontFamily: "inherit",
    },
    "& .MuiBadge-colorError": {
      backgroundColor: "#4C42BF"
    },
    "& .MuiBadge-badge": {
      height: "14px",
      minWidth: "10px",
      borderRadius: "14px",
      padding: "0px 4px"
    },
    "& .MuiListItemText-primary": {
      fontFamily: "SFProTextRegular",
      fontSize: "12px",
      fontWeight: "400",
      color: "#000000",
      lineHeight: "15.24px"
    }
  },
  dial_root: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  dial_paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  badge: {
    fontSize: "10px",
    marginTop: "3px",

  },
  iconBadge: {
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#4C42BF"
    }
  },
  bodyText: {
    paddingRight: "10px"
  },
  countText: {
    width: "22px",
    height: "22px",
    backgroundColor: "#FF7761",
    fontFamily: "SFProDisplayBold !important",
    fontWeight: 50,
    fontSize: "12px",
    color: "#000000",
    lineHeight: "10.74px",
    borderRadius: "3px"
  },
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  }
}));

const NewDashboard = props => {
  const classes = useStyles();
  const { getUserOrgInfo, getDashboardData, userInfo, getUsersByOrgId, getDraftData,
    getToReviewData, getStarredData, showMarkAsApproveBtn, userOrgData, getSandboxData,
    dashBoardError } = props;

  const [navIndex, setNavIndex] = useState(0);
  const [, setUserName] = useState("");
  const [draftCount, setDraftCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [starredCount, setStarredCount] = useState(0);
  const [sandboxCount, setSandboxCount] = useState(0);
  const [email, setEmail] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [orgName, setOrgName] = useState("");
  // const [invisible,] = useState(false);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(true);
  const [testMode, setTestMode] = useState(false);
  const [splitBtn, setSplitBtn] = useState(SplitBtns.CREATE_NEW_MAP);
  const [showDropDown, setShowDropDown] = useState(false);
  const [orgDropDown, showOrgDropDown] = useState(false);
  const consumer = useContext(AuthConsumer);
  const screenEle = useRef(null);
  const menuEle = useRef(null);
  const [errModal, showErrModal] = useState(false);
  const [errModalObj, setErrorModelObj] = useState({});

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleChange = (val) => {
    console.log(val)
    setOrgName(val);
  };
  const handleOnSelect = (index) => {
    setNavIndex(index);
    if (userInfo && userInfo.email) {
      if (index === 0) {
        getDashboardData(window.ORG_ID, userInfo.email);
        showMarkAsApproveBtn(false);
      }
      else if (index === 1) {
        getDraftData(userInfo.email);
        showMarkAsApproveBtn(false);
      }
      else if (index === 2) {
        getToReviewData(userInfo.email);
        showMarkAsApproveBtn(true);
      }
      else {
        getStarredData(userInfo.email);
        showMarkAsApproveBtn(false);
      }
      setLoader(true);
      setTestMode(false);
    }
  }

  const onSandboxSelect = () => {
    setTestMode(true);
    getSandboxData(userInfo.email)
  }

  const handleSignOut = () => {
    console.log(consumer);
    if (consumer.signoutRedirectCallback) {
      consumer.signoutRedirectCallback();
    }
  }

  useEffect(() => {
    if (dashBoardError) {
      console.log("dashBoardError", dashBoardError);
      setLoader(false)
      showErrModal(true);
      setErrorModelObj(dashBoardError);
    }

  }, [dashBoardError])

  useEffect(() => {
    /*** Alert if clicked on outside of element ***/
    function handleClickOutside(event) {
      if (screenEle.current && !screenEle.current.contains(event.target)) {
        console.log("dsafadf")
        showOrgDropDown(false);
      }
      if (menuEle.current && !menuEle.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    /*** Bind the event listener ***/
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      /*** Unbind the event listener on clean up ***/
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [screenEle]);

  const orgIndex = (orgs) => {
    for (var i = 0; i < orgs.length; i += 1) {
      if (orgs[i]['organisationName'] === orgName) {
        return i;
      }
    }
    return -1;

  }

  useEffect(() => {
    if (userOrgData && userOrgData.length > 0) {
      let val = orgIndex(userOrgData);
      console.log(val)
      setLoader(true);
      if (val !== -1) {
        const org = userOrgData[val];
        window.ORG_ID = org.organisationId;
        getDashboardData(org.organisationId, userInfo.email);
        getUsersByOrgId(userInfo.email);
      }
      else {
        const org = userOrgData[0];
        window.ORG_ID = org.organisationId;
        setOrgList(userOrgData);
        setOrgName(org.organisationName)
        getDashboardData(org.organisationId, userInfo.email);
        getUsersByOrgId(userInfo.email);
      }
    }

  }, [userOrgData, orgName])

  useEffect(() => {
    console.log("second call ")
    if (!props.userInfo) {
      let authService = new AuthService();
      authService.getUser().then((user) => {
        console.log(user);
        if (user) {
          console.log("calling set userinfo");
          props.setUserInfo(user.profile);

        }
      });
    }
    if (userInfo) {
      if (userInfo.name) setUserName(userInfo.name);
      if (userInfo.email) {
        setTimeout(() => {
          console.log("calling get dashboard");
          setEmail(userInfo.email)
          getUserOrgInfo(userInfo.email)
        }, 1000);
      }
    }
  }, [userInfo])

  const renderBadge = (title) => {

    if (title === 'My Drafts') {
      return <>
        <ListItemText className="body-text" primary={title} />
        {draftCount < 0 && <Avatar className={classes.countText} variant="square">
          {draftCount}
        </Avatar>}
      </>
    }
    else if (title === 'To Review') {
      return <>
        <ListItemText className="body-text" primary={title} />
        {reviewCount > 0 && <Avatar className={classes.countText} variant="square">
          {reviewCount}
        </Avatar>}
      </>
    }
    else if (title === 'Starred') {
      return <>
        <ListItemText className="body-text" primary={title} />
        {starredCount < 0 && <Avatar className={classes.countText} variant="square">
          {starredCount}
        </Avatar>}
      </>
    }
    else {
      return <ListItemText className="body-text" primary={title} />
    }
  }

  return (
    <div className={classes.root} >
      {/* <CssBaseline /> */}
      {/* <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Avatar style={{ width: "32px", height: "32px" }} />
            <Typography variant="h3" noWrap className={classes.title}>
              {`Welcome, ${userName}`}
            </Typography>
          </div>
          <div className={classes.formControl}>
            <TextField
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={orgName}
              variant="outlined"
            >
              {orgList && orgList.map((org) => <option key={org.organisationId} value={org.organisationName}>{org.organisationName}</option>
              )}
            </TextField>
            <Button className={classes.iconBadge} style={{ alignItems: "right" }}>
              <Badge color="primary" variant="dot">
                <NotificationsIcon style={{ color: "#20203F" }} />
              </Badge>
            </Button>
          </div>

        </Toolbar>
      </AppBar> */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.drawerContainer} >
          <div>
            {open ? <div className="wrapper d-flex justify-content-space-between" >
              <div className="d-flex flex-row align-items-center" style={{ gap: "5px" }} ref={screenEle} >
                <Avatar className="org-avatar-text">{orgName && orgName.charAt(0)}</Avatar>
                <div className="d-flex flex-col" >
                  <span className="org-title-txt">{orgName}</span>
                  <span className="label-text-II">{email}</span>
                </div>
                <div className="d-flex flex-col" onClick={() => showOrgDropDown(!orgDropDown)}>
                  <img src='./icons/uparrow.svg' width="10px" height="10px" />
                  <img src='./icons/downarrow.svg' width="10px" height="10px" />
                </div>
                <Card
                  style={{
                    width: 210, minHeight: 134, background: "#ffffff", display: orgDropDown ? "flex" : "none",
                    zIndex: 5, position: "fixed", left: "12px", top: "65px"
                  }} >
                  <CardContent>
                    <div className="org-dropdown" >
                      <div className="org-title">
                        <span className="label-text-II">{email}</span>
                      </div>
                      {
                        orgList && orgList.map((org, index) =>
                          <div className="org-title"
                            key={index}
                            onClick={() => {
                              handleChange(org.organisationName)
                              showOrgDropDown(false);
                            }}>
                            <Avatar className="org-avatar-text">{org.organisationName ? org.organisationName.charAt(0) : ""}</Avatar>
                            <span className="org-title-txt" >{org.organisationName}</span>
                          </div>
                        )
                      }
                      <div className="signout-btn"
                        onClick={() => {
                          handleSignOut();
                          showOrgDropDown(false);
                        }}>
                        <img src='./icons/signout.svg' width="15px" height="15px" />
                        <span className="label-text-II">Sign out all accounts</span>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="left-arrow-nav" onClick={handleDrawer}>
                <ChevronLeftIcon />
              </div>
            </div> : <div className="wrapper-right-arrow">
              <div className="right-arrow" onClick={handleDrawer}>
                <ChevronRightIcon />
              </div>
            </div>
            }

            <List className={classes.listContent} style={{ paddingTop: "30px" }}>
              <div className="list-content-label" id="nav-maps" sytle={{ display: open ? "flex" : "none" }}>{open ? "MAPS" : ""}</div>
              {navTitles.map((val, index) => (
                <ListItem button key={val.title} onClick={() => handleOnSelect(index)} style={{ backgroundColor: navIndex === index ? "#ECECF1" : "" }}>
                  <ListItemIcon style={{ minWidth: "40px" }}>{val.icon}</ListItemIcon>
                  {renderBadge(val.title)}
                </ListItem>
              ))}
            </List>
            <List className={classes.listContent} style={{ paddingTop: "15px" }}>
              <div className="list-content-label" id="nav-test" sytle={{ display: open ? "flex" : "none" }}>{open ? "TEST" : ""}</div>
              {['Sandbox'].map((text) => (
                <ListItem button key={text} onClick={() => onSandboxSelect()}>
                  <ListItemIcon style={{ minWidth: "40px" }}><img src='./icons/sandbox.svg' width="25px" height="25px" style={{ marginLeft: "7px" }} /></ListItemIcon>
                  <ListItemText className="body-text" primary={text} />
                  {sandboxCount < 0 && <Avatar className={classes.countText} variant="square">
                    {sandboxCount}
                  </Avatar>}
                </ListItem>
              ))}
            </List>
          </div>
          {/* <div >
            <List className={classes.listContent}>
              {['Settings',].map((text) => (
                <ListItem button key={text}>
                  <ListItemIcon style={{ minWidth: "40px" }}><img src='./icons/settings.svg' width="25px" height="25px" style={{ marginLeft: "7px" }} /></ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </div> */}
        </div>
      </Drawer>
      <main className={classes.content} >
        <Toolbar style={{ display: "flex", justifyContent: "space-between", paddingRight: "0px" }}>
          <div className="d-flex flex-row justify-content-space-between" style={{ width: "100%" }}>
            <Typography variant="h1" style={{ marginBottom: "10px" }} noWrap className={classes.tableheading} >
              {navTitles[navIndex].title}
            </Typography>
          </div>
          {
            testMode ?
              <div className="d-flex justify-content-start" style={{ gap: "10px", marginLeft: "70px" }} >
                <Link
                  to={{
                    pathname: "/home",
                    state: { mode: "TEST" }
                  }}

                  style={{ textDecoration: "none" }}>
                  <Button
                    className="primary-regular-button"
                    size="medium"
                  >Create Test Map</Button>
                </Link>
              </div> :
              <div className="d-flex justify-content-end" style={{ gap: "10px" }} ref={menuEle}>
                <div className="split-button-container" >
                  <div className="split-btn-group">
                    <img src='./icons/plus.svg' width="10px" height="10px" />
                    {
                      splitBtn === SplitBtns.CREATE_NEW_MAP ?
                        <Link
                          to="/home"
                          style={{ textDecoration: "none" }}>
                          <button
                            className="split-btn text-transform-captial"
                            size="medium"
                          >New Map</button>
                        </Link> :
                        <Link
                          to="/upload"
                          style={{ textDecoration: "none" }}>
                          <button className="split-btn text-transform-captial">
                            Convert Map
                          </button>
                        </Link>
                    }
                    <button className="split-btn-icon" onClick={() => setShowDropDown(true)} >
                      <img src='./icons/downarrow.svg' width="10px" height="10px" />
                    </button>
                  </div>
                  <Card style={{
                    width: 150, height: 80, background: "#ffffff", display: showDropDown ? "flex" : "none",
                    zIndex: 5, top: "80px", position: "fixed", right: "20px"
                  }} >
                    <CardContent>
                      <div className="btn-list" >
                        <div className="btn-text text-transform-captial"
                          onClick={() => {
                            setSplitBtn(SplitBtns.CREATE_NEW_MAP);
                            setShowDropDown(false);
                          }}>Create New Map</div>
                        <div className="btn-text text-transform-captial"
                          onClick={() => {
                            setSplitBtn(SplitBtns.CONVERT_MAP);
                            setShowDropDown(false);
                          }}>Convert Map</div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
          }
        </Toolbar>
        <Typography>
          {loader ? <Dialog
            className={classes.dialog}
            open={true}
            BackdropProps={{
              classes: {
                root: classes.dial_root,
              },
            }}
            PaperProps={{
              classes: {
                root: classes.dial_paper,
              },
            }}
          >
            <CircularProgress
              size={40}
              thickness={5.6}
              style={{ color: "#049FD9" }}
            />
          </Dialog> : null}
          <MapTable
            {...props}
            setDraftCount={setDraftCount}
            setReviewCount={setReviewCount}
            setSandboxCount={setSandboxCount}
            setStarredCount={setStarredCount}
            setLoader={setLoader} />
        </Typography>
      </main>
      {errModal && 
      // <Modal
      //   open={open}
      //   onClose={() => showErrModal(false)}
      //   aria-labelledby="modal-modal-title"
      //   aria-describedby="modal-modal-description"
      // >
      //   <Box className={classes.box}>
      //     <Typography id="modal-modal-title" variant="h6" component="h2" className={classes.tableheading}>
      //       {errModalObj && errModalObj.type ? errModalObj.type : ""}
      //     </Typography>
      //     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      //       {errModalObj && errModalObj.message ? errModalObj.message : ""}
      //     </Typography>
      //   </Box>
      // </Modal>
      <Dialog
          open={true}
          onClose={()=>showErrModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {errModalObj && errModalObj.type ? errModalObj.type : ""}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {errModalObj && errModalObj.message ? errModalObj.message : ""}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      }
    </div>
  );
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardData,
      getUsersByOrgId,
      getDraftData,
      getStarredData,
      getToReviewData,
      setUserInfo,
      showMarkAsApproveBtn,
      getUserOrgInfo,
      getSandboxData
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo.data,
    userOrgData: state.userInfo.userOrgInfo,
    dashBoardError: state.dashboard.dashboardErr
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewDashboard);