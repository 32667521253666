import { SET_SVG_GENERATION_FLAG } from "../actions/svgRegenerationAction";

const initialState ={
    svgRegenerationFlag: "fresh"
}
export function svgGenerationflag(state = initialState, action) {
  switch (action.type) {
    case SET_SVG_GENERATION_FLAG:
      return {
        svgRegenerationFlag: action.data,
      };
    default:
      return state;
  }
}
