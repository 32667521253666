import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 180,
    height: "35px",
    border: "1px solid #E8EBF1"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
   
  },
  iconButton: {
    padding: 10,
  },
}));

export default function SearchField() {
    const classes = useStyles();
  
    return (
      <Paper component="form" className={classes.root} elevation={0}>
        <InputBase
          className={classes.input}
          placeholder="Search..."
          inputProps={{ 'aria-label': 'search data maps' }}
        />
      </Paper>
    );
  }