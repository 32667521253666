
import { GET_DASHBOARD_DATA, GET_DATAMAP_DATA, GET_SVG_FILE, GET_REVIEW_DETAILS , COMMENT_DETAILS, DASHBOARD_ERROR} from "../actions/constant";

const initialState = {};

export function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                dashboardData: action.data
            };
        case GET_DATAMAP_DATA:
            return {
                ...state,
                datamapData: action.data
            }
        case GET_SVG_FILE:
            return {
                ...state,
                filePath: action.data
            }
        case GET_REVIEW_DETAILS:
            return {
                ...state,
                reviewDetails: action.data
            }
        case COMMENT_DETAILS:
            return {
                ...state,
                commentData: action.data
            }
        case DASHBOARD_ERROR: {
            return {
                ...state,
                dashboardErr: action.data
            }
        }
        default:
            return state;
    }
}