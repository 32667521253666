import React, { Component } from "react";

class YesCallout extends Component {
  render() {
    return (
      <svg
        width="106px"
        height="106px"
        viewBox="0 0 106 106"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>yes callout icon</title>
        <desc>Created with Sketch.</desc>
        <g
          id="yes-callout-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect
            id="Rectangle"
            fill="#E8EBF1"
            x="0"
            y="0"
            width="106"
            height="106"
          />
          <path
            d="M75,0 C75,0.859688541 75,1.91085941 75,3.15351262 C75,5.01749243 73.601722,6.51731656 72.3840179,7.03048312 C70.702026,7.73931051 66.4395595,9.4915398 58.0177664,13.0406636 C57.0731897,13.4387284 55.1705823,15.0990153 55.0568608,18.0068967 C54.9832964,19.8879513 54.9811132,42.1464149 55.0503111,84.7822876 C55.0524275,86.0863272 55.0503111,88.528892 57.7390389,89.8294003 C59.3275202,90.5977314 62.0753039,91.4785749 69.8685721,95.5389054 C72.8321023,97.0829189 75,99.1773321 75,101.9864 C75,103.859112 75,104.863646 75,105"
            id="Line-7"
            stroke="#E03C2D"
            strokeWidth="7"
          />
          <line
            x1="91.5"
            y1="-0.329113924"
            x2="91.5"
            y2="104.329114"
            id="Line-7"
            stroke="#4EB3DF"
            strokeWidth="7"
          />
          <line
            x1="82.5"
            y1="-0.329113924"
            x2="82.5"
            y2="104.329114"
            id="Line-7"
            stroke="#FFC435"
            strokeWidth="7"
          />
          <path
            d="M19,30 L65,30 C67.7614237,30 70,32.2385763 70,35 L70,68 C70,70.7614237 67.7614237,73 65,73 L19,73 C16.2385763,73 14,70.7614237 14,68 L14,35 C14,32.2385763 16.2385763,30 19,30 Z"
            id="Rectangle"
            stroke="#1747A6"
            fillOpacity="0.7"
            fill="#FFFFFF"
            strokeDasharray="5"
          />
        </g>
      </svg>
    );
  }
}
export default YesCallout;
