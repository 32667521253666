export const dataCentersList = [
  {
    name: "Allen",
    city: "Allen",
    latitude: 33.1087,
    longitude: -96.6735,
    type: "city",
    code: ""
  },
  {
    name: "Amsterdam",
    city: "Amsterdam",
    latitude: 52.35,
    longitude: 4.9166,
    type: "city",
    code: ""
  },
  {
    name: "Ashburn",
    city: "Ashburn",
    latitude: 39.04,
    longitude: -77.485,
    type: "city",
    code: ""
  },
  {
    name: "Atlanta",
    city: "Atlanta",
    latitude: 33.7627,
    longitude: -84.4231,
    type: "city",
    code: ""
  },
  {
    name: "Austin",
    city: "Austin",
    latitude: 30.3038,
    longitude: -97.7545,
    type: "city",
    code: ""
  },
  {
    name: "Bangalore",
    city: "Bangalore",
    latitude: 12.97,
    longitude: 77.56,
    type: "city",
    code: ""
  },
  {
    name: "Berlin",
    city: "Berlin",
    latitude: 52.5218,
    longitude: 13.4015,
    type: "city",
    code: ""
  },
  {
    name: "Brussels",
    city: "Brussels",
    latitude: 50.8333,
    longitude: 4.3333,
    type: "city",
    code: ""
  },
  {
    name: "Bucharest",
    city: "Bucharest",
    latitude: 44.4334,
    longitude: 26.0999,
    type: "city",
    code: ""
  },
  {
    name: "Belfast",
    city: "Belfast",
    latitude: 54.6,
    longitude: -5.96,
    type: "city",
    code: ""
  },
  {
    name: "Carrollton",
    city: "Carrollton",
    latitude: 32.9885,
    longitude: -96.9001,
    type: "city",
    code: ""
  },
  {
    name: "Calgary",
    city: "Calgary",
    latitude: 51.083,
    longitude: -114.08,
    type: "city",
    code: ""
  },
  {
    name: "Chicago",
    city: "Chicago",
    latitude: 41.8373,
    longitude: -87.6861,
    type: "city",
    code: ""
  },
  {
    name: "Chennai",
    city: "Chennai",
    latitude: 13.09,
    longitude: 80.28,
    type: "city",
    code: ""
  },
  {
    name: "Chengdu",
    city: "Chengdu",
    latitude: 30.67,
    longitude: 104.07,
    type: "city",
    code: ""
  },
  {
    name: "Delhi",
    city: "Delhi",
    latitude: 28.67,
    longitude: 77.23,
    type: "city",
    code: ""
  },
  {
    name: "Dubai",
    city: "Dubai",
    latitude: 25.23,
    longitude: 55.28,
    type: "city",
    code: ""
  },
  {
    name: "Dublin",
    city: "Dublin",
    latitude: 53.3331,
    longitude: -6.2489,
    type: "city",
    code: ""
  },
  {
    name: "Dallas",
    city: "Dallas",
    latitude: 32.7938,
    longitude: -96.7659,
    type: "city",
    code: ""
  },
  {
    name: "Frankfurt",
    city: "Frankfurt",
    latitude: 50.1,
    longitude: 8.675,
    type: "city",
    code: ""
  },
  {
    name: "Holliston",
    city: "Holliston",
    latitude: 42.2,
    longitude: -71.424,
    type: "city",
    code: ""
  },
  {
    name: "Islamabad",
    city: "Islamabad",
    latitude: 33.7,
    longitude: 73.1666,
    type: "city",
    code: ""
  },
  {
    name: "Irvine",
    city: "Irvine",
    latitude: 33.6772,
    longitude: -117.7738,
    type: "city",
    code: ""
  },
  {
    name: "Johannesburg",
    city: "Johannesburg",
    latitude: -26.17,
    longitude: 28.03,
    type: "city",
    code: ""
  },
  {
    name: "Krakow",
    city: "Krakow",
    latitude: 50.06,
    longitude: 19.96,
    type: "city",
    code: ""
  },
  {
    name: "Los Angeles",
    city: "Los Angeles",
    latitude: 34.114,
    longitude: -118.4068,
    type: "city",
    code: ""
  },
  {
    name: "London",
    city: "London",
    latitude: 51.5,
    longitude: -0.1167,
    type: "city",
    code: ""
  },
  {
    name: "Miami",
    city: "Miami",
    latitude: 25.784,
    longitude: -80.2102,
    type: "city",
    code: ""
  },
  {
    name: "Milan",
    city: "Milan",
    latitude: 45.47,
    longitude: 9.205,
    type: "city",
    code: ""
  },
  {
    name: "Melbourne",
    city: "Melbourne",
    latitude: -37.82,
    longitude: 144.975,
    type: "city",
    code: ""
  },
  {
    name: "Mountain View",
    city: "Mountain View",
    latitude: 37.4001,
    longitude: -122.0796,
    type: "city",
    code: ""
  },
  {
    name: "Mumbai",
    city: "Mumbai",
    latitude: 19.017,
    longitude: 72.857,
    type: "city",
    code: ""
  },
  {
    name: "New York",
    city: "New York",
    latitude: 40.6943,
    longitude: -73.9249,
    type: "city",
    code: ""
  },
  {
    name: "Noida",
    city: "Noida",
    latitude: 28.5355,
    longitude: 77.391,
    type: "city",
    code: ""
  },
  {
    name: "Palo Alto",
    city: "Palo Alto",
    latitude: 37.3917,
    longitude: -122.1465,
    type: "city",
    code: ""
  },
  {
    name: "Paris",
    city: "Paris",
    latitude: 48.8667,
    longitude: 2.3333,
    type: "city",
    code: ""
  },
  {
    name: "Prague",
    city: "Prague",
    latitude: 50.0833,
    longitude: 14.466,
    type: "city",
    code: ""
  },
  {
    name: "Pune",
    city: "Pune",
    latitude: 18.53,
    longitude: 73.85,
    type: "city",
    code: ""
  },
  {
    name: "Reston",
    city: "Reston",
    latitude: 38.96872,
    longitude: -77.3411,
    type: "city",
    code: ""
  },
  {
    name: "Raleigh",
    city: "Raleigh",
    latitude: 35.8323,
    longitude: -78.6441,
    type: "city",
    code: ""
  },
  {
    name: "Research Triangle Park",
    city: "Research Triangle Park",
    latitude: 35.9048,
    longitude: -78.8576,
    type: "city",
    code: ""
  },
  {
    name: "Richardson",
    city: "Richardson",
    latitude: 32.9717,
    longitude: -96.7092,
    type: "city",
    code: ""
  },
  {
    name: "Santa Clara",
    city: "Santa Clara",
    latitude: 37.3646,
    longitude: -121.9679,
    type: "city",
    code: ""
  },
  {
    name: "Sunriver",
    city: "Sunriver",
    latitude: -121.4386,
    longitude: 43.884,
    type: "city",
    code: ""
  },
  {
    name: "San Francisco",
    city: "San Francisco",
    latitude: 37.7561,
    longitude: -122.4429,
    type: "city",
    code: ""
  },
  {
    name: "San Mateo",
    city: "San Mateo",
    latitude: 37.5522,
    longitude: -122.3122,
    type: "city",
    code: ""
  },
  {
    name: "San Jose, CA",
    city: "San Jose, CA",
    latitude: 37.302,
    longitude: -121.8488,
    type: "city",
    code: ""
  },
  {
    name: "San Jose, Costa Rica",
    city: "San Jose, Costa Rica",
    latitude: 9.9347,
    longitude: -84.0875,
    type: "city",
    code: ""
  },
  {
    name: "Sao Paulo",
    city: "Sao Paulo",
    latitude: -23.5587,
    longitude: -46.625,
    type: "city",
    code: ""
  },
  {
    name: "Seattle",
    city: "Seattle",
    latitude: 47.6217,
    longitude: -122.3238,
    type: "city",
    code: ""
  },
  {
    name: "Singapore",
    city: "Singapore",
    latitude: 1.293,
    longitude: 103.8558,
    type: "city",
    code: ""
  },
  {
    name: "Sunnyvale",
    city: "Sunnyvale",
    latitude: 37.3846,
    longitude: -122.0261,
    type: "city",
    code: ""
  },
  {
    name: "Sydney",
    city: "Sydney",
    latitude: -33.92,
    longitude: 151.1852,
    type: "city",
    code: ""
  },
  {
    name: "Toronto",
    city: "Toronto",
    latitude: 43.7,
    longitude: -79.42,
    type: "city",
    code: ""
  },
  {
    name: "Tokyo",
    city: "Tokyo",
    latitude: 35.685,
    longitude: 139.7514,
    type: "city",
    code: ""
  },
  {
    name: "Uberlandia",
    city: "Uberlandia",
    latitude: -18.9,
    longitude: -48.28,
    type: "city",
    code: ""
  },
  {
    name: "Vancouver",
    city: "Vancouver",
    latitude: 49.2734,
    longitude: -123.1216,
    type: "city",
    code: ""
  },
  {
    name: "Washington DC",
    city: "Washington DC",
    latitude: 38.9047,
    longitude: -77.0163,
    type: "city",
    code: ""
  },
  {
    name: "Warsaw",
    city: "Warsaw",
    latitude: 52.25,
    longitude: 21.0,
    type: "city",
    code: ""
  },
  {
    name: "Alabama",
    city: "Montgomery",
    latitude: 32.361538,
    longitude: -86.279118,
    type: "state",
    code: ""
  },
  {
    name: "Alaska",
    city: "Juneau",
    latitude: 58.301935,
    longitude: -134.41974,
    type: "state",
    code: ""
  },
  {
    name: "Arizona",
    city: "Phoenix",
    latitude: 33.448457,
    longitude: -112.073844,
    type: "state",
    code: ""
  },
  {
    name: "Arkansas",
    city: "Little Rock",
    latitude: 34.736009,
    longitude: -92.331122,
    type: "state",
    code: ""
  },
  {
    name: "California",
    city: "Sacramento",
    latitude: 38.555605,
    longitude: -121.468926,
    type: "state",
    code: ""
  },
  {
    name: "Colorado",
    city: "Denver",
    latitude: 39.7391667,
    longitude: -104.984167,
    type: "state",
    code: ""
  },
  {
    name: "Connecticut",
    city: "Hartford",
    latitude: 41.767,
    longitude: -72.677,
    type: "state",
    code: ""
  },
  {
    name: "Delaware",
    city: "Dover",
    latitude: 39.161921,
    longitude: -75.526755,
    type: "state",
    code: ""
  },
  {
    name: "Florida",
    city: "Tallahassee",
    latitude: 30.4518,
    longitude: -84.27277,
    type: "state",
    code: ""
  },
  {
    name: "Georgia",
    city: "Atlanta",
    latitude: 33.76,
    longitude: -84.39,
    type: "state",
    code: ""
  },
  {
    name: "Hawaii",
    city: "Honolulu",
    latitude: 21.30895,
    longitude: -157.826182,
    type: "state",
    code: ""
  },
  {
    name: "Idaho",
    city: "Boise",
    latitude: 43.613739,
    longitude: -116.237651,
    type: "state",
    code: ""
  },
  {
    name: "Illinois",
    city: "Springfield",
    latitude: 39.78325,
    longitude: -89.650373,
    type: "state",
    code: ""
  },
  {
    name: "Indiana",
    city: "Indianapolis",
    latitude: 39.790942,
    longitude: -86.147685,
    type: "state",
    code: ""
  },
  {
    name: "Iowa",
    city: "Des Moines",
    latitude: 41.590939,
    longitude: -93.620866,
    type: "state",
    code: ""
  },
  {
    name: "Kansas",
    city: "Topeka",
    latitude: 39.04,
    longitude: -95.69,
    type: "state",
    code: ""
  },
  {
    name: "Kentucky",
    city: "Frankfort",
    latitude: 38.197274,
    longitude: -84.86311,
    type: "state",
    code: ""
  },
  {
    name: "Louisiana",
    city: "Baton Rouge",
    latitude: 30.45809,
    longitude: -91.140229,
    type: "state",
    code: ""
  },
  {
    name: "Maine",
    city: "Augusta",
    latitude: 44.323535,
    longitude: -69.765261,
    type: "state",
    code: ""
  },
  {
    name: "Maryland",
    city: "Annapolis",
    latitude: 38.972945,
    longitude: -76.501157,
    type: "state",
    code: ""
  },
  {
    name: "Massachusetts",
    city: "Boston",
    latitude: 42.2352,
    longitude: -71.0275,
    type: "state",
    code: ""
  },
  {
    name: "Michigan",
    city: "Lansing",
    latitude: 42.7335,
    longitude: -84.5467,
    type: "state",
    code: ""
  },
  {
    name: "Minnesota",
    city: "Saint Paul",
    latitude: 44.95,
    longitude: -93.094,
    type: "state",
    code: ""
  },
  {
    name: "Mississippi",
    city: "Jackson",
    latitude: 32.32,
    longitude: -90.207,
    type: "state",
    code: ""
  },
  {
    name: "Missouri",
    city: "Jefferson City",
    latitude: 38.572954,
    longitude: -92.189283,
    type: "state",
    code: ""
  },
  {
    name: "Montana",
    city: "Helana",
    latitude: 46.595805,
    longitude: -112.027031,
    type: "state",
    code: ""
  },
  {
    name: "Nebraska",
    city: "Lincoln",
    latitude: 40.809868,
    longitude: -96.675345,
    type: "state",
    code: ""
  },
  {
    name: "Nevada",
    city: "Carson City",
    latitude: 39.160949,
    longitude: -119.753877,
    type: "state",
    code: ""
  },
  {
    name: "New Hampshire",
    city: "Concord",
    latitude: 43.220093,
    longitude: -71.549127,
    type: "state",
    code: ""
  },
  {
    name: "New Jersey",
    city: "Trenton",
    latitude: 40.221741,
    longitude: -74.756138,
    type: "state",
    code: ""
  },
  {
    name: "New Mexico",
    city: "Santa Fe",
    latitude: 35.667231,
    longitude: -105.964575,
    type: "state",
    code: ""
  },
  {
    name: "New York",
    city: "Albany",
    latitude: 42.659829,
    longitude: -73.781339,
    type: "state",
    code: ""
  },
  {
    name: "North Carolina",
    city: "Raleigh",
    latitude: 35.771,
    longitude: -78.638,
    type: "state",
    code: ""
  },
  {
    name: "North Dakota",
    city: "Bismarck",
    latitude: 48.813343,
    longitude: -100.779004,
    type: "state",
    code: ""
  },
  {
    name: "Ohio",
    city: "Columbus",
    latitude: 39.962245,
    longitude: -83.000647,
    type: "state",
    code: ""
  },
  {
    name: "Oklahoma",
    city: "Oklahoma City",
    latitude: 35.482309,
    longitude: -97.534994,
    type: "state",
    code: ""
  },
  {
    name: "Oregon",
    city: "Salem",
    latitude: 44.931109,
    longitude: -123.029159,
    type: "state",
    code: ""
  },
  {
    name: "Pennsylvania",
    city: "Harrisburg",
    latitude: 40.269789,
    longitude: -76.875613,
    type: "state",
    code: ""
  },
  {
    name: "Rhode Island",
    city: "Providence",
    latitude: 41.82355,
    longitude: -71.422132,
    type: "state",
    code: ""
  },
  {
    name: "South Carolina",
    city: "Columbia",
    latitude: 34.0,
    longitude: -81.035,
    type: "state",
    code: ""
  },
  {
    name: "Barranquilla",
    city: "Columbia",
    latitude: 11.0041,
    longitude: 74.8070,
    type: "city",
    code: ""
  },
  
  {
    name: "South Dakota",
    city: "Pierre",
    latitude: 44.367966,
    longitude: -100.336378,
    type: "state"
  },
  {
    name: "Tennessee",
    city: "Nashville",
    latitude: 36.165,
    longitude: -86.784,
    type: "state",
    code: ""
  },
  {
    name: "Texas",
    city: "Austin",
    latitude: 30.266667,
    longitude: -97.75,
    type: "state",
    code: ""
  },
  {
    name: "Utah",
    city: "Salt Lake City",
    latitude: 40.7547,
    longitude: -111.892622,
    type: "state",
    code: ""
  },
  {
    name: "Vermont",
    city: "Montpelier",
    latitude: 44.26639,
    longitude: -72.57194,
    type: "state",
    code: ""
  },
  {
    name: "Virginia",
    city: "Richmond",
    latitude: 37.54,
    longitude: -77.46,
    type: "state",
    code: ""
  },
  {
    name: "Washington",
    city: "Olympia",
    latitude: 47.042418,
    longitude: -122.893077,
    type: "state",
    code: ""
  },
  {
    name: "West Virginia",
    city: "Charleston",
    latitude: 38.349497,
    longitude: -81.633294,
    type: "state",
    code: ""
  },
  {
    name: "Wisconsin",
    city: "Madison",
    latitude: 43.074722,
    longitude: -89.384444,
    type: "state",
    code: ""
  },
  {
    name: "Wyoming",
    city: "Cheyenne",
    latitude: 41.145548,
    longitude: -104.802042,
    type: "state",
    code: ""
  },
  {
    name: "Australia",
    city: "Canberra",
    latitude: -35.26666667,
    longitude: 149.133333,
    type: "country",
    code: "AUS"
  },
  {
    name: "Bulgaria",
    city: "Bulgaria",
    latitude: 42.7339,
    longitude: 25.4858,
    type: "country",
    code: "BGR"
  },
  {
    name: "Sofia",
    city: "Sofia",
    latitude: 42.6977,
    longitude: 23.321,
    type: "city",
    code: ""
  },
  {
    name: "Canada",
    city: "Ottawa",
    latitude: 45.41666667,
    longitude: -75.7,
    type: "country",
    code: "CAN"
  },
  {
    name: "China",
    city: "Beijing",
    latitude: 39.91666667,
    longitude: 116.383333,
    type: "country",
    code: "CHN"
  },
  {
    name: "Colombia",
    city: "Bogota",
    latitude: 4.6,
    longitude: -74.083333,
    type: "country",
    code: "COL"
  },
  {
    name: "Costa Rica",
    city: "San Jose",
    latitude: 9.933333,
    longitude: -84.08333,
    type: "country",
    code: "CRI"
  },
  {
    name: "Czech Republic",
    city: "Prague",
    latitude: 50.08333333,
    longitude: 14.466667,
    type: "country",
    code: "CZE"
  },
  {
    name: "Germany",
    city: "Berlin",
    latitude: 52.51666667,
    longitude: 13.4,
    type: "country",
    code: "DEU"
  },
  {
    name: "Hong Kong",
    city: "Hong Kong",
    latitude: 22.286394,
    longitude: 114.149139,
    type: "country",
    code: "HKG"
  },
  {
    name: "India",
    city: "New Delhi",
    latitude: 28.6,
    longitude: 77.2,
    type: "country",
    code: "IND"
  },
  {
    name: "Jordan",
    city: "Jordan",
    latitude: 30.5852,
    longitude: 36.2384,
    type: "country",
    code: "JOR"
  },
  {
    name: "Amman",
    city: "Amman",
    latitude: 31.95,
    longitude: 35.933333,
    type: "city",
    code: ""
  },
  {
    name: "Netherlands",
    city: "Amsterdam",
    latitude: 52.35,
    longitude: 4.916667,
    type: "country",
    code: "NLD"
  },
  {
    name: "United Kingdom",
    city: "London",
    latitude: 51.5,
    longitude: -0.083333,
    type: "country",
    code: "GBR"
  },
  {
    name: "USA",
    city: "Washington D.C.",
    latitude: 38.883333,
    longitude: -77,
    type: "country",
    code: "USA"
  },
  {
    name: "Quebec",
    city: "Quebec",
    latitude: 46.829853,
    longitude: -71.254028,
    type: "city",
    code: ""
  },
  {
    name: "Osaka",
    city: "Osaka",
    latitude: 34.669529,
    longitude: 135.497009,
    type: "city",
    code: ""
  },
  {
    name: "Madrid",
    city: "Madrid",
    latitude: 40.4168,
    longitude: 3.7038,
    type: "city",
    code: ""
  },
  {
    name: "Calgary",
    city: "Calgary",
    latitude: 51.0447,
    longitude: 114.0719,
    type: "city",
    code: ""
  },
  {
    name: "Montreal",
    city: "Montreal",
    latitude: 45.5017,
    longitude: 73.5673,
    type: "city",
    code: ""
  },
  {
    name: "Gurgaon",
    city: "Gurgaon",
    latitude: 28.4595,
    longitude: 77.0266,
    type: "city",
    code: ""
  },
  {
    name: "St Ghislan",
    city: "Belgium",
    latitude: 50.4491,
    longitude: 3.8184,
    type: "city",
    code: ""
  },
];

export const latLngLookup = () => {
  const lookupHash = {};
  dataCentersList.map(location => {
    lookupHash[location.name] = {
      city: location.city,
      latitude: location.latitude,
      longitude: location.longitude,
      type: location.type,
      code: location.code
    };
  });
  return lookupHash;
};
