// @flow

import * as R from "ramda";
import {SAVE_FORM_PROGRESS , CLEAR_FORM_PROGRESS, SAVE_ON_CLOSE_APP} from "../actions/saveprogressAction"


export function formSnapshot(state: State = {}, action): State {
  const { type, data } = action;
  switch (type) {
    case SAVE_FORM_PROGRESS:
      return R.assoc("latest", data, state);
    case CLEAR_FORM_PROGRESS:
      return {};
    case SAVE_ON_CLOSE_APP:
      return {};
    default:
      return state;
  }
}
