
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    AppBar, Button, Paper, CardContent, Card, Chip, Dialog, CircularProgress,
    DialogTitle, DialogActions, Tooltip, IconButton, Avatar
} from '@material-ui/core';
import { Link } from "react-router-dom";
import "./index.scss";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSVGFileById, setSVGFilePath } from "../../actions/svgRegenerationAction";
import { addCommentsForMap, getCommentsForMap, updateApproved } from "../../actions/apiActions";
import { toggleIsCommented, showMarkAsApproveBtn } from "../../actions/metaDataAction";
import { getConvertDate } from "../utils/utils";
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import { MapStatus, ImgSliderValues, SlidingAction } from '../../constant';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(() => ({
    statusBtn: {
        backgroundColor: "#049fd9",
        width: "max-content",
        height: "23px",
        fontSize: "10px",
        fontWeight: "600",
        fontFamily: "SFProText",
        color: "#ffffff",
        textTransform: "capitalize",
    },
    root: {
        flexGrow: 1,
        
    },
    card: {
        '& .MuiAvatar-root':{
            color: "#000000",
            fontSize: "8px",
            fontWeight: "400",
            fontFamily: "SFProTextRegular !important",
            lineHeight: "7.62px",
        }
    },
    appBar: {
        position: 'relative',
        backgroundColor: "#ffffff",

    },
    toolbar: {
        flexGrow: 1,
        backgroundColor: "#ffffff"
    },
    title: {
        flexGrow: 1,
        fontFamily: "SFProDisplaySemibold !important",
        fontSize: "24px",
        textTransform: "capitalize",
        paddingLeft: "20px",
        color: "#39393B"
    },
    h2: {
        fontFamily: "SFProDisplaySemibold !important",
        fontSize: "18px",
        textTransform: "capitalize",
        color: "#39393B"
    },
    chip: {
        fontFamily: "SFProTextRegular !important",
        fontSize: "10px",
        textTransform: "capitalize",
        color: "#000000",
        height: "14px",
        backgroundColor: "#CDB9EA",
    },
    dialog: {
        "& .MuiDialog-scrollPaper": {
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "30%"
        }
    },
    dial_root: {
        backgroundColor: "transparent",

    },
    dial_paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    statusChip: {
        marginRight: "25px",
        backgroundColor: "#9FD6BA",
        textTransform: "capitalize",
    },
    btn: {
        color: "#4C42BF",
        height: "30px",
        width: "max-content",
        fontSize: "10px",
        fontFamily: "SFProTextRegular !important",
        border: "1px solid #4C42BF",
    },
    iconBtn: {
        color: "#4C42BF",
        height: "30px",
        width: "30px",
        borderRadius: "3px",
        '& .MuiSvgIcon-root': {
            width: "15px",
        }

    }
}));

const SVGView = (props) => {
    const { getSVGFileById, file, showApproveBtn, updateApproved, setSVGFilePath } = props;
    const classes = useStyles();
    const [datamapId, setDatamapId] = useState("");
    const [comment, setComment] = useState("");
    const [rows, setRows] = useState([]);
    const [, setCurrentEditor] = useState("");
    const [reviewers, setReviewers] = useState([])
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showErrImg, setErrImg] = useState(false);
    const [ mapTitle, setMapTitle] = useState("");
    const [imgWidth, setImgWidth] = useState(1200);
    const [imgHeight, setImgHeight] = useState(550);
    const [ svgUrl, setSvgUrl] = useState('')
    const slidingStep = 50;

    const { addCommentsForMap,
        commentData,
        isCommented,
        getCommentsForMap,
        toggleIsCommented,
        showMarkAsApproveBtn,
        markApproved } = props;

    useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            showMarkAsApproveBtn(false);
            setErrImg(false);
            let img = document.querySelector("#svgImg");
            img.src = null;
            setSVGFilePath('');
            setReviewers([]);
        }
    }, [])

    useEffect(() => {
        if (commentData) {
            if (commentData.reviewHistory) {
                setRows(commentData.reviewHistory)
            }
            if (commentData.ownedBy) {
                setCurrentEditor(commentData.ownedBy);
            }
            if (commentData.reviewers && commentData.reviewers.length > 0) {
                setReviewers(commentData.reviewers)
            }
            else setReviewers([])
        }
    }, [commentData])

    useEffect(() => {
        if (datamapId) {
            getCommentsForMap(datamapId);
            toggleIsCommented(false)
        }

    }, [isCommented])

    useEffect(() => {
        if (props.location && props.location.state) {
            setDatamapId(props.location.state.mapId);
            setMapTitle(props.location.state.mapName);
            getCommentsForMap(props.location.state.mapId);
            if (props.location.state.mapStatus === MapStatus.APPROVED) {
                setShowStatus(true);
            }
        }
        getSVGFileById(props.location.state.mapId);
    }, [])


    useEffect(() => {
        if (file === "NO SVG FILE") {
            setLoading(false);
            setErrImg(true);
        }
        else if (file) {
            fetch(file, {
                method: "GET",
                headers: {}
            }).then(res => {
                if (res && res.status === 200) {
                    res.arrayBuffer().then(function (buffer) {
                        //const url = window.URL.createObjectURL(new Blob([buffer], { type: "image/jpeg"}));
                        var arrayBufferView = new Uint8Array(buffer);
                        var blob = new Blob([arrayBufferView], { type: 'image/svg+xml' });
                        var urlCreator = window.URL || window.webkitURL;
                        var imageUrl = urlCreator.createObjectURL(blob);
                        var img = document.querySelector("#svgImg");
                        if (imageUrl && img) {
                            img.src = imageUrl;
                            img.style.display = "flex";
                            setSvgUrl(imageUrl);
                            //setLoading(false);
                        }
                        //link.click();
                    });
                }
                else {
                    setLoading(false);
                    setErrImg(true);
                }
            }).catch(err => {
                console.log(err);
                setLoading(false);
                setErrImg(true);
            });
        }
    }, [file]);


    const changeSize = (type) => {
        if (type === SlidingAction.INC && imgWidth !== ImgSliderValues.maxwidth) {
            const height = imgHeight + slidingStep;
            const width = imgWidth + slidingStep;
            setImgHeight(height)
            setImgWidth(width);
        }
        else if (type === SlidingAction.DEC && imgWidth !== ImgSliderValues.minWidth) {
            const height = imgHeight - slidingStep;
            const width = imgWidth - slidingStep;
            setImgHeight(height)
            setImgWidth(width);
        }

    }


    const handleSubmit = () => {
        let data = {
            "reviewedDate": new Date(),
            "comments": comment,
            "reviewer": window.USER_INFO ? window.USER_INFO.email : "",
        }
        addCommentsForMap(data, datamapId);
        setComment("");
    }

    const handleOnDownload = async () => {
        await fetch(file, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${datamapId}.svg`);
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    const markAsApproved = () => {
        const emailId = window.USER_INFO ? window.USER_INFO.email : ""
        const payload =
        {
            "modifiedDate": new Date(),
            "modifiedBy": emailId,
            "message": "approved"
        }
        updateApproved(payload, emailId, datamapId);
    }

    return (
        <div className={classes.root}>
            <div style={{ display: "flex", flexDirection: "row", }}>
                <Toolbar className={classes.toolbar}>
                    <Link
                        to="/"
                        style={{ textDecoration: "none", color: "#000000" }}>
                        <div className="pl-10">
                            <ArrowBackIosIcon />
                        </div>
                    </Link>
                    <Typography variant="h1" className={classes.title}>
                        {mapTitle}
                    </Typography>
                    {
                        showApproveBtn && <>{
                            (markApproved || showStatus) ? <Chip size="medium" label={MapStatus.APPROVED} className={classes.statusChip} /> :
                                <Button className="primary-regular-button" elevation={0} onClick={() => setOpenDialog(true)} >
                                    Mark as &quot;Approved&quot;
                                </Button>
                        }
                        </>
                    }
                </Toolbar>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
                <Paper style={{ width: "70%", margin: "10px", }} elevation={0} >
                    <AppBar className={classes.appBar} elevation={0} >
                        {loading ?
                            <Dialog
                                className={classes.dialog}
                                open={true}
                                BackdropProps={{
                                    classes: {
                                        root: classes.dial_root,
                                    },
                                }}
                                PaperProps={{
                                    classes: {
                                        root: classes.dial_paper,
                                    },
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    thickness={5.6}
                                    style={{ color: "#049FD9" }}
                                />
                            </Dialog> : null
                        }
                        {/* {
                            showErrImg && <div style={{
                                display: "grid", justifyContent: "center", marginTop: "15%",
                                marginLeft: "25%"
                            }} ><img id="errImg" src="./images/broken_2.png" height="200px" width="50%" /></div>
                        } */}
                        <div className="image-box" style={{ alignItems: showErrImg ? "flex-start" : "center" }}>
                            {
                                showErrImg && <div className="error-details">
                                    <span className="error-title">
                                        This map hasn’t been generated yet!
                                    </span>
                                    <span className="error-content">
                                        The editor must validate and generate the data map before a preview can be displayed.
                                    </span>
                                </div>
                            }
                            <img className="svgImg" id="svgImg" style={{ display: "none" }} onError={i => i.target.style.display = 'none'} alt="no Svg" height={imgHeight} width={ "100%"} onLoad={() => { setErrImg(false); setLoading(false) }} />
                        </div>
                    </AppBar>
                </Paper>
                <Card className={classes.card} style={{ background: "#ffffff", margin: "10px", width: "25%" }} elevation={0}>
                    <CardContent className={classes.cardView}>
                        <div className="card-blocks">
                            <span className="label-text">Editor</span>
                            <div className="avatar-editor">
                                <Avatar className="avatar-text" style={{ width: "22px", height: "22px", backgroundColor: "#DCEC2B" }} >KA</Avatar>
                                <div className="body-text word-break">Karthk N </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px", width: "100%" }}>
                            <span className="label-text" >Reviewers</span>
                            <div className="reviewers-block">{
                                reviewers.length > 0 ?
                                    reviewers.map((val, i) => {
                                        return (
                                            <div key={i} className="avatar-editor">
                                                <Avatar className="label-text" style={{ width: "22px", height: "22px", backgroundColor: "#DCEC2B" }} >KA</Avatar>
                                                <div className="body-text word-break">{`${val.firstName} ${val.lastName}`}</div>
                                            </div>
                                        )
                                    }) : <span className="body-text">No Reviewers</span>
                            }</div>

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                            <span className={classes.h2}>Discussion</span>
                        </div>
                        <div className="buttonIn">
                            <textarea placeholder="Add a comment" className="cmt-input" type="text" id="enter" value={comment} onChange={(e) => setComment(e.target.value)} />
                            <button id="enter" type="submit" className="input-button" onClick={() => handleSubmit()}><SendTwoToneIcon style={{ color: "#4C42BF", width: "20px" }} /></button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "12px" }}>
                            {rows && rows.map((row, i) => {
                                return (
                                    <div key={i} style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "baseline", marginTop: "10px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                            <span className="body-text">{`${row.firstName ? row.firstName : ""} ${row.lastName ? row.lastName : ""}`} </span>
                                            <span style={{ fontSize: "10px", fontWeight: 600, fontFamily: "SFProTextRegular", color: "#7E7E7E" }}>{getConvertDate(row.reviewedDate)}</span>
                                        </div>
                                        <span className="body-text">{row.comments}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </CardContent>
                </Card>
                {/* <Paper>
                </Paper> */}
            </div>
            {
                <div className="image-toolbar">
                    <div className="tool-buttons">
                        <Tooltip title="Download">
                            <Button
                                disabled={loading || showErrImg}
                                className={classes.btn}
                                size="small"
                                variant="outlined"
                                startIcon={
                                    !showErrImg ?
                                        <img src='./icons/download.svg' width="12px" height="12px" /> :
                                        <img src='./icons/disableDownload.svg' width="12px" height="12px" />
                                }
                                onClick={() => handleOnDownload()}>
                                Download
                            </Button>
                        </Tooltip>
                        <Tooltip title="New Tab">
                            <Button
                                disabled={loading || showErrImg}
                                className={classes.btn}
                                onClick={() =>
                                    window.open(svgUrl, "_blank")
                                }
                                size="small"
                                variant="outlined"
                                startIcon={
                                    !showErrImg ?
                                        <img src='./icons/pictureIn.svg' width="12px" height="12px" color="inherit" /> :
                                        <img src='./icons/disablePictureIn.svg' width="12px" height="12px" color="inherit" />
                                }
                            >
                                Open in New Tab
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="tool-buttons">
                        <IconButton
                            disabled={loading || showErrImg}
                            value={""}
                            className={classes.iconBtn}
                            style={{ border: showErrImg ? "1px solid #9E9EA2" : "1px solid #4C42BF" }}
                            onClick={() => changeSize(SlidingAction.DEC)}
                        >
                            <RemoveIcon width={15} />
                        </IconButton>
                        <IconButton
                            disabled={loading || showErrImg}
                            value={""}
                            className={classes.iconBtn}
                            style={{ border: showErrImg ? "1px solid #9E9EA2" : "1px solid #4C42BF" }}
                            onClick={() => changeSize(SlidingAction.INC)}
                        >
                            <AddIcon width={15} />
                        </IconButton>
                    </div>
                </div>
            }
            <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are You Sure To Approve The Map ?
                </DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your data will be lost if you don&apos;t save them.
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-evenly",
                        }}
                    >
                        <Button
                            className="secondary-button"
                            onClick={() => {
                                markAsApproved();
                                setOpenDialog(false);
                            }}
                            color="primary"
                        >
                            Approve
                        </Button>
                        <Button
                            className="secondary-button"
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="primary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                        {/* <SaveProgressSection buttonType="button" /> */}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSVGFileById,
            addCommentsForMap,
            getCommentsForMap,
            toggleIsCommented,
            showMarkAsApproveBtn,
            updateApproved,
            setSVGFilePath
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        file: state.dashboard.filePath,
        commentData: state.dashboard.commentData,
        isCommented: state.metaData.isCommented,
        showApproveBtn: state.metaData.showApproveBtn,
        markApproved: state.metaData.markApproved

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SVGView);
