// @flow

import type { JSONSchema } from "../types";

export const DataEntryRootFormKey = "dataEntryForm";

export type RegularSections = {
  generalInfo: $PropertyType<JSONSchema, "generalInfo">,
  dataCenters: $PropertyType<JSONSchema, "dataCenters">
};

export type DynamicNodeSections = {
  inputNodes: $PropertyType<JSONSchema, "inputNodes">,
  processingNodes: $PropertyType<JSONSchema, "processingNodes">,
  retentionNodes: $PropertyType<JSONSchema, "retentionNodes">
};

export type DataEntryFormOutput = RegularSections & DynamicNodeSections;

export const GeneralInfoFormKey: $Keys<DataEntryFormOutput> = "generalInfo";
export const DataCentersFormKey: $Keys<DataEntryFormOutput> = "dataCenters";
export const InputNodesFormKey: $Keys<DynamicNodeSections> = "inputNodes";
export const ProcessingNodesFormKey: $Keys<DynamicNodeSections> =
  "processingNodes";
export const RetentionNodesFormKey: $Keys<DynamicNodeSections> =
  "retentionNodes";
export const SVGRegenerationFormKey = "svgRegeneration";

export const InputNodeIndividualEntryPrefix = "inputNodeForm";
export const ProcessingNodeIndividualEntryPrefix = "processNodeForm";
export const RetentionNodeIndividualEntryPrefix = "retentionNodeForm";
