import * as React from "react";
import * as R from "ramda";
import * as ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { addDataEntries } from "./reducers/dataEnrtyReducer";
import { formSnapshot } from "./reducers/snapshot.reducer";
import { addProcessingNodes } from "./reducers/processingNodesReducer";
import { addRetentionNodes } from "./reducers/retentionNodesReducer";
import { addDataCenters } from "./reducers/dataCentersReducer";
import { addInputNodes } from "./reducers/inputNodesReducer";
import { generateSVG } from "./svgGenerationLogic/features/generateSvg/index.reducer";
import { existingSvg } from "./reducers/loadExistingRedcer";
import { editReviewData } from "./reducers/reviewEditReducer";
import { svgGenerationflag } from "./reducers/svgRegenerationReducer";
import { uploadDetails } from "./reducers/uploadReducer";
import { dashboardReducer } from "./reducers/dashboardReducer";
import { productInfoReducer } from "./reducers/productInfoReducer";
import { userInfoReducer } from "./reducers/userInfoReducer";
import { metaDataReducer } from "./reducers/metaDataReducer";
// import { SAVE_ON_CLOSE_APP} from "./actions/saveprogressAction";
import App from "./App";
import axios from "axios";

export const appReducer = combineReducers({
  dataEntry: addDataEntries,
  formSnapshot: formSnapshot,
  dataCenters: addDataCenters,
  processingnodes: addProcessingNodes,
  retentionnodes: addRetentionNodes,
  inputnodes: addInputNodes,
  svgGeneration: generateSVG,
  svgRegeneration: svgGenerationflag,
  existingSvg: existingSvg,
  editData: editReviewData,
  uploadData: uploadDetails,
  dashboard: dashboardReducer,
  productInfo: productInfoReducer,
  userInfo: userInfoReducer,
  metaData: metaDataReducer
});

export const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = {};
  }
  return appReducer(state, action);
};


const newLaunch = () => {
  let composeEnhancers = compose;
  if (process.env.NODE_ENV === "development") {
    composeEnhancers =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          stateSanitizer: state => {
            const svgPath = ["existingSvg", "existingSvgString"];
            return R.ifElse(
              R.path(svgPath),
              R.assocPath(svgPath, "<<SVG_BLOB>>"),
              R.identity
            )(state);
          }
        })) ||
      compose;
  }

  axios.defaults.baseURL = '';

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  const reactRootElement = document.querySelector("#root");

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    reactRootElement
  );
};

newLaunch();
