// @flow

import { SVG } from "../logic/svgWrapper";

import type { JSONSchema, RetentionNode } from "../../types";
import { DeletionCiscoOneGrid } from "../grid";
import { fontsSVGLibrary } from "../../../styles";
import {
  drawMultiTypeNodeAndOutlines,
  emptySVGElement,
  // generateSingleLineTextWithInRect,
  generateMulticoloredNode,
  calculateWordWidths,
  calculateLines,
  type SVGRepresentation
} from "./nodesCommon";
import type { RetentionNodeSectionIdentifiers } from "../logic/positioning";

// Size & margins.
// We'll be using a 280x120 node if copy text is small enough.
const defaultNodeSize = { w: 250, h: DeletionCiscoOneGrid.nodeHeight };
const maxDefaultNodeSize = { w: 250, h: 150};

const minLineWidth = 14;
const maxLineWidth = 16;

let lineWidth = minLineWidth;
// const textboxSize = {
//   w: defaultNodeSize.w * 0.8
// };

export function generateRetentionNodeSVG(
  svgMain: string,
  svgAuxiliary: string,
  nodeId: string,
  node: RetentionNode
) {
  const drawMain = SVG(svgMain);
  const drawAuxiliary = SVG(svgAuxiliary);
  drawMain
    .clear()
    .id(nodeId)
    .addClass("node-kind-retention")
    .addClass(`controller-${node.controller}`)
    .addClass(`timeline-${node.timeline}`);

  // 0. Start with some defaults. Textbox size will determine the final node size.
  let actualSize = Object.assign({}, defaultNodeSize);
  // let fontStyles = fontsPlainSVG.minnode;
  let wordsWithComputedWidth = calculateWordWidths(node.nodeCopy);
  let lines = calculateLines(wordsWithComputedWidth, lineWidth);
  let fontSize = "23";

  if (lines.length > 4) {
    actualSize = Object.assign({}, maxDefaultNodeSize);
    lineWidth = maxLineWidth;
    wordsWithComputedWidth = calculateWordWidths(node.nodeCopy);
    lines = calculateLines(wordsWithComputedWidth, lineWidth);
    fontSize = "20";
  }

  // 2. Main viewbox & node background
  drawMain
    .viewbox(0, 0, actualSize.w, actualSize.h)
    .size(actualSize.w, actualSize.h);

  drawMultiTypeNodeAndOutlines(node, drawMain, actualSize, nodeId);

  // 3. Generate textbox.
  // drawMain.svg(
  //   generateSingleLineTextWithInRect(
  //     node.nodeCopy,
  //     actualSize.w - textboxSize.w,
  //     actualSize.h / 2,
  //     lineWidth,
  //     fontStyles,
  //     "retentionNode",
  //     xspan
  //   )
  // );

  var nested = drawMain.nested().attr({
    height: "50",
    width: "200",
  });

  var group = nested.group().translate(115, -40);
  let y;
  let x = 13;

  if (fontSize === "23") {
    if (lines.length === 1) {
      y = 108;
    } else if (lines.length === 2) {
      y = 95;
    }
    if (lines.length === 3) {
      y = 87;
    } else {
      y = 78;
    }
  } else {
    if (lines.length === 1) {
      y = 110;
    } else if (lines.length === 2) {
      y = 97;
    } else if (lines.length === 3) {
      y = 89;
    } else if (lines.length === 4) {
      y = 83;
    } else {
      y = 75;
    }
  }

  var text = drawMain
    .text(function(add) {
      for (let i = 0; i < lines.length; i++) {
        add
          .tspan(lines[i])
          .x(x)
          .y(y);
        y = y + 25;
      }
    })
    .font({
      family: fontsSVGLibrary.node.family,
      weight: 400,
      size: fontSize,
      fill: "#57585B",
      anchor: "middle",
    });

  group.add(text);

  // 5. Memorize the result & cleanup...
  const result = drawMain.svg();
  drawMain.clear();
  drawAuxiliary.clear();

  return result;
}

export type Output = RetentionNode & SVGRepresentation;

export function generateRetentionNodesInner(
  retentionNodesData: $NonMaybeType<$PropertyType<JSONSchema, "retentionNodes">>,
  SVGMainId: string,
  SVGAuxId: string,
  svgGenerator: typeof generateRetentionNodeSVG
): {
  [controllerSubcategoryCombination: RetentionNodeSectionIdentifiers]: Array<Output>
} {
  return generateMulticoloredNode(
    retentionNodesData,
    SVGMainId,
    SVGAuxId,
    svgGenerator,
    ({ controller, timeline }) => `C${controller}_T${timeline}`
  );
}

export function generateRetentionNodes(
  retentionNodesData: $NonMaybeType<$PropertyType<JSONSchema, "retentionNodes">>,
  SVGMainId: string,
  SVGAuxId: string,
  shouldProduceDummyNodes: boolean
): {
  [controllerSubcategoryCombination: RetentionNodeSectionIdentifiers]: Array<Output>
} {
  return generateRetentionNodesInner(
    retentionNodesData,
    SVGMainId,
    SVGAuxId,
    !shouldProduceDummyNodes ? generateRetentionNodeSVG : emptySVGElement
  );
}