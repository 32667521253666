import * as api from "../apis/datamaps.api";
import { GET_DASHBOARD_DATA, GET_DATAMAP_DATA, GET_REVIEW_DETAILS, IS_EDIT_FLOW, 
  COMMENT_DETAILS, MARK_APPROVED, EDITOR_UPDATED, DASHBOARD_ERROR} from './constant';
import { IS_UPLOADED, IS_COMMENT_ADD } from "../actions/constant";


export const uploadExistingDataFromJSON = (data) => {
  return async (dispatch) => {
    console.log("calling api json upload...")
    const res = await api.uploadDataMap(data);
    if (res && res.status === 200) {
      dispatch({
        type: IS_UPLOADED,
        data: true
      })
    }
  }
}

export const uploadExistingData = (data, svgFile) => {
  return async (dispatch) => {
    console.log("calling api svg json and svg uplaod...")
    const firstRes = await api.uploadDataMap(data);
    if (firstRes && firstRes.status === 200 && firstRes.data) {
      const secondRes = await api.uploadSVGFile(svgFile, firstRes.data.datamapId);
      if (secondRes && secondRes.status === 200) {
        dispatch({
          type: IS_UPLOADED,
          data: true
        })
      }
      else {
        dispatch({
          type: IS_UPLOADED,
          data: false
        })
      }
    }
    else {
      dispatch({
        type: IS_UPLOADED,
        data: false
      })
    }
  }
}

export const getDashboardData = (org_id, emailId) => {
  return async (dispatch, getState) => {
    console.log(getState);
    console.log("calling get data maps api...");
    try{
      const response = await api.getDashboardData(org_id, emailId);
      if (response && response.status === 200) {
        dispatch({
          type: GET_DASHBOARD_DATA,
          data: response.data
        });
      }else {
        dispatch({
          type: DASHBOARD_ERROR,
          data: response.data
        });
      }
    }
    catch(err){
      dispatch({
        type: DASHBOARD_ERROR,
        data: {
          "type": "API_ERROR",
          "message": "Failed to load the data"
         }
      });
    }
  }
}

export const getToReviewData = (emailId) => {
  return async (dispatch) => {
    console.log("calling get to review maps api...")
    const response = await api.getToReviewMaps(emailId);
    dispatch({
      type: GET_DASHBOARD_DATA,
      data: response.data
    });
  }
}

export const getDraftData = (emailId) => {
  return async (dispatch) => {
    console.log("calling get to draft maps api...")
    const response = await api.getDraftMaps(emailId);
    dispatch({
      type: GET_DASHBOARD_DATA,
      data: response.data
    });
  }
}
export const getStarredData = (emailId) => {
  return async (dispatch) => {
    console.log("calling get to starred maps api...")
    const response = await api.getStarredMaps(emailId);
    dispatch({
      type: GET_DASHBOARD_DATA,
      data: response.data
    });
  }
}

export const getSandboxData = (emailId) => {
  return async (dispatch) => {
    console.log("calling get to starred maps api...")
    const response = await api.getSandboxMaps(emailId);
    dispatch({
      type: GET_DASHBOARD_DATA,
      data: response.data
    });
  }
}

export const getDataMapsId = (org_id, dataMapId) => {
  return async (dispatch) => {
    console.log("calling get data map by id api...")
    const response = await api.getDataMapById(org_id, dataMapId);
    dispatch({
      type: GET_DATAMAP_DATA,
      data: response.data
    });
    dispatch({
      type: IS_EDIT_FLOW,
      data: true
    })
  }
}

export const getReviewDetails = (dataMapId) => {
  return async (dispatch) => {
    console.log("calling get Review data map by id api...")
    const response = await api.getReviewDetailsByMapId(dataMapId);
    dispatch({
      type: GET_REVIEW_DETAILS,
      data: response.data
    });
  }

}

export const addCommentsForMap = (data, dataMapId) => {
  return async (dispatch) => {
    console.log("calling put Review data map by id api...")
    const res = await api.addComments(data, dataMapId);
    if (res && res.status === 200) {
      dispatch({
        type: IS_COMMENT_ADD,
        data: res.data
      });
    }
  }
}

export const getCommentsForMap = (dataMapId) => {
  return async (dispatch) => {
    console.log("calling get comments data map by id api...")
    const res = await api.getComments(dataMapId);
    if (res && res.status === 200) {
      dispatch({
        type: COMMENT_DETAILS,
        data: res.data
      });
    }
  }
}

export const updateApproved = (data, emailId, dataMapId) => {
  return async (dispatch) => {
    console.log("calling update approve api...")
    const res = await api.updateApproveStatus(data, emailId, dataMapId);
    if (res && res.status === 200) {
      dispatch({
        type: MARK_APPROVED,
        data: true
      });
    }
  }
}

export const updateCurrentEditor = (data, emailId, dataMapId) => {
  return async (dispatch) => {
    console.log("calling Update Current Editor api...")
    const res = await api.updateCurrentEditor(data, emailId, dataMapId);
    if (res && res.status === 200) {
      dispatch({
        type: EDITOR_UPDATED,
        data: true
      });
    }
  }
}


export const updateReviewers = (data, dataMapId) => {
  return async () => {
    console.log("calling Update Reviewers api...");
    const res = await api.updateReviewers(data, dataMapId);
    if (res && res.status === 200) {
      console.log(res);
    }
  }
}