// @flow
import * as R from "ramda";
import type { Dispatch } from "redux";

import type { DataEntryFormOutput } from "../dataEntry/types";
import { GeneralInfoFormKey } from "../dataEntry/types";
import { SVGMainId, SVGAuxId } from "./index";
import { getFinalSVGCode } from "./logic/getFinalSVGCode";

export const SET_SVG_CODE: "SET_SVG_CODE" = "SET_SVG_CODE";
export const SVG_CREATION_REQUESTED: "SVG_CREATION_REQUESTED" =
  "SVG_CREATION_REQUESTED";

type ActionSVG_CREATION_REQUESTED = {
  type: typeof SVG_CREATION_REQUESTED,
  payload: DataEntryFormOutput
};

type ActionSET_SVG_CODE = {
  type: typeof SET_SVG_CODE,
  payload: string
};

export type ActionSVG = ActionSVG_CREATION_REQUESTED | ActionSET_SVG_CODE;

const normalizeContracts = values => {
  // $FlowFixMe
  return R.over(
    R.lensProp(GeneralInfoFormKey),
    R.mergeDeepRight({
      contracts: {
        customerToSubprocessor: false
      }
    })
  )(values);
};

export const generateSVGRoutine = (formValues: DataEntryFormOutput) => {
  return async (dispatch: Dispatch<*>, getState: any) => {
    await dispatch({
      type: SVG_CREATION_REQUESTED,
      payload: normalizeContracts(formValues)
    });
    const { formDataForSvg } = getState().svgGeneration;
    const existingSvg = getState().existingSvg || {};
    // const existingSvg = {};

    const SVGString = await getFinalSVGCode(
      formDataForSvg,
      existingSvg,
      formDataForSvg.generalInfo.contracts.customerToSubprocessor,
      SVGMainId,
      SVGAuxId
    );
    dispatch({ type: SET_SVG_CODE, payload: SVGString });
  };
};
