/* eslint-disable no-useless-escape */
// @flow
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./index.scss";
import Select from "react-select";
import { dataCentersDropdownList } from "./dataCentersDropdownList";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Error from "../svgIcons/error";
import { addDataCentersInfo } from "../../actions/dataCentersAction";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toggleIsUpdated } from "../../actions/metaDataAction";
class DataCentersSection extends Component<any, any> {
  state = {
    openGoBack: false,
    policies: "",
    options: dataCentersDropdownList(),
    CiscoDataCenters: [],
    ThirdPartyDataCenters: [],
    policiesError: false,
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;

    if (data) {
      let centers = [];
      let thirdParties = [];
      if (data.ciscoLocations) {
        for (let location of data.ciscoLocations) {
          centers.push({
            id: location,
            value: location,
            label: location,
          });
        }
      }
      if (data.thirdPartyLocations) {
        for (let thirdParty of data.thirdPartyLocations) {
          thirdParties.push({
            id: thirdParty,
            value: thirdParty,
            label: thirdParty,
          });
        }
      }
      this.setState({
        policies: data.policiesDescription,
        CiscoDataCenters: centers,
        ThirdPartyDataCenters: thirdParties,
      });
    }
  }

  validationDataCenters() {
    // if (!this.state.policies) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    return true;
  }
  saveDataCentersInfo() {
    if (this.props.isEdit) {
      this.props.toggleIsUpdated(true);
    }
    else {
      let saveData = {
        policiesDescription: this.state.policies,
        ciscoLocations:
          this.state.CiscoDataCenters &&
          this.state.CiscoDataCenters.map((ele) => ele.value),
        thirdPartyLocations:
          this.state.ThirdPartyDataCenters &&
          this.state.ThirdPartyDataCenters.map((ele) => ele.value),
      };
      console.log(saveData);
      this.props.addDataCentersInfo(saveData);
    }
  }
  filterOption = (option, inputValue) => {
    // const { options } = this.state;
    // const result = options.filter(opt =>
    //   opt.label.toLowerCase().includes(inputValue.toLowerCase())
    // );
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "8%",
          paddingTop: "1%",
          height: "550px"
        }}
      >
        <Dialog
          open={this.state.openGoBack}
          onClose={() => {
            this.setState({
              openGoBack: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Please fill out the required fields.
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Moving to the next section will cause you to lose your current
              progress.
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className="primary-button"
                onClick={() => {
                  this.setState({
                    openGoBack: false,
                  });
                }}
                color="primary"
              >
                OK
              </Button>
              {/* <Button
                className="primary-button"
                onClick={() => {
                  this.props.handleNext();
                  this.setState({
                    openGoBack: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Lose progress and edit node
              </Button> */}
            </div>
          </DialogActions>
        </Dialog>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          noValidate
          autoComplete="off"
        >
          <div style={{ marginBottom: "50px" }}>
            <div
              style={{
                paddingBottom: "3%",
              }}
              className="input-label"
            >
              Data Center Policies <span className="color-red">*</span>
            </div>
            <div className="border-bottom-1 input-wrapper width-550" >
              <textarea
                className="input-text-active"
                style={{ minWidth: "550px", minHeight: "38px" }}
                id="text"
                placeholder="e.g., This information can be found under Cross-border Transfers."
                value={this.state.policies}
                onChange={() => {
                  this.setState({
                    policies: event.target.value,
                    policiesError: false,
                  });
                }}
                onBlur={() => {
                  if (!this.state.policies) {
                    this.setState({
                      policiesError: true,
                    });
                  } else {
                    this.setState({
                      policiesError: false,
                    });
                  }
                }}
              />
              {this.state.policiesError ? <Error /> : ""}
            </div>
            {this.state.policiesError ? (
              <div style={{ color: "#CD0000", width: "550px" }}>
                Please enter the text that should appear in the node.
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ marginBottom: "50px" }}>
            <div className="input-label">Cisco Data Centers</div>
            <Select
              isMulti
              filterOption={this.filterOption}
              noOptionsMessage={() => "No more options"}
              options={this.state.options}
              onChange={(opt, meta) => {
                this.setState({
                  CiscoDataCenters: opt,
                });
                console.log(meta);
              }}
              defaultValue={this.state.CiscoDataCenters}
            />
          </div>
          <div style={{ marginBottom: "50px" }}>
            <div className="input-label">Third Party Data Centers</div>
            <Select
              className="width-550"
              isMulti
              filterOption={this.filterOption}
              noOptionsMessage={() => "No more options"}
              options={this.state.options}
              onChange={(opt, meta) => {
                this.setState({
                  ThirdPartyDataCenters: opt,
                });
                console.log(meta);
              }}
              defaultValue={this.state.ThirdPartyDataCenters}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addDataCentersInfo, toggleIsUpdated }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    data: state.dataCenters.dataCenters,
    isEdit: state.metaData.isEditFlow
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataCentersSection);