
/**** API BASE PATH FOR LOCAL ****/
//export const API_BASE_PATH = 'https://syalol4ll3.execute-api.us-east-1.amazonaws.com/dev1';

/**** API BASE PATH DEV URL / DEMO URL ****/
export const API_BASE_PATH = 'https://dassgj0oc5.execute-api.us-east-1.amazonaws.com/dev';

/**** API ROOT PATH  ****/
export const API_ROOT_PATH = '/api/v2';

export const headers = ()=> {
    return{
    'Content-Type': 'application/json',
    'Authorization':`Bearer ${localStorage.getItem("bearer-token")}`,
    }
}