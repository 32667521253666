import {IS_UPLOADED } from "../actions/constant";

const initialState = {};

export function uploadDetails(state = initialState, action) {
    switch (action.type) {
        case IS_UPLOADED:
            return { 
                isUploaded: action.data 
            };
        default:
            return state;
    }
}