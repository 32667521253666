/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, Dialog, DialogTitle, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    dial_root: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    dial_paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    dialog: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        '& .MuiDialog-paperScrollPaper': {
            alignItems: "center",
        }
    }
}));
const Loader = () => {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={true}
                BackdropProps={{
                    classes: {
                        root: classes.dial_root,
                    },
                }}
                PaperProps={{
                    classes: {
                        root: classes.dial_paper,
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ color: "#049FD9", fontSize: "16px" }}>Uploading Data Map....</DialogTitle>
                <CircularProgress
                    size={40}
                    thickness={5.6}
                    style={{ color: "#049FD9" }}
                />
            </Dialog>
        </div>
    )
}

export default Loader