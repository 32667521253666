// @flow

import { getDefaultDataTypeColorMap } from "../../dataEntry/sections/dataTypes";
import { fontsPlainSVG } from "../../../styles";
import * as R from "ramda";
import type { Output } from "./processingNode";

export const outlineStrokeWidth = 8;
export const outlineStrokeWidthDiv2 = outlineStrokeWidth / 2;

export const nodeRadius = 40;
export const lineHeight = 20;
export const nodeInnerBoxSuffix = "-innerbox";

export function generateCopyText(
  lines: Array<string>,
  x: number,
  cy: number,
  lHeight?: number
) {
  let usedLineHeight = lineHeight;
  if (lHeight) {
    usedLineHeight = lHeight;
  }
  const totalHeight = lines.length * usedLineHeight;
  const initialY = cy - totalHeight / 2;

  const textLine = (content, y) =>
    `<text x=${x} y=${y} ${fontsPlainSVG.node}>${content}</text>`;
  const textContent = lines
    .map((line, idx) => {
      const a = textLine(line, initialY + (idx + 1) * usedLineHeight);
      return a;
    })
    .join("");

  return textContent;
}

export function generateCopyTextSingleLine(
  content: string,
  x: number,
  cy: number,
  lineWidth: number
) {
  const y = cy + lineHeight / 2;

  let wordsWithComputedWidth = calculateWordWidths(content);
  let lines = calculateLines(wordsWithComputedWidth, lineWidth);

  const text = lines[0];
  const text1 = lines[1] ? lines[1] : "";
  const text2 = lines[2] ? lines[2] : "";
  const text3 = lines[3] ? lines[3] : "";
  const text4 = lines[4] ? lines[4] : "";
  const text5 = lines[5] ? lines[5] : "";
  const text6 = lines[6] ? lines[6] : "";
  const text7 = lines[7] ? lines[7] : "";
  const text8 = lines[8] ? lines[8] : "";
  const text9 = lines[9] ? lines[9] : "";
  const text10 = lines[10] ? lines[10] : "";
  return `<text x=${x} y=${y} ${
    fontsPlainSVG.node
  }><tspan x=${x} dy=".2em">${text}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text1}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text2}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text3}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text4}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text5}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text6}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text7}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text8}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text9}</tspan>
                                                    <tspan x=${x} dy="1.2em">${text10}</tspan></text>`;
}

export function drawUnicoloredInputRectangle(
  drawMain: any,
  actualSize: { w: number, h: number },
  dataType: string,
  nodeId: string
) {
  drawMultiTypeNodeAndOutlines(
    { dataTypes: [dataType] },
    drawMain,
    actualSize,
    nodeId
  );
}

export type SVGRepresentation = {
  nodeId: string,
  svg: string,
};

export function addNodeOrderInfo(
  dataTypes: Array<string>
): { maskHumanReadable: string } {
  // Create a binary mask derived from a group of datatypes & convert them into the BYR notation
  // as used above.
  const maskBinary: number = R.reduce((acc: number, x) => {
    const mask = 0b1 << Number.parseInt(x);
    return acc | mask;
  })(0b000, dataTypes);

  const maskHumanReadable: string = R.join("")([
    (0b001 & maskBinary) > 0 ? "B" : "0",
    (0b010 & maskBinary) > 0 ? "Y" : "0",
    (0b100 & maskBinary) > 0 ? "R" : "0",
  ]);

  return {
    dataTypes,
    maskHumanReadable,
  };
}

export function addOutlinesForBoxes(
  drawMain: any,
  whiteRegionSize: any,
  outlineStrokeWidth: number,
  types: Array<string>
) {
  // Sort by color.
  const sortedTypes = R.sort(R.comparator(R.lt), types);

  const verticalSize2 = whiteRegionSize.h / 2 - nodeRadius;

  // Unicolored nodes can reuse the same method as two-colored, no need to have 3 cases.
  const verticalLine = (coeff) =>
    types.length < 3 ? `l 0,${coeff * verticalSize2}` : `l 0,${coeff * 22}`;

  // Draw top
  const commandTop = `
    M ${outlineStrokeWidth},${outlineStrokeWidth + nodeRadius}
    a ${nodeRadius},${nodeRadius} 0 0 1 ${nodeRadius},-${nodeRadius}
    l ${whiteRegionSize.w - 2 * nodeRadius},0
    a ${nodeRadius},${nodeRadius} 0 0 1 ${nodeRadius},${nodeRadius}
    ${verticalLine(1)}
    M ${outlineStrokeWidth},${outlineStrokeWidth + nodeRadius}
    ${verticalLine(1)}
  `;

  drawMain
    .path(commandTop)
    .stroke({
      color: getDefaultDataTypeColorMap[sortedTypes[0]],
      width: 2 * outlineStrokeWidth,
      linecap: "flat",
    })
    .fill("none");

  // Draw bottom
  const commandBottom = `
    M ${outlineStrokeWidth},${outlineStrokeWidth +
    whiteRegionSize.h -
    nodeRadius}
    a ${nodeRadius},${nodeRadius} 0 0 0 ${nodeRadius},${nodeRadius}
    l ${whiteRegionSize.w - 2 * nodeRadius},0
    a ${nodeRadius},${nodeRadius} 0 0 0 ${nodeRadius},-${nodeRadius}
    ${verticalLine(-1)}
    M ${outlineStrokeWidth},${outlineStrokeWidth +
    whiteRegionSize.h -
    nodeRadius}
    ${verticalLine(-1)}
  `;

  drawMain
    .path(commandBottom)
    .stroke({
      color: getDefaultDataTypeColorMap[sortedTypes[sortedTypes.length - 1]],
      width: 2 * outlineStrokeWidth,
      linecap: "flat",
    })
    .fill("none");

  // Optional : Draw middle
  if (types.length === 3) {
    const commandMiddle = `
    M 0,${-15 + outlineStrokeWidth + whiteRegionSize.h / 2}
    l 0,30
    M ${whiteRegionSize.w + outlineStrokeWidth},${-15 +
      outlineStrokeWidth +
      whiteRegionSize.h / 2}
    l 0,30
  `;

    drawMain
      .path(commandMiddle)
      .stroke({
        color: getDefaultDataTypeColorMap[sortedTypes[1]],
        width: 2 * outlineStrokeWidth,
        linecap: "flat",
      })
      .fill("none");
  }
}

export function drawMultiTypeNodeAndOutlines(
  node: { dataTypes: Array<string> },
  drawMain: any,
  actualSize: { w: number, h: number },
  nodeId: string
) {
  const nodeNested = drawMain.nested().id(`${nodeId}${nodeInnerBoxSuffix}`);
  // We need to render the outlines first & then the white content box.
  addOutlinesForBoxes(
    nodeNested,
    {
      w: actualSize.w - outlineStrokeWidth * 2,
      h: actualSize.h - outlineStrokeWidth * 2,
    },
    outlineStrokeWidth,
    node.dataTypes
  );

  nodeNested
    .rect(
      actualSize.w - outlineStrokeWidth * 2,
      actualSize.h - outlineStrokeWidth * 2
    )
    .fill("#ffffff")
    .rx(nodeRadius)
    .move(outlineStrokeWidth, outlineStrokeWidth);
}

// Flow has an issue with addIndex, disabling.
//$FlowFixMe
const indexedReduce = R.addIndex(R.reduce);

// Using BYR format for easier readability ; this might've also been an array of binary flags.
// B00 (Blue) will be atop BY0 (Blue+Yellow) and so on. The array below describes the ordering &
// we use a quick reduction to build a color type to priority map.
//$FlowFixMe - see above.
const colorOrder: { [mask: string]: number } = indexedReduce(
  (acc, mask, idx) => ({ ...acc, [mask]: idx })
)({}, ["B00", "BY0", "0Y0", "BYR", "0YR", "B0R", "00R"]);

export function generateMulticoloredNode<OutputType>(
  // prettier-ignore
  nodesData: any,
  SVGMainId: string,
  SVGAuxId: string,
  svgGenerator: (
    svgMain: string,
    svgAuxiliary: string,
    nodeId: string,
    node: any
  ) => void,
  groupingFunction: (any) => string
): OutputType {
  const maskIndex = R.compose(
    R.flip(R.prop)(colorOrder),
    R.prop("maskHumanReadable")
  );

  return R.compose(
    // Ramda doesn't have typings for lift...
    //$FlowFixMe
    R.lift(
      R.compose(
        R.sortWith([R.ascend(maskIndex)]),
        R.map((nodes) =>
          R.mergeRight(
            R.compose(
              addNodeOrderInfo,
              R.prop("dataTypes")
            )(nodes)
          )(nodes)
        )
      )
    ),
    R.groupBy(groupingFunction),
    R.map(
      ([nodeId, content]) =>
        ({
          svg: svgGenerator(SVGMainId, SVGAuxId, nodeId, content),
          nodeId,
          ...content,
        }: Output)
    ),
    R.toPairs
  )(nodesData);
}

export function generateSingleLineTextWithInRect(
  content: string,
  x: number,
  cy: number,
  lineWidth: number,
  fontStyle: any,
  type: string,
  xspan: string
) {
  if (type === "inputNode") {
    // const y = cy + lineHeight / 2;
    let wordsWithComputedWidth = calculateWordWidths(content);
    let lines = calculateLines(wordsWithComputedWidth, lineWidth);
    if (lines.length === 1) {
      const text = lines[0];
      return `<text x=${x} y="50" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy="1.1em">${text}</tspan>`;
    } else if (lines.length === 2) {
      const text = lines[0];
      const text1 = lines[1] ? lines[1] : "";
      return `<text x=${x} y="60" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
                                                  <tspan x=${xspan} dy="1.2em">${text1}</tspan>`;
    } else if (lines.length === 3) {
      const text = lines[0];
      const text1 = lines[1] ? lines[1] : "";
      const text2 = lines[2] ? lines[2] : "";
      return `<text x=${x} y="50" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
                                                  <tspan x=${xspan} dy="1.2em">${text1}</tspan>
                                                  <tspan x=${xspan} dy="1.2em">${text2}</tspan>`;
    } else {
      const text = lines[0];
      const text1 = lines[1] ? lines[1] : "";
      const text2 = lines[2] ? lines[2] : "";
      const text3 = lines[3] ? lines[3] : "";
      return `<text x=${xspan} y="50" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
                                                  <tspan x=${xspan} dy="1.2em">${text1}</tspan>
                                                  <tspan x=${xspan} dy="1.2em">${text2}</tspan>
                                                  <tspan x=${xspan} dy="1.2em">${text3}</tspan>`;
    }
  }
  if (type === "processingNode") {
    // const y = cy + lineHeight / 2;
    let wordsWithComputedWidth = calculateWordWidths(content);
    let lines = calculateLines(wordsWithComputedWidth, lineWidth);
    if (lines.length === 1) {
      const text = lines[0];
      return `<text x=${x} y="70" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy="-0.3em" >${text}</tspan>`;
    } else if (lines.length === 2) {
      const text = lines[0];
      const text1 = lines[1];
      return `<text x=${x} y="50" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
      <tspan x=${xspan} dy="1.2em">${text1}</tspan>`;
    } else if (lines.length === 3) {
      const text = lines[0];
      const text1 = lines[1];
      const text2 = lines[2];
      return `<text x=${x} y="30" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
        <tspan x=${xspan} dy="1.2em">${text1}</tspan>
        <tspan x=${xspan} dy="1.2em">${text2}</tspan>`;
    } else if (lines.length === 4) {
      const text = lines[0];
      const text1 = lines[1];
      const text2 = lines[2];
      const text3 = lines[3];
      return `<text x=${x} y="30" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
        <tspan x=${xspan} dy="1.2em">${text1}</tspan>
        <tspan x=${xspan} dy="1.2em">${text2}</tspan>
        <tspan x=${xspan} dy="1.2em">${text3}</tspan>`;
    } else {
      const text = lines[0];
      const text1 = lines[1];
      const text2 = lines[2];
      const text3 = lines[3];
      return `<text x=${x} y="30" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
        <tspan x=${xspan} dy="1.2em">${text1}</tspan>
        <tspan x=${xspan} dy="1.2em">${text2}</tspan>
        <tspan x=${xspan} dy="1.2em">${text3}</tspan>`;
    }
  }
  if (type === "dataAssets") {
    // const y = cy + lineHeight / 2;
    let wordsWithComputedWidth = calculateWordWidths(content);
    let lines = calculateLines(wordsWithComputedWidth, lineWidth);
    const text = lines[0];
    const text1 = lines[1] ? lines[1] : "";
    const text2 = lines[2] ? lines[2] : "";
    const text3 = lines[3] ? lines[3] : "";
    const text4 = lines[4] ? lines[4] : "";
    const text5 = lines[5] ? lines[5] : "";
    const text6 = lines[6] ? lines[6] : "";
    const text7 = lines[7] ? lines[7] : "";
    const text8 = lines[8] ? lines[8] : "";
    const text9 = lines[9] ? lines[9] : "";
    const text10 = lines[10] ? lines[10] : "";

    return `<text x=${x} y="30" ${
      fontsPlainSVG.node
    }><tspan x="25" dy=".2em">${text}</tspan>
    tspan x="25" dy=".2em">${text1}</tspan>
    tspan x="25" dy=".2em">${text2}</tspan>
    tspan x="25" dy=".2em">${text3}</tspan>
    tspan x="25" dy=".2em">${text4}</tspan>
    tspan x="25" dy=".2em">${text5}</tspan>
    tspan x="25" dy=".2em">${text6}</tspan>
    tspan x="25" dy=".2em">${text7}</tspan>
    tspan x="25" dy=".2em">${text8}</tspan>
    tspan x="25" dy=".2em">${text9}</tspan>
    tspan x="25" dy=".2em">${text10}</tspan>`;
  }
  if (type === "retentionNode") {
    // const y = cy + lineHeight / 2;
    // console.log(y);
    let wordsWithComputedWidth = calculateWordWidths(content);
    let lines = calculateLines(wordsWithComputedWidth, lineWidth);
    if (lines.length === 1) {
      const text = lines[0];
      return `<text x=${x} y="70" text-anchor="middle" ${fontStyle}><tspan x=${xspan}="1.1em">${text}</tspan>`;
    } else if (lines.length === 2) {
      const text = lines[0];
      const text1 = lines[1];
      return `<text x=${x} y="50" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
      <tspan x=${xspan} dy="1.2em">${text1}</tspan>`;
    } else if (lines.length === 3) {
      const text = lines[0];
      const text1 = lines[1] ? lines[1] : "";
      const text2 = lines[2] ? lines[2] : "";
      return `<text x=${x} y="30" text-anchor="middle" ${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
        <tspan x=${xspan} dy="1.2em">${text1}</tspan>
        <tspan x=${xspan} dy="1.2em">${text2}</tspan>`;
    } else {
      const text = lines[0];
      const text1 = lines[1] ? lines[1] : "";
      const text2 = lines[2] ? lines[2] : "";
      const text3 = lines[3] ? lines[3] : "";
      return `<text x=${x} y="30" text-anchor="middle"${fontStyle}><tspan x=${xspan} dy=".2em">${text}</tspan>
        <tspan x=${xspan} dy="1.2em">${text1}</tspan>
        <tspan x=${xspan} dy="1.2em">${text2}</tspan>
        <tspan x=${xspan} dy="1.2em">${text3}</tspan>`;
    }
  }
  if (type === "dataCenter") {
    const y = cy + lineHeight / 2;
    // const text = content.slice(0, 90);
    let wordsWithComputedWidth = calculateWordWidths(content);
    let lines = calculateLines(wordsWithComputedWidth, lineWidth);
    const text = lines[0];
    const text1 = lines[1] ? lines[1] : "";
    const text2 = lines[2] ? lines[2] : "";

    return `<text x=${x} y=${y} ${
      fontsPlainSVG.node
    }><tspan x=${x} dy=".2em">${text}</tspan>
    <tspan x=${x} dy="1.2em">${text1}</tspan>
    <tspan x=${x} dy="1.2em">${text2}</tspan>`;
  }
  if (type === "CallOutSvg") {
    const y = cy + lineHeight / 2;
    const text = content.slice(0, 90);
    return `<text x=${x} y=${y} ${
      fontsPlainSVG.node
    }><tspan x=${x} dy="0.2em">${text}</tspan>`;
  }
}
export function calculateLines(wordsWithComputedWidth: any, lineWidth: any) {
  const wordsByLines = wordsWithComputedWidth.reduce(
    (result, { word, width }) => {
      const lastLine = result[result.length - 1] || { words: [], width: 0 };
      if (lastLine.words.length === 0) {
        // First word on line
        const newLine = { words: [word], width };
        result.push(newLine);
      } else if (lastLine.width + width <= lineWidth) {
        lastLine.words.push(word);
        lastLine.width += width;
      } else {
        // Word too long to fit on existing line
        const newLine = { words: [word], width };
        result.push(newLine);
      }
      return result;
    },
    []
  );
  return wordsByLines.map((line) => line.words.join(" "));
}
export function calculateWordWidths(content: any) {
  // Calculate length of each word to be used to determine number of words per line
  const words = content.split(/\s+/);
  const wordsWithComputedWidth = words.map((word) => {
    return { word: word, width: word.length };
  });
  return wordsWithComputedWidth;
}

export const emptySVGElement: any = () => {
  return `<svg></svg>`;
};
