import { createGlobalStyle } from 'styled-components';
import SFProTextBold from './SF-Pro-Text-Bold.otf';
import SFProTextLight from './SF-Pro-Text-Light.otf';
import SFProTextRegular from './SF-Pro-Text-Regular.otf';
import SFProDisplayBlack from './SF-Pro-Display-Black.otf';
import SFProDisplaySemibold from './SF-Pro-Display-Semibold.otf';
import SFProDisplayLight from './SF-Pro-Display-Light.otf';
import SFProDisplayBold from './SF-Pro-Display-Bold.otf';
import SFProDisplayRegular from './SF-Pro-Display-Regular.otf';
import SFProTextBlack from './SF-Pro-Text-Black.otf';

export default createGlobalStyle`
    @font-face {
        font-family: 'SFProTextBold';
        src: local('SFProTextBold'), local('SFProTextBold'),
        url(${SFProTextBold}) format('woff');
    }

    @font-face {
        font-family: 'SFProTextBlack';
        src: local('SFProDisplayBold'), local('SFProTextBlack'),
        url(${SFProTextBlack}) format('woff');
    }

    @font-face {
        font-family: 'SFProTextLight';
        src: local('SFProTextLight'), local('SFProTextLight'),
        url(${SFProTextLight}) format('woff');
    }

    @font-face {
        font-family: 'SFProTextRegular';
        src: local('SFProTextRegular'), local('SFProTextRegular'),
        url(${SFProTextRegular}) format('woff');
    }

    @font-face {
        font-family: 'SFProDisplayBlack';
        src: local('SFProDisplayBlack'), local('SFProDisplayBlack'),
        url(${SFProDisplayBlack}) format('otf'),
    }

    @font-face {
        font-family: 'SFProDisplaySemibold';
        src: local('SFProDisplaySemibold'), local('SFProDisplaySemibold'),
        url(${SFProDisplaySemibold}) format('woff');
    }

    @font-face {
        font-family: 'SFProDisplayBold';
        src: local('SFProDisplayBold'), local('SFProDisplayBold'),
        url(${SFProDisplayBold}) format('woff');
    }

    @font-face {
        font-family: 'SFProDisplayLight';
        src: local('SFProDisplayLight'), local('SFProDisplayLight'),
        url(${SFProDisplayLight}) format('woff');
    }

    @font-face {
        font-family: 'SFProDisplayRegular';
        src: local('SFProDisplayRegular'), local('SFProDisplayRegular'),
        url(${SFProDisplayRegular}) format('woff');
    }

`;


