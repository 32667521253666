import { SET_DATAMAP_ID, IS_UPDATED, IS_EDIT_FLOW, IS_COMMENT_ADD, SHOW_MARK_AS_APPROVE, MARK_APPROVED, EDITOR_UPDATED, ADD_REVIEVERS } from "../actions/constant";

const initialState = {
    reviewers: []
};

export function metaDataReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATAMAP_ID:
            return {
                ...state,
                dataMapId: action.data
            };
        case IS_UPDATED:
            return {
                ...state,
                isUpdated: action.data
            };
        case IS_EDIT_FLOW:
            return {
                ...state,
                isEditFlow: action.data
            }
        case IS_COMMENT_ADD:
            return {
                ...IS_COMMENT_ADD,
                isCommented: action.data
            }
        case SHOW_MARK_AS_APPROVE:
            return {
                ...state,
                showApproveBtn: action.data
            }
        case MARK_APPROVED:
            return {
                ...state,
                markApproved: action.data
            }
        case EDITOR_UPDATED:
            return {
                ...state,
                editorUpdated: action.data
            }
        case ADD_REVIEVERS: 
            return {
                ...state, 
                reviewers : action.data
            }
        default:
            return state;
    }
}