// @flow

import * as R from "ramda";

import type {
  JSONSchema,
  InputNode,
  ProcessingNode,
  RetentionNode
} from "../../types";
import type { DataEntryFormOutput } from "../../dataEntry/types";
import {
  InputNodesFormKey,
  DataCentersFormKey,
  ProcessingNodesFormKey,
  RetentionNodesFormKey,
  GeneralInfoFormKey
} from "../../dataEntry/types";

type Tuple = [string, InputNode | ProcessingNode | RetentionNode];
type Nodes =
  | $PropertyType<JSONSchema, "inputNodes">
  | $PropertyType<JSONSchema, "processingNodes">
  | $PropertyType<JSONSchema, "retentionNodes">;

const applyNodesRemoval = (changesRequired: { [id: string]: any }) => (
  nodes: Nodes,
  existingNodes: Nodes
) => {
  const existing = R.keys(existingNodes);
  const current = R.keys(nodes);
  changesRequired["removals"] = R.concat(
    changesRequired["removals"],
    R.difference(existing, current)
  );
};

const applyNodesAdditions = (changesRequired: { [id: string]: any }) => (
  nodes: Nodes,
  existingNodes: Nodes
) => {
  const existing = R.keys(existingNodes);
  const current = R.keys(nodes);
  changesRequired["additions"] = R.concat(
    changesRequired["additions"],
    R.difference(current, existing)
  );
};

const applyNodesChanges = (changesRequired: { [id: string]: any }) => (
  nodes: Nodes,
  existingNodes: Nodes,
  formKey: string
) => {
  const nodesKeysModifiedOnly = R.intersection(
    R.keys(nodes),
    R.keys(existingNodes)
  );

  const [nodesArray, existingNodesArray]: [Array<Tuple>, Array<Tuple>] = (R.map(
    R.compose(
      R.toPairs,
      R.pick(nodesKeysModifiedOnly)
    )
  )([(nodes: any), (existingNodes: any)]): any);

  nodesArray.forEach((node, index) => {
    const nodeObject = node[1];
    const nodeKey = node[0];
    const existingNodeObject = existingNodesArray[index][1];

    // find out what is different and store in object
    for (let property in node[1]) {
      if (JSON.stringify(nodeObject[property]) !== JSON.stringify(existingNodeObject[property])) {
        if (!changesRequired[formKey][nodeKey]) {
          changesRequired[formKey][nodeKey] = {
            [property]: nodeObject[property]
          };
        }
        changesRequired[formKey][nodeKey] = Object.assign(
          changesRequired[formKey][nodeKey],
          {
            [property]: nodeObject[property]
          }
        );
      }
    }
  });

  changesRequired["existing"] = R.mergeDeepLeft({
    [formKey]: nodesKeysModifiedOnly
  })(changesRequired["existing"]);
};

function applyDataCenterMapChanges({ formValues, changesRequired }) {
  Object.assign(changesRequired, {
    [DataCentersFormKey]: formValues.dataCenters
  });
}

function checkGeneralInfoChanges({
  formValues,
  existingSvgValues,
  changesRequired
}) {
  const paths = R.map(field => ({
    key: field,
    path: R.path(["generalInfo", field])
  }))(["productName", "dataSheetVersion", "date"]);

  const diff = R.reduce((acc, pathObj) => {
    const newVal = pathObj.path(formValues);
    const oldVal = pathObj.path(existingSvgValues);

    let addition = {};
    if (!R.equals(newVal, oldVal)) {
      addition = { [pathObj.key]: newVal };
    }

    return { ...acc, ...addition };
  }, {})((paths: any));

  changesRequired.generalInfo = diff;
}

export const existingSVGDiff = (
  formValues: DataEntryFormOutput,
  existingSvgValues: DataEntryFormOutput
): any => {
  let changesRequired = {
    [InputNodesFormKey]: {},
    [ProcessingNodesFormKey]: {},
    [RetentionNodesFormKey]: {},
    [DataCentersFormKey]: {},
    [GeneralInfoFormKey]: {},
    removals: [],
    additions: [],
    existing: {
      [InputNodesFormKey]: [],
      [ProcessingNodesFormKey]: [],
      [RetentionNodesFormKey]: []
    },
    dataTypesChanged: false
  };
  let existingInputNodesValuesToCompare = existingSvgValues.inputNodes;
  let existingProcessingNodesValuesToCompare =
    existingSvgValues.processingNodes;
  let existingRetentionNodesValuesToCompare = existingSvgValues.retentionNodes;

  R.map(R.apply(applyNodesRemoval(changesRequired)))([
    [formValues.inputNodes, existingInputNodesValuesToCompare],
    [formValues.processingNodes, existingProcessingNodesValuesToCompare],
    [formValues.retentionNodes, existingRetentionNodesValuesToCompare]
  ]);

  R.map(R.apply(applyNodesAdditions(changesRequired)))([
    [formValues.inputNodes, existingInputNodesValuesToCompare],
    [formValues.processingNodes, existingProcessingNodesValuesToCompare],
    [formValues.retentionNodes, existingRetentionNodesValuesToCompare]
  ]);

  //$FlowFixMe
  R.map(R.apply(applyNodesChanges(changesRequired)))([
    [
      formValues.inputNodes,
      existingInputNodesValuesToCompare,
      InputNodesFormKey
    ],
    [
      formValues.processingNodes,
      existingProcessingNodesValuesToCompare,
      ProcessingNodesFormKey
    ],
    [
      formValues.retentionNodes,
      existingRetentionNodesValuesToCompare,
      RetentionNodesFormKey
    ]
  ]);

  const dataCentersAreEqual = R.equals(
    formValues.dataCenters,
    existingSvgValues.dataCenters
  );

  !dataCentersAreEqual &&
    applyDataCenterMapChanges({ formValues, changesRequired });

  checkGeneralInfoChanges({ formValues, existingSvgValues, changesRequired });

  const oldDataTypes = existingSvgValues.generalInfo.dataTypes ? existingSvgValues.generalInfo.dataTypes : {}
  const newDataTypes = formValues.generalInfo.dataTypes ? formValues.generalInfo.dataTypes : {}

  if(!R.isEmpty(oldDataTypes) && !R.isEmpty(newDataTypes)){
    for(let key in oldDataTypes){
      let obj1 = oldDataTypes[key]
      let obj2 = newDataTypes[key]

      if(obj1 && obj2){
        const datatype1 = obj1.datatype ? obj1.datatype : "";
        const datatype2 = obj2.datatype ? obj2.datatype : "";
        if(datatype1 !== datatype2){
          changesRequired.dataTypesChanged = true
        }
      }
    }
  }

  return changesRequired;
};
