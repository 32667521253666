import {
  ADD_CUSTOMER_NODES,
  ADD_CISCO_NODES,
  ADD_SUBPROCESSOR_NODES,
  SET_PROCESSING_NODES_JSON_VALUES
} from "../actions/processNodesAction";

const initialState = {};

export function addProcessingNodes(state = initialState, action) {
  switch (action.type) {
    case SET_PROCESSING_NODES_JSON_VALUES:
      return action.data;
    case ADD_CUSTOMER_NODES:
      return {
        ...state,
        customerNodes: action.data
      };
    case ADD_CISCO_NODES:
      return {
        ...state,
        ciscoNodes: action.data
      };
    case ADD_SUBPROCESSOR_NODES:
      return {
        ...state,
        subprocessorNodes: action.data
      };
    default:
      return state;
  }
}
