import React, { Component } from "react";

class Error extends Component {
  render() {
    return (
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>validation/invalid input</title>
        <desc>Created with Sketch.</desc>
        <g
          id="validation/invalid-input"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <circle id="Oval" fill="#CD0000" cx="9" cy="9" r="9" />
          <g
            id="Group-8"
            transform="translate(5.000000, 5.000000)"
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeWidth="2"
          >
            <line x1="0" y1="0" x2="7.73433396" y2="7.73433396" id="Path-3" />
            <line
              x1="0"
              y1="0"
              x2="7.73433396"
              y2="7.73433396"
              id="Path-3"
              transform="translate(3.867167, 3.867167) rotate(-90.000000) translate(-3.867167, -3.867167) "
            />
          </g>
        </g>
      </svg>
    );
  }
}
export default Error;
