import { SET_PRODUCT_INFO } from "../actions/constant";

const initialState = {};

export function productInfoReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT_INFO:
            return {
                data: action.data
            };
        default:
            return state;
    }
}