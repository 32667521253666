import React, { Component } from "react";
class Warning extends Component {
    render(){
        return(
            <svg
            width="18px"
            height="18px"
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <title>validation/warning</title>
            <desc>Created with Sketch.</desc>
            <g
              id="validation/warning"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <circle id="Oval" fill="#DFA700" cx="9" cy="9" r="9" />
              <circle id="Oval" fill="#FFFFFF" cx="9" cy="14" r="1" />
              <rect
                id="Rectangle"
                fill="#FFFFFF"
                x="8"
                y="3"
                width="2"
                height="8"
                rx="1"
              />
            </g>
          </svg>
        );
    }
}
export default Warning;