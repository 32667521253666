/* eslint-disable react/prop-types */
import React from "react";
import "./Master.scss";
import { Global1 as GlobalStyle } from "./styles/index";
import {BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GlobalFontStyle from "./fonts/Cisco-Fonts/fonts";
import GRFontStyle from "./fonts/GR-Fonts/fonts";
import FileDownload from "./components/fileDownload";
import { GenerateSvg } from "./components/generateSVG";
import { AuthProvider } from "./components/auth/authProvider";
import VerticalTabs from "./components/VerticalTabs";
import {  AuthConsumer } from "./components/auth/authProvider";
import Dashboard from "./components/dashboard/index";
import {  createBrowserHistory } from "history";
import EditMap from "./components/edit-map";
import NewDashboard from "./components/newDashboard";
import SVGView from "./components/reviewMap";
import HorizontalStepper from "./components/horizontal-stepper";

const AuthRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={props => (
    <AuthConsumer>{
      ({ isAuthenticated, signinRedirect, getUser}) => {
        if (isAuthenticated()) {
            getUser().then((user) => {
            if (user) {
              console.log("calling set userinfo")
              localStorage.setItem("bearer-token", user.id_token);
              window.USER_INFO = user.profile;
              window.TOKEN = user.id_token;
            }
            })
          return <Component {...props} />;
        } else {
          signinRedirect();
          return <span>loading...</span>;
        }
      }}
    </AuthConsumer>)} />;
};


const history = createBrowserHistory();  
class App extends React.Component {

  render() {

    if (process.env.REACT_APP_UNHIDE_SVG_STAGING) {
      window.__DATAMAP_TESTING_SHOW_SVG = function () {
        const elem = document.querySelector("#svgRender");
        if (elem) {
          elem.style.position = "relative";
          elem.style.left = "0";
        }
      };
    }
    window.inputNodeCount = 1;
    window.processNodeCount = 1;
    window.retentionNodeCount = 1;

    return (
      <Router history={history}>
        <div>
          <GlobalStyle />
          <GlobalFontStyle />
          <GRFontStyle />
          <div>
            <AuthProvider>
              <Switch>
                <AuthRoute exact path="/" component={NewDashboard} />
                <Route exact path="/dashboard" component={NewDashboard} />
                <Route path="/home" component={() => <VerticalTabs index={0} />} />
                <Route path="/upload" component={() => <VerticalTabs index={1} />} />
                <Route path="/input" component={HorizontalStepper} />
                <Route path="/edit" component={EditMap} />
                <Route path="/new" component={Dashboard} />
                <Route path="/review" component={SVGView} />
                <Route path="/stepper" component={HorizontalStepper} />
              </Switch>
            </AuthProvider>
            <GenerateSvg />
            <FileDownload />
          </div>
        </div>
     </Router>
    );
  }
}

export default App;
