import { SET_INPUT_NODES_JSON_VALUES, ADD_INPUT_NODES} from "../actions/inputNodesAction";


const initialState= {}


export function addInputNodes(state= initialState, action){
    switch (action.type) {
        case SET_INPUT_NODES_JSON_VALUES: 
        return {
            inputNodes: action.data
        }
        case ADD_INPUT_NODES:
          return {
            ...state,
            inputNodes: action.data
          };
        default:
          return state;
      }
}