
import * as api from "../apis/datamaps.api";
import { GET_SVG_FILE, IS_UPLOADED} from "./constant";
export const SET_SVG_GENERATION_FLAG = "SET_SVG_GENERATION_FLAG";


export function setSVGGenerationFlag(data) {
  return {
    type: SET_SVG_GENERATION_FLAG,
    data: data,
  };
}


export function getSVGFileById(datamapId) {
  return async (dispatch) => {
    console.log("calling get svg map by id api...")
    try {
      const response = await api.getSVGFile(datamapId);
      if (response && response.status === 200) {
        dispatch({
          type: GET_SVG_FILE,
          data: response.data
        });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_SVG_FILE,
        data: "NO SVG FILE"
      })
    }

  }
}

export function saveSVGFileById(datamapId, svgFile) {
  return async (dispatch) => {
    const res = await api.uploadSVGFile(svgFile, datamapId);
    if (res && res.status === 200) {
      dispatch({
        type: IS_UPLOADED,
        data: true
      })
    }
  }
}

export function setSVGFilePath(path) {
  return ({
    type: GET_SVG_FILE,
    data: path
  })

}