// @flow

import * as R from "ramda";
import { SVG } from "../logic/svgWrapper";

import type { InputNode, JSONSchema } from "../../types";
import { fontsSVGLibrary } from "../../../styles";
// import { CustomerDataInputGrid } from "../grid";
import {
  drawUnicoloredInputRectangle,
  emptySVGElement,
  // generateSingleLineTextWithInRect,
  calculateWordWidths,
  calculateLines,
  type SVGRepresentation,
} from "./nodesCommon";

// Size & margins.
// We'll be using a 280x120 node if copy text is small enough.
const indexedReduce = R.addIndex(R.reduce);
const colorOrder: { [mask: string]: number } = indexedReduce(
  (acc, mask, idx) => ({ ...acc, [mask]: idx })
)({}, ["B00", "BY0", "0Y0", "BYR", "0YR", "B0R", "00R"]);

const defaultNodeSize = { w: 450, h: 120 };
const maxDefaultNodeSize = { w: 450, h: 150 };

const minLineWidth = 20;
const maxLineWidth = 25;

const textboxSize = {
  w: defaultNodeSize.w * 0.55,
};

const iconSize = { size: defaultNodeSize.w * 0.17 };
const sideMargin = defaultNodeSize.w * 0.15;
export const iconIdIdentifierSuffix = "-icon";

export function inputNode(
  svgMain: string,
  svgAuxiliary: string,
  nodeId: string,
  node: InputNode
) {
  const drawMain = SVG(svgMain);
  const drawAuxiliary = SVG(svgAuxiliary);
  drawMain
    .clear()
    .id(nodeId)
    .addClass("node-kind-input")
    .addClass(`controller-0`);

  // 0. Start with some defaults. Textbox size will determine the final node size.
  let actualSize = Object.assign({}, defaultNodeSize);
  let lineWidth = minLineWidth;
  let wordsWithComputedWidth = calculateWordWidths(node.nodeCopy);
  let lines = calculateLines(wordsWithComputedWidth, lineWidth);
  let fontSize = "23";

  if (lines.length > 3) {
    actualSize = Object.assign({}, maxDefaultNodeSize);
    lineWidth = maxLineWidth;
    fontSize = "20";
  }
  // let xspan = "11.8em";

  // 2. Main viewbox & node background

  drawMain
    .viewbox(0, 0, actualSize.w, actualSize.h)
    .size(actualSize.w, actualSize.h);
  drawUnicoloredInputRectangle(drawMain, actualSize, node.dataType, nodeId);

  console.log(actualSize.w - textboxSize.w);
  console.log(actualSize.h / 2);

  // 3. Generate textbox.
  // drawMain.svg(
  //   generateSingleLineTextWithInRect(
  //     node.nodeCopy,
  //     actualSize.w - textboxSize.w,
  //     actualSize.h / 2,
  //     lineWidth,
  //     fontStyles,
  //     "inputNode",
  //     xspan
  //   )
  // );

  // 3. New Implimentation for generation of text box

  var nested = drawMain.nested().attr({
    height: "50",
    width: "200",
  });

  var group = nested.group().translate(275, -40);

  let y;
  let x = 13;

  if (lines.length === 1) {
    y = 105;
  } else if (lines.length === 2) {
    y = 91;
  } else {
    y = 87;
  }
  var text = drawMain
    .text(function(add) {
      for (let i = 0; i < lines.length; i++) {
        add
          .tspan(lines[i])
          .x(x)
          .y(y);
        y = y + 25;
      }
    })
    .font({
      family: fontsSVGLibrary.node.family,
      weight: 400,
      size: fontSize,
      fill: "#57585B",
      anchor: "middle",
    });

  group.add(text);
  // if (lines.length < 3) {
  //   let y = 55;
  //   let x = 15;
  //   for (let i = 0; i < lines.length; i++) {
  //     group.add(
  //       drawMain
  //         .text(lines[i])
  //         .x(x)
  //         .y(y)
  //         .attr({
  //           position: "absolute",
  //         })
  //         .font({
  //           family: "CiscoSansGlobal",
  //           weight: 400,
  //           size: "23",
  //           fill: "#57585B",
  //           anchor: "middle",
  //           "dominant-baseline": "central",
  //           "alignment-baseline": "middle",
  //         })
  //     );
  //     y = y + 25;
  //   }
  // } else {
  //   wordsWithComputedWidth = calculateWordWidths(node.nodeCopy);
  //   lines = calculateLines(wordsWithComputedWidth, lineWidth);
  //   let y = 55;
  //   let x = 15;
  //   for (let i = 0; i < lines.length; i++) {
  //     group.add(
  //       drawMain
  //         .text(lines[i])
  //         .x(x)
  //         .y(y)
  //         .dy(20)
  //         .font({
  //           family: fontsSVGLibrary.node.family,
  //           weight: 400,
  //           size: "20",
  //           fill: "#57585B",
  //           anchor: "middle",
  //           "dominant-baseline": "central",
  //         })
  //     );
  //     y = y + 25;
  //   }
  // }

  
  // 4. Icon.
  // 4.1. Create a group to contain the icon, which makes the following transformation easier to reason with.
  const gIcon = drawMain.group().id(`${nodeId}${iconIdIdentifierSuffix}`);
  const svgIcon = gIcon.svg(
    R.compose(
      decodeURIComponent,
      escape,
      atob
    )(node.iconSvgString)
  );

  // 4.2. Change fill color.
  svgIcon.select("path,rect").each(function() {
    this.attr({ fill: fontsSVGLibrary.node.fill });
    this.attr({ stroke: fontsSVGLibrary.node.fill });
  });

  // 4.3. Will now attempt to scale it so that it's close to iconSize.size in both width and height.
  // Then, we move it to the left.
  const { width, height } = gIcon.bbox();
  const maxDimension = Math.max(width, height);
  const scaleFactor = iconSize.size / maxDimension;

  // Ankit Tharwani: changed the y-axis to factor in non-square icons
  gIcon.cy(actualSize.h / 2 - (maxDimension - height) / 2);
  gIcon.x(sideMargin);
  gIcon.scale(scaleFactor, scaleFactor);

  // 5. Memorize the result & cleanup...
  const result = drawMain.svg();
  drawMain.clear();
  drawAuxiliary.clear();

  return result;
}

export type Output = InputNode & SVGRepresentation;

export function generateInputNodesInner(
  inputNodesData: $NonMaybeType<$PropertyType<JSONSchema, "inputNodes">>,
  SVGMainId: string,
  SVGAuxId: string,
  svgGenerator: typeof inputNode
): Array<Output> {
  const maskIndex = R.compose(
    R.flip(R.prop)(colorOrder),
    R.prop("maskHumanReadable")
  );

  return R.compose(
    R.sortWith([
      R.ascend(maskIndex),
      R.ascend(R.prop("dataType")),
      R.ascend(R.prop("nodeId")),
    ]),
    R.map(
      ([nodeId, content]) =>
        ({
          svg: svgGenerator(SVGMainId, SVGAuxId, nodeId, content),
          nodeId,
          ...content,
        }: Output)
    ),
    R.toPairs
  )(inputNodesData);
}

export function generateInputNodes(
  inputNodesData: $NonMaybeType<$PropertyType<JSONSchema, "inputNodes">>,
  SVGMainId: string,
  SVGAuxId: string,
  shouldProduceDummyNodes: boolean
): Array<Output> {
  return generateInputNodesInner(
    inputNodesData,
    SVGMainId,
    SVGAuxId,
    !shouldProduceDummyNodes ? inputNode : emptySVGElement
  );
}
