import * as api from "../apis/datamaps.api";
import { SET_DATAMAP_ID , IS_EDIT_FLOW} from "./constant";

export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const ADD_GENERAL_INFO = "ADD_GENERAL_INFO";
export const ADD_PRODUCTION_INFO = "ADD_PRODUCTION_INFO";
export const SET_DATA_TYPES = "SET_DATA_TYPES";


export const createData = () => {
  return async (dispatch) => {
    const response = await api.createNewMap({});
    dispatch({
      type: ADD_GENERAL_INFO,
      data: response.data
    })
  }
}

export function addGeneralInfo(generalData) {

  return async (dispatch) => {
    console.log("calling create new map api...")
    const res = await api.createNewMap(generalData);
    if (res && res.status === 200) {
      window.MAP_DATA = res.data;
      dispatch({
        type: ADD_GENERAL_INFO,
        data: generalData.generalInfo
      })
      dispatch({
        type: SET_DATAMAP_ID,
        data: res.data ? res.data.datamapId : ""
      })
      dispatch({
        type: IS_EDIT_FLOW,
        data: false
      })
    }
  }

  // return {
  //   type: ADD_GENERAL_INFO,
  //   data: generalData.generalInfo
  // }
}

export function addProductionInfo(productionInfo) {
  return {
    type: ADD_PRODUCTION_INFO,
    data: productionInfo
  };
}

export function setInitialState(jsonData) {
  return {
    type: SET_INITIAL_STATE,
    data: jsonData
  };
}

export function setDataTypes(data) {
  let dataTypes = {};
  let count = []
  for (let key in data) {
    let obj = data[key];
    if (obj && obj.dataType && !count.includes(obj.dataType)) {
      if (obj.dataType === "0") {
        dataTypes["registrationData"] = {
          datatype: "Registered Data",
          dataAssets: obj.dataAssets ? obj.dataAssets : ""
        };
        count.push("0")
      }
      if (obj.dataType === "1") {
        dataTypes["generatedData"] = {
          datatype: "Generated Data",
          dataAssets: obj.dataAssets ? obj.dataAssets : ""
        };
        count.push("1")
      }
      if (obj.dataType === "2") {
        dataTypes["hostUsageData"] = {
          datatype: "Host & Usage Data",
          dataAssets: obj.dataAssets ? obj.dataAssets : ""
        };
        count.push("2")
      }
    }
  }
  return {
    type: SET_DATA_TYPES,
    data: dataTypes
  };
}

export function setDataTypeValues(data) {
  return {
    type: SET_DATA_TYPES,
    data: data
  };

}