// @flow

import { fontsSVGLibrary } from "../../../styles";
import { calloutBoxId } from "../callOutSvg";

// Uses a secondary SVG element to "preview" how much space the text takes.
// Then, will attempt to break it line by line, incrementing the maximum allowed width by 20 units if there are more
// than 4 lines.
// SVG doesn't have word wrap and SVG.js doesn't provide much beyond putting the rendering behind an API.
//
// baran : I didn't want to bring in an entire library like Fabric just to have text resize. Adding a canvas rendering
// library in the middle of the pipeline felt a bit too much.
export function wordWrapText(
  drawAuxiliary: any,
  source: string,
  textboxSize: { w: number },
  lineHeight: number,
  maxLinesAllowed?: number
): { lines: Array<string>, targetWidth: number } {
  if (!maxLinesAllowed) {
    maxLinesAllowed = 4;
  }

  const targetWidthOriginal = textboxSize.w;
  let allowedIterationCount = 30;
  let targetWidth = targetWidthOriginal;
  let lines = [];

  // eslint-disable-next-line
  while (allowedIterationCount > 0) {
    drawAuxiliary.clear();
    drawAuxiliary.size(targetWidth, lineHeight);

    let sourceArr = source.match(/(\S+)/g) || [];
    let cache = "";
    lines = [];

    while (sourceArr.length > 0) {
      drawAuxiliary.clear();
      const tmp = `${cache}${sourceArr[0]} `;
      const textbox = drawAuxiliary.text(tmp).font({ ...fontsSVGLibrary.node });

      if (textbox.length() > targetWidth) {
        lines = [...lines, cache];
        cache = `${sourceArr[0]} `;
      } else {
        cache = tmp;
      }

      sourceArr.shift();
    }
    lines = [...lines, cache];

    if (lines.length > maxLinesAllowed) {
      targetWidth = targetWidth + lineHeight;
    } else {
      break;
    }

    allowedIterationCount--;
  }

  return { lines, targetWidth };
}

// SVG.js binds this to the current element ; we shouldn't change this to an arrow function.
export function toFront() {
  this.front();
}

export function removeElement(draw: any, selectQueryString: string) {
  draw.select(selectQueryString).each(function() {
    this.remove();
  });
}

export function reorderDatamapContent(drawContent: any) {
  drawContent.select(".datamap-line.linetype-0").each(toFront);
  drawContent.select(".datamap-line.linetype-1").each(toFront);
  drawContent.select(".datamap-line.linetype-2").each(toFront);

  drawContent.select(`#${calloutBoxId}`).each(function() {
    this.remove();
    drawContent.add(this);
    toFront.call(this);
  });
  drawContent.select("#contracts").each(function() {
    this.remove();
    drawContent.add(this);
    toFront.call(this);
  });
  drawContent.select("#contracts-subprocessor").each(function() {
    this.remove();
    drawContent.add(this);
    toFront.call(this);
  });
  drawContent.select(".datamap-node").each(toFront);
}
