// @flow

import React, { Component } from "react";
import * as R from "ramda";
import "./index.scss";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addInputNodeData } from "../../actions/inputNodesAction";
import Error from "../svgIcons/error";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Warning from "../svgIcons/warning";
import Snackbar from "@material-ui/core/Snackbar";
import { editInputNodes } from "../../actions/reviewAction";
import { saveInputNodes, toggleIsUpdated } from "../../actions/metaDataAction";

class InputNodeSection extends Component<any, any> {
  constructor(props) {
    super(props);
    this.handleAddNode = this.handleAddNode.bind(this);
  }
  state = {
    productInfo: "",
    selectedValue: "0",
    setOpen: false,
    nodeCopy: "",
    error: false,
    warning: false,
    dataAssests: true,
    iconSvgString: "",
    fileName: "",
    nodeiconError: false,
    openGoBack: false,
    nodeCount: 1,
    registrationDataAssets: "",
    generatedDataAssets: "",
    hostUsageDataAssets: "",
    registrationDataType: "",
    generatedDataType: "",
    hostUsageDataType: "",
    addedNodes: [],
    showAddNodeButton: true,
    showUpdateNodeButton: false,
    selectedIndex: "",
    dataAssestsValue: "",
    openDeleteNode: false,
    openAddNodeError: false,
    showSnackbar: false,
    openPartiallyFilled: false,
    openOneNodePerType: false,
    changed: false,
    proceedIndex: "0",
    showUpdateSnackbar: false,
  };

  componentDidMount() {
    this.props.onRef(this);
    const { editData } = this.props;
    if (!R.isEmpty(editData) && !R.isNil(editData)) {
      let data = this.state.addedNodes[editData.nodeIndex];
      this.setState({
        iconSvgString: data.iconSvgString,
        selectedValue: data.dataType,
        nodeCopy: data.nodeCopy,
        dataAssestsValue: data.dataAssets,
        showAddNodeButton: false,
        showUpdateNodeButton: true,
        selectedIndex: editData.nodeIndex,
      });
    }
  }

  componentWillUnmount() {
    this.props.editInputNodes({});
  }

  UNSAFE_componentWillMount() {
    const { data, dataTypes } = this.props;
    console.log(dataTypes)
    if (dataTypes) {
      if (this.state.selectedValue === "0") {
        this.setState({
          dataAssestsValue:
            dataTypes.registrationData && dataTypes.registrationData.dataAssets,
        });
      }

      dataTypes.forEach((dt, i) => {
        if (i === 0) {
          if (this.state.selectedValue === "0") {
            this.setState({
              dataAssestsValue: dt.dataAssets,
              registrationDataAssets: dt.dataAssets,
              registrationDataType: dt.dataType,
            });
          }
          else {
            this.setState({
              registrationDataAssets: dt.dataAssets,
              registrationDataType: dt.dataType,
            })
          }
        }
        else if (i === 1) {
          this.setState({
            generatedDataAssets: dt.dataAssets,
            generatedDataType: dt.dataType,
          })
        }
        else if (i === 2) {
          this.setState({
            hostUsageDataAssets: dt.dataAssets,
            hostUsageDataType: dt.dataType,
          })
        }
      })


      // this.setState({
      //   registrationDataAssets:
      //     dataTypes.registrationData && dataTypes.registrationData.dataAssets,
      //   generatedDataAssets:
      //     dataTypes.generatedData && dataTypes.generatedData.dataAssets,
      //   hostUsageDataAssets:
      //     dataTypes.hostUsageData && dataTypes.hostUsageData.dataAssets,
      //   registrationDataType:
      //     dataTypes.registrationData && dataTypes.registrationData.datatype,
      //   generatedDataType:
      //     dataTypes.generatedData && dataTypes.generatedData.datatype,
      //   hostUsageDataType:
      //     dataTypes.hostUsageData && dataTypes.hostUsageData.datatype,
      // });
    }
    if (data) {
      let nodes = [];
      for (let key in data) {
        nodes.push(data[key]);
      }
      this.setState({
        addedNodes: nodes,
      });
    }
  }

  islocalandstateEqual = (index) => {
    let selectedNode = this.state.addedNodes[index];
    if (
      selectedNode.iconSvgString === this.state.iconSvgString &&
      selectedNode.nodeCopy === this.state.nodeCopy &&
      selectedNode.dataType === this.state.selectedValue
    ) {
      return true;
    }
    return false;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let nodes = [];
      for (let key in nextProps.data) {
        nodes.push(nextProps.data[key]);
      }
      this.setState({
        addedNodes: nodes,
      });
    }
  }

  isPartialFilled = () => {
    if (!this.state.nodeCopy && this.state.iconSvgString) {
      return true;
    }
    if (this.state.nodeCopy && !this.state.iconSvgString) {
      return true;
    }
    if (this.state.nodeCopy && this.state.iconSvgString) {
      return true;
    }
    return false;
  };

  handleInputChange = (ev: any) => {
    let file = R.pathOr([], ["target", "files"])(ev);
    // If no icon was selected, don't continue.
    if (file.length === 0) return;
    file = file[0];
    let fileName = file && file.name;
    this.setState({
      fileName,
    });
    const reader = new FileReader();

    reader.onload = (evReader: any) => {
      const iconSvgString = R.compose(
        btoa,
        unescape,
        encodeURIComponent
      )(evReader.target.result);

      this.setState({
        iconSvgString,
        changed: true,
      });
    };
    reader.readAsText(file);
  };

  handleAddNode() {
    let data = [...this.state.addedNodes];
    data.push({
      iconSvgString: this.state.iconSvgString,
      dataType: this.state.selectedValue,
      nodeCopy: this.state.nodeCopy,
      dataAssets: this.state.dataAssestsValue,
    });

    this.props.addInputNodeData(data);

    this.setState({
      iconSvgString: "",
      selectedValue: "0",
      nodeCopy: "",
      dataAssestsValue: "",
      showSnackbar: true,
      changed: false,
    });
  }


  saveInputNodes() {
    if (this.props.isEdit) {
      this.props.toggleIsUpdated(true);
    }
    else {
      const { data } = this.props;
      console.log(data)
      this.props.saveInputNodes(data);
    }
  }

  handleChange = (value) => {
    this.setState({
      selectedValue: value,
      changed: true,
    });
    if (value === "0") {
      this.setState({
        dataAssestsValue: this.state.registrationDataAssets,
      });
    }
    if (value === "1") {
      this.setState({
        dataAssestsValue: this.state.generatedDataAssets,
      });
    }
    if (value === "2") {
      this.setState({
        dataAssestsValue: this.state.hostUsageDataAssets,
      });
    }
  };

  handleClickOpen = () => {
    this.setState({
      setOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      setOpen: false,
    });
  };
  changeprod = (event) => {
    this.setState({
      nodeCopy: event.target.value,
      error: false,
      changed: true,
    });
  };

  message = (event) => {
    if (!event.target.value) {
      this.setState({
        error: true,
      });
    } else {
      this.setState({
        error: false,
      });
    }
  };

  validationDataInputs() {
    // const { dataTypes } = this.props;

    // let dataId = [];
    // let datatypeCount = [];
    // for (let key in dataTypes) {
    //   if (!R.isEmpty(dataTypes[key].dataAssets)) {
    //     dataId.push(dataTypes[key].id);
    //   }
    // }

    // let addnodes = [...this.state.addedNodes];

    // for (let i = 0; i < addnodes.length; i++) {
    //   datatypeCount.push(addnodes[i].dataType);
    // }

    // for (let d of dataId) {
    //   if (!R.contains(d, datatypeCount)) {
    //     this.setState({
    //       openOneNodePerType: true,
    //     });
    //     return false;
    //   }
    // }

    // if (this.state.addedNodes.length <= 0) {
    //   this.setState({
    //     openAddNodeError: true,
    //   });
    //   return false;
    // }

    // if (this.state.addedNodes.length > 0) {
    //   if (this.state.selectedIndex >= 0 && this.state.showUpdateNodeButton) {
    //     const islocalandstateEqual = this.islocalandstateEqual(
    //       this.state.selectedIndex
    //     );
    //     if (islocalandstateEqual) {
    //       return true;
    //     } else {
    //       this.setState({
    //         openGoBack: true,
    //       });
    //       return false;
    //     }
    //   }
    // }

    // if (
    //   !this.state.iconSvgString &&
    //   this.state.nodeCopy &&
    //   this.state.addedNodes.length > 0
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.iconSvgString &&
    //   !this.state.nodeCopy &&
    //   this.state.addedNodes.length > 0
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.iconSvgString &&
    //   this.state.nodeCopy &&
    //   this.state.addedNodes.length > 0
    // ) {
    //   this.setState({
    //     openGoBack: true,
    //   });
    //   return false;
    // }
    return true;
  }

  decodeSvgString = (value) => {
    let decodedSvg = "";
    if (!R.isEmpty(value) && value) {
      decodedSvg = R.compose(
        decodeURIComponent,
        escape,
        atob
      )(value);
    }
    return decodedSvg;
  };
  onDeleteIcon = () => {
    this.setState({
      iconSvgString: "",
      nodeiconError: true,
    });
  };

  handleCheckClick = (index) => {
    const isPartialFilled = this.isPartialFilled();
    if (isPartialFilled && this.state.changed) {
      this.setState({
        openPartiallyFilled: true,
        proceedIndex: index,
      });
    } else {
      this.handleOnClickNode(index);
    }
  };

  handleOnClickNode = (index) => {
    this.setState({
      showAddNodeButton: false,
      showUpdateNodeButton: true,
      selectedIndex: index,
      changed: false
    });
    let data = this.state.addedNodes[index];
    this.setState({
      iconSvgString: data.iconSvgString,
      selectedValue: data.dataType,
      nodeCopy: data.nodeCopy,
      dataAssestsValue: data.dataAssets,
    });
  };

  handleUpdateNode = () => {
    let nodes = [...this.state.addedNodes];
    let data = nodes[this.state.selectedIndex];
    data.iconSvgString = this.state.iconSvgString;
    data.dataType = this.state.selectedValue;
    data.nodeCopy = this.state.nodeCopy;
    data.dataAssets = this.state.dataAssestsValue;
    nodes[this.state.selectedIndex] = data;
    this.setState({
      // addedNodes: nodes,
      iconSvgString: "",
      selectedValue: "0",
      nodeCopy: "",
      dataAssestsValue: this.state.registrationDataAssets,
      showAddNodeButton: true,
      showUpdateNodeButton: false,
      changed: false,
      showUpdateSnackbar: true,
    });

    this.props.addInputNodeData(nodes);
  };
  handleCancel = () => {
    this.setState({
      iconSvgString: "",
      selectedValue: "0",
      nodeCopy: "",
      dataAssestsValue: this.state.registrationDataAssets,
      showUpdateNodeButton: false,
      showAddNodeButton: true,
      changed: false,
    });
  };

  handleOpenPopUpDelete = (index) => {
    this.setState({
      selectedIndex: index,
      openDeleteNode: true,
    });
  };

  handleDeleteNode = () => {
    let nodes = [...this.state.addedNodes];
    nodes.splice(this.state.selectedIndex, 1);
    this.props.addInputNodeData(nodes);

    this.setState({
      iconSvgString: "",
      selectedValue: "0",
      nodeCopy: "",
      dataAssestsValue: this.state.registrationDataAssets,
      showUpdateNodeButton: false,
      showAddNodeButton: true,
    });
  };

  render() {
    const isEnabled =
      this.state.iconSvgString &&
      this.state.selectedValue &&
      this.state.nodeCopy;
    // this.state.registrationDataAssets;
    return (
      <div style={{
        display: "flex", justifyContent: "space-evenly", overflow: "auto",
        height: "550px", paddingBottom: "15%"
      }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className="snackbar"
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({
              showSnackbar: false,
            });
          }}
          message="Node successfully added."
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className="snackbar"
          open={this.state.showUpdateSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({
              showUpdateSnackbar: false,
            });
          }}
          message="Node successfully updated."
        />
        <Dialog
          open={this.state.openOneNodePerType}
          onClose={() => {
            this.setState({
              openOneNodePerType: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you want to move on without adding a data input node for the all
            data types?
          </DialogTitle>

          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openOneNodePerType: false,
                  });
                }}
                color="primary"
              >
                Add nodes now
              </Button>
              <Button
                className="primary-button "
                onClick={() => {
                  this.props.handleNext();
                  this.setState({
                    openOneNodePerType: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Come back later
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openPartiallyFilled}
          onClose={() => {
            this.setState({
              openPartiallyFilled: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You have unsaved changes. Are you sure you want to proceed?
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Editing a node will cause you to lose your current progress.
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openPartiallyFilled: false,
                  });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => {
                  this.handleOnClickNode(this.state.proceedIndex);
                  this.setState({
                    openPartiallyFilled: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Proceed
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openGoBack}
          onClose={() => {
            this.setState({
              openGoBack: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You have unsaved changes. Are you sure you want to proceed?
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openGoBack: false,
                  });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => {
                  this.props.handleNext();
                  this.setState({
                    openGoBack: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Proceed
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDeleteNode}
          onClose={() => {
            this.setState({
              openDeleteNode: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this node?
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="secondary-button"
                onClick={() => {
                  this.setState({
                    openDeleteNode: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                className="danger-button"
                onClick={() => {
                  this.handleDeleteNode();
                  this.setState({
                    openDeleteNode: false,
                  });
                }}
                color="primary"
              >
                Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openAddNodeError}
          onClose={() => {
            this.setState({
              openDeleteNode: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            please create a node first
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                className="primary-button"
                onClick={() => {
                  this.setState({
                    openAddNodeError: false,
                  });
                }}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <div
          style={{
            width: "50%",
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "3%",
          }}
        >
          <div
            className="input-label"
            style={{
              paddingBottom: "10px",
            }}
          >
            Data Type <span className="color-red">*</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={this.state.selectedValue === "0"}
              onChange={() => this.handleChange("0")}
              value="a"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
              className="radio-button"
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#4EB3DF",
              }}
            />
            <div
              style={{
                fontSize: "16px",
                fontFamily: "CiscoSansTTLight",
                color: "#39393B",
              }}
            >
              {this.state.registrationDataType}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={this.state.selectedValue === "1"}
              onChange={() => this.handleChange("1")}
              value="b"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
              className="radio-button"
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#ffc435",
              }}
            />
            <div
              style={{
                fontSize: "16px",
                fontFamily: "CiscoSansTTLight",
                color: "#39393B",
              }}
            >
              {this.state.generatedDataType}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={this.state.selectedValue === "2"}
              onChange={() => this.handleChange("2")}
              value="c"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
              className="radio-button"
            />
            <div
              style={{
                float: "left",
                height: "20px",
                width: "20px",
                margin: "15px",
                backgroundColor: "#e03c2d",
              }}
            />
            <div
              style={{
                fontSize: "16px",
                fontFamily: "CiscoSansTTLight",
                color: "#39393B",
              }}
            >
              {this.state.hostUsageDataType}
            </div>
          </div>
          <div
            className="input-label"
            style={{
              paddingBottom: "20px",
              paddingTop: "40px",
            }}
          >
            Data Assets
          </div>
          <div
            style={{
              fontSize: "16px",
              fontFamily: "CiscoSansTTLight",
              color: "#39393B",
            }}
          >
            {this.state.selectedValue === "0" ? (
              <div>
                {" "}
                {this.state.registrationDataAssets ? (
                  this.state.registrationDataAssets
                ) : (
                  <div style={{ color: "#916c00", width: "428px" }}>
                    Please add data assets for this data type on the Product
                    Information page.
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {this.state.selectedValue === "1" ? (
              <div>
                {this.state.generatedDataAssets ? (
                  this.state.generatedDataAssets
                ) : (
                  <div style={{ color: "#916c00", width: "428px" }}>
                    Please add data assets for this data type on the Product
                    Information page.
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {this.state.selectedValue === "2" ? (
              <div>
                {this.state.hostUsageDataAssets ? (
                  this.state.hostUsageDataAssets
                ) : (
                  <div style={{ color: "#916c00", width: "428px" }}>
                    Please add data assets for this data type on the Product
                    Information page.
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="input-label"
            style={{
              paddingBottom: "20px",
              paddingTop: "40px",
            }}
          >
            Node Text <span className="color-red">*</span>
          </div>
          <div className="border-bottom-1 input-wrapper width-428">
            <input
              placeholder="e.g., Customer creates an account"
              className="input-text-active width-428"
              value={this.state.nodeCopy}
              id="test"
              onBlur={() => this.message(event)}
              onChange={() => this.changeprod(event)}
            />
            {this.state.error ? <Error /> : ""}
            {this.state.nodeCopy.length >= 60 ? <Warning /> : ""}
          </div>
          {this.state.error ? (
            <div style={{ color: "#CD0000", width: "428px" }}>
              Please enter the text that should appear in the node.
            </div>
          ) : (
            ""
          )}
          {this.state.nodeCopy.length >= 60 &&
            this.state.nodeCopy.length < 100 ? (
            <div style={{ color: "#916C00", width: "428px" }}>
              Node text size will be smaller if character count exceeds 60.
            </div>
          ) : (
            ""
          )}
          {this.state.nodeCopy.length >= 100 ? (
            <div style={{ color: "#916C00", width: "428px" }}>
              Node will expand beyond its usual size if character count exceeds
              100.
            </div>
          ) : (
            ""
          )}
          <p className="helper-text">
            Character count: {this.state.nodeCopy.length}/60
          </p>
          <div
            className="input-label"
            style={{
              paddingBottom: "20px",
              paddingTop: "40px",
            }}
          >
            Node Icon <span className="color-red">*</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "428px",
              justifyContent: "space-between",
              paddingBottom: "40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.iconSvgString ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    id={`iconPreviewImage-${1234}`}
                    dangerouslySetInnerHTML={{
                      __html: this.decodeSvgString(this.state.iconSvgString),
                    }}
                  />
                  <div>
                    <div
                      style={{
                        color: "#39393b",
                        paddingLeft: "20px",
                        fontSize: "16px",
                        fontFamily: "CiscoSansTTLight",
                      }}
                    >
                      {" "}
                      {this.state.fileName}
                    </div>
                    <div
                      style={{
                        fontStyle: "italic",
                        fontSize: "14px",
                        paddingLeft: "20px",
                        color: "#9E9EA2",
                        fontFamily: "CiscoSansTTLight",
                      }}
                    >
                      Accepted file type:.svg
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#E8EBF1",
                      borderRadius: "5px",
                      borderStyle: "dotted",
                      borderColor: "lightgray",
                      borderWidth: "2pxc",
                    }}
                  />
                  <div>
                    <div
                      style={{
                        color: "#9E9EA2",
                        paddingLeft: "20px",
                        fontSize: "16px",
                        fontFamily: "CiscoSansTTLight",
                      }}
                    >
                      No file selected
                    </div>
                    {this.state.nodeiconError ? (
                      <div
                        style={{
                          color: "#cd0000",
                          paddingLeft: "20px",
                          fontSize: "14px",
                          fontFamily: "CiscoSansTTLight",
                        }}
                      >
                        Please upload an icon for the node.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
            {this.state.iconSvgString ? (
              <div onClick={this.onDeleteIcon}>
                <Error />
              </div>
            ) : (
              ""
            )}
            <label className="secondary-button-lable cursor-pointer">
              {" "}
              Choose File
              <input
                id="load-icon"
                type="file"
                accept=".svg"
                onChange={(ev) => this.handleInputChange(ev)}
              />
            </label>
          </div>
          <div
            style={{
              width: "428px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {this.state.showAddNodeButton ? (
              <Button
                variant="contained"
                disabled={!isEnabled}
                className="primary-button"
                // onClick={() => {this.handleAddNode}}
                onClick={this.handleAddNode}
              >
                Add Node
              </Button>
            ) : (
              ""
            )}
            {this.state.showUpdateNodeButton ? (
              <div>
                <Button
                  variant="contained"
                  disabled={!isEnabled}
                  className="primary-button"
                  // onClick={() => {this.handleAddNode}}
                  onClick={this.handleUpdateNode}
                >
                  Update Node
                </Button>{" "}
                <div
                  className="cursor-pointer"
                  style={{ textAlign: "center" }}
                  onClick={this.handleCancel}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          style={{
            width: "30%",
            height: "calc(100vh - 160px)",
            minHeight: "calc(100vh - 160px)",
            overflowY: "auto",
            backgroundColor: "#E8EBF1",
            paddingTop: "5%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "70%", height: "max-content" }}>
              {this.state.addedNodes.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div className="padding15-datainput">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleCheckClick(index)}
                        >
                          {data.dataType === "0" ? (
                            <div className="Registration height-30 border-top-left-right-8" />
                          ) : (
                            ""
                          )}
                          {data.dataType === "1" ? (
                            <div className="Generated height-30 border-top-left-right-8" />
                          ) : (
                            ""
                          )}
                          {data.dataType === "2" ? (
                            <div className="Host-and-Usage height-30 border-top-left-right-8" />
                          ) : (
                            ""
                          )}
                          <div
                            className="text-processingnode"
                            style={{
                              fontSize: "14px",
                              color: "#39393B",
                              fontFamily: "CiscoSansTTRegular",
                            }}
                          >
                            {data.nodeCopy}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        fontStyle: "italic",
                        textDecoration: "underline",
                        color: "black",
                      }}
                      onClick={() => this.handleOpenPopUpDelete(index)}
                    >
                      <Error />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addInputNodeData, editInputNodes, saveInputNodes, toggleIsUpdated }, dispatch);
};

const mapStateToProps = (state) => {
  console.log(state)
  return {
    data: state.inputnodes.inputNodes,
    dataTypes: state.productInfo.data && state.productInfo.data.dataTypes,
    editData: state.editData.editIN,
    isEdit: state.metaData.isEditFlow
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputNodeSection);
