// @flow
import type { DataEntryFormOutput } from "../../dataEntry/types";

const generateMetaCommon = (identifier: string, value: DataEntryFormOutput) => {
  return `<text fill-opacity="0" id=${identifier}>${JSON.stringify(
    value
  )}</text>`;
};

export const generateFormValuesMetaData = (formValues: DataEntryFormOutput) => {
  return generateMetaCommon("datamap-formvalues", formValues);
};

export const generateDrawMetaData = (meta: any) => {
  return generateMetaCommon("datamap-drawmeta", meta);
};
