// @flow
import React from "react";
import * as R from "ramda";
import "./index.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { generateSVGRoutine } from "../../svgGenerationLogic/features/generateSvg/actions";
import { validatedSubmit } from "../utils/validatedSubmit";
import type { MaybeErrorType } from "../utils/validatedSubmit/types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const InputNodeIndividualEntryPrefix = "inputNodeForm";
export const ProcessingNodeIndividualEntryPrefix = "processNodeForm";
export const RetentionNodeIndividualEntryPrefix = "retentionNodeForm";

const useStyles = (theme) => ({
  root: {
    padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 3}px 0`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
  },
});

class SVGGeneration extends React.Component<any, any> {
  state = {
    formvalues: {},
    calloutError: "",
    nodeError: "",
    nodeCountError: "",
    isError: false,
    disable: false,
    showDialog: false,
  };

  componentDidMount() {

    console.log("svg generation")
    let saveData = {
      svgRegeneration: {
        svgRegenerationFlag: "fresh",
      },
    };
    const {
      generalInfo,
      productInfo,
      datacenters,
      inputnodes,
      processingnodes,
      retentionnodes,
      svgRegeneration
    } = this.props;


    console.log(generalInfo)
    console.log(productInfo);

    // if (generalInfo) {
    //   saveData = { ...saveData, ...generalInfo };
    // }

    if(generalInfo && productInfo && productInfo.dataTypes){
      console.log("jlakdjlalfjal afoeaor oi")
      let gData = {...generalInfo, ...productInfo};
      let dataTypes = {};
      productInfo.dataTypes.forEach((val, i)=>{
        if(i === 0){
          dataTypes["registrationData"] = {
            datatype: val.dataType? val.dataType: "",
            dataAssets: val.dataType ? val.dataType : ""
          };
        }
        else if (i===1) {
          dataTypes["generatedData"] = {
            datatype: val.dataType? val.dataType: "",
            dataAssets: val.dataType ? val.dataType : ""
          };
        }
        if (i===2) {
          dataTypes["hostUsageData"] = {
            datatype: val.dataType? val.dataType: "",
            dataAssets: val.dataType ? val.dataType : ""
          };
        }
      });

      gData["dataTypes"] = dataTypes;
      saveData["generalInfo"] = gData;

    }

    if (datacenters) {
      saveData = { ...saveData, ...datacenters };
    }

    if (inputnodes) {
      let inputNodesObj = {};
      for (let i = 0; i < inputnodes.length; i++) {
        // let node = nodes[i];
        // node.dataAssets = types[node.dataType].dataAssets;
        const identifier = `${InputNodeIndividualEntryPrefix}-${i + 1}`;
        inputNodesObj[identifier] = inputnodes[i];
      }

      saveData["inputNodes"] = inputNodesObj;
    }


    if (processingnodes) {
      let combinedNodes = [];
      let processingNodesObj = {};
      const { customerNodes, ciscoNodes, subprocessorNodes } = processingnodes;

      if (ciscoNodes) {
        combinedNodes = [...combinedNodes, ...ciscoNodes];
      }
      if (customerNodes) {
        combinedNodes = [...combinedNodes, ...customerNodes];
      }
      if (subprocessorNodes) {
        combinedNodes = [...combinedNodes, ...subprocessorNodes];
      }

      for (let i = 0; i < combinedNodes.length; i++) {
        // const node = {...combinedNodes[i]};
        // const identifer = node.identifer
        // delete node.identifer
        // processingNodesObj[identifer] = node
        const identifier = `${ProcessingNodeIndividualEntryPrefix}-${i + 1}`;
        processingNodesObj[identifier] = combinedNodes[i]; 

      }
      console.log(processingNodesObj)
      saveData["processingNodes"] = processingNodesObj;
    }

    if (retentionnodes) {
      let combinedNodes = [];
      let retentionNodesObj = {};
      const { ciscoNodes, subprocessorNodes } = retentionnodes;

      if (ciscoNodes) {
        combinedNodes = [...combinedNodes, ...ciscoNodes];
      }
      if (subprocessorNodes) {
        combinedNodes = [...combinedNodes, ...subprocessorNodes];
      }

      for (let i = 0; i < combinedNodes.length; i++) {
        const identifier = `${RetentionNodeIndividualEntryPrefix}-${i + 1}`;
        retentionNodesObj[identifier] = combinedNodes[i];
      }
      saveData["retentionNodes"] = retentionNodesObj;
    }
    if(svgRegeneration){
      let obj = {
        svgRegenerationFlag: svgRegeneration
      }
      saveData["svgRegeneration"] = obj;
    }

    console.log(saveData);
    this.setState({ formvalues: saveData });
  }

  componentDidUpdate(prevProps) {
    const { finalSvgCode } = this.props;
    if (finalSvgCode !== prevProps.finalSvgCode) {
      this.setState({
        disable: true,
        showDialog: true,
      });
    }
  }

  alertErrors = (validationErrors) => {
    if (!R.isEmpty(validationErrors.errors)) {
      const {
        nodeValidity,
        calloutValidity,
        nodeCountValidity,
      } = validationErrors.errors;

      if (calloutValidity) {
        let error = `- ${calloutValidity.label}: ${calloutValidity.error}\n`;
        this.setState({
          calloutError: error,
        });
      }
      if (nodeValidity) {
        let error = `- ${nodeValidity.label}: ${nodeValidity.error}\n`;
        this.setState({
          nodeError: error,
        });
      }

      if (nodeCountValidity) {
        let error = `- ${nodeCountValidity.label}: ${
          nodeCountValidity.error
        }\n`;
        this.setState({
          nodeCountError: error,
        });
      }

      this.setState({ isError: true });
    }
  };

  generateSvg = () => {
    if (!R.isEmpty(this.state.formvalues)) {
      validatedSubmit(this.state.formvalues, (validations: MaybeErrorType) =>
        R.isEmpty(validations)
          ? this.props.generateSVGRoutine(this.state.formvalues)
          : this.alertErrors(validations)
      );
    }
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.isError}
          onClose={() => {
            this.setState({
              openPartiallyFilled: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "700px"}}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.calloutError ? this.state.calloutError : ""}
            <br />
            {this.state.nodeError ? this.state.nodeError : ""}
            <br />
            {this.state.nodeCountError ? this.state.nodeCountError : ""}
          </DialogTitle>
          <DialogActions>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                className="primary-button"
                onClick={() => {
                  this.setState({
                    isError: false,
                  });
                }}
                color="primary"
              >
                OK
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showDialog}
          onClose={() => {
            this.setState({
              openPartiallyFilled: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you want to View the DashBoard?
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will be redirected to the Home page after you save.
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button
                  className="secondary-button"
                  onClick={() => {
                    this.props.clearProgress();
                    window.inputNodeCount = 1;
                    window.processNodeCount = 1;
                    window.retentionNodeCount = 1;
                  }}
                  color="primary"
                >
                  Go To DashBoard
                </Button>
              </Link>
              {/* <SaveProgressSection buttonType="button" /> */}
            </div>
          </DialogActions>
        </Dialog>
        <Button
          disabled={this.state.disable}
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            this.generateSvg();
            this.setState({
              disable: true,
            });
          }}
          className="primary-button"
        >
          Validate and Generate SVG
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    generateSVGRoutine: (data) => {
      dispatch(generateSVGRoutine(data));
    },
    clearProgress: () => {
      dispatch({ type: "RESET" });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    generalInfo: state.dataEntry.generalInfo,
    productInfo: state.productInfo.data,
    inputnodes: state.inputnodes.inputNodes,
    processingnodes: state.processingnodes,
    retentionnodes: state.retentionnodes,
    datacenters: state.dataCenters,
    dataTypes: state.dataEntry.generalInfo.dataTypes,
    finalSvgCode: state.svgGeneration.finalSvgCode,
    svgRegeneration: state.svgRegeneration.svgRegenerationFlag,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(SVGGeneration));
