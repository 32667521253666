// @flow
import * as R from "ramda";
import { SVG } from "../logic/svgWrapper";

import type { JSONSchema } from "../../types";
import { fontsPlainSVG, fontsSVGLibrary } from "../../../styles";
import { LeftXDataAssets, TopYDataAssets } from "../grid";
import { calculateWordWidths, calculateLines } from "../nodeSvg/nodesCommon";
import {
  getDefaultDataTypeColorMap,
  getDefaultDataTypeDetailsMap,
} from "../../dataEntry/sections/dataTypes";

export const DataAssetsSectionId = "section-dataassets-root";

export function drawDataAssetsSection(
  svgMain: string,
  svgAuxiliary: string,
  textFields: Array<[string, string]>,
  dataTypes: any
) {
  const drawMain = SVG(svgMain);
  const drawAuxiliary = SVG(svgAuxiliary);

  drawMain
    .clear()
    .viewbox(0, 0, 1456, 1150)
    .size(1275, 1000)
    .x(LeftXDataAssets)
    .y(TopYDataAssets)
    .id(DataAssetsSectionId);

  let currentY = 0;

  for (const [dataType, dataAssets] of textFields) {
    const textBoxNested = drawMain.nested();
    // const textBox = textBoxNested.svg(
    //   // x && y set to sensible defaults... designer will change if too long
    //   generateCopyTextSingleLine(dataAssets, 470, 20, 80)
    // );

    let lineWidth = 64;
    let wordsWithComputedWidth = calculateWordWidths(dataAssets);
    let lines = calculateLines(wordsWithComputedWidth, lineWidth);
    let fontSize = "23";

    var group = textBoxNested.group().translate(470, -40);
    let y = 68;
    let x = 13;
    let boxheight = 10;
    if (lines.length === 1) {
      let text = drawMain
        .text(function(add) {
          for (let i = 0; i < lines.length; i++) {
            add
              .tspan(lines[i])
              .x(x)
              .y(y + 30);
            boxheight = boxheight + 25;
          }
        })
        .font({
          family: fontsSVGLibrary.node.family,
          weight: 400,
          size: fontSize,
          fill: "#57585B",
        });
      group.add(text);
    } else {
      let text = drawMain
        .text(function(add) {
          for (let i = 0; i < lines.length; i++) {
            add
              .tspan(lines[i])
              .x(x)
              .y(y);
            y = y + 30;
            boxheight = boxheight + 25;
          }
        })
        .font({
          family: fontsSVGLibrary.node.family,
          weight: 400,
          size: fontSize,
          fill: "#57585B",
        });
      group.add(text);
    }

    const textBoxHeight = Math.max(boxheight, 85);

    textBoxNested
      .rect(460, textBoxHeight + 20)
      .fill({
        color: getDefaultDataTypeColorMap[dataType],
      })
      .rx(20);

    // textBoxNested.svg(
    //   `<text x="50" y="${16 + (textBoxHeight + 20) / 2}" ${
    //     fontsPlainSVG.dataAssetsDataTypeNode
    //   }>${getDefaultDataTypeDetailsMap[dataType].name}</text>`
    // );

    if (!R.isNil(dataTypes) && !R.isEmpty(dataTypes)) {
      let keys = [];
      for (let key in dataTypes) {
        let data = dataTypes[key];
        keys.push(data.datatype);
      }
      textBoxNested.svg(
        `<text x="50" y="${16 + (textBoxHeight + 20) / 2}" ${
          fontsPlainSVG.dataAssetsDataTypeNode
        }>${keys[dataType]}</text>`
      );
    } else {
      textBoxNested.svg(
        `<text x="50" y="${16 + (textBoxHeight + 20) / 2}" ${
          fontsPlainSVG.dataAssetsDataTypeNode
        }>${getDefaultDataTypeDetailsMap[dataType].name}</text>`
      );
    }

    textBoxNested.y(currentY);

    currentY += textBoxHeight + 20 + 30;
  }

  const result = drawMain.svg();
  drawMain.clear();
  drawAuxiliary.clear();

  return result;
}

type InputNodes = $NonMaybeType<$PropertyType<JSONSchema, "inputNodes">>;

export function sortInputNodesData(inputNodesData: InputNodes) {
  return R.compose(
    R.sortBy(R.view(R.lensIndex(0))),
    R.toPairs,
    R.mergeRight({
      "0": "",
      "1": "",
      "2": "",
    }),
    //$FlowFixMe
    R.lift(
      R.compose(
        R.join(","),
        R.reduce((acc, { dataAssets }) => [...acc, dataAssets], [])
      )
    ),
    R.groupBy(R.prop("dataType")),
    R.values
  )(inputNodesData);
}

export function generateDataAssets(
  inputNodesData: InputNodes,
  SVGMainId: string,
  SVGAuxId: string,
  dataTypes: any
): string {
  const textFields = sortInputNodesData(inputNodesData);

  return drawDataAssetsSection(SVGMainId, SVGAuxId, textFields, dataTypes);
}
